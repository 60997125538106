import React from 'react'
import './BenifitsOfCollabration.scss'
import { motion } from 'framer-motion';

const BenifitsOfCollabration = () => {
    
    const MotionContainer ={
        hidden: { opacity: 0.5, y: 200, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},

        hiddenContent: { opacity: 0.5, y: 250 },
        visibleContent: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1, }},
        
        hiddenBoximg: { y:30,  },
        visibleBoximg: { y:0,  
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenP2: { y: 35,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},
                    
        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},
        
    }    
  return (
    <section className='main-BOC'>
        <motion.div
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"
         className='BOC-head'>
            <motion.h1 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >Benefits of Collaboration</motion.h1>
            <motion.p  
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1"
            className='para1'>Purpose-Built Products with Business User Control</motion.p>
            <motion.p  
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP2"
                whileInView="visibleP2"
            className='para2'>WE DON'T JUST BUILD, <span className='orange_code'>WE PERFORM</span></motion.p>
        </motion.div>
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"
        className='BOC-box'>
            <div className='BOC-Card'>
                <motion.img loading='lazy' 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                
                src='/Images/DigitalEngineering/BOC1.svg' alt='Boc1' />
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1"                
                ><span className='orange_code'>High</span> Competence</motion.h1>
            </div>
            <div className='BOC-Card'>
                <motion.img loading='lazy' 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                
                src='/Images/DigitalEngineering/BOC2.svg' alt='Boc2' />
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1"                
                >Great <span className='orange_code'>Value </span> for Money</motion.h1>
            </div>
            <div className='BOC-Card'>
                <motion.img loading='lazy' 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                
                src='/Images/DigitalEngineering/BOC3.svg' alt='Boc3' />
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1"                
                >Exclusive<span className='orange_code'> Team </span> commitment</motion.h1>
            </div>
            <div className='BOC-Card'>
                <motion.img loading='lazy' 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                
                src='/Images/DigitalEngineering/BOC4.svg' alt='Boc4' />
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1"                
                >Custom<span className='orange_code'> Agile team </span> selection</motion.h1>
            </div>
            <div className='BOC-Card'>
                <motion.img loading='lazy' 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                
                src='/Images/DigitalEngineering/BOC5.svg' alt='Boc5' />
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1"                
                ><span className='orange_code'>Comprehensive  </span>Team Management</motion.h1>
            </div>
        </motion.div>
    </section>
  )
}

export default BenifitsOfCollabration