import React, { useEffect, useState } from 'react';
import "./DigitalEngineering.scss";
import { Helmet } from 'react-helmet';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { Breadcrumbs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { motion } from 'framer-motion';
import WhatMakesEvenionDifferent from '../../Components/DigitalEngineering/WhatMakesEvenionDifferent';
import PracticeAreas from '../../Components/DigitalEngineering/PracticeAreas';
import BenifitsOfCollabration from '../../Components/DigitalEngineering/BenifitsOfCollabration';
import DigiOurStack from '../../Components/DigitalEngineering/DigiOurStack';
import BrandAssociation from '../../Components/DigitalEngineering/BrandAssociation';
import ContactUsBtn from '../../Components/ContactUs-btn/ContactUsBtn';




const DigitalEngineering = () => {
    const [isLoading, setIsLoading] = useState(false);


    const handleLoadingComplete = () => {
        setIsLoading(true);
    };

    useEffect(() => {
        window.scrollTo(0, localStorage.getItem('position'));
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };
        scrollToTop();
        const onBeforeUnload = () => {
            window.scrollTo(0, 0);
        };
        window.addEventListener('beforeunload', onBeforeUnload);
    }, [])

    const MotionContainer = {
        hidden: { opacity: 0.5, y: 95 },
        visible: {
            opacity: 1, y: 0, scale: 1,
            transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }
        },

        hiddenContent: { opacity: 0.5, y: 95 },
        visibleContent: {
            opacity: 1, y: 0, scale: 1,
            transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }
        },

        hiddenVideo: { opacity: 0.5, y: 95 },
        visibleVideo: {
            opacity: 1, y: 0, scale: 1,
            transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }
        },

        hiddenImg: { y: 50, },
        visibleImg: {
            y: 0,
            transition: { duration: 1, delay: 0.25 }
        },

        hiddenImgM: { x: -20, y: 35, scale: 0.5 },
        visibleImgM: {
            x: 0, y: 0, scale: 1,
            transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }
        },

        hiddenH: { x: -225, y: 54, scale: 0.6 },
        visibleH: {
            x: 0, y: 0, scale: 1,
            transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }
        },

        hiddenHM: { x: -55, y: 15, scale: 0.7 },
        visibleHM: {
            x: 0, y: 0, scale: 1,
            transition: { duration: 0.55,  ease: [0, 0.71, 0.2, 1.01] }
        },

        hiddenvideoM: { y: 55, opacity: 0.5 },
        visiblevideoM: {
            y: 0, opacity: 1,
            transition: { duration: 0.50,  ease: [0, 0.71, 0.2, 1.01] }
        },


        hiddenCC: { y: 100, scale: 0.9, opacity: 0.5 },
        visibleCC: {
            y: 0, scale: 1, opacity: 1,
            transition: { duration: 1 }
        },

        hiddenPara: { y: 35, },
        visiblePara: {
            y: 0,
            transition: { duration: 0.75, }
        },

        hiddenImgg: { y: 30, },
        visibleImgg: {
            y: 0,
            transition: { duration: 0.75, }
        },

        hiddenHeading: { y: 40, },
        visibleHeading: {
            y: 0,
            transition: { duration: 0.75, }
        },

        hiddenCCC: { y: 90, opacity: 0.1 },
        visibleCCC: {
            y: 0, opacity: 1,
            transition: { duration: 1, }
        },

    }

    return (
        <div>
            <Helmet>
                <title> Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies</title>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies" />
                <meta name="description" content="Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies" />
                <meta property="description" content="Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies" />
                <meta property="og:description" content="Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies" />
                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies" />
                <meta name="twitter:description" content="Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies" />
                <meta name="twitter:image" content="Digital Engineering - Enable digital transformation with our domain expertise today for industry-leading digital engineering consulting services and digital engineering solutions. - Evenion Technologies" />
            </Helmet>


            {isLoading ?

                <>
                    <div className='Main_Digital' id='Main_Digital_Page'>
                        {/* <> */}
                            <section className='Digital_banner'>
                                <motion.img loading='lazy'
                                    className="blur"
                                    src="/Images/DigitalEngineering/Mask Group 233@2x.png" alt="about-us" />
                                <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{ top: 0 }}>
                                    <div className='breadcrumb_text'><a href="/">Home</a></div>
                                    <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Services</Typography>
                                    <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Testing-as -a-Service</Typography>
                                </Breadcrumbs>

                                <div className='inner_Digital'>
                                    <motion.div
                                        viewport={{ once: true }}
                                        variants={MotionContainer}
                                        initial="hidden"
                                        whileInView="visible"
                                        className='inner_Digital1'
                                    >
                                        <motion.h1
                                            viewport={{ once: true }}
                                            variants={MotionContainer}
                                            initial="hiddenH"
                                            whileInView="visibleH"
                                        >Digital Engineering</motion.h1>
                                        {/* <h2>Testing As A Service</h2> */}
                                        <p className='Pre-para1'>Empowering Tomorrow's  Digital World Today</p>
                                        <p className='Pre-para2'>We're dedicated to pushing boundaries and redefining what's achievable in the digital realm.</p>
                                        <div className="btn-container btn-container-tablet">
                                            <div className='btn-lii btn-lii-tablet'>
                                                <a
                                                    href="/contact-us#contact_form"><span className='btn-liil btn-liil-tablet'>Contact Us  &nbsp; <ArrowBackIcon className="rotatee rotatee-tablet" /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </motion.div>
                                </div>
                            </section>

                            <section className='Digital_banner_mobile'>
                                <motion.img loading='lazy' className="blur"
                                    src="/Images/DigitalEngineering/Mask Group 229@2x.png" alt="about-us" />
                                <div className='inner_Digital_mobile'>
                                    <motion.div
                                        viewport={{ once: true }}
                                        variants={MotionContainer}
                                        initial="hiddenvideoM"
                                        animate="visiblevideoM"
                                    >
                                        <motion.h1
                                            variants={MotionContainer}
                                            initial="hiddenHM"
                                            animate="visibleHM"
                                        >Digital Engineering</motion.h1>
                                        <p className='Pre-para1'>Empowering Tomorrow's<br></br> Digital World Today</p>
                                        <p className='Pre-para2'>We're dedicated to pushing boundaries and redefining what's achievable in the digital realm.</p>
                                        <div className="btn-stacke-mobile ">
                                            <a
                                                href="/contact-us#contact_form"><span className='btn-inner-mobile'>Contact Us  &nbsp;<ArrowBackIcon className="stacke-arrow-mobile" /></span>
                                            </a>
                                        </div>
                                    </motion.div>
                                </div>
                            </section>
                        {/* </> */}


                        <div className='Slide-Digital'>
                            <WhatMakesEvenionDifferent />
                            <div className='Bg-OurStacks-BA'>
                                <PracticeAreas />
                                <BenifitsOfCollabration />
                                <DigiOurStack />
                                <BrandAssociation />



                                <section className="Contact-Container"  >
                                    <div className="Contact-Container-inside  ">
                                        <a href="/contact-us#contact_form"><span className='Contact-Button  '>Contact Us &nbsp;
                                            <ArrowBackIcon className='Irotating ' style={{ fontSize: '7vw' }} />
                                        </span>
                                        </a>
                                    </div>
                                </section>

                                <section className="Contact-Container-tablet" style={{ backgroundColor: "#001220" }}>
                                    <div className="Contact-Container-inside-tablet  ">
                                        <a href="/contact-us#contact_form"><span className='Contact-Button-tablet'>Contact Us &nbsp;
                                            <ArrowBackIcon className='Irotating-tablet ' style={{ fontSize: '7vw' }} />
                                        </span>
                                        </a>
                                    </div>
                                </section>

                                <section className="Contact-Container-mobile" style={{ backgroundColor: "#001220" }}>
                                    <div className="Contact-Container-inside-mobile">
                                        <a href="/contact-us#contact_form"><span className='Contact-Button-mobile'>Contact Us &nbsp;
                                            <ArrowBackIcon className='Irotating-mobile' style={{ fontSize: '8vw' }} />
                                        </span>
                                        </a>
                                    </div>
                                </section>

                                {/* <ContactUsBtn /> */}
                            </div>




                        </div>







                    </div>
                </>
                : <LoadingSpinner onLoadingComplete={handleLoadingComplete} />}
        </div>
    )
}

export default DigitalEngineering;
