import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import "./sitemap.css";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const Sitemap = () => {
    const [blogList, setBlogList] = useState();
    const navigate = useNavigate();
    
    const getApply = async () => {
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`)
        setBlogList(data.data.data[0]);
    }

    useEffect(() => {
        getApply();
    }, [])

    const getSingleBlogs = (data) => {
        navigate({
            pathname: `/blogs/${data.slug.split(" ")
                .join("-").toLowerCase()}`,
            state: {
                data: data,
                blogList: blogList
            }
        });
    }

    return (
        <>
            <Helmet>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Digital transformation consulting and Services Company - Evenion Technologies" />
                <meta name="description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="og:description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="og:url" content="https://www.eveniontechnologies.com/home" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image"
                    content="https://www.eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <meta property="og:image:secure_url" content="https://www.eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Digital transformation consulting and Services Company - Evenion Technologies" />
                <meta name="twitter:description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta name="twitter:image" content="https://www.eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <script type="application/ld+json">
                    {`{
                        "@context": "Schema.org - Schema.org 
                        "@type": "Organization",
                        "name": "Evenion Technologies",
                        "url": "https://www.eveniontechnologies.com/", 
                        "logo": "https://www.eveniontechnologies.com/Images/logo/evenion_logo.png", 
                        "sameAs": [
                        "https://facebook.com/eveniontechnologies/",
                        "https://www.linkedin.com/company/evenion-technologies/",
                        "https://twitter.com/EvenionTech/"
                        ]
                    }`
                    }
                </script>
            </Helmet>
            <div className="breadcrumb_container">
                <div className="breadcrumb_content">
                    <div className="breadcrumb_links">
                        <div className="link"><a href="/">Home</a></div>
                        <div className="active_now">Sitemap</div>
                    </div>
                </div>
            </div>
            <Container fluid="xxl" className="p-0 m-0">
                <section className="page-content sitemap_container_main">
                    <div className="container-fluid contact-policy sitemap_container">
                        <div className="row p-0">
                            <div className="card w-100">
                                <div className="card-body ">
                                    <h1 className="align p-3">Sitemap </h1>
                                    <div className="row list_container">
                                        <div className="col-md-6">
                                            <ul> <a href="/">
                                                <h3 className="size">Home</h3></a>
                                            </ul>
                                            <ul className="sub_class_sitemap">
                                                <h3 className="size_1">Services</h3>
                                                <li>
                                                    <a href="/DigitalEngineering">
                                                        <h4 className="children">Digital Engineering
                                                        </h4>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/professional-services">
                                                        <h4 className="children">Professional Services
                                                        </h4>
                                                    </a>
                                                </li><li>
                                                    <a href="/analytics-services">
                                                        <h4 className="children">Analytic Services
                                                        </h4>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/taas-services">
                                                        <h4 className="children">TaaS
                                                        </h4>
                                                    </a>
                                                </li>
                                            </ul>

                                            <ul className="sub_class_sitemap">
                                                <h3 className="size_1">Products</h3>
                                                <li>
                                                    <a href="/stackerank">
                                                        <h4 className="children">StackéRank
                                                        </h4>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/datasensex">
                                                        <h4 className="children">DataSenseX
                                                        </h4>
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul className="sub_class_sitemap">
                                                <h3 className="size_1">Partners</h3>
                                                <li>
                                                    <a href="/precisely">
                                                        <h4 className="children">Precisely
                                                        </h4>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/flowize">
                                                        <h4 className="children">Flowize
                                                        </h4>
                                                    </a>
                                                </li>
                                            </ul>
                               
                                            <ul>
                                                <a href="/about-us">
                                                    <h3 className="size">About Us</h3>
                                                </a>
                                            </ul>
                                            <ul>
                                                <a href="/career">
                                                    <h3 className="size">Career</h3>
                                                </a>
                                            </ul>

                                            <ul>
                                                <a href="/contact-us">
                                                    <h3 className="size">Contact Us</h3>
                                                </a>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className='sub_class_sitemap'>
                                                <a href="/blogs">
                                                    <h3 className="size_1">Blogs</h3>
                                                </a>
                                                {blogList && blogList.map(ele => (
                                                    <>
                                                        {ele.status === 'Active' &&
                                                            <li className="children" onClick={() => getSingleBlogs(ele)} >{ele.title}</li>
                                                        }
                                                    </>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}

export default Sitemap;