import React from 'react'
import "./AboutPrecisely.scss"
import { motion } from 'framer-motion';

const AboutPrecisely = () => {

  const MotionContainer = {
    hidden: { y: 150, scale: 0.7, opacity: 0.1 },
    visible: { y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1 }},

    hiddenBox: { y: 100, scale: 0.8, opacity: 0.7 },
    visibleBox: { y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1 }},
    
    hiddenHead: { y: 35, scale: 0.95, },
    visibleHead: { y: 0, scale: 1,
    transition:{ duration: 1, }},
    
    hiddenPara: { y: 35,  },
    visiblePara: { y: 0 ,
    transition:{ duration: 0.75,  }},

    hiddenHeading: { y: 40,  },
    visibleHeading: { y: 0, 
    transition:{ duration: 0.75,  }},
    
    hiddenImgg: { y: 20,  },
    visibleImgg: { y: 0 ,
    transition:{ duration: 0.75,  }},

}    
  return (
    <>
    <section className='AboutPrecisely'>
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
          whileInView="visible"  
        className='Precise-Head'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenHead"
            whileInView="visibleHead"   
            >About Precisely</motion.h1>
        </motion.div>

        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenBox"
            whileInView="visibleBox"  
        className='Precise-Box1'>
            <div className='Precise-content1'>
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenHeading"
                    whileInView="visibleHeading"           
                >Build your possibilities on data you can trust</motion.h1>
                <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"    
                className='content-pre1'>
                Precisely helps you to achieve data integrity by ensuring the accuracy, consistency and context of your data. This means you can make better, faster, more confident decisions based on a deeper understanding of data you can trust.
                </motion.p>
                <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"   
                className='content-pre1'>
                Precisely's vision is to provide a unique combination of software, data enrichment and strategic services.        </motion.p>
            </div>
            <div className='Precise-img1'>
                <img loading='lazy' src="Images/precisely\Mask Group 109@2x.png" alt="" />
            </div>
        </motion.div>


        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenBox"
          whileInView="visibleBox"  
        className='Precise-Box2'>
            <div className='Precise-img2'>
                <img loading='lazy' src="Images/precisely/Group 3709@2x.png" alt="" />
            </div>
            <div className='Precise-content2'>
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenHeading"
                    whileInView="visibleHeading"   
                >Data Integrity Suite</motion.h1>
                <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"   
                className='content-pre1'>With its flexible, modular approach, can meet your needs no matter where you are on your journey to data integrity. This leaves you to focus on what<br/> matters most – building new possibilities.</motion.p>
            </div>
        </motion.div>

        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenBox"
          whileInView="visibleBox"  
        className='Precise-Box3'>
                <div className='Precise-content3'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenHeading"
                        whileInView="visibleHeading" 
                    >Automate Studio</motion.h1>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"      
                    className='content-pre3'>
                    Premier Excel-to-SAP solutions platform that enables business users to manage SAP data in Excel for tasks like mass data changes and updates            </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"                      
                    className='content-pre3'><span className='lii'></span> Add new data in SAP (materials, vendors, customers, etc.) or make changes (journal entries, invoices, etc.). </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"    
                    className='content-pre3'><span className='lii'></span> Modify large data sets across SAP using Excel, like material updates, pricing changes, and account hierarchies. </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre3'><span className='lii'></span> Move data into SAP from old systems, during M&A shifts, or upgrading to SAP S/4HANA.  </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre3'><span className='lii'></span> Transfer SAP data to Excel for on-the-fly reports, changes, or sharing with other systems</motion.p>
                </div>
                <div className='Precise-img3'>
                    <img loading='lazy' src="Images/precisely/Group 3710.svg" alt="" />
                </div>
        </motion.div>


        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenBox"
          whileInView="visibleBox"  
        className='Precise-Box4'>
                <div className='Precise-img4'>
                    <img loading='lazy' src="Images/precisely/Group 3708.svg" alt="" />
                </div> 
                <div className='Precise-content4 round'>
                    <h1>Automate Evolve</h1>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4'>The exclusive solution to digitize and automate intricate SAP data tasks in your SAP ecosystem – including product launches, materials, customer/vendor management, G/L account handling, and <br/> SAP plant maintenance</motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4 '><span className='lii'></span> Create adaptable processes for complex situations and stay current in a changing business environment.</motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4 '><span className='lii'></span> Ensure accurate data from the start and maintain its quality using a wide array of data management tools </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4 '><span className='lii'></span>Quickly achieve valuable results with comprehensive SAP features that surpass basic data integration. </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4 '><span className='lii'></span> Drive significant outcomes efficiently and enhance agility using business-oriented software designed for scale.</motion.p>
                </div>

        </motion.div>
    </section>



{/* // Mobile */}
<section className='AboutPrecisely-mobile'>
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"  
        className='Precise-Head-mobile'>
            <motion.h1
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenHead"
                whileInView="visibleHead"   
            >About Precisely</motion.h1>
        </motion.div>

        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenBox"
            whileInView="visibleBox"          
        className='Precise-Box1-mobile'>
            <motion.div 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenImgg"
                whileInView="visibleImgg"  
            className='Precise-img1-mobile'>
                <img loading='lazy' src="Images/precisely\Mask Group 109@2x.png" alt="" />
            </motion.div>
            <div className='Precise-content1-mobile'>
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenHead"
                    whileInView="visibleHead"                   
                >Build your possibilities on data you can trust</motion.h1>
                <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"                  
                className='content-pre1'>
                Precisely helps you to achieve data integrity by ensuring the accuracy, consistency and context of your data. This means you can make better, faster, more confident decisions based on a deeper understanding of data you can trust.
                {/* </p>
                <p className='content-pre1'> */}
                Precisely's vision is to provide a unique combination of software, data enrichment and strategic services.        </motion.p>
            </div>
    
        </motion.div>


        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenBox"
            whileInView="visibleBox"  
        className='Precise-Box2-mobile'>
            <motion.div 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenImgg"
                whileInView="visibleImgg"              
            className='Precise-img2-mobile'>
                <img loading='lazy' src="Images/precisely/Group 3709@2x.png" alt="" />
            </motion.div>
            <div className='Precise-content2-mobile'>
                <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenHead"
                    whileInView="visibleHead"   
                >Data Integrity Suite</motion.h1>
                <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"                   
                className='content-pre1'>With its flexible, modular approach, can meet your needs no matter where you are on your journey to data integrity. This leaves you to focus on what matters most – building new possibilities.</motion.p>
            </div>
        </motion.div>



        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenBox"
            whileInView="visibleBox"          
        className='Precise-Box3-mobile'>
                <motion.div
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenImgg"
                    whileInView="visibleImgg"  
                className='Precise-img3-mobile'>
                    <img loading='lazy' src="Images/precisely/Group 3710.svg" alt="" />
                </motion.div>
                <div className='Precise-content3-mobile'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenHead"
                        whileInView="visibleHead"       
                    >Automate Studio</motion.h1>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre3-mobile '>
                    Premier Excel-to-SAP solutions platform that enables business users to manage SAP data in Excel for tasks like mass data changes and updates            </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre3-mobile'><span className='lii'></span> Add new data in SAP (materials, vendors, customers, etc.) or make changes (journal entries, invoices, etc.). </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre3-mobile'><span className='lii'></span> Modify large data sets across SAP using Excel, like material updates, pricing changes, and account hierarchies. </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre3-mobile'><span className='lii'></span> Move data into SAP from old systems, during M&A shifts, or upgrading to SAP S/4HANA.  </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre3-mobile'><span className='lii'></span> Transfer SAP data to Excel for on-the-fly reports, changes, or sharing with other systems</motion.p>
                </div>

        </motion.div>


        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenBox"
            whileInView="visibleBox"          
        className='Precise-Box4-mobile'>
                <motion.div 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenImgg"
                    whileInView="visibleImgg"  
                className='Precise-img4-mobile'>
                    <img loading='lazy' src="Images/precisely/Group 3708.svg" alt="" />
                </motion.div> 
                <div className='Precise-content4-mobile'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenHead"
                        whileInView="visibleHead"                   
                    >Automate Evolve</motion.h1>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4-mobile'>The exclusive solution to digitize and automate intricate SAP data tasks in your SAP ecosystem – including product launches, materials, customer/vendor management, G/L account handling, and SAP plant maintenance</motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4-mobile'><span className='lii'></span> Create adaptable processes for complex situations and stay current in a changing business environment.</motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4-mobile'><span className='lii'></span> Ensure accurate data from the start and maintain its quality using a wide array of data management tools </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4-mobile'><span className='lii'></span> Quickly achieve valuable results with comprehensive SAP features that surpass basic data integration. </motion.p>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPara"
                        whileInView="visiblePara"  
                    className='content-pre4-mobile'><span className='lii'></span> Drive significant outcomes efficiently and enhance agility using business-oriented software designed for scale.</motion.p>
                </div>

        </motion.div>
    </section>

    </>
  )
}

export default AboutPrecisely