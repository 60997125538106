import React, { useEffect, useState, useRef } from 'react';
import './AboutUs.scss';
import { Helmet } from 'react-helmet';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion,    useInView,  } from 'framer-motion';
import OurApproach from './OurApproach';
import OurStories from './OurStories';
import Leadership from './Leadership';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OurTeam from './OurTeam';
import { SetData, getData } from '../../util';
import { Breadcrumbs,Typography } from '@mui/material';


const AboutUs = () => {
    const ref = useRef(null)
    // const isInView = useInView(ref, {once: false});

    const [isLoading, setIsLoading] = useState(false);
    const handleLoadingComplete = () => {
        setIsLoading(true);
      };

    useEffect(() => {
        // setIsLoading(true);
        window.scrollTo(0, localStorage.getItem('position'));
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
        };
        scrollToTop();
        const onBeforeUnload = () => {
            window.scrollTo(0, 0);
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        SetData("aboutUS");
        localStorage.setItem("tab", 'AboutUs')

},[])



    return (
        <div>
            <Helmet>
                <title>We Are Passionate About What We Do - Helping Organizations with their Digital Vision - AboutUs</title>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="We Are Passionate About What We Do - Helping Organizations with their Digital Vision - AboutUs" />
                <meta property="og:description" content="We Are Passionate About What We Do - Helping Organizations with their Digital Vision - AboutUs" />
                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="We Are Passionate About What We Do - Helping Organizations with their Digital Vision - AboutUs" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="We Are Passionate About What We Do - Helping Organizations with their Digital Vision - AboutUs" />
                <meta name="twitter:description" content="We Are Passionate About What We Do - Helping Organizations with their Digital Vision" />
                <meta name="twitter:image" content="We Are Passionate About What We Do - Helping Organizations with their Digital Vision - AboutUs" />
            </Helmet>
            {isLoading ? 
                <>
                <div className='main-Class' id="main-class-about">

            
                    <section 
                        className="framer-about-us-main-section framer-about-us-main-section-tablet">
                            <motion.img
                                className="blur "
                                src="/Images/new/about-us-banner-New.png" alt="about-us" />
                                 <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
                                    <div className='breadcrumb_text'><a href="/">Home</a></div>
                                    <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >About Us</Typography>
                                    </Breadcrumbs>
                            <div  className='framer-about-us-insideContent framer-about-us-insideContent-tablet'>
                                <motion.div
                                    style={{
                                        width: "100%",
                                    }}
                                    variants={{
                                        hidden: { opacity: 0.5, y: 85,  },
                                        visible: { opacity: 1, y: 0, }
                                    }}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}}
                                >
                                    <motion.h1
                                        className='blur-h'
                                        variants={{
                                            hidden: { x: -274, y:72, scale: 0.4},
                                            visible: { x: 0, y: 0, scale: 1 }
                                        }}
                                        initial="hidden"
                                        animate="visible"
                                        transition={{ duration: 0.5, delay: 0.25 ,ease: [0, 0.71, 0.2, 1.01]}}
                                    >About Us</motion.h1>
                                    <p className='f-para1'>We are passionate about what we do</p>
                                    <p className='f-para2'>Helping Organizations with their Digital Vision</p>
                                    <div className="btn-container btn-container-tablet">
                                        <div className='btn-lii btn-lii-tablet'>
                                            <a
                                                href="/contact-us#contact_form"><span className='btn-liil btn-liil-tablet'>Contact Us  &nbsp; <ArrowBackIcon className="rotatee rotatee-tablet"/></span>
                                            </a>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </section>

                        {/* mobile */}
                        <section 
                        className="framer-about-us-main-section-mobile ">
                            {window.innerWidth>480 ?
                                <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new'>
                                <div className='breadcrumb_text'><a href="/">Home</a></div>
                                <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' className='breadfont' >About Us</Typography>
                                </Breadcrumbs>
                            : "" }
                            <motion.img
                                className="blur "
                                // src="Images/new/about_us_mobile.png" alt="about-us" />
                                // src="Images/new/about_us_mob.png" alt="about-us" />
                                src="Images/new/about_us_mob11.png" alt="about-us" />
                            <div  className='framer-about-us-insideContent-mobile'>
                                <motion.div
                                    style={{
                                        width: "100%",
                                    }}
                                    variants={{
                                        hidden: { opacity: 0.5, y: 85,  },
                                        visible: { opacity: 1, y: 0, }
                                    }}
                                    initial="hidden"
                                    animate="visible"
                                    transition={{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}}
                                >
                                    <motion.h1
                                        className='blur-h'
                                        variants={{
                                            hidden: { x: -274, y:72, scale: 0.4},
                                            visible: { x: 0, y: 0, scale: 1 }
                                        }}
                                        initial="hidden"
                                        animate="visible"
                                        transition={{ duration: 0.5, delay: 0.25 ,ease: [0, 0.71, 0.2, 1.01]}}
                                    >About Us</motion.h1>
                                    <p className='f-para1'>We are passionate about <br></br> what we do</p>
                                    <p className='f-para2'>Helping Organizations with their Digital Vision</p>

                                    {/* <div className=" btn-container-mobile ">
                                        <div className='btn-lii-mobile'>
                                            <a
                                                href="/contact-us#contact_form"><span className='btn-liil-mobile'>Contact Us  &nbsp;<ArrowBackIcon className="rotatee-mobile "/></span>
                                            </a>
                                        </div>
                                    </div> */}
                                                <div className="btn-stacke-mobile">
                    <a
                        href="/contact-us#contact_form"><span className='btn-inner-mobile'>Contact Us  &nbsp;<ArrowBackIcon className="stacke-arrow-mobile"/></span>
                    </a>
            </div>
                                </motion.div>
                            </div>
                        </section>

<div  ref={ref} className="main_container1"  >
            <OurApproach />
            <OurStories style={{overflow: "hidden"}} />
            <Leadership />
            <OurTeam />
            <motion.section
            viewport={{ref, once:true}}
            variants={{
                hidden: { y: 200, opacity: 0.8, scale: 0.8},
                visible: { y: 0, opacity: 1, scale: 1},
            }}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 1, delay: 0.10,}} 
            className='We-Evenion-Container'>
                <motion.h1
                viewport={{ref, once:false}}
                variants={{
                    hidden: { y: 60, opacity: 0.9 , scale:0.95},
                    visible: { y: 0, opacity: 1 , scale:1},
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.25,}} 
                >We're Evenion</motion.h1>
                <motion.p 
                viewport={{ref, once:false}}
                variants={{
                    hidden: { y: 40, opacity: 0.8 },
                    visible: { y: 0, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, }} 
                className='We-Evenion-pa'>As Individuals, <span className='orange_code'>Genuine</span></motion.p>
                <motion.p 
                viewport={{ref, once:false}}
                variants={{
                    hidden: { y: 40, opacity: 0.8 },
                    visible: { y: 0, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, }} 
                className='We-Evenion-pa '>Together, We Are Even More <span className='orange_code'>Authentic</span></motion.p>
            </motion.section>
{/* mobile */}
            <section className=' We-Evenion-Container-mobile'>
                <motion.h1
                viewport={{ref, once:true}}
                variants={{
                    hidden: { y: 85, opacity: 0.6 , scale:1.3},
                    visible: { y: 0, opacity: 1 , scale:1},
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.25,}} 
                >We're Evenion</motion.h1>
                <motion.p 
                viewport={{ref, once:false}}
                variants={{
                    hidden: { y: 30, opacity: 0.8 },
                    visible: { y: 0, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, }} 
                className='We-Evenion-pa-mobile'>As Individuals, <span className='orange_code'>Genuine</span></motion.p>
                <motion.p 
                viewport={{ref, once:false}}
                variants={{
                    hidden: { y: 30, opacity: 0.8 },
                    visible: { y: 0, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, }} 
                className='We-Evenion-pa-mobile'>Together, We Are Even More <span className='orange_code'>Authentic</span></motion.p>
            </section>



            <section style={{overflow: "hidden"}} className = 'We-Evenion-Container-tablet'>
            <motion.div
                viewport={{ref, once:false}}
                variants={{
                    hidden: { x: '-40vw', opacity: 0.2 },
                    visible: { x: 0, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, }} 
                className='Img-Wrapper' >
                    <div className='Evenion-Img8'> </div>
                    <div className='Evenion-Img1'> </div>
                    <div className='Evenion-Img3'> </div>
                    <div className='Evenion-Img5'> </div>
                    <div className='Evenion-Img4'> </div>
                </motion.div>
                <motion.div 
                viewport={{ref, once:false}}
                variants={{
                    hidden: { x: '40vw', opacity: 0.2 },
                    visible: { x: 0, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, }} 
                className='Img-Wrapper2'>
                    <div className='Evenion-Img6'> </div>
                    <div className='Evenion-Img7'> </div>
                    <div className='Evenion-Img15'> </div>
                    <div className='Evenion-Img9'> </div>
                    <div className='Evenion-Img2'> </div>
                </motion.div>
                
            </section>

            <section className="Contact-Container">
                <div className="Contact-Container-inside  ">
                    <a href="/contact-us#contact_form"><span className='Contact-Button  '>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating ' style={{ fontSize: '7vw'}}/>
                    </span>
                    </a>
                </div>
            </section>

            <section className="Contact-Container-tablet">
                <div className="Contact-Container-inside-tablet  ">
                    <a href="/contact-us#contact_form"><span className='Contact-Button-tablet'>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating-tablet ' style={{ fontSize: '7vw'}}/>
                    </span>
                    </a>
                </div>
            </section>

            <section className="Contact-Container-mobile">
                <div className="Contact-Container-inside-mobile">
                    <a href="/contact-us#contact_form"><span className='Contact-Button-mobile'>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating-mobile' style={{ fontSize: '8vw'}}/>
                    </span>
                    </a>
                </div>
            </section>


                    </div>   
            </div>       
                </>
              : <LoadingSpinner onLoadingComplete={handleLoadingComplete}/>     }
        </div >
    )
}
export default AboutUs;
