import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./GetInTouchProduct.scss";
import { Button, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import getuseForm from './getFormProduct.js';
import getvalidate from './getValidateInfoProduct';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Lottie from 'react-lottie';
import * as mailAnimation from '../../jsonFiles/mail_send.json'


const GetInTouch = (props) => {

    var regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    var reg = /^[a-zA-Z\s]*$/;
    var phoneNumberRegex2 = /^[\d+\s()-]+$/;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [errors, setErrors] = useState({});
    const { handleChange, values } = getuseForm(getvalidate);

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: mailAnimation,
        // rendererSettings: {
        //   preserveAspectRatio: 'xMidYMid slice'
        // }
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(getvalidate(values));

if (values.name !== '' && reg.test(values.name)  && values.email !== '' && regex.test(values.email) && values.phone !== '' && phoneNumberRegex2.test(values.phone) && values.phone.length >5 && values.phone.length <20 && values.message !== '' && isNaN(values.message)) {

            const getData = async () => {
                let url = `${process.env.REACT_APP_API_URL}/contactus`;
                await 
                axios.post(url, {
                    name: values.name,
                    email: values.email,
                    phone_number: values.phone,
                    message: values.message,
                    from: 'Stackerank'
                })
                    .then(response => {
                        if (response) {
                            setModal(true);
                            handleReset();
                        }
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            }
            getData();

        }
    }
    const handleReset = (e) => {
        values.name = "";
        values.email = "";
        values.phone = "";
        values.message = "";
    }
    

    return (
        <>
<section id="contact_form_product"  className='stackeRank-Form'>
            <div className='stackeRank-Form-left'>
            {/* <h1 className='stackeRank-H'>Stackèrank</h1> */}
            <img loading='lazy' src="Images/Stakerank/gradient_logo.svg" alt="" />
            <p className='stackeRank-p'>We'd love to<br/>hear from you</p>
            <p className='stackeRank-p-Mobile'>We'd love to hear from you</p>
            </div>
            <div className='stackeRank-Form-right'>
<div class="login-box login-box-tablet">
    <form onSubmit={handleSubmit}>
    <div class="user-box user-box-tablet">
        <input 
        type="text" 
        name="name" 
        placeholder='Name' 
        className='input-color' 
        autoComplete='off'
        value={values.name}
        onChange={handleChange} /><br />
        {errors.name && <p className="error_Product">{errors.name}</p>}
    </div>
    <div class="user-box user-box-tablet">
        <input 
        type="text"
        name="email"
        placeholder='Email' 
        className='input-color'          
        autoComplete='off'
        value={values.email}
        onChange={handleChange} /><br />
        {errors.email && <p className="error_Product">{errors.email}</p>}
    </div>
    <div class="user-box user-box-tablet">
        <input
        type="text"
        name="phone"
        placeholder='Phone No' 
        className='input-color'
        autoComplete='new-password'
        value={values.phone}
        onChange={handleChange} /><br />
        {errors.phone && <p className="error_Product">{errors.phone}</p>}
    </div>
        
        <div class="user-boxx user-boxx-tablet">
        <textarea 
        type="text"
        name="message"
        placeholder='Message' 
        className='input-color'   
        autoComplete='off'
        value={values.message}
        onChange={handleChange} />
        
        <br />
        {errors.message && <p className="error_Product">{errors.message}</p>}
    </div>
        <button type="submit" className='send-btn'>Connect for Demo &nbsp;<ArrowBackIcon className='rotate-arrow'/> </button>
        <button type="submit" className='send-btn-tablet'>Connect for Demo &nbsp;<ArrowBackIcon className='rotate-arrow-tablet'/> </button>
    </form>
</div>


<div class="login-box-mobile">
    <form onSubmit={handleSubmit}>
    <div class="user-box user-box-mobile">
        <input 
        type="text" 
        name="name" 
        placeholder='Name' 
        className='input-color' 
        autoComplete='off'
        value={values.name}
        onChange={handleChange} /><br />
        {errors.name && <p className="error_Product">{errors.name}</p>}
    </div>
    <div class="user-box-mobile">
        <input 
        type="text"
        name="email"
        placeholder='Email' 
        className='input-color'          
        autoComplete='off'
        value={values.email}
        onChange={handleChange} /><br />
        {errors.email && <p className="error_Product">{errors.email}</p>}
    </div>
    <div class="user-box-mobile">
        <input
        type="text"
        name="phone"
        placeholder='Phone No' 
        className='input-color'
        autoComplete='new-password'
        value={values.phone}
        onChange={handleChange} /><br />
        {errors.phone && <p className="error_Product">{errors.phone}</p>}
    </div>
        
        <div class="user-boxx-mobile">
        <textarea 
        type="text"
        name="message"
        placeholder='Message' 
        className='input-color'   
        autoComplete='off'
        value={values.message}
        onChange={handleChange} />
        
        <br />
        {errors.message && <p className="error_Product">{errors.message}</p>}
    </div>
        <button type="submit" className='send-btn'>Connect for Demo &nbsp;<ArrowBackIcon className='rotate-arrow'/> </button>
        <button type="submit" className='send-btn-tablet'>Connect for Demo &nbsp;<ArrowBackIcon className='rotate-arrow-tablet'/> </button>
    </form>
    <Modal isOpen={modal}   className='modal-contentPro '>
                <ModalBody >
                    <h5 className="text-center bg-color my-4">Thank you for contacting us!</h5>
                    <div className='gif-set'>
                    {/* <img loading='lazy' src="Images/Stakerank/mail send (2).gif" alt="" /> */}
                    <Lottie options={defaultOptions}/>
                    </div>
                    <p className="mb-4 text-center model_patra">Our Team will reach you shortly!
                    </p>
                    <Button color="btn-white" className="modal_close" onClick={toggle}>Close  </Button>
                </ModalBody>
            </Modal>
</div>
</div>

</section>
        </>

)
}

export default GetInTouch;
