import React from 'react'
import './DigitalTransformation.scss';
import { motion } from 'framer-motion';
import YourComponent from './YourComponent';

const DigitalTransformation = () => {
  const MotionContainer ={
    hidden: { opacity: 0.5, y: 200, scale: 0.98, },
    visible: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50,   delay: 0.15,}},
    
    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, delay: 0.15,}},
    
    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenP1: { y: 35,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, delay: 0.15,}},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    hiddenP2: { y: 20,  opacity: 0.9 },
    visibleP2: { y: 0, opacity: 1,
    transition:{ duration: 0.50, delay: 0.15,}},

    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.15, }},
    
}    

  return (
    <>
    <section className='main-digiTrans'>
        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hidden"
          whileInView="visible"
        className='head-DigiTran'>
            <motion.p 
                      viewport={{  once: false}}
                      variants={MotionContainer}
                      initial="hiddenP1"
                      whileInView="visibleP1"
            className='para1'>Empower Your</motion.p>
            <motion.h1
                      viewport={{  once: false}}
                      variants={MotionContainer}
                      initial="hiddenH1"
                      whileInView="visibleH1"
            className='head1'>Digital Transformation</motion.h1>
            <motion.p
                      viewport={{  once: false}} 
                      variants={MotionContainer}
                      initial="hiddenP2"
                      whileInView="visibleP2"
            className='para2'>Elevate your business with our innovative solutions tailored for diverse industries, driven by expertise, agility, and cutting-edge technology</motion.p>
        </motion.div>


{/* desktop container */}
        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hidden"
          whileInView="visible"
        className='Container-DigiTran'>
          <div className='DigiBox'>
              <div className='img-bg'>
                <img      src="/Images/Home/product engineerinv@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o1 </span> <br></br>Product <br></br> Engineering</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Our specialized team in product engineering is dedicated to transforming your concepts into high-performance, market-ready solutions, ensuring innovation and efficiency at every stage of development.</motion.p>
              </div>
          </div>

          <div className='DigiBox'>
              <div className='img-bg'>
                <img      src="/Images/Home/financial@2x.png" alt='default02'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o2 </span> <br></br>Financial  <br></br> Services</motion.h1>
                <motion.p
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1"
                >Navigate the complex landscape of financial services with our tailored solutions, designed to enhance operational efficiency, compliance, and customer satisfaction, ensuring your success in the ever-evolving industry.</motion.p>
              </div>
          </div>

          <div className='DigiBox'>
              <div className='img-bg'>
                <img      src="/Images/Home/edutech@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o3 </span> <br></br>Edu-Tech</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Empowering education through cutting-edge solutions, our skilled team crafts transformative products—from intuitive learning management systems to interactive educational apps. We leverage technology to enhance the teaching and learning experience for both institutions and students</motion.p>
              </div>
          </div>


          <div className='DigiBox'>
              <div className='img-bg'>
                <img      src="/Images/Home/insurance@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o4</span> <br></br>Insurance</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Revolutionizing the insurance sector, we specialize in cutting-edge technologies and secure applications that redefine industry processes. Our comprehensive solutions ensure clients enjoy enhanced security, efficiency, and reliability in today's dynamic digital landscape.</motion.p>
              </div>
          </div>


          <div className='DigiBox'>
              <div className='img-bg'>
                <img      src="/Images/Home/logistics@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o5 </span> <br></br>Logistics</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Optimize your supply chain and logistics operations with our solutions, leveraging state-of-the-art technology to improve efficiency, reduce costs, and enhance visibility across the entire logistics ecosystem.</motion.p>
              </div>
          </div>


          <div className='DigiBox'>
              <div className='img-bg'>
                <img      src="/Images/Home/saas@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o6 </span> <br></br> SaaS <span className='saasClass'>(Software as a Service)</span> </motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Embrace the future of software delivery with our SaaS solutions, offering scalable, flexible, and user-friendly platforms that empower businesses to stay agile, responsive, and competitive in the rapidly evolving digital landscape.</motion.p>
              </div>
          </div>



        </motion.div>

        {/* Tablet */}
        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hidden"
          whileInView="visible"
        className='DigiTab-Container'>


          <div className='DigiTab'>
            <div className='img-bg'>
                <img      src="/Images/Home/product engineerinv@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o1 </span> <br></br>Product Engineering</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Our specialized team in product engineering is dedicated to transforming your concepts into high-performance, market-ready solutions, ensuring innovation and efficiency at every stage of development.</motion.p>
              </div>
          </div>


          <div className='DigiTab'>
          <div className='img-bg'>
                <img      src="/Images/Home/financial@2x.png" alt='default02'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o2 </span> <br></br>Financial   Services</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Navigate the complex landscape of financial services with our tailored solutions, designed to enhance operational efficiency, compliance, and customer satisfaction, ensuring your success in the ever-evolving industry.</motion.p>
              </div>
          </div>


          <div className='DigiTab'>
          <div className='img-bg'>
                <img      src="/Images/Home/edutech@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o3 </span> <br></br>Edu-Tech</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Empowering education through cutting-edge solutions, our skilled team crafts transformative products—from intuitive learning management systems to interactive educational apps. We leverage technology to enhance the teaching and learning experience for both institutions and students</motion.p>
              </div>
          </div>


          <div className='DigiTab'>
          <div className='img-bg'>
                <img      src="/Images/Home/insurance@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o4</span> <br></br>Insurance</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Revolutionizing the insurance sector, we specialize in cutting-edge technologies and secure applications that redefine industry processes. Our comprehensive solutions ensure clients enjoy enhanced security, efficiency, and reliability in today's dynamic digital landscape.</motion.p>
              </div>
          </div>


          <div className='DigiTab'>
          <div className='img-bg'>
                <img      src="/Images/Home/logistics@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o5 </span> <br></br>Logistics</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Optimize your supply chain and logistics operations with our solutions, leveraging state-of-the-art technology to improve efficiency, reduce costs, and enhance visibility across the entire logistics ecosystem.</motion.p>
              </div>
          </div>


          <div className='DigiTab'>
          <div className='img-bg'>
                <img      src="/Images/Home/saas@2x.png" alt='default01'/>
              </div>
              <div className='content-bg'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                > <span className='numClass'> o6 </span> <br></br> SaaS <span className='saasClass'>(Software as a Service)</span> </motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Embrace the future of software delivery with our SaaS solutions, offering scalable, flexible, and user-friendly platforms that empower businesses to stay agile, responsive, and competitive in the rapidly evolving digital landscape.</motion.p>
              </div>
          </div>


        </motion.div>


    </section>


{/* // Mobile */}
<section className='Main-DigiTrans-Mobile'> 
    <motion.div 
              viewport={{  once: true}}
              variants={MotionContainer}
              initial="hiddenMob"
              whileInView="visibleMob"
    className='Mobile-digiC'> 
        <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
        className='para1'>Empower Your</motion.p>
        <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
        >Digital <br></br>Transformation</motion.h1>
        <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
        className='para2'>Elevate your business with our innovative solutions tailored for diverse industries, driven by expertise, agility, and cutting-edge technology</motion.p>
    </motion.div>

    <motion.div 
                  viewport={{  once: true}}
                  variants={MotionContainer}
                  initial="hiddenMob"
                  whileInView="visibleMob"
    className='Mobile-DigiBox'>

      <YourComponent
        imageUrl="/Images/Home/product engineerinv@2x.png"
        heading1="Product"
        heading2="Engineering "
        nummm="01"
        paragraph="Our specialized team in product engineering is dedicated to transforming your concepts into high-performance, market-ready solutions, ensuring innovation and efficiency at every stage of development."
      />



      <YourComponent
        imageUrl="/Images/Home/financial@2x.png"
        heading1="Financial"
        heading2="Services"
        nummm="02"
        paragraph="Navigate the complex landscape of financial services with our tailored solutions, designed to enhance operational efficiency, compliance, and customer satisfaction, ensuring your success in the ever-evolving industry."
      />



      <YourComponent
        imageUrl="/Images/Home/edutech@2x.png"
        heading1="Edu-Tech"
        heading2=""
        nummm="03"
        paragraph="Empowering education through cutting-edge solutions, our skilled team crafts transformative products—from intuitive learning management systems to interactive educational apps. We leverage technology to enhance the teaching and learning experience for both institutions and students"
      />



      <YourComponent
        imageUrl="/Images/Home/insurance@2x.png"
        heading1="Insurance"
        heading2=""
        nummm="04"
        paragraph="Revolutionizing the insurance sector, we specialize in cutting-edge technologies and secure applications that redefine industry processes. Our comprehensive solutions ensure clients enjoy enhanced security, efficiency, and reliability in today's dynamic digital landscape."
      />



      <YourComponent
        imageUrl="/Images/Home/logistics@2x.png"
        heading1="Logistics"
        heading2=""
        nummm="05"
        paragraph="Optimize your supply chain and logistics operations with our solutions, leveraging state-of-the-art technology to improve efficiency, reduce costs, and enhance visibility across the entire logistics ecosystem."
      />



      <YourComponent
        imageUrl="/Images/Home/saas@2x.png"
        heading1="SaaS"
        heading2=""
        nummm="06"
        paragraph="Embrace the future of software delivery with our SaaS solutions, offering scalable, flexible, and user-friendly platforms that empower businesses to stay agile, responsive, and competitive in the rapidly evolving digital landscape."
      />

      


    </motion.div>
</section>



    </>
  )
}

export default DigitalTransformation