import React from 'react'
import './WhatWeOffer.scss'
import { motion } from 'framer-motion';

const WhatWeOffer = () => {
    
    const MotionContainer ={
        hidden: { opacity: 0.5, y: 300, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},

        hiddenContent: { opacity: 0.5, y: 150 },
        visibleContent: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1, }},
        
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenP2: { y: 35,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenB1: { y: 25,  opacity: 0.9 },
        visibleB1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenB2: { y: 25,  opacity: 0.9 },
        visibleB2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
           
        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},
            
        hiddenMobimg: { y:35,  opacity: 0.5},
        visibleMobimg: { y:0,  opacity: 1,
        transition:{ duration: 0.55, delay: 0.10,}},

        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

        hiddenMobP2: { y: 20,  opacity: 0.9 },
        visibleMobP2: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},
    }    
  return (
    <>
    <section className='WhatWeOffer-main'>
    <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hidden"
                    whileInView="visible"
        className='WhatWeOffer-content'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >What we offer?</motion.h1><br/>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
            className='para1'>Our Testing as a Service (TaaS) solutions encompass a comprehensive range of testing specialties, ensuring your software meets the highest quality standards and performance benchmark</motion.p>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
            className='para2'><span className='orange_code'>ELEVATING</span> TESTING <span className='orange_code'>EXCELLENCE</span> </motion.p>
        </motion.div>

        <div className='WhatWeOffer-OutsideBox'>
        <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent"
            className='InsideBox1'>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/15.svg" alt="default-img"/>
                    </div>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1"
                    >Product Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"
                    >Ensure your products meet quality standards and perform as expected</motion.p> 
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/16.svg" alt="default-img"/>
                    </div>
                    <motion.h1 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1"                    
                    >Functional Testing</motion.h1>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"                    
                    >Verify that your applications meet specified functional requirements</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/17.svg" alt="default-img"/>
                    </div>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1"
                    >ETL Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"
                    >Evaluate the accuracy and reliability of data transformations.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/18.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1"                    
                    >Data Warehouse Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"                    
                    >Validate the integrity and performance of your data warehousing solutions.</motion.p> 
                </div>
            </motion.div>

            <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent"
            className='InsideBox2'>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/19.svg" alt="default-img"/>
                    </div>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1" 
                    >BI Report Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"    
                    >Confirm the accuracy and reliability of your business intelligence reports</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/20.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1" 
                    >Performance Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"    
                    >Assess the speed, responsiveness, and stability of your applications.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/21.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1" 
                    >Automation Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"    
                    >Improve efficiency and accuracy through automated testing solutions.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/22.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1" 
                    >UI/UX Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"    
                    >Enhance user satisfaction by evaluating the user interface and experience.</motion.p>  
                </div>
            </motion.div>

            <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent"
             className='InsideBox3'>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/23.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1" 
                    >Application Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"    
                    >Thoroughly test your applications for functionality and performance</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/24.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1" 
                    >Regression Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"    
                    >Detect and address any unintended impacts of code changes on existing features.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/25.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1" 
                    >Security Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"    
                    >Identify vulnerabilities and ensure the security of your applications and data.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/26.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB1"
                        whileInView="visibleB1" 
                    >Compatibility Testing</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenB2"
                        whileInView="visibleB2"    
                    >Ensure your software performs flawlessly across various platforms and devices.</motion.p>  
                </div>
            </motion.div>

        </div>
    </section>

    <section className='WhatWeOffer-main-tablet'>
    <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hidden"
                    whileInView="visible"
        className='WhatWeOffer-content'>
            <motion.h1  
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >What we offer?</motion.h1><br/>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
            className='para1'>Our Testing as a Service (TaaS) solutions encompass a comprehensive range of testing specialties, ensuring your software meets the highest quality standards and performance benchmark</motion.p>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP2"
            whileInView="visibleP2"
            className='para2'><span className='orange_code'>ELEVATING</span> TESTING <span className='orange_code'>EXCELLENCE</span> </motion.p>
        </motion.div>
        <div className='WhatWeOffer-OutsideBox'>
         <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent"
             className='InsideBox1'>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/15.svg" alt="default-img"/>
                    </div>
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
                    >Product Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
                    >Ensure your products meet quality standards and perform as expected</motion.p> 
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/16.svg" alt="default-img"/>
                    </div>
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
                    >Functional Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
                    >Verify that your applications meet specified functional requirements</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/17.svg" alt="default-img"/>
                    </div>
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >ETL Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Evaluate the accuracy and reliability of data transformations.</motion.p>  
                </div>
            </motion.div>

            <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent"
            className='InsideBox2'>
            <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/18.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
                    >Data Warehouse Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
                    >Validate the integrity and performance of your data warehousing solutions.</motion.p> 
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/19.svg" alt="default-img"/>
                    </div>
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
                    >BI Report Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
                    >Confirm the accuracy and reliability of your business intelligence reports</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/20.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
                    >Performance Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
                    >Assess the speed, responsiveness, and stability of your applications.</motion.p>  
                </div>
            </motion.div>

            <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent"
             className='InsideBox3'>
              <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/21.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >Automation Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Improve efficiency and accuracy through automated testing solutions.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/22.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >UI/UX Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Enhance user satisfaction by evaluating the user interface and experience.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/23.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >Application Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Thoroughly test your applications for functionality and performance</motion.p>  
                </div>
            </motion.div>

            <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent"
         className='InsideBox4'>
            <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/24.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >Regression Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Detect and address any unintended impacts of code changes on existing features.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/25.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >Security Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Identify vulnerabilities and ensure the security of your applications and data.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/26.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >Compatibility Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Ensure your software performs flawlessly across various platforms and devices.</motion.p>  
                </div>
            </motion.div>

            <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent" 
            className='InsideBox5'>
            <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/24.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >Regression Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Detect and address any unintended impacts of code changes on existing features.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/25.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >Security Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Identify vulnerabilities and ensure the security of your applications and data.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/26.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                    
                    >Compatibility Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                    
                    >Ensure your software performs flawlessly across various platforms and devices.</motion.p>  
                </div>
            </motion.div>

        </div>
    </section>

{/* MObile */}
    <section className='WhatWeOffer-main-mobile'>
        <motion.div
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
        className='WhatWeOffer-content-mobile'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"            
            >What we offer?</motion.h1><br/>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"            
            className='para1'>Our Testing as a Service (TaaS) solutions encompass a comprehensive range of testing specialties, ensuring your software meets the highest quality standards and performance benchmark</motion.p>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP2"
            whileInView="visibleMobP2"            
            className='para2'><span className='orange_code'>ELEVATING</span> TESTING <span className='orange_code'>EXCELLENCE</span> </motion.p>
        </motion.div>

        <motion.div
                                    viewport={{  once: true}}
                                    variants={MotionContainer}
                                    initial="hiddenMob"
                                    whileInView="visibleMob"
        className='InsideBox-main-mobile'>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/15.svg" alt="default-img"/>
                    </div>
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
                    >Product Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Ensure your products meet quality standards and perform as expected</motion.p> 
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/16.svg" alt="default-img"/>
                    </div>
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >Functional Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Verify that your applications meet specified functional requirements</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/17.svg" alt="default-img"/>
                    </div>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"
                        >ETL Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
                    >Evaluate the accuracy and reliability of data transformations.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/18.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >Data Warehouse Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Validate the integrity and performance of your data warehousing solutions.</motion.p> 
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/19.svg" alt="default-img"/>
                    </div>
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >BI Report Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Confirm the accuracy and reliability of your business intelligence reports</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/20.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >Performance Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
                    >Assess the speed, responsiveness, and stability of your applications.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/21.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
                    >Automation Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Improve efficiency and accuracy through automated testing solutions.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/22.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >UI/UX Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Enhance user satisfaction by evaluating the user interface and experience.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/23.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >Application Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Thoroughly test your applications for functionality and performance</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/24.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >Regression Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Detect and address any unintended impacts of code changes on existing features.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/25.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >Security Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Identify vulnerabilities and ensure the security of your applications and data.</motion.p>  
                </div>
                <div className='WhatWeOffer-Box'>
                    <div className='icon-circle'>
                        <img loading='lazy' src="/Images/taas/26.svg" alt="default-img"/>
                    </div>                    
                    <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                    
                    >Compatibility Testing</motion.h1>
                    <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                    
                    >Ensure your software performs flawlessly across various platforms and devices.</motion.p>  
                </div>
        </motion.div>
    </section>
    </>
    
    )
}

export default WhatWeOffer