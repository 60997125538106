import React, { useEffect, useState } from 'react';
import "./HomePage.scss";
import "./HomePage.css";
import { Helmet } from 'react-helmet';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { Breadcrumbs, Typography } from '@mui/material';
import DigitalTransformation from './DigitalTransformation';
import EngagmentModel from './EngagmentModel';
import StickyPage from './StickyPage';
import OurProduct from './OurProduct';
import BrandAssociation from './BrandAssociation';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from 'framer-motion';


const HomePage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleLoadingComplete = () => {
        setIsLoading(true);
      };


  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  const MotionContainer ={
    hidden: { opacity: 0.1, y: 145 },
    visible: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

    hiddenMob: { opacity: 0.1, y: 95 },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50,  ease: [0, 0.71, 0.2, 1.01]}},

    hiddenHead1: { opacity: 0.5, y: 95, scale:0.8 },
    visibleHead1: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.75,  delay: 0.15,}},

    hiddenHeadMob1: { opacity: 0.5, y: 95, scale:0.8 },
    visibleHeadMob1: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.75,}},

    hiddenHead2: { opacity: 0.5, y: 105, scale:0.8 },
    visibleHead2: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.80,  delay: 0.15,}},

    hiddenHeadMob2: { opacity: 0.5, y: 105, scale:0.8 },
    visibleHeadMob2: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.80, }},

    hiddenHead3: { opacity: 0.5, y: 115, scale:0.8 },
    visibleHead3: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.90,  delay: 0.15,}},

    hiddenHeadMob3: { opacity: 0.5, y: 115, scale:0.8 },
    visibleHeadMob3: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.90,  }},

    hiddenbtn: { opacity: 0.1,   },
    visiblebtn: { opacity: 1,   
    transition:{ duration: 0.95,  delay: 0.15,}},

    hiddenbtnMob: { opacity: 0.1,   },
    visiblebtnMob: { opacity: 1,   
    transition:{ duration: 0.95,  delay: 0.15,}},



    
}  

    return (
        <>
            <Helmet>
                <title>Digital transformation consulting and Services Company - Evenion Technologies</title>
                <meta name="description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Digital transformation consulting and Services Company - Evenion Technologies" />
                <meta property="og:description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="og:url" content="https://www.eveniontechnologies.com/home" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="https://eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <meta property="og:image:secure_url" content="https://eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Digital transformation consulting and Services Company - Evenion Technologies" />
                <meta name="twitter:description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta name="twitter:image" content="https://eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <script type="application/ld+json">
                    {`{
                        "@context": "Schema.org - Schema.org 
                        "@type": "Organization",
                        "name": "Evenion Technologies",
                        "url": "https://www.eveniontechnologies.com/", 
                        "logo": "https://eveniontechnologies.com/Images/logo/evenion_logo.png", 
                        "sameAs": [
                        "https://facebook.com/eveniontechnologies/",
                        "https://www.linkedin.com/company/evenion-technologies/",
                        "https://twitter.com/EvenionTech/"
                        ]
                    }`
                    }
                </script>
            </Helmet>
            {isLoading ? 
                <>
            <div className='Main-Home'>
                <section className='Main-Home-Banner'>
                        <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' >
                        <Typography color="#0F326C" fontFamily=' space-Grotesksb' fontWeight='300' >Home</Typography>
                        </Breadcrumbs>
                        <video   className='video-height' loop autoPlay muted playsInline>
                            <source src="/Images/Home/Evenion Videi.mp4" type="video/mp4" />
                            <p>This browser not support in video tag</p>
                        </video>
                        
                        <motion.img
                            src="/Images/Home/Rectangle 1464.png"
                            alt="Overlay Image"
                            className="overlay-image-Home overlayy"
                            />
                        <motion.img
                            src="/Images/Home/Mask Group 248.svg"
                            alt="Overlay Image"
                            className="overlay-image-Home "
                            />
                                

                    <motion.div 
                        variants={MotionContainer}
                        initial="hidden"
                        animate="visible"
                    className='inside-Banner'>
                        <motion.h1
                            variants={MotionContainer}
                            initial="hiddenHead1"
                            animate="visibleHead1"
                        >Digital Transformation, Strategy & Consulting</motion.h1>
                        <motion.p 
                            variants={MotionContainer}
                            initial="hiddenHead2"
                            animate="visibleHead2"
                        className='para1'>We build Global Products & Solutions for you</motion.p>
                        <motion.p 
                            variants={MotionContainer}
                            initial="hiddenHead3"
                            animate="visibleHead3"
                        className='para2'>FLEXIBLE <br></br> PERFORMANCE ORIENTED <br></br> SCALABLE, SECURE & SUSTAINABLE</motion.p>
                    </motion.div>
                        <a href="/contact-us#contact_form">
                            <motion.div
                                variants={MotionContainer}
                                initial="hiddenbtn"
                                animate="visiblebtn"
                            className="custom-div">
                                <h2 className="heading-div heading-div1"><span className='centerClass'>Contact Us <br></br> <ArrowBackIcon className="rotatee rotatee-tablet" style={{scale : "1.5"}}/></span></h2>
                                <h2 className="heading-div heading-div2"><span className='centerClass'>Contact Us <br></br> <ArrowForwardIcon className="rotatee rotatee-tablet" style={{scale : "1.5", rotateZ : "180deg"}}/></span></h2>
                            </motion.div>
                        </a>

                </section>



{/* Mobile */}
                <section className='Mobile-Home-Banner'>
                        <video   className='video-height' loop autoPlay muted playsInline>
                            <source src="/Images/Home/Evenion Videi.mp4" type="video/mp4" />
                            <p>This browser not support in video tag</p>
                        </video>
                        <img loading='lazy' src="/Images/Home/Rectangle 1464.png" alt="Overlay Image" className="overlay-image-Home-mobile overlayy" />
                        <img loading='lazy' src="/Images/Home/mobile_banner_ciricle.svg" alt="Overlay Image" className="overlay-image-Home-mobile " />
                        {/* <svg className="overlay-image-Home-mobile " xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="430" height="862" viewBox="0 0 430 862">
                            <defs>
                                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#010814"/>
                                <stop offset="0.498" stop-color="#010814" stop-opacity="0.969"/>
                                <stop offset="1" stop-color="#010814" stop-opacity="0.722"/>
                                </linearGradient>
                                <clipPath id="clip-path">
                                <rect id="Rectangle_1464" data-name="Rectangle 1464" width="430" height="862" transform="translate(6140 -22955)" fill="url(#linear-gradient)"/>
                                </clipPath>
                            </defs>
                            <g id="Mask_Group_256" data-name="Mask Group 256" transform="translate(-6140 22955)" clip-path="url(#clip-path)">
                                <g id="Ellipse_168" data-name="Ellipse 168" transform="translate(5826 -22657)" fill="none" stroke="#ebf5f5" stroke-width="1" opacity="0.66">
                                <ellipse cx="529.5" cy="564" rx="529.5" ry="564" stroke="none"/>
                                <ellipse cx="529.5" cy="564" rx="529" ry="563.5" fill="none"/>
                                </g>
                                <g id="Ellipse_169" data-name="Ellipse 169" transform="translate(5983 -22536)" fill="none" stroke="#ebf5f5" stroke-width="1" opacity="0.66">
                                <ellipse cx="372" cy="396" rx="372" ry="396" stroke="none"/>
                                <ellipse cx="372" cy="396" rx="371.5" ry="395.5" fill="none"/>
                                </g>
                                <g id="Ellipse_170" data-name="Ellipse 170" transform="translate(6074 -22417)" fill="none" stroke="#ebf5f5" stroke-width="1" opacity="0.66">
                                <ellipse cx="281" cy="270.5" rx="281" ry="270.5" stroke="none"/>
                                <ellipse cx="281" cy="270.5" rx="280.5" ry="270" fill="none"/>
                                </g>
                            </g>
                        </svg> */}


                    <motion.div 
                        variants={MotionContainer}
                        initial="hiddenMob"
                        animate="visibleMob"
                    className='inside-Banner-mobile'>
                        <motion.h1
                            variants={MotionContainer}
                            initial="hiddenHeadMob1"
                            animate="visibleHeadMob1"
                        >Digital Transformation,<br></br> Strategy & Consulting</motion.h1>
                        <motion.p 
                            variants={MotionContainer}
                            initial="hiddenHeadMob2"
                            animate="visibleHeadMob2"
                        className='para1'>We build Global <br></br> Products & Solutions for you</motion.p>
                        <motion.p 
                            variants={MotionContainer}
                            initial="hiddenHeadMob3"
                            animate="visibleHeadMob3"
                        className='para2'>FLEXIBLE <br></br> PERFORMANCE ORIENTED <br></br> SCALABLE, SECURE <br></br> & SUSTAINABLE</motion.p>
                    </motion.div>
                        <a href="/contact-us#contact_form">
                            <motion.div
                                variants={MotionContainer}
                                initial="hiddenbtnMob"
                                animate="visiblebtnMob"
                            className="custom-div-mobile">
                                <h2 className="heading-div-mobile"><span className='centerClass'>Contact Us <br></br> <ArrowBackIcon className="rotatee rotatee-tablet" style={{scale : "1"}}/></span></h2>
                            </motion.div>
                        </a>

                </section>





            <DigitalTransformation />
            <EngagmentModel />
            <StickyPage />
            <OurProduct />
            <BrandAssociation />

            <section className="Contact-Container" style={{backgroundColor: "#001220"}}>
                <div className="Contact-Container-inside  " >
                    <a href="/contact-us#contact_form"><span className='Contact-Button  '>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating ' style={{ fontSize: '7vw'}}/>
                    </span>
                    </a>
                </div>
            </section>

            <section className="Contact-Container-tablet">
                <div className="Contact-Container-inside-tablet  ">
                    <a href="/contact-us#contact_form"><span className='Contact-Button-tablet'>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating-tablet ' style={{ fontSize: '7vw'}}/>
                    </span>
                    </a>
                </div>
            </section>

            <section className="Contact-Container-mobile">
                <div className="Contact-Container-inside-mobile">
                    <a href="/contact-us#contact_form"><span className='Contact-Button-mobile'>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating-mobile' style={{ fontSize: '8vw'}}/>
                    </span>
                    </a>
                </div>
            </section>

            </div>



                   
                </>
                : <LoadingSpinner onLoadingComplete={handleLoadingComplete} />
            }
        </>
    )  
}

export default HomePage;



// App.js



