import React, { useState, useEffect } from 'react';
import './StickyPage.scss'; 
import { motion } from 'framer-motion';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const images = ['/Images/Home/DE.png', '/Images/Home/Ai Data.png', '/Images/Home/ED.png', '/Images/Home/OT.png'];
const MotionContainer ={
  hidden: { opacity: 0.5, y: 200, scale: 0.98, },
  visible: { opacity: 1, y: 0, scale: 1, 
  transition:{ duration: 1,   delay: 0.15,}},
  
  hiddenH1: { y: 40,  opacity: 0.9 },
  visibleH1: { y: 0, opacity: 1, 
  transition:{ duration: 1, delay: 0.15,}},

  hiddenP1: { y: 35,  opacity: 0.9 },
  visibleP1: { y: 0, opacity: 1,
  transition:{ duration: 1, delay: 0.15,}},

  hiddenP2: { y: 20,  opacity: 0.9 },
  visibleP2: { y: 0, opacity: 1,
  transition:{ duration: 1, delay: 0.15,}},

  hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
  visibleMob: { opacity: 1, y: 0, scale: 1, 
  transition:{ duration: 1,   delay: 0.15,}},

  hiddenMobH1: { y: 30,  opacity: 0.9 },
  visibleMobH1: { y: 0, opacity: 1, 
  transition:{ duration: 0.50, },},

  hiddenMobP1: { y: 25,  opacity: 0.9 },
  visibleMobP1: { y: 0, opacity: 1,
  transition:{ duration: 0.50, }},

}   
const slideContents = [
  
  {
    title: <motion.h1 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenH1"
                whileInView="visibleH1"
    className='Scrollhead1 bg-blackk'> Digital <br></br> Engineering </motion.h1>,
    subtitle: <motion.h2
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1" 
    className='Scrollhead2 bg-blackk'>Building Tomorrow’s Solutions Today</motion.h2>,
    content: <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1" 
    className='ScrollPara  bg-blackk'>Embrace the digital era with our Digital Engineering services. Our team of experts combines technical prowess with innovation to engineer solutions that drive digital transformation. Whether it's software development, system integration, or platform optimization, we architect robust solutions tailored to propel your business forward.</motion.p>,
    link: <a href="DigitalEngineering" className='paddingClass'>
      <motion.div 
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenP1"
          whileInView="visibleP1"     
    className='learnMore'>Learn More <ArrowBackIcon className='LearnArrow' /> </motion.div></a>,
    // link: <a href="#">Link for Slide 1</a>,
  },
  {
    title: <motion.h1 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenH1"
                whileInView="visibleH1"
    className='Scrollhead11 bg-whitee'>AI & Data</motion.h1>,
    subtitle: <motion.h2
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1" 
    className='Scrollhead22 bg-whitee'>Unleash the Power of Data with Our AI Expertise</motion.h2>,
    content: <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1" 
    className='ScrollPara bg-whitee'>Dive into the future with our cutting-edge AI and Data solutions. From predictive analytics to machine learning, we leverage the latest technologies to turn your data into actionable insights. Transform your business with intelligence-driven strategies and stay ahead in the era of data-driven decision-making.</motion.p>,
     link: <a href="contact-us" className='paddingClass'>
      <motion.div
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenP1"
          whileInView="visibleP1" 
     className='learnMore'>Learn More <ArrowBackIcon className='LearnArrow' /> </motion.div></a>,
    // link: <a href="#">Link for Slide 2</a>,
  },
  {
    title: <motion.h1 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenH1"
                whileInView="visibleH1" 
    className='Scrollhead1 bg-blackk'>Experience <br></br> Design </motion.h1>,
    subtitle: <motion.h2
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1" 
    className='Scrollhead2 bg-blackk'>Elevate User Engagement through Intuitive Design</motion.h2>,
    content: <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1" 
    className='ScrollPara  bg-blackk'>Craft unforgettable experiences for your audience with our Experience Design services. We blend creativity with user-centric design principles to ensure that every interaction leaves a lasting impression. From user interface (UI) to user experience (UX), we shape digital experiences that resonate and captivate.</motion.p>,
     link: <a href="contact-us" className='paddingClass'>
      <motion.div
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1" 
     className='learnMore'>Learn More <ArrowBackIcon className='LearnArrow' /> </motion.div></a>,
    // link: <a href="#">Link for Slide 3</a>,
  },
  {
    title: <motion.h1 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenH1"
                whileInView="visibleH1"        
    className='Scrollhead1 bg-whitee'>Operation <br></br> Transformation</motion.h1>,
    subtitle: <motion.h2
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1" 
    className='Scrollhead2 bg-whitee'>Redefine Efficiency through Operational Excellence</motion.h2>,
    content: <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1" 
    className='ScrollPara bg-whitee'>Revolutionize your business processes with our Operation Transformation services. We analyze, streamline, and optimize your operations to enhance efficiency and drive growth. From workflow automation to organizational restructuring, we guide you through a transformative journey, ensuring your operations are agile and adaptive to the evolving business landscape</motion.p>,
     link: <a href="contact-us" className='paddingClass'>
      <motion.div
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenP1"
          whileInView="visibleP1" 
     className='learnMore'>Learn More <ArrowBackIcon className='LearnArrow' /> </motion.div></a>,
    // link: <a href="#">Link for Slide 4</a>,
  },
];

const StickyPage = () => {
  const MotionContainer ={
    hidden: { opacity: 0.5, y: 200, scale: 0.98, },
    visible: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1,   delay: 0.15,}},
    
    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP1: { y: 35,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP2: { y: 20,  opacity: 0.9 },
    visibleP2: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1,   delay: 0.15,}},
    
    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    
}   
  const [activeSection, setActiveSection] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('');

  const handleScroll = () => {
    const sections = document.querySelectorAll('.scrollable-section .defaultClass');

    let found = false;

    sections.forEach((section, index) => {
      // const rect = section.getBoundingClientRect();
      const rect = section ? section.getBoundingClientRect() : null;
      const isInView = rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2;

      if (isInView && !found) {
        setActiveSection(index);
        found = true;
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
        switch (activeSection) {
          case 1:
            setBackgroundColor('transparent linear-gradient(180deg, #5149AC 0%, #241F57 100%');
            break;
          case 2:
            setBackgroundColor('transparent linear-gradient(119deg, #B7C3E9 0%, #7A80B0 100%)');
            break;
          case 3:
            setBackgroundColor('transparent linear-gradient(119deg, #72ADD6 0%, #5A87C2 100%)');
            break;
          default:
            setBackgroundColor('linear-gradient(118deg, #A2D1F6 0%, #989CFD 100%)'); // Reset to default color
            break;
        }
      }, [activeSection]);


  return (

    <>
    <div className="sticky-pagess" style={{ background: backgroundColor }}>
      <div className="sticky-sectionss padclass">
           <motion.h2 
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenH1"
              whileInView="visibleH1"
           className='headScroll1 colClass addWhite'>Services</motion.h2>
           <motion.p 
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenP1"
              whileInView="visibleP1"
           className='para1 colClass addWhite'>Empowering Your Business Through the Fusion of Technology and Innovation</motion.p>
           <motion.h1 
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenH1"
              whileInView="visibleH1"
           className={activeSection === 0 ? 'active unclassActive' : ''}><span className='numscroll'>01</span> <span className='headScroll colClass addWhite'> Digital <br></br> Engineering     </span></motion.h1>
           <motion.h1 
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenH1"
              whileInView="visibleH1"
           className={activeSection === 1 ? 'active classActive' : '' }><span className='numscroll'>02</span> <span className='headScroll colClass '> AI & Data </span></motion.h1>
           <motion.h1 
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenH1"
              whileInView="visibleH1"
           className={activeSection === 2 ? 'active unclassActive' : ''}><span className='numscroll'>03</span> <span className='headScroll colClass addWhite'> Experience <br></br> Design       </span></motion.h1>
           <motion.h1 
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenH1"
              whileInView="visibleH1"
           className={activeSection === 3 ? 'active classActive' : ''}><span className='numscroll'>04</span> <span className='headScroll colClass '> Operation <br></br> Transformation</span></motion.h1>
       </div>
       <div className="sticky-sectionss ">
        <span className='borderLine1'></span>
        {images.slice(0, activeSection + 1).map((image, index) => (
          <img
          key={index}
          src={image}
          alt={`Photo ${index + 1}`}
          className={activeSection === index ? 'active' : ''}
          />
          ))}
        <span className='borderLine2'></span>
      </div>
      <div className="scrollable-section">
        {Array.from({ length: 4 }, (_, index) => (
          <div key={index} id={`paragraph${index + 1}`} 
          // className={index === activeSection ? 'active' : ''}
          className={`defaultClass ${index === activeSection ? 'active' : ''}`}

          >
            {slideContents[index].title}
            {slideContents[index].subtitle}
            {slideContents[index].content}
            {slideContents[index].link}
          </div>
        ))}
      </div>
    </div>


    {/* Mobile demo 3 */}
    <section className='MobileSticky3'>

      <motion.div 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hiddenMob"
        whileInView="visibleMob"
      className='Service-Content'>
        <motion.h1
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenMobH1"
          whileInView="visibleMobH1"
        >Services</motion.h1>
        <motion.p
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenMobP1"
          whileInView="visibleMobP1" 
        >Empowering Your Business Through the Fusion of Technology and Innovation</motion.p>
      </motion.div>

      <motion.div 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hiddenMob"
        whileInView="visibleMob"
      className='card1 Service-Scroll'>
          <span className='bdLine'></span>
          <img     src='/Images/Home/DE-mobile.png' alt='deafilt' />
          <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
          > Digital  Engineering </motion.h1>
          <motion.h2 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
          className='Cl-B'>Building Tomorrow’s Solutions Today</motion.h2>
          <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
          >Embrace the digital era with our Digital Engineering services. Our team of experts combines technical prowess with innovation to engineer solutions that drive digital transformation. Whether it's software development, system integration, or platform optimization, we architect robust solutions tailored to propel your business forward.</motion.p>
          <a href="#" className='paddCl'>
            <motion.div 
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenMobP1"
              whileInView="visibleMobP1"
            className='SerMore'>Learn More <ArrowBackIcon className='SerMoreArrow' /> </motion.div></a>  
      </motion.div>

      <motion.div 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hiddenMob"
        whileInView="visibleMob"
      className='card2 Service-Scroll'>
          <span className='bdLine'></span>
          <img     src="/Images/Home/Ai Data-mobile.png" alt='deafilt' />
          <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1" 
          > AI & Data </motion.h1>
          <motion.h2
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
          >Unleash the Power of Data with Our AI Expertise</motion.h2>
          <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
          >Dive into the future with our cutting-edge AI and Data solutions. From predictive analytics to machine learning, we leverage the latest technologies to turn your data into actionable insights. Transform your business with intelligence-driven strategies and stay ahead in the era of data-driven decision-making.</motion.p>
          <a 
          href="#" className='paddCl'>
            <motion.div 
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenP1"
              whileInView="visibleP1"
        className='SerMore'>Learn More <ArrowBackIcon className='SerMoreArrow' /> </motion.div></a>  
      </motion.div>

      <motion.div 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hiddenMob"
        whileInView="visibleMob"
      className='card3 Service-Scroll'>
          <span className='bdLine'></span>
          <img     src="/Images/Home/ED.png" alt='deafilt' />
          <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
          > Experience Design </motion.h1>
          <motion.h2 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
          className='Cl-B'>Elevate User Engagement through Intuitive Design</motion.h2>
          <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
          >Craft unforgettable experiences for your audience with our Experience Design services. We blend creativity with user-centric design principles to ensure that every interaction leaves a lasting impression. From user interface (UI) to user experience (UX), we shape digital experiences that resonate and captivate.</motion.p>
          <a href="#" className='paddCl'>
            <motion.div 
             viewport={{  once: false}}
             variants={MotionContainer}
             initial="hiddenMobP1"
             whileInView="visibleMobP1"
            className='SerMore'>Learn More <ArrowBackIcon className='SerMoreArrow' /> </motion.div></a>  
      </motion.div>

      <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hiddenMob"
          whileInView="visibleMob"
      className='card4 Service-Scroll '>
          <span className='bdLine'></span>
          <img     className='img-rotate' src="/Images/Home/OT.png" alt='deafilt' />
          <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
          > Operation Transformation </motion.h1>
          <motion.h2 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
          >Redefine Efficiency through Operational Excellence</motion.h2>
          <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
          >Revolutionize your business processes with our Operation Transformation services. We analyze, streamline, and optimize your operations to enhance efficiency and drive growth. From workflow automation to organizational restructuring, we guide you through a transformative journey, ensuring your operations are agile and adaptive to the evolving business landscape</motion.p>
          <a 
          href="#" className='paddCl'>
            <motion.div 
             viewport={{  once: false}}
             variants={MotionContainer}
             initial="hiddenMobP1"
             whileInView="visibleMobP1"
            className='SerMore'>Learn More <ArrowBackIcon className='SerMoreArrow' /> </motion.div></a>  
      </motion.div>




    </section>
    </>
  );
};

export default StickyPage;

