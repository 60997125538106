
import { Row, Col } from 'reactstrap';
import "./Footer.scss";
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router';
import {Modal, ModalBody, Button} from 'reactstrap';
import Lottie from 'react-lottie';
import useSmoothHorizontalScroll from 'use-smooth-horizontal-scroll';
import * as mailAnimation from '../../jsonFiles/mail_send.json';
import validate from '../../Conatiners/ContactUs/getValidateInfoConatact';
import useForm from '../../Components/CareerOpen/useForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SlideToggle from "react-slide-toggle";
import {motion, transform, useScroll, useSpring} from 'framer-motion'
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(0);
    const [activeTab, setActiveTab] = useState("tab1");
    const [blogList, setBlogList] = useState();
    const ref = useRef(null)
    // const isInView = useInView(ref, { once: false });
    const [searchTerm, setSearchTerm] = useState('');
    const [email, setEmail] = useState('');
    const { handleChange, values } = useForm(validate);
    const [errors, setErrors] = useState({});
    const [confirmmodal, setconfirmmodal] = useState(false);
    const toggleConfirm = () => setconfirmmodal(!confirmmodal);

    // mobile
    const [openDropdown, setOpenDropdown] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenPartnership, setDropdownOpenPartnership] = useState(false);
    const [dropdownOpenProduct, setDropdownOpenProduct] = useState(false);
    const [show, setShow] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const toggleP = () => setDropdownOpenPartnership(prevState => !prevState);
    const toggleProduct = () => setDropdownOpenProduct(prevState => !prevState);
    // const { isSticky, element } = UseSticky();
    const [flag, setFlag] = useState(false);
    const [flagProduct, setFlagProduct] = useState(false);
    const [flagPartner, setFlagPartner] = useState(false);

    const [flagSD, setFlagSD] = useState(false);
    const [flagProductD, setFlagProductD] = useState(false);
    const [flagPartnerD, setFlagPartnerD] = useState(false);

    const [classActive, setClassActive] = useState(false);
    const [classActiveServices, setclassActiveServices] = useState(false);
    const [classActivePartnership, setclassActivePartnership] = useState(false);
    const [classActiveProducts, setclassActiveProducts] = useState(false);
    const rmvClassMob = () => {
        setShow(false);
        setClassActive(false);
        setclassActiveServices(false);
        setclassActivePartnership(false);
        setclassActiveProducts(false)
        setFlag(false);
        setFlagPartner(false);
        setFlagProduct(false);
    }

    const toggleDropdown = (dropdownType) => {
        setOpenDropdown((prevOpenDropdown) => (prevOpenDropdown === dropdownType ? null : dropdownType));
      };



    const currentYear = new Date().getFullYear();

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: mailAnimation}


    const filteredBlogs = blogList?.filter(blog =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.short_description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } = useSmoothHorizontalScroll();

    const tabsObject = [
        { name: 'All' },
        { name: 'Featured' },
        { name: 'Smart Code' },
        { name: 'Case Study' },
        { name: 'Product Management' },
        { name: 'Evenion Offerings' },
        { name: 'Experience Design' },
        { name: 'Dev Ops' },
        { name: 'Management Desk' }
    ]

    const getBlogs = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/blogs`)
            .then(response => {
                setBlogList(response.data.data[0]);
                setIsLoading(false);

            })
            .catch(error => {
                console.log("Error => ", error);
            });
    }


    const handleLoadingComplete = () => {
        setIsLoading(true);
    };


    useEffect(() => {
        localStorage.setItem("tab", 'Services')
        getBlogs();
    }, [])

    useEffect(() => {
        // Close the dropdowns when the route changes
        setOpenDropdown(null);
        setDropdownOpen(false);
        setDropdownOpenPartnership(false);
        setDropdownOpenProduct(false);
        setShow(false);
        setClassActive(false);
        setclassActiveServices(false);
        setclassActivePartnership(false);
        setclassActiveProducts(false);
        setFlag(false);
        setFlagPartner(false);
        setFlagProduct(false);
      }, [location.pathname]);
    function myFunction(e, id) {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        setActive(id);

    }

    const [scrolled, setScrolled] = React.useState(false);
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(()=>{

    },[])

    
    let navbarClasses = ['navlist'];
    if (scrolled) {
        navbarClasses.push('scrolled');
    }

    let navbarClassesmob = ['navlist_mob'];
    if (scrolled) {
        navbarClassesmob.push('scrolled');
    }

    var regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;

    const handleEmailSubscribe = (e) => {

        setErrors(validate(values));
        
        if (email !== '' && regex.test(email)) {
            setErrors({})
            const payload = {
                "email" : email,
                "from" : "Evenion"
            }
            
            const getApply = async () => {
                const url = `${process.env.REACT_APP_API_URL}/subscribe`;
                await axios.post(url, payload ).then((response) => {
                    setconfirmmodal(true);
                    setEmail("");
                    setErrors({})
                })
                    .catch(error => {
                        console.log("Error => ", error);
                    });
            };
            getApply();        }

    };

return (
    <>
    <div>
            <footer id="footer-dev-eve">
                <div className="footer-section">
                    <div className="footer-desktop">
                        <div className="d-flex justify-content-between">
                            <div>
                                <div   className='subscribe-container'>
                                    {window.innerWidth > 599 ?
                                        <div >
                                            <span className='subscribe_text ColorCl'>Subscribe to Our letter</span><br />
                                            <div className='flexCl'>
                                            <input placeholder="Enter your mail ID" type="email" className='subscribe_input widCl'  value={email} onChange={handleEmail} />
                                            <button className='btnMail' onClick={handleEmailSubscribe} type="submit">
                                                <img loading='lazy' className='BeforeEmail' src="/Images/Footer/Component 173 – 1@2x.png" alt="deafult" />
                                                <img loading='lazy' className='AfterEmail' src="/Images/Footer/Component 173 – 2@2x.png" alt="deafult" />
                                                </button>
                                            {errors.email && <p className="error_text-email">{errors.email}</p>}
                                            </div>
                                         </div>
                                        :
                                        <div>
                                            <span className='subscribe_text'>Subscribe to your letter</span><br />
                                            <div className='flexCl'>
                                            <input placeholder="Enter your mail ID" type="email" className='subscribe_input'  value={email}  onChange={handleEmail} />
                                            {errors.email && <p className="error_text-email">{errors.email}</p>}
                                            <button type="submit" className='btnMail' onClick={handleEmailSubscribe} >
                                            <img loading='lazy' className='BeforeEmail' src="/Images/Footer/Component 173 – 1@2x.png" alt="deafult" />
                                                <img loading='lazy' className='AfterEmail' src="/Images/Footer/Component 173 – 2@2x.png" alt="deafult" />
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="footer-social iconGap">
                                <a target="_blank" href="https://www.linkedin.com/company/evenion-technologies">
                                    <img loading='lazy' className="linked" width="160" src="/Images/Footer/Group 7018@2x.png" alt="img" />
                                </a>
                                <a target="_blank" href="https://m.facebook.com/eveniontechnologies/">
                                    <img loading='lazy' className="fb" width="160" src="/Images/Footer/Group 7017@2x.png" alt="img" />
                                </a>
                                <a target="_blank" href="https://twitter.com/EvenionTech">
                                    <img loading='lazy' className="twt" width="160" src="/Images/Footer/Group 7016@2x.png" alt="img" />
                                </a>
                                <a target="_blank" href="https://www.instagram.com/evenion_technologies">
                                    <img loading='lazy' className="insta" width="160" src="/Images/Footer/Group 6689@2x.png" alt="img" />
                                </a>
                            </div>
                        </div>

                        {/* /* Revamp Footer */}
                        <Row className="footer_new_container pt-5">
                            <Col md={2}>
                                <div>
                                    <h2>Company</h2>
                                    <ul className="footer_link">
                                        <Link to="/about-us" onClick={scrollTop}><li>About Us</li></Link>
                                        {/* {window.innerWidth > 1024 ?
                                        <a href="/about-us#our_leader"> <li>Leadership</li></a> : ""} */}
                                        {/* {window.innerWidth >= 768 &&  window.innerWidth <= 1024 ? 
                                        <a href="/about-us#our_leader1"> <li>Leadership</li></a> : <a href="/about-us#our_leader"> <li>Leadership</li></a>}  */}
                                
                                        <a href="/about-us#our_team"> <li>Our Team</li></a>
                                        <a href="/career"> <li>Careers</li></a>
                                        <a href="/contact-us"> <li>Contact</li></a>
                                        <a href="/contact-us"> <li>Location</li></a>
                                        <a href="/blogs"> <li>Blogs</li></a>
                                    </ul>

                                </div>
                            </Col>
                            <Col md={3} className="digi_left">
                                <div>
                                    <h2>Digital Transformation</h2>
                                    <ul className="footer_link">
                                        {/* <Link to="/digital-services" onClick={scrollTop}><li>Digital Services</li></Link> */}
                                        <Link to="/DigitalEngineering" onClick={scrollTop}><li>Digital Engineering</li></Link>
                                        <Link to="/project-services" onClick={scrollTop}><li>Project Services</li></Link>
                                        <Link to="/professional-services" onClick={scrollTop}><li>Professional Services</li></Link>
                                        <Link to="/analytics-services" onClick={scrollTop}><li>Analytic Services</li></Link>
                                        <Link to="/taas-services" onClick={scrollTop}><li>Testing as a Service</li></Link>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={3} >
                            <div>
                                <h2>PARTNERSHIPS</h2>
                                <ul className="footer_link">
                                    <Link to="/precisely" onClick={scrollTop}><li>Precisely</li></Link>
                                    <Link to="/flowize" onClick={scrollTop}><li>Flowize</li></Link>
                                </ul>
                            </div>
                            </Col>
                            <Col md={2} className="p-0">
                            <div>
                                <h2>Products</h2>
                                <ul className="footer_link">
                                    <Link to="/stackerank" onClick={scrollTop}><li>StackéRank</li></Link>
                                    <Link to="/datasensex" onClick={scrollTop}><li>DataSenseX</li></Link>
                                </ul>
                            </div>
                            </Col>
                            <Col md={2}>
                                <a href="/#industry"><h2>Industries</h2></a>
                                <div className="industry_container">
                                         <div className="">
                                             <ul className="footer_link">
                                                 <li>Real Estate</li>
                                                 <li>Chemical</li>
                                                 <li>Logistics</li>
                                                 <li>Agri Tech</li>
                                                 <li>Manufacturing</li>
                                                 <li>Mar Tech</li>
                                                 <li>Hi Tech</li>
                                                 <li>Auto OEM</li>
                                                 <li>Health Tech</li>
                                                 <li>SaaS</li>
                                             </ul>
                                         </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                                 <a href="/" className='HomeIcon'>
                                 <img loading='lazy' src="/Images/Footer/Final Logo 1.png" alt='deafult' />
                                 </a>
                                 <div className='Footer-line'></div>
                                 <div className='Footer-copy'>
                                    <div className='CopyRight'>
                                        {/* <img loading='lazy' src="/Images/Footer/Group 4012@2x.png" alt='default' /> */}
                                        <p className='colorWhite'>&copy; {currentYear} Evenion Technologies</p>
                                    </div>
                                    <div className='site-map'>
                                    <Link className='clSiteMap' to="/sitemap" onClick={scrollTop}>Sitemap</Link>
                                    <p className='BackBtn'  onClick={scrollTop}>Back to Top <KeyboardArrowUpIcon className='ActHover'/> </p>
                                    </div>
                                 </div>
                        </Row>





                    </div>


{/* Mobile */}
                    <div className="footer-mobile">
                    <div   className='subscribe-container FooterPad'>
                                    {window.innerWidth > 599 ?
                                        <div >
                                            <span className='subscribe_text ColorClass'>Subscribe to Our letter</span><br />
                                            <div className='flexCl'>
                                            <input placeholder="Enter your mail ID" type="email" className='subscribe_input widCl'  value={email} onChange={handleEmail} />
                                            <button className='btnMail' onClick={handleEmailSubscribe} type="submit">
                                                <img loading='lazy' className='BeforeEmail' src="/Images/Footer/Component 173 – 1@2x.png" alt="deafult" />
                                                <img loading='lazy' className='AfterEmail' src="/Images/Footer/Component 173 – 2@2x.png" alt="deafult" />
                                                </button>
                                            {errors.email && <p className="error_text-email">{errors.email}</p>}
                                            </div>
                                         </div>
                                        :
                                        <div>
                                            <span className='subscribe_text ColorClass'>Subscribe to Our letter</span><br />
                                            <div className='flexClCl'>
                                            <input placeholder="Enter your mail ID" type="email" className='subscribe_input  widClass'  value={email}  onChange={handleEmail} />
                                            {errors.email && <p className="error_text-email">{errors.email}</p>}
                                            <button type="submit" className='btnMail' onClick={handleEmailSubscribe} >
                                            {/* <img loading='lazy' className='BeforeEmail' src="/Images/Footer/Component 173 – 1@2x.png" alt="deafult" /> */}
                                                <img loading='lazy' className='BeforeEmail' src="/Images/Footer/Component 173 – 2@2x.png" alt="deafult" />
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                    <div className="footer-social text-center-mobile p-3 ">
                                <a target="_blank" href="https://www.linkedin.com/company/evenion-technologies">
                                    <img loading='lazy' className="linked" width="160" src="/Images/Footer/Group 7018@2x.png" alt="img" />
                                </a>
                                <a target="_blank" href="https://m.facebook.com/eveniontechnologies/">
                                    <img loading='lazy' className="fb" width="160" src="/Images/Footer/Group 7017@2x.png" alt="img" />
                                </a>
                                <a target="_blank" href="https://twitter.com/EvenionTech">
                                    <img loading='lazy' className="twt" width="160" src="/Images/Footer/Group 7016@2x.png" alt="img" />
                                </a>
                                <a target="_blank" href="https://www.instagram.com/evenion_technologies">
                                    <img loading='lazy' className="insta" width="160" src="/Images/Footer/Group 6689@2x.png" alt="img" />
                                </a>
                        </div>


                       

                        {/* Adding the dropdrop as SideBar header */}
                        <motion.div className="nav-bar-mobile " >
                            <ul className="flex-column p-cl nav-lists-mobile" id="nav-lists">
                            <motion.li
                                variants={{
                                    hidden: { opacity: 0, y: 105, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 0.85, delay: 0.31, }}
                                >
                                    <SlideToggle duration={500} collapsed render={({ setCollapsibleElement   }) => (
                                        <div className="dropdown">
                                            <button className={`${classActiveServices ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown" onClick={() => toggleDropdown('company')}>
                                            <Row >
                                                    <Col>
                                                    <span>COMPANY</span>
                                                    </Col>
                                                    <Col style={{textAlign:'end'}}>
                                                 {openDropdown === 'company' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}                                                    </Col>
                                                </Row>
                                                </button>{openDropdown === 'company' && (
                                                <ul className="nested__menu_items">
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/about-us">About Us</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/about-us#our_team">Our Team</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/career">Careers</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/contact-us">Contact</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/contact-us">Location</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/blogs">Blogs</NavLink></li>
                                                </ul>
                                                )}
                                        </div>
                                    )}
                                    />
                                </motion.li>
                                <motion.li
                                variants={{
                                    hidden: { opacity: 0, y: 105, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 0.85, delay: 0.31, }}
                                >
                                    <SlideToggle duration={500} collapsed render={({ setCollapsibleElement   }) => (
                                        <div className="dropdown">
                                            <button className={`${classActiveServices ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown" onClick={() => toggleDropdown('services')}>
                                            <Row >
                                                    <Col>
                                                    <span>DIGITAL TRANSFORMATION</span>
                                                    </Col>
                                                    <Col style={{textAlign:'end'}}>
                                                 {openDropdown === 'services' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}                                                    </Col>
                                                </Row>
                                                </button>{openDropdown === 'services' && (
                                                <ul className="nested__menu_items">
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/DigitalEngineering">Digital Engineering</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/project-services">Project Services</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/professional-services">Professional Services</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/analytics-services">Analytic  As a Service</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/taas-services">Testing As a Service</NavLink></li>
                                                </ul>
                                                )}
                                        </div>
                                    )}
                                    />
                                </motion.li>
                                <motion.li
                                variants={{
                                    hidden: { opacity: 0, y: 145, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 1.25, delay: 0.37,  }}
                                >
                                    <SlideToggle duration={500} collapsed render={({  setCollapsibleElement   }) => (
                                        <div className="dropdown">
                                            <button className={`${classActivePartnership ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown" onClick={() => toggleDropdown('partners')}>
                                            <Row >
                                                    <Col>
                                                    <span>PARTNERSHIPS</span>
                                                    </Col>
                                                    <Col style={{textAlign:'end'}}>
                                                    {openDropdown === 'partners' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}                                                    </Col>
                                                </Row>
                                                </button>
                                            {openDropdown === 'partners' && (
                                                <ul className="nested__menu_items">
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/precisely">Precisely</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/flowize">Flowize</NavLink></li>
                                                </ul>
                                            )}
                                            
                                        </div>
                                    )}
                                    />
                                </motion.li>
                                <motion.li
                                variants={{
                                    hidden: { opacity: 0, y: 125, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 1.05, delay: 0.34,  }}
                                >
                                    <SlideToggle duration={500} collapsed render={({  setCollapsibleElement   }) => (
                                        <div className="dropdown">
                                            <button className={`${classActiveProducts ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown"  onClick={() => toggleDropdown('products')}>
                                                <Row >
                                                    <Col>
                                                    <span>PRODUCTS</span>
                                                    </Col>
                                                    <Col style={{textAlign:'end'}}>
                                                    {openDropdown === 'products' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}                                                    </Col>
                                                </Row>
                                                </button>
                                            {openDropdown === 'products' && (
                                                <ul className="nested__menu_items">
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/stackerank">StackéRank</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/datasensex">DataSenseX</NavLink></li>
                                                </ul>
                                            )}
                                        </div>
                                    )}
                                    />
                                </motion.li>
                                <motion.li
                                variants={{
                                    hidden: { opacity: 0, y: 105, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 0.85, delay: 0.31, }}
                                >
                                    <SlideToggle duration={500} collapsed render={({ setCollapsibleElement   }) => (
                                        <div className="dropdown">
                                            <button className={`${classActiveServices ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown" onClick={() => toggleDropdown('industries')}>
                                            <Row >
                                                    <Col>
                                                    <span>INDUSTRIES</span>
                                                    </Col>
                                                    <Col style={{textAlign:'end'}}>
                                                 {openDropdown === 'industries' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}                                                    </Col>
                                                </Row>
                                                </button>{openDropdown === 'industries' && (
                                                <ul className="nested__menu_items">
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Real Estate</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Chemical</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Logistics</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Agri Tech</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Manufacturing</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Mar Tech</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Hi Tech</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Auto OEM</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">Health Tech</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="">SaaS</NavLink></li>
                                                </ul>
                                                )}
                                        </div>
                                    )}
                                    />
                                </motion.li>

                                <a href="/" className='HomeIcon-mobile'>
                                 <img loading='lazy' src="/Images/Footer/Final Logo 1.png" alt='deafult' />
                                 </a>
                                 <div className='Footer-line'></div>
                                 <div className='Footer-copy-mobile'>
                                    <div className='CopyRight'>
                                        {/* <img loading='lazy' src="/Images/Footer/Group 4012@2x.png" alt='default' /> */}
                                        <p className='colorWhite'>&copy; {currentYear} Evenion Technologies</p>
                                    </div>
                                    <div className='site-map-mobile'>
                                    <Link className='clSiteMap' to="/sitemap" onClick={scrollTop}>Sitemap</Link>
                                    <p className='BackBtn'  onClick={scrollTop}>Back to Top <KeyboardArrowUpIcon className='ActHover'/> </p>
                                    </div>
                                 </div>

                            </ul>
                        </motion.div>
                        
                    </div>


                </div>
            </footer >
            {/* <div className="copyright">
                <div className="copy-text ">
                    &#169; 2023 Evenion Technologies.
                </div>
            </div> */}
        </div >

<Modal isOpen={confirmmodal} className='modal-contentPro' style={{alignItems:'center'}}>
<ModalBody>
<h5 className="text-center bg-color my-4">We appreciate your subscription! </h5>
<div className='gif-set'>
<Lottie options={defaultOptions}/>
</div>
{window.innerWidth> 470?
<p className="mb-4 text-center model_patra">Stay tuned for exciting blogs and exclusive content</p>
:
<p className="mb-4 text-center model_patra">Stay tuned for exciting blogs<br/> and exclusive content</p>
}
<Button color="btn-white" className="modal_close" onClick={toggleConfirm}>Close</Button>
</ModalBody>
</Modal>

</>
    )
}

export default Footer;