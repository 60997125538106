import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "./CommonNeedsDigi.css";

const commonNeedsDigi = () => {
    return (
        <div className="common-needs-banner-digi">
            <Row className="common-needs-heading-container">
                <Col className="p-0">
                    <div className="section_main_heading heading-right"><img loading='lazy' className="heading_rotate_img" src="/Images/new/heading_logo_2.png" alt="Angular" /> <span className="heading_img white_color"><span className="orange_code">C</span>ommon <span className="orange_code">N</span>eeds</span></div>
                    <div className="section_sub_heading heading-right">Amazing things happen to your business when we connect these dots of utility &amp; value</div>
                    <div className="section_sub_text heading-right">Design, discover, <span className="orange_code">Develop</span></div>
                </Col>
            </Row>
            <Container className="digi-needs-container-padding digi_common_desktop">
                <Row className="align_items_center">
                    <Col md={6} className="engage_mob_box">
                        <div className="model_img_container img_eng_2_digi">
                            <img loading='lazy' src="/Images/new/card_1.svg" alt="model" />
                            <div className="model_caption_proj">
                                <div className="engage_logo"><img loading='lazy' src="/Images/new/digi_common_logo_1.png" alt="model" /></div>
                                <div className="engage_head engage_line_height">I need help to <span className="orange_code">expand</span> our team</div>
                                <div className="engage_logo_line"><img loading='lazy' src="/Images/new/line.png" alt="model" /></div>
                                <div className="engage_para">Our dedicated development team model is a long-term, strategic approach to securing development capacity – on your terms</div>
                            </div>
                        </div>
                        <div className="model_img_container img_eng_2_digi">
                            <img loading='lazy' src="/Images/new/card_2a.svg" alt="model" />
                            <div className="model_caption_proj_1">
                                <div className="engage_logo"><img loading='lazy' src="/Images/new/digi_common_logo_2.png" alt="model" /></div>
                                <div className="engage_head engage_line_height">I need an <span className="orange_code">MVP</span>, Fast</div>
                                <div className="engage_logo_line"><img loading='lazy' src="/Images/new/line.png" alt="model" /></div>
                                <div className="engage_para">From idea to fruition - define &amp; develop the necessary groundwork for your idea to become reality</div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="engage_mob_box">
                        <div className="model_img_container img_eng_3">
                            <img loading='lazy' src="/Images/new/card_3.svg" alt="model" />
                            <div className="model_caption_proj_2">
                                <div className="engage_logo"><img loading='lazy' src="/Images/new/digi_common_logo_3.png" alt="model" /></div>
                                <div className="engage_head engage_line_height">I need a <span className="orange_code">Turnkey</span> Software Solution</div>
                                <div className="engage_logo_line"><img loading='lazy' src="/Images/new/line.png" alt="model" /></div>
                                <div className="engage_para">In this model, our developers work on a project basis to deliver great ideas, effective design, fast development and a touch of our unique culture</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="digi-needs-container-padding digi_common_mob">
                <Row className="align_items_center m-0 digi-common-box-design">
                    <Col md={6} className="engage_mob_box">
                        <div className="digi_common_box">
                            <img loading='lazy' src="/Images/new/proj_common_1.png" alt="model" />
                            <div className="model_caption_proj digi_box_1">
                                <div className="engage_logo"><img loading='lazy' src="/Images/new/digi_common_logo_1.png" alt="model" /></div>
                                <div className="engage_head engage_line_height">I need help to <span className="orange_code">expand</span> our team</div>
                                <div className="engage_logo_line"><img loading='lazy' src="/Images/new/line.png" alt="model" /></div>
                                <div className="engage_para">Our dedicated development team model is a long-term, strategic approach to securing development capacity – on your terms</div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="engage_mob_box">
                        <div className="digi_common_box">
                            <img loading='lazy' src="/Images/new/proj_common_2.png" alt="model" />
                            <div className="model_caption_proj_2 digi_box_2">
                                <div className="engage_logo"><img loading='lazy' src="/Images/new/digi_common_logo_3.png" alt="model" /></div>
                                <div className="engage_head engage_line_height">I need a <span className="orange_code">Turnkey</span> Software Solution</div>
                                <div className="engage_logo_line"><img loading='lazy' src="/Images/new/line.png" alt="model" /></div>
                                <div className="engage_para">In this model, our developers work on a project basis to deliver great ideas, effective design, fast development and a touch of our unique culture</div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="engage_mob_box">
                        <div className="digi_common_box">
                            <img loading='lazy' src="/Images/new/proj_common_1.png" alt="model" />
                            <div className="model_caption_proj_1 digi_box_3">
                                <div className="engage_logo"><img loading='lazy' src="/Images/new/digi_common_logo_2.png" alt="model" /></div>
                                <div className="engage_head engage_line_height">I need an <span className="orange_code">MVP</span>, Fast</div>
                                <div className="engage_logo_line"><img loading='lazy' src="/Images/new/line.png" alt="model" /></div>
                                <div className="engage_para">From idea to fruition - define &amp; develop the necessary groundwork for your idea to become reality</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default commonNeedsDigi;