import React, { useEffect, useState } from "react";
// import "./Products.css";
// import Products from '../../Components/Products/Products';
import Products from "../../Components/DataSenSex/WhyDataSenSex";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import "./Datasensex.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WhyDataSenSex from "../../Components/DataSenSex/WhyDataSenSex";
import UseCases from "../../Components/DataSenSex/UseCases";
import Accordion from "../../Components/DataSenSex/Accordion";
import GetInTouch from "../../Components/DataSenSex/GetInTouchDataSensex";
import { motion } from "framer-motion";
import { Breadcrumbs, Typography } from "@mui/material";

const Datasensex = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadingComplete = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    window.scrollTo(0, localStorage.getItem("position"));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
    const onBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", onBeforeUnload);
  }, []);

  const MotionContainer = {
    hidden: { opacity: 0.5, y: 95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenContent: { opacity: 0.5, y: 95 },
    visibleContent: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { duration: 0.50, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenVideo: { opacity: 0.5, y: 95 },
    visibleVideo: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenImg: { x: -45, y: 80, scale: 0.5 },
    visibleImg: {
      x: 0,
      y: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenImgM: { x: -20, y: 35, scale: 0.5 },
    visibleImgM: {
      x: 0,
      y: 0,
      scale: 1,
      transition: { duration: 0.50, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenH: { x: -95, y: 35, scale: 0.8 },
    visibleH: {
      x: 0,
      y: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenHM: { x: -40, y: 10, scale: 0.8 },
    visibleHM: {
      x: 0,
      y: 0,
      scale: 1,
      transition: { duration: 0.55, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenvideoM: { y: 55, opacity: 0.5 },
    visiblevideoM: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.50, ease: [0, 0.71, 0.2, 1.01] },
    },
  };

  return (
    <div>
      <Helmet>
        <title>
        Automation of document capture, recognition & classification of unstructured data - DataSenseX
        </title>
        <meta
          name="description"
          content="Extraction of unstructured data | Unstructured Data Processing| Intelligent Document Processing | Automated Document Processing - DataSenseX"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Automation of document capture, recognition & classification of unstructured data - DataSenseX"
        />
        <meta
          property="og:description"
          content="Extraction of unstructured data | Unstructured Data Processing| Intelligent Document Processing | Automated Document Processing - DataSenseX"
        />
        <meta
          property="description"
          content="Extraction of unstructured data | Unstructured Data Processing| Intelligent Document Processing | Automated Document Processing - DataSenseX"
        />
        <meta
          property="og:url"
          content="Digital Transformation and Consulting Services Company - Evenion Technologies"
        />
        <meta
          property="og:site_name"
          content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation"
        />
        <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
        <meta
          property="og:image"
          content="Extracting structured data from complex documents - DataSenseX"
        />
        <meta
          property="og:image:secure_url"
          content="Digital Transformation and Consulting Services Company - Evenion Technologies"
        />
        <meta property="og:image:width" content="630" />
        <meta property="og:image:height" content="420" />
        <meta property="og:image:alt" content="Evenion" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="article:published_time"
          content="2020-11-25GMT+000012:40:02+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-08-29GMT+000014:49:23+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Automation of document capture, recognition & classification of unstructured data - DataSenseX"
        />
        <meta
          name="twitter:description"
          content="Extraction of unstructured data | Unstructured Data Processing| Intelligent Document Processing | Automated Document Processing - DataSenseX"
        />
        <meta
          name="twitter:image"
          content="Extracting structured data from complex documents - DataSenseX"
        />
      </Helmet>

      {isLoading ? (
        <>
          {/* <Products /> */}
          <div className="Main_DataSenSex" id="Main_DataSenSex_Page">
            <section className="DataSenSex_Main">
              <img loading='lazy' src="Images/DataSenSex/banner_blue.svg" alt="" />
              <Breadcrumbs
                aria-label="breadcrumb"
                separator=">>"
                className="breadcrumb_container_new"
                style={{ top: 0 }}
              >
                <div className="breadcrumb_text">
                  <a href="/">Home</a>
                </div>
                <Typography
                  color="#0F326C"
                  fontFamily=" space-Grotesksb"
                  fontWeight="300"
                >
                  Products
                </Typography>
                <Typography
                  color="#0F326C"
                  fontFamily=" space-Grotesksb"
                  fontWeight="300"
                >
                  DataSenseX
                </Typography>
              </Breadcrumbs>
              <div className="DataSenSex_Main_Inner">
                <motion.div
                  viewport={{ once: true }}
                  variants={MotionContainer}
                  initial="hidden"
                  whileInView="visible"
                  className="DataSenSex_Inner"
                >
                  <motion.img
                    viewport={{ once: true }}
                    variants={MotionContainer}
                    initial="hiddenImg"
                    whileInView="visibleImg"
                    src="Images\DataSenSex\Group 3649.svg"
                    alt=""
                  />
                  <motion.h1
                    viewport={{ once: true }}
                    variants={MotionContainer}
                    initial="hiddenH"
                    whileInView="visibleH"
                  >
                    DataSenseX
                  </motion.h1>
                  <p className="Datapara1">
                    Extracting Unstructured data from complex documents
                  </p>
                  <p className="Datapara2"> Structured Data Platform</p>
                  <div className="btn-container-stacker">
                    <div className="btn-lii-stacker">
                      <a href="/datasensex#contact_form_datasensex">
                        <span className="btn-liil-stacker">
                          Connect for Demo &nbsp;
                          <ArrowBackIcon className="rotatee-stacker " />
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="btn-stacke-tablet">
                    <a href="/datasensex#contact_form_datasensex">
                      <span className="btn-inner">
                        Connect for Demo &nbsp;
                        <ArrowBackIcon className="stacke-arrow-tablet" />
                      </span>
                    </a>
                  </div>
                </motion.div>
              </div>

              <div className="DataSenSex_Main_Inner-right">
                <motion.div
                  viewport={{ once: true }}
                  variants={MotionContainer}
                  initial="hiddenVideo"
                  whileInView="visibleVideo"
                >
                  <video className="Data-video" loop autoPlay muted playsInline>
                    <source
                      src="/Images/DataSenSex/Datasensex ‑ Made with FlexClip (1).mp4"
                      type="video/mp4"
                    />
                    <p>This browser not support in video tag</p>
                  </video>
                </motion.div>
              </div>
            </section>

{/* Mobile */}
            <section className="DataSenSex_Main_mobile_sec">
              {window.innerWidth > 480 ? (
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator=">>"
                  className="breadcrumb_container_new"
                >
                  <div className="breadcrumb_text">
                    <a href="/">Home</a>
                  </div>
                  <Typography
                    color="#0F326C"
                    fontFamily=" space-Grotesksb"
                    fontWeight="300"
                  >
                    Products
                  </Typography>
                  <Typography
                    color="#0F326C"
                    fontFamily=" space-Grotesksb"
                    fontWeight="300"
                  >
                    DataSenseX
                  </Typography>
                </Breadcrumbs>
              ) : (
                ""
              )}
              <img loading='lazy' src="Images/DataSenSex/image (1).png" alt="" />
              <div className="banner-up-DataSensex">
                <motion.div
                  viewport={{ once: false }}
                  variants={MotionContainer}
                  initial="hiddenvideoM"
                  whileInView="visiblevideoM"
                >
                  <video
                    className="Data-video-DataSensex-Mobile"
                    loop
                    autoPlay
                    muted
                    playsInline
                  >
                    <source
                      src="/Images/DataSenSex/Datasensex ‑ Made with FlexClip (1).mp4"
                      type="video/mp4"
                    />
                    <p>This browser not support in video tag</p>
                  </video>
                </motion.div>
              </div>
              <motion.div
                variants={MotionContainer}
                initial="hiddenContent"
                animate="visibleContent"
                className="DataSenSex_Main_mobile_Content1"
              >
                <motion.img
                  variants={MotionContainer}
                  initial="hiddenImgM"
                  animate="visibleImgM"
                  src="Images\DataSenSex\Group 3649.svg"
                  alt=""
                />
                <motion.h1
                  variants={MotionContainer}
                  initial="hiddenHM"
                  animate="visibleHM"
                >
                  DataSenseX
                </motion.h1>
                <p className="Datapara1">
                  Extracting Unstructured data from <br></br>complex documents
                </p>
                <p className="Datapara2"> Structured Data Platform </p>
                <div className="btn-stacke-mobile"> 
                  <a href="/datasensex#contact_form_datasensex">
                    <span className="btn-inner-mobile">
                      Connect for Demo &nbsp;
                      <ArrowBackIcon className="stacke-arrow-mobile" />
                    </span>
                  </a>
                </div>
              </motion.div>
            </section>

            <div className="Main_DataSenSex2">
              <WhyDataSenSex />
              <UseCases />
              <Accordion />
              <GetInTouch />
              <div className="add_svg">
                <img loading='lazy' src="Images/Stakerank/Group 454.svg" alt="" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadingSpinner onLoadingComplete={handleLoadingComplete} />
      )}
    </div>
  );
};

export default Datasensex;
