import React from 'react'
import { motion } from 'framer-motion';
import './TaaSBenefits.scss'

const TaaSBenefits = () => {

    const MotionContainer ={
        hidden: { opacity: 0.5, y: 300, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},

        hiddenContent: { opacity: 0.5, y: 150 },
        visibleContent: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1, }},
        
        hiddenBoximg: { y:30,  opacity: 0.5},
        visibleBoximg: { y:0,  opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenP2: { y: 35,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.15, }},
        
    hiddenMobimg: { y:35,  opacity: 0.5},
    visibleMobimg: { y:0,  opacity: 1,
    transition:{ duration: 0.55, delay: 0.10,}},

    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    hiddenMobP2: { y: 20,  opacity: 0.9 },
    visibleMobP2: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},
        
    }    
    return (
        <>
        <section className='TaasBenefits'>
        <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hidden"
                    whileInView="visible"
        
        className='taasBenefits-Content'>
        <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >
                TaaS Benefits</motion.h1>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
            className='para1'>we specialize in Testing as a Service (TaaS). TaaS is an outsourcing model designed to optimize your organization's testing activities by entrusting them to expert service providers instead of relying solely on in-house resources.</motion.p>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
            className='para2'>UNLOCKING <span className='orange_code'>EFFICIENCY</span>, MAXIMIZING <span className='orange_code'>QUALITY</span>, <br/> WHERE QUALITY MEETS <span className='orange_code'>SCALABILITY</span></motion.p>
        </motion.div>

        <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenContent"
                    whileInView="visibleContent"
        className='TaaSBox-main'>
            <div className='Taasbox-inside1'>
                <div className='TaaSbox'>
                    <motion.img loading='lazy' 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoximg"
                        whileInView="visibleBoximg"
                    src="/Images/taas/Group 3721.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >SCALABLE <span className='orange_code'>MODEL</span></motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >Adapt your testing resources to your project's needs, ensuring flexibility and efficiency in resource allocation.</motion.p>
                </div>
                <div className='TaaSbox'>
                    <motion.img loading='lazy' 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoximg"
                        whileInView="visibleBoximg"
                    src="/Images/taas/Group 3723.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >COST-<span className='orange_code'>EFFICIENCY</span></motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >Reduce in-house testing costs with our expertise, minimizing infrastructure needs</motion.p>
                </div>
                <div className='TaaSbox'>
                    <motion.img
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoximg"
                        whileInView="visibleBoximg"
                    src="/Images/taas/Group 3724.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >STANDARDIZATION</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >Ensure consistency and uniformity in your testing processes, promoting higher quality and reliability.</motion.p>
                </div>
            </div>

            <div className='Taasbox-inside2'>
                <div className='TaaSbox'>
                    <motion.img loading='lazy' 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoximg"
                        whileInView="visibleBoximg"
                    src="/Images/taas/Group 3722.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >LICENSING <span className='orange_code'>BENEFITS</span></motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >Eliminate the complexity of managing testing tool licenses with our comprehensive TaaS solutions.</motion.p>
                </div>
                <div className='TaaSbox'>
                    <motion.img
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoximg"
                        whileInView="visibleBoximg"
                    src="/Images/taas/Group 3725.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >DATA <span className='orange_code'>CENTRALIZATION</span></motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >Securely store and manage your testing data, enhancing collaboration and accessibility for your team.</motion.p>
                </div>
            </div>
        </motion.div>
    </section>

{/* Mobile */}
<section className='TaasBenefits-mobile'>
        <motion.div
                            viewport={{  once: true}}
                            variants={MotionContainer}
                            initial="hiddenMob"
                            whileInView="visibleMob"
        className='taasBenefits-Content-mobile'>
            <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"            
            >TaaS Benefits</motion.h1>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP1"
                        whileInView="visibleMobP1"            
            className='para1'>we specialize in Testing as a Service (TaaS). TaaS is an outsourcing model designed to optimize your organization's testing activities by entrusting them to expert service providers instead of relying solely on in-house resources.</motion.p>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP2"
                        whileInView="visibleMobP2"            
            className='para2'>UNLOCKING <span className='orange_code'>EFFICIENCY</span>, <br></br> MAXIMIZING <span className='orange_code'>QUALITY</span>, WHERE<br></br> QUALITY MEETS <span className='orange_code'>SCALABILITY</span></motion.p>
        </motion.div>
        <div className='taasBenefits-Content-mobile2'>
        <motion.div
                            viewport={{  once: true}}
                            variants={MotionContainer}
                            initial="hiddenMob"
                            whileInView="visibleMob"
        className='Taasbox-inside-mobile'>
                <div className='TaaSbox'>
                    <motion.img
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobimg"
                        whileInView="visibleMobimg"                    
                    src="/Images/taas/Group 3721.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"                            
                    >SCALABLE <span className='orange_code'>MODEL</span></motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP2"
                        whileInView="visibleMobP2"                      
                    >Adapt your testing resources to your project's needs, ensuring flexibility and efficiency in resource allocation.</motion.p>
                </div>
                <div className='TaaSbox'>
                    <motion.img
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobimg"
                        whileInView="visibleMobimg"                    
                    src="/Images/taas/Group 3723.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"                            
                    >COST-<span className='orange_code'>EFFICIENCY</span></motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP2"
                        whileInView="visibleMobP2"                       
                    >Reduce in-house testing costs with our expertise, minimizing infrastructure needs</motion.p>
                </div>
                <div className='TaaSbox'>
                    <motion.img
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP2"
                        whileInView="visibleMobP2"                     
                    src="/Images/taas/Group 3724.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"                           
                    >STANDARDIZATION</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP2"
                        whileInView="visibleMobP2"                      
                    >Ensure consistency and uniformity in your testing processes, promoting higher quality and reliability.</motion.p>
                </div>
                <div className='TaaSbox'>
                    <motion.img
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobimg"
                        whileInView="visibleMobimg"                    
                    src="/Images/taas/Group 3722.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"                            
                    >LICENSING <span className='orange_code'>BENEFITS</span></motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP2"
                        whileInView="visibleMobP2"                     
                    >Eliminate the complexity of managing testing tool licenses with our comprehensive TaaS solutions.</motion.p>
                </div>
                <div className='TaaSbox'>
                    <motion.img
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobimg"
                        whileInView="visibleMobimg"                    
                    src="/Images/taas/Group 3725.svg" alt="default" />
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"                           
                    >DATA <span className='orange_code'>CENTRALIZATION</span></motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP2"
                        whileInView="visibleMobP2"                     
                    >Securely store and manage your testing data, enhancing collaboration and accessibility for your team.</motion.p>
                </div>
            </motion.div>
        </div>

</section>
    </>
  )
}

export default TaaSBenefits