import React, { useEffect, useState } from "react";
import './Career.scss';
import '../AboutUs/AboutUs.scss'
import ourCulture from "../../Components/OurCulture/OurCulture";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { Col, Row } from "reactstrap";

const OurCulture = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: false });

    const [isActive, setIsActive] = useState(false);
    const handleClickChange = event => {
        setIsActive(current => !current);
    };
    const viewVariants = {
        hidden: { y: 20 },
        visible: {
            y: 0,
            transition: {
                duration: 1,
                delay: 0.15
            }
        }

    }
    return (
        <>
            <motion.section
                viewport={{ ref, once: true }}
                variants={{
                    hidden: { y: 300, scale: 0.8, opacity: 0.5 },
                    visible: { y: 0, scale: 1, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.25 }}
                className="Our-Approach Our-Approach-mobile Our-Approach-tablet col-md-12">

                <div className="Our-Approach-Ex-Container Our-Approach-Ex-Container-mobile Our-Approach-Ex-Container-tablet col-md-12" 
                style={{marginBottom: "1%"}}
                >
                    <motion.h1
                        variants={{
                            hidden: { y: 40, scale: 0.9, opacity: 0.5 },
                            visible: { y: 0, scale: 1, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25, }}
                        className='open_oppor col-md-12' >Our Culture</motion.h1>
                    <motion.div
                        viewport={{ ref, once: false }}
                        variants={{
                            hidden: { y: 20, opacity: 0.3 },
                            visible: { y: 0, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25, }}
                    >
                        <motion.p
                            viewport={{ ref, once: false }}
                            variants={{
                                hidden: { y: 30, opacity: 0.2 },
                                visible: { y: 0, opacity: 1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={{ duration: 1, delay: 0.15, }}
                            className='Our-p col-md-12' style={{fontSize:20, paddingBottom:"0%"}}>We are Engineers, Designers, Analysts and Programmers</motion.p>
                        <motion.p
                            viewport={{ ref, once: false }}
                            variants={{
                                hidden: { y: 30, opacity: 0.2 },
                                visible: { y: 0, opacity: 1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={{ duration: 1, delay: 0.15, }}
                            className='Our-pa col-md-12' style={{paddingBottom:'4%', fontSize:18}}>SHAPING FUTURE<span className='orange_code'> DIGITALLY </span> </motion.p>
                    </motion.div>
                    {window.innerWidth > 767 ?
                        <div style={{paddingRight:"3%", paddingLeft:'3%', marginBottom:"5%"}}>
                            <Row>
                                <Col>
                                    <motion.div className="border_culture"
                                        viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <motion.div className="culture_a1"
                                            viewport={{ ref, once: false }}
                                            variants={{
                                                hidden: { y: 20, },
                                                visible: { y: 0, },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25, }}>
                                            <Row>
                                                <motion.img loading='lazy' src="/Images/new/career_1.png" alt="how_we_add" className="culture_img"
                                                    viewport={{ ref, once: false }}
                                                    variants={{
                                                        hidden: { y: 20, opacity: 0.3 },
                                                        visible: { y: 0, opacity: 1 },
                                                    }}
                                                    initial="hidden"
                                                    whileInView="visible"
                                                    transition={{ duration: 1, delay: 0.25, }} />
                                                <div className="culture_content">
                                                    <div className="culture_head"><span className="orange_code">Problem Solvers</span></div>
                                                    <div className="culture_para">We are problem solvers first and strongly believe all the problems have optimized solutions. We give cross-functional problem solving a whole new meaning. &nbsp; &nbsp;</div>
                                                </div>
                                            </Row>
                                        </motion.div>
                                    </motion.div>
                                </Col>
                                <Col>
                                    <motion.div className="border_culture"
                                        viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <motion.div className="culture_a1"
                                            viewport={{ ref, once: false }}
                                            variants={{
                                                hidden: { y: 20 },
                                                visible: { y: 0 },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25, }}>
                                            <Row>
                                                <motion.img loading='lazy' src="/Images/new/career_3.png" alt="how_we_add" className="culture_img2"
                                                    viewport={{ ref, once: false }}
                                                    variants={{
                                                        hidden: { y: 20 },
                                                        visible: { y: 0 },
                                                    }}
                                                    initial="hidden"
                                                    whileInView="visible"
                                                    transition={{ duration: 1, delay: 0.25, }} />
                                                <div className="culture_content">
                                                    <div className="culture_head"><span className="orange_code">Empathy</span></div>
                                                    <div className="culture_para">We strongly believe empathy is a key factor in collaboration. Since we're responsible for building our client's dreams, we enjoy walking a mile in their shoes.</div>
                                                </div>
                                            </Row>
                                        </motion.div>
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <motion.div className="border_culture"
                                        viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <motion.div className="culture_a1" viewport={{ ref, once: false }}
                                            variants={{
                                                hidden: { y: 20 },
                                                visible: { y: 0 },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25, }}>
                                            <Row>
                                                <motion.img loading='lazy' src="/Images/new/career_2.png" alt="how_we_add" className="culture_img"
                                                    viewport={{ ref, once: false }}
                                                    variants={{
                                                        hidden: { y: 20 },
                                                        visible: { y: 0 },
                                                    }}
                                                    initial="hidden"
                                                    whileInView="visible"
                                                    transition={{ duration: 1, delay: 0.25, }} />
                                                <div className="culture_content">
                                                    <div className="culture_head"><span className="orange_code">Transparency</span></div>
                                                    <div className="culture_para">We believe clear and open communication plays a vital role in helping us deliver our best. We pride ourselves for transparency at all levels and aspects of work. &nbsp;</div>
                                                </div>
                                            </Row>
                                        </motion.div>
                                    </motion.div>
                                </Col>
                                <Col>
                                    <motion.div className="border_culture"
                                        viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <motion.div className="culture_a1"
                                            viewport={{ ref, once: false }}
                                            variants={{
                                                hidden: { y: 20 },
                                                visible: { y: 0 },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25, }}>
                                            <Row>
                                                <motion.img loading='lazy' src="/Images/new/career_4.png" alt="how_we_add" className="culture_img4" height={100}
                                                    viewport={{ ref, once: false }}
                                                    variants={{
                                                        hidden: { y: 20 },
                                                        visible: { y: 0 },
                                                    }}
                                                    initial="hidden"
                                                    whileInView="visible"
                                                    transition={{ duration: 1, delay: 0.25, }} />
                                                <div className="culture_content">
                                                    <div className="culture_head"><span className="orange_code">We Don’t Give Up</span></div>
                                                    <div className="culture_para">We are proactive. Instead of saying  "It's not possible", we list a series of possibilities and alternatives and get the job done.                                     </div>
                                                </div>
                                            </Row>
                                        </motion.div>
                                    </motion.div>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div style={{marginBottom:"15%"}}>
                            <Row>
                                <motion.div className="border_culture-mobile"
                                    viewport={{ ref, once: false }}
                                    variants={{
                                        hidden: { y: 20 },
                                        visible: { y: 0 },
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25, }}>
                                    <motion.div className="culture_a1-mobile"
                                        viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <Row>
                                            <motion.div className="culture_content" viewport={{ ref, once: false }}
                                                variants={{
                                                    hidden: { y: 20 },
                                                    visible: { y: 0 },
                                                }}
                                                initial="hidden"
                                                whileInView="visible"
                                                transition={{ duration: 1, delay: 0.25, }}>
                                                {window.innerWidth>481?
                                                <Row>
                                                    <motion.img loading='lazy' src="/Images/new/career_1.png" alt="how_we_add" className="culture_img-mobile"/>
                                                    <div className="culture_head"><span className="orange_code">Problem Solvers</span></div>
                                                </Row>
                                                    :
                                                    <>
                                                     <motion.img loading='lazy' src="/Images/new/career_1.png" alt="how_we_add" className="culture_img-mobile"/> <br/>
                                                    <div className="culture_head"><span className="orange_code">Problem Solvers</span></div>
                                                    </>
                                                    }
                                                <div className="culture_para-mobile">We are problem solvers first and strongly believe all the problems have optimized solutions. We give cross-functional problem solving a whole new meaning.</div>
                                                <br />
                                            </motion.div>
                                        </Row>
                                    </motion.div>
                                </motion.div>
                            </Row>
                            <Row>
                                <motion.div className="border_culture-mobile"
                                    viewport={{ ref, once: false }}
                                    variants={{
                                        hidden: { y: 20 },
                                        visible: { y: 0 },
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25, }}>
                                    <motion.div className="culture_a1-mobile" viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <Row>
                                            <motion.div className="culture_content" viewport={{ ref, once: false }}
                                                variants={{
                                                    hidden: { y: 20 },
                                                    visible: { y: 0 },
                                                }}
                                                initial="hidden"
                                                whileInView="visible"
                                                transition={{ duration: 1, delay: 0.25, }}>
                                                     {window.innerWidth>481?
                                                <Row>
                                                <motion.img loading='lazy' src="/Images/new/career_3.png" alt="how_we_add" className="culture_img2-mobile" />
                                                <div className="culture_head"><span className="orange_code">Empathy</span></div>
                                                </Row>
                                                    :
                                                    <>
                                                    <motion.img loading='lazy' src="/Images/new/career_3.png" alt="how_we_add" className="culture_img2-mobile" /><br/>
                                                <div className="culture_head"><span className="orange_code">Empathy</span></div>
                                                    </>
                                                    }
                                                
                                                <div className="culture_para-mobile">We strongly believe empathy is a key factor in collaboration. Since we're responsible for building our client's dreams, we enjoy walking a mile in their shoes.</div>
                                                <br />
                                            </motion.div>
                                        </Row>
                                    </motion.div>
                                </motion.div>
                            </Row>
                            <Row>
                                <motion.div className="border_culture-mobile" viewport={{ ref, once: false }}
                                    variants={{
                                        hidden: { y: 20 },
                                        visible: { y: 0 },
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25, }}>
                                    <motion.div className="culture_a1-mobile" viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <Row>
                                            <motion.div className="culture_content"
                                                viewport={{ ref, once: false }}
                                                variants={{
                                                    hidden: { y: 20 },
                                                    visible: { y: 0 },
                                                }}
                                                initial="hidden"
                                                whileInView="visible"
                                                transition={{ duration: 1, delay: 0.25, }}>
                                                       {window.innerWidth>481?
                                                <Row>
                                                <motion.img loading='lazy' src="/Images/new/career_2.png" alt="how_we_add" className="culture_img-mobile" />
                                                <div className="culture_head"><span className="orange_code">Transparency</span></div>
                                            </Row>
                                                    :
                                                    <>
                                                     <motion.img loading='lazy' src="/Images/new/career_2.png" alt="how_we_add" className="culture_img-mobile" /><br/>
                                                <div className="culture_head"><span className="orange_code">Transparency</span></div>
                                                    </>
                                                    }
                                               
                                                <div className="culture_para-mobile">We believe clear and open communication plays a vital role in helping us deliver our best. We pride ourselves for transparency at all levels and aspects of work.</div>
                                                <br />
                                            </motion.div>
                                        </Row>
                                    </motion.div>
                                </motion.div>
                            </Row>
                            <Row>
                                <motion.div className="border_culture-mobile"
                                    viewport={{ ref, once: false }}
                                    variants={{
                                        hidden: { y: 20 },
                                        visible: { y: 0 },
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25, }}>
                                    <motion.div className="culture_a1-mobile"
                                        viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <Row>
                                            <motion.div className="culture_content" viewport={{ ref, once: false }}
                                                variants={{
                                                    hidden: { y: 20 },
                                                    visible: { y: 0 },
                                                }}
                                                initial="hidden"
                                                whileInView="visible"
                                                transition={{ duration: 1, delay: 0.25, }}>
                                                        {window.innerWidth>481?
                                               <Row>
                                               <motion.img loading='lazy' src="/Images/new/career_4.png" alt="how_we_add" className="culture_img4-mobile" height={100} />
                                               <div className="culture_head"><span className="orange_code">We Don’t Give Up</span></div>
                                           </Row>
                                                    :
                                                    <>
                                                     <motion.img loading='lazy' src="/Images/new/career_4.png" alt="how_we_add" className="culture_img4-mobile" height={100} /> <br/>
                                               <div className="culture_head"><span className="orange_code">We Don’t Give Up</span></div>
                                                    </>
                                                    }
                                                
                                                <div className="culture_para-mobile">We are proactive. Instead of saying "It's not possible", we list a series of possibilities and alternatives and get the job done.</div>
                                                <br />
                                            </motion.div>
                                        </Row>
                                    </motion.div>
                                </motion.div>
                            </Row>
                        </div>
                    }
                </div>
            </motion.section>
        </>
    )
}
export default OurCulture;