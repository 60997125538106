import React, { useState } from 'react';
import './Accordion.scss';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';


const Accordion = () => {
  const [expandedCard, setExpandedCard] = useState(0);

  const MotionContainerData ={
    hidden: { x: 0, y: 0, scale: 0.2, opacity: 0.2 },
    visible: { x: 0, y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hidden1: { x: 0, y: 0, scale: 0.2, opacity: 0.2 },
    visible1: { x: 0, y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hidden2: { x: 0, y: 0, scale: 0.2, opacity: 0.2 },
    visible2: { x: 0, y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hidden3: { x: 0, y: 0, scale: 0.2, opacity: 0.2 },
    visible3: { x: 0, y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hidden4: { x: 0, y: 0, scale: 0.2, opacity: 0.2 },
    visible4: { x: 0, y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hidden5: { x: 0, y: 0, scale: 0.2, opacity: 0.2 },
    visible5: { x: 0, y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hiddenHead: { x: 20, y: 0,  opacity: 0.5 },
    visibleHead: { x: 0, y: 0,  opacity: 1 ,
    transition:{ duration: 0.25,  }},

    hiddenPara: { y: 15,    },
    visiblePara: { y: 0,   
    transition:{ duration: 0.75,  }},

    hiddenContainer: { y: 175, opacity: 0.2   },
    visibleContainer: { y: 0,   opacity: 1,
    transition:{ duration: 1,  }},

    hiddenContainer1: { y: 150, opacity: 0.2,   },
    visibleContainer1: { y: 0,   opacity: 1,
    transition:{ duration: 1,   }},
}

  const cards = [
    { title: 'AUTOMATE INVOICE PROCESSING & REDUCE COST', content: 'Simplify and optimize your invoice processing workflows with DataSenseX. Experience an impressive 85% reduction in processing costs per document by automating invoice classification and data extraction. Seamlessly integrate with your existing RPA or ERP system for efficient and scalable invoice processing' },
    { title: 'STREAMLINE PURCHASE ORDER PROCESSING', content: 'Say goodbye to manual efforts and cut down processing costs by over 90% per order. DataSenseX enables automated classification and data extraction for streamlined purchase order management. Integrate seamlessly with your RPA, CRM, or ERP system to achieve end-to-end automation' },
    { title: 'STREAMLINE INSURANCE CLAIMS PROCESSING', content: 'Drive down costs in insurance claims processing by leveraging AI-powered automation. DataSenseX automates classification and data extraction from various claim documents, streamlining the entire claims handling process.' },
    { title: 'EFFICIENT LOAN APPLICATION PROCESSING & REDUCED UNDERWRITING COSTS', content: 'Optimize your loan application processing with DataSenseX. Automate classification and data extraction to verify critical information, such as income, expenses, and assets. Experience substantial reductions in underwriting costs while ensuring accuracy and compliance' },
    { title: 'ENHANCE DOCUMENT MANAGEMENT IN HR ONBOARDING', content: 'Simplify HR onboarding processes by automating document classification & data extraction. DataSenseX helps you achieve streamlined operations, improving efficiency & compliance throughout employee onboarding journey.' },
    { title: 'ACCELERATE MEDICAL RECORDS DIGITIZATION AND ANALYSIS', content: 'Experience a transformation in healthcare with DataSenseX. Automate the digitization of medical records & enable efficient data extraction for analysis. Improve patient care & decision-making while reducing administrative burdens' },
  ];

  const toggleCard = (index) => {
    setExpandedCard((prevExpandedCard) => (prevExpandedCard === index ? 0 : index));
  };

  const imageView = () =>{
    if(expandedCard === 0){
        return   <>
        <motion.div
        viewport={{once: false}}
        variants={MotionContainerData}
        initial="hidden"
        animate="visible"
        >
        <img  src="Images\DataSenSex\6.png" alt="1" />
        </motion.div>
          </>
        } else if(expandedCard===1){
        return (
        <motion.img  
            viewport={{once: false}}
            variants={MotionContainerData}
            initial="hidden1"
            animate="visible1"
            src="Images\DataSenSex\5.png" alt="2" />
        )
        } else if(expandedCard===2){
        return (
        <motion.div 
        viewport={{once: false}}
        variants={MotionContainerData}
        initial="hidden2"
        animate="visible2"
        >
          <motion.img 
            src="Images\DataSenSex\9.png" alt="3" />
            </motion.div>
            )
        }else if(expandedCard===3){
        return  <motion.img 
            viewport={{once: false}}
            variants={MotionContainerData}
            initial="hidden3"
            animate="visible3"
            src="Images\DataSenSex\11.png" alt="4" />
        }else if(expandedCard===4){
        return (
        <motion.div
        viewport={{once: false}}
        variants={MotionContainerData}
        initial="hidden4"
        animate="visible4"
        >
        <motion.img 
            src="Images\DataSenSex\8.png" alt="5" />
            </motion.div>
        )
        }else {
          return  <motion.img 
          viewport={{once: false}}
          variants={MotionContainerData}
          initial="hidden5"
          animate="visible5"
          src="Images\DataSenSex\7.png" alt="6" />
        }
  }

  return (
    <>
    <motion.section 
      viewport={{once: true}}
      variants={MotionContainerData}
      initial="hiddenContainer1"
      whileInView="visibleContainer1"
    className='Toggle-State'>

    <div className="expanding-accordion-DataSensex">
      {cards.map((card, index) => (
        <div
        key={index}
          className={`card-DataSensex ${expandedCard === index ? 'expanded-DataSensex' : ''}`}
          >
          <div className="card-header-DataSensex" onClick={() => toggleCard(index)} >
            <motion.h3 
            viewport={{once: true}}
            variants={MotionContainerData}
            initial="hiddenHead"
            whileInView="visibleHead"
            className="card-title-DataSensex" >{card.title}   </motion.h3>
            {  expandedCard === index ?  <CloseIcon className='Rotate-Open' /> :  <CloseIcon className='Rotate-Close'  /> }
          </div>
          {expandedCard === index && (
            <motion.div 
            viewport={{once: false}}
            variants={MotionContainerData}
            initial="hiddenPara"
            animate="visiblePara"
            className="card-content-DataSensex">
              {card.content}
              {/* {imageView()} */}
            </motion.div>
          )}
        </div>
      ))}
    </div>
      <motion.div 
        viewport={{once: false}}
        variants={MotionContainerData}
        initial="hidden3"
        whileInView="visible3"
        className='img-Conatiner-toggle'>
        {imageView()}
      </motion.div>
      </motion.section>

{/* Mobile */}
<section className='Toggle-State-Mobile'>
<motion.div 
  viewport={{once: true}}
  variants={MotionContainerData}
  initial="hiddenContainer"
  whileInView="visibleContainer"
className="expanding-accordion-DataSensex-Mobile">
  {cards.map((card, index) => (
    <div
    key={index}
      className={`card-DataSensex-Mobile ${expandedCard === index ? 'expanded-DataSensex-Mobile' : ''}`}>
      <div className="card-header-DataSensex-Mobile" onClick={() => toggleCard(index)} >
        <h3 className="card-title-DataSensex-Mobile" >{card.title}   </h3>
        {  expandedCard === index ?  <CloseIcon className='Rotate-Open' /> :  <CloseIcon className='Rotate-Close'  /> }
      </div>
      {expandedCard === index && (
        <motion.div 
        viewport={{once: false}}
        variants={MotionContainerData}
        initial="hiddenPara"
        whileInView="visiblePara"
        className="card-content-DataSensex-Mobile">
          {card.content}
          <div className='inside'>
          { imageView()}
          </div>
        </motion.div>
      )}
    </div>
  ))}
</motion.div>
  </section>
      </>
  );
};

export default Accordion;