import React, { useRef } from 'react';
import { motion,    useInView,  } from 'framer-motion';
import "./WhyEvenion.scss"

const WhyEvenion = () => {
    

  const MotionContainer ={
    hidden: { y: 300, scale: 0.8, opacity: 0.5 },
    visible: { y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, }},

    hiddenBoxP1: { y:40,  opacity: 0.5},
    visibleBoxP1: { y:0,  opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoxP2: { y:30,  opacity: 0.5},
    visibleBoxP2: { y:0,  opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoxIcon: { y:200,  opacity: 0.2},
    visibleBoxIcon: {y:0,  opacity: 1,
    transition:{ duration: 0.95, }},
    
    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.15, }},
        
    hiddenMobimg: { y:35,  opacity: 0.5},
    visibleMobimg: { y:0,  opacity: 1,
    transition:{ duration: 0.55, delay: 0.10,}},

    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    hiddenMobP2: { y: 20,  opacity: 0.9 },
    visibleMobP2: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},
}


const ref = useRef(null)
const isInView = useInView(ref, {once: false});


  return (
    <>
    <section className='whyEvenion_Analytics'>
        <motion.div
            viewport={{ ref, once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible">
        <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
        >Why Evenion</motion.h1>
        </motion.div>
        <motion.div 
            viewport={{once: true}}
            variants={MotionContainer}
            initial="hiddenBoxIcon"
            whileInView="visibleBoxIcon"
        className='Why_evenion_inner'>
            <div className='Why_evenion_box1 gradient-border-mask1'>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                className="Head_indus">Data and analytics strategy</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"  
                className='para-text para1'>We’ll map your analytics initiatives to quantifiable business outcomes with a data-driven approach.</motion.p>
            </div>
            <div className='Why_evenion_box2 gradient-border-mask1'>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                className="Head_indus">Data discovery and augmentation</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"  
                className='para-text para2'>We bring you 360-degree customer views by augmenting your assets with third-party data and predictive analytics.</motion.p>
            </div>
            <div className='Why_evenion_box3 gradient-border-mask1'>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                className="Head_indus">Data management and beyond</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"  
                className='para-text para3'>In addition to data synthesis and analytics, we assist with governance, monetization and compliance.</motion.p>
            </div>
            <div className='Why_evenion_box4 gradient-border-mask1'>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                className="Head_indus">Value <br/> Realization</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"  
                className='para-text para4'>Find the real value of data by defining what really matters to the business – in the short & long term – and then integrating the data and AI agenda into a strategic roadmap aligned to business priorities.</motion.p>
            </div>
        </motion.div>
    </section> 


{/* Mobile */}
    <section className='whyEvenion_Analytics_mobile'>
        <motion.div
            viewport={{ ref, once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
            >
        <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
        >Why Evenion</motion.h1>
        </motion.div>

        <motion.div 
            viewport={{once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
        className='Why_evenion_inner_mobile1 '>
            <div className='Why_evenion_box1_mobile gradient-border-mask-mobile'>
                <motion.h1 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"
                className="Head_indus">Data and analytics strategy</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"                
                className='para-text para1'>We’ll map your analytics initiatives to quantifiable business outcomes with a data-driven approach.</motion.p>
            </div>
            <div className='Why_evenion_box2_mobile gradient-border-mask-mobile'>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"                
                className="Head_indus">Data discovery and augmentation</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"
                className='para-text para2'>We bring you 360-degree customer views by augmenting your assets with third-party data and predictive analytics.</motion.p>
            </div>
        </motion.div>
        <motion.div 
            viewport={{once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
            className='Why_evenion_inner_mobile2'>
            <div className='Why_evenion_box3_mobile gradient-border-mask-mobile'>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"      
                className="Head_indus">Data management and beyond</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"    
                className='para-text para3'>In addition to data synthesis and analytics, we assist with governance, monetization and compliance.</motion.p>
            </div>
            <div className='Why_evenion_box4_mobile gradient-border-mask-mobile'>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"     
                className="Head_indus">Value <br/> Realization</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"
                className='para-text para4'>Find the real value of data by defining what really matters to the business – in the short & long term – and then integrating the data and AI agenda into a strategic roadmap aligned to business priorities.</motion.p>
            </div>
        </motion.div>
    </section> 

    </>
  )
}

export default WhyEvenion