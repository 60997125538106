import React, { useState, useRef } from 'react';
import './AboutUs.scss';
import './Leadership.scss'
import { motion,    useInView,  } from 'framer-motion';

const Leadership = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, {once: false});

const storeData1 = 
" Sudhir founded Evenion Technologies in 2016 and is its Chief Executive Officer. With over 15 years of experience in the information technology sector, he brings expertise in Global Channel Management, Strategy Implementation, Global Sales, Global Business Management, GTM (Go-to-Market), and Channel Development. His experience spans Professional Services, Software Products and Consumer Products. Prior to establishing Evenion, Sudhir served as the Global Head and Vice President of a US-based SAP Partner firm, playing a key role in establishing their India operations. He has also been associated with renowned companies such as Tesla Motors and Cooper Industries in the USA. Sudhir holds an MBA from the Stuart School of Business at the Illinois Institute of Technology, Illinois, further enhancing his business acumen and leadership capabilities.";


return (
<>
<section 
className='LeaderShip-Container col-md-12' id='our_leader'>
                    <motion.h1
                        viewport={{ref, once:true}}
                        variants={{
                            hidden: { y: 150, scale:1.1, opacity: 0.2 },
                            visible: { y: 0, scale: 1, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 0.9, delay: 0.25,}}
                    >Leadership</motion.h1>
                    <motion.div    
                    viewport={{ref, once:true}}
                    variants={{
                        hidden: { y: 40,  opacity: 0.9 },
                        visible: { y: 0,  opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}}               
                    className='LeaderShip-Container-inside   row'>
                                <motion.div                               
                                className='Founder-Container col-md-11 '>
                                    <div className='Founder-img-Container'> <img loading='lazy' src="/Images/new/Group 7474.png" alt="founder" />
                                    <div className="linked-inn linked-inn-max">
                                        <a href="https://www.linkedin.com/in/sudhirharegoppa/" target="_blank"><img loading='lazy' src="/Images/new/linkedin-blue.png" alt="box" /></a>
                                    </div>
                                    </div>
                                    <div className='Founder-Content-Container'>
                                        <motion.p 
                                        viewport={{ref, once:false}}
                                        variants={{
                                            hidden: { y: 30, opacity: 0.8 },
                                            visible: { y: 0, opacity: 1 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 0.9, delay: 0.25,}}  
                                        className='Founder-Name'>SUDHIR HAREGOPPA</motion.p>
                                        <motion.p 
                                        viewport={{ref, once:false}}
                                        variants={{
                                            hidden: { y: 30, opacity: 0.8 },
                                            visible: { y: 0, opacity: 1 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 0.9, delay: 0.25,}}  
                                        className='Founder-Role'>FOUNDER & CEO</motion.p>
                                        <motion.p 
                                        viewport={{ref, once:false}}
                                        variants={{
                                            hidden: { y: 50, opacity: 0.8 },
                                            visible: { y: 0, opacity: 1 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 0.9, delay: 0.25,}} 
                                        className='Founder-Stories'> Sudhir founded Evenion Technologies in 2016 and is its Chief Executive Officer. With over 15 years of experience in the information technology sector, he brings expertise in Global Channel Management, Strategy Implementation, Global Sales, Global Business Management, GTM (Go-to-Market), and Channel Development. His experience spans Professional Services, Software Products and Consumer Products.
                                        Prior to establishing Evenion, Sudhir served as the Global Head and Vice President of a US-based SAP Partner firm, playing a key role in establishing their India operations. He has also been associated with renowned companies such as Tesla Motors and Cooper Industries in the USA.
                                        Sudhir holds an MBA from the Stuart School of Business at the Illinois Institute of Technology, Illinois, further enhancing his business acumen and leadership capabilities.</motion.p>
                                        
                                    {/* //  className='Founder-Stories'>{storeData1.length > 600 ? storeData1.substring(0, 600) + " ..." : storeData1} </motion.p> */}
                                    </div>
                                </motion.div>

                                <motion.div 
                                viewport={{ref, once:true}}
                                variants={{
                                    hidden: { y: 40,  opacity: 0.9 },
                                    visible: { y: 0,  opacity: 1 },
                                }}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 0.9, delay: 0.25,}}
                                className='CO-Founder-Container col-md-11 '>
                                    <div className='CO-Founder-img-Container'> <img loading='lazy' src="/Images/new/Group 7472.png" alt="founder" />
                                    <div className="linked-in-preeti  linked-in-preeti-max">
                                    <a href="https://www.linkedin.com/in/preetichaturvedi/" target="_blank"><img loading='lazy' src="/Images/new/linkedin-blue.png" alt="box" /></a>
                                    </div>
                                    </div>
                                    <div className='CO-Founder-Content-Container'>
                                        <motion.p 
                                        viewport={{ref, once:false}}
                                        variants={{
                                            hidden: { y: 30, opacity: 0.8 },
                                            visible: { y: 0, opacity: 1 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 0.9, delay: 0.25,}}  
                                        className='CO-Founder-Name'>PREETI CHATURVEDI</motion.p>
                                        <motion.p 
                                        viewport={{ref, once:false}}
                                        variants={{
                                            hidden: { y: 30, opacity: 0.8 },
                                            visible: { y: 0, opacity: 1 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 0.9, delay: 0.25,}}  
                                        className='CO-Founder-Role'>CO-FOUNDER & DIRECTOR</motion.p>
                                        <motion.p 
                                        viewport={{ref, once:false}}
                                        variants={{
                                            hidden: { y: 50, opacity: 0.8 },
                                            visible: { y: 0, opacity: 1 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 0.9, delay: 0.25,}}  
                                        className='CO-Founder-Stories'>Preeti, the Co-Founder of Evenion, leads the Digital Transformation, Strategy, and Consulting business. With a background as a technologist turned product person, she brings nearly 15 years of experience encompassing Technology, Product Development, User Experience, Product Strategy, and Roadmap.
                                        Preeti has been involved with numerous early-stage startups in India and the USA, including FirstCry, Hubzu, as well as esteemed tech veterans like Rediff and IBM. Her diverse experience has honed her expertise in driving innovation and delivering impactful solutions.
                                        Preeti holds a Master’s in Technology from the International Institute of Information Technology, Bangalore, solidifying her technical knowledge and proficiency. She is an alumni of IIMB Bangalore and a flag-bearer of the 10K Women Entrepreneurs program, sponsored by Goldman Sachs and NSRCEL.</motion.p>
                                    </div>
                                </motion.div>
                    </motion.div>
                </section>
{/* tablet */}

<section className='LeaderShip-Container-tablet  col-md-12' id='our_leader1'>
                    <motion.h1
                    viewport={{ref, once:true}}
                    variants={{
                        hidden: { y: 150, scale:1.3, opacity: 0.2 },
                        visible: { y: 0, scale: 1, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 1, delay: 0.25,}}
                    >Leadership</motion.h1>
                    <motion.div                  
                    className='LeaderShip-Container-inside-tablet   row'>
                                <motion.div  
                                viewport={{ref, once:false}}
                                variants={{
                                    hidden: { y: 50,  opacity: 0.7 },
                                    visible: { y: 0, opacity: 1 },
                                }}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 1, delay: 0.25,}}                              
                                className='Founder-Container-tablet  col-md-11 '>
                                    <div className='Founder-img-Container-tablet '> <img loading='lazy' src="/Images/new/Group 7474.png" alt="founder" />           
                                    <div className="linked-in">
                                        <a href="https://www.linkedin.com/in/sudhirharegoppa/" target="_blank"><img loading='lazy' src="/Images/new/linkedin-blue.png" alt="box" /></a>
                                    </div>
                                    </div>
                                    <div className='Founder-Content-Container-tablet '>
                                        <motion.p className='Founder-Name-tablet '>SUDHIR HAREGOPPA</motion.p>
                                        <motion.p className='Founder-Role-tablet '>FOUNDER & CEO</motion.p>
                                        <motion.p  className='Founder-Stories-tablet '> Sudhir founded Evenion Technologies in 2016 and is its Chief Executive Officer. With over 15 years of experience in the information technology sector, he brings expertise in Global Channel Management, Strategy Implementation, Global Sales, Global Business Management, GTM (Go-to-Market), and Channel Development. His experience spans Professional Services, Software Products and Consumer Products.
Prior to establishing Evenion, Sudhir served as the Global Head and Vice President of a US-based SAP Partner firm, playing a key role in establishing their India operations. He has also been associated with renowned companies such as Tesla Motors and Cooper Industries in the USA.
Sudhir holds an MBA from the Stuart School of Business at the Illinois Institute of Technology, Illinois, further enhancing his business acumen and leadership capabilities. </motion.p>
                                    </div>
                                </motion.div>

                                <motion.div  
                                viewport={{ref, once:false}}
                                variants={{
                                    hidden: { y: 60,  opacity: 0.7 },
                                    visible: { y: 0, opacity: 1 },
                                }}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 1, delay: 0.25,}}  
                                className='CO-Founder-Container-tablet  col-md-11 '>
                                    <div className='CO-Founder-img-Container-tablet '> <img loading='lazy' src="/Images/new/Group 7472.png"  alt="founder" />
                                    <div className="linked-in">
                                    <a href="https://www.linkedin.com/in/preetichaturvedi/" target="_blank"><img loading='lazy' src="/Images/new/linkedin-blue.png" alt="box" /></a>
                                    </div>
                                    </div>

                                    <div className='CO-Founder-Content-Container-tablet '>
                                        <motion.p className='CO-Founder-Name-tablet '>PREETI CHATURVEDI</motion.p>
                                        <motion.p className='CO-Founder-Role-tablet '>CO-FOUNDER & DIRECTOR</motion.p>
                                        <motion.p className='CO-Founder-Stories-tablet '>Preeti, the Co-Founder of Evenion, leads the Digital Transformation, Strategy, and Consulting business. With a background as a technologist turned product person, she brings nearly 15 years of experience encompassing Technology, Product Development, User Experience, Product Strategy, and Roadmap.
Preeti has been involved with numerous early-stage startups in India and the USA, including FirstCry, Hubzu, as well as esteemed tech veterans like Rediff and IBM. Her diverse experience has honed her expertise in driving innovation and delivering impactful solutions.
Preeti holds a Master’s in Technology from the International Institute of Information Technology, Bangalore, solidifying her technical knowledge and proficiency. She is an alumni of IIMB Bangalore and a flag-bearer of the 10K Women Entrepreneurs program, sponsored by Goldman Sachs and NSRCEL.</motion.p>
                                    </div>
                                </motion.div>
                    </motion.div>
                </section>

{/* mobile */}
<motion.section  
className='LeaderShip-mobile'>
    <motion.h1
    viewport={{ref, once:true}}
    variants={{
        hidden: { y: 175, scale:1.2, opacity: 0.2 },
        visible: { y: 0, scale: 1, opacity: 1 },
    }}
    initial="hidden"
    whileInView="visible"
    transition={{ duration: 0.9, delay: 0.25,}}
    >Leadership</motion.h1>
    <div className='LeaderShip-container-mobile'>
        <motion.div 
            viewport={{ref, once:false}}
            variants={{
                hidden: { y: 45,  opacity: 0.7 },
                visible: { y: 0, scale: 1, opacity: 1 },
            }}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.9, delay: 0.25,}}
        className='founder-container-mobile'>
            <div className='founder-img-mobile'>
            <img loading='lazy' src="/Images/new/Group 7474.png" alt="founder" />
            <div className="linked-in">
                <a href="https://www.linkedin.com/in/sudhirharegoppa/" target="_blank"><img loading='lazy' src="/Images/new/linkedin-blue.png" alt="box" /></a>
            </div>
            </div>
            <div className='founder-content-mobile'>
        <div className='founder-name-mobile'>SUDHIR HAREGOPPA</div>
        <div className='founder-role-mobile'>FOUNDER &amp; CEO</div>
        <div className='founder-text-mobile'>Sudhir founded Evenion Technologies in 2016 and is its Chief Executive Officer. With over 15 years of experience in the information technology sector, he brings expertise in Global Channel Management, Strategy Implementation, Global Sales, Global Business Management, GTM (Go-to-Market), and Channel Development. His experience spans Professional Services, Software Products and Consumer Products.
Prior to establishing Evenion, Sudhir served as the Global Head and Vice President of a US-based SAP Partner firm, playing a key role in establishing their India operations. He has also been associated with renowned companies such as Tesla Motors and Cooper Industries in the USA.
Sudhir holds an MBA from the Stuart School of Business at the Illinois Institute of Technology, Illinois, further enhancing his business acumen and leadership capabilities.</div>
            </div>
        </motion.div>

        <motion.div 
        viewport={{ref, once:false}}
        variants={{
            hidden: { y: 45,  opacity: 0.7 },
            visible: { y: 0,  opacity: 1 },
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.9, delay: 0.25,}}
        className='founder-container-mobile'>
            <div className='founder-img-mobile'>
            <img loading='lazy' src="/Images/new/Group 7472.png" alt="box" />
            <div className="linked-in">
            <a href="https://www.linkedin.com/in/preetichaturvedi/" target="_blank"><img loading='lazy' src="/Images/new/linkedin-blue.png" alt="box" /></a>
            </div>
            </div>
            <div className='founder-content-mobile'>
        <div className='founder-name-mobile'>PREETI CHATURVEDI</div>
        <div className='founder-role-mobile'>CO-FOUNDER & DIRECTOR</div>
        <div className='founder-text-mobile'>Preeti, the Co-Founder of Evenion, leads the Digital Transformation, Strategy, and Consulting business. With a background as a technologist turned product person, she brings nearly 15 years of experience encompassing Technology, Product Development, User Experience, Product Strategy, and Roadmap.
Preeti has been involved with numerous early-stage startups in India and the USA, including FirstCry, Hubzu, as well as esteemed tech veterans like Rediff and IBM. Her diverse experience has honed her expertise in driving innovation and delivering impactful solutions.
Preeti holds a Master’s in Technology from the International Institute of Information Technology, Bangalore, solidifying her technical knowledge and proficiency. She is an alumni of IIMB Bangalore and a flag-bearer of the 10K Women Entrepreneurs program, sponsored by Goldman Sachs and NSRCEL.</div>
            </div>
        </motion.div>
    </div>  
</motion.section>
</>
  )
}

export default Leadership