import { useState } from 'react';

const GetuseForm = validate => {
    const [values, setValues] = useState({
        name: "",
        phone: "",
        email: "",
        companyname: "",
        message: ""
    });
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    return { handleChange, values };

};
export default GetuseForm;