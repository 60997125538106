
import React, { useEffect, useState , } from 'react';
import './ContactUs.scss';
import GetInTouch from "../../Components/GetInTouch/GetInTouch";
import { Helmet } from 'react-helmet';
import GetinTouchConatct from './GetinTouchContact';
import ReachUs from './ReachUs';
import SiliconCard from './SiliconCard';


const ContactUs = () => {


    useEffect(() => {
        window.scrollTo(0, localStorage.getItem('position'));
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
        };
        scrollToTop();
        const onBeforeUnload = () => {
            window.scrollTo(0, 0);
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        }, [])
    return (
        <div>
            <Helmet>
                <title>Let's talk Technology Let's talk Solutions - Whether you are interested in hearing from us about our offerings, partnering with us, joining us or doing business with us - contact us to see how we can help you - Contact Us</title>
                <meta name="description" content="Let's talk Technology Let's talk Solutions - Contact Us"/>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Let's talk Technology Let's talk Solutions - Contact Us" />
                <meta property="description" content="Let's talk Technology Let's talk Solutions - Whether you are interested in hearing from us about our offerings, partnering with us, joining us or doing business with us - contact us to see how we can help you - Contact Us" />
                <meta property="og:description" content="Let's talk Technology Let's talk Solutions - Whether you are interested in hearing from us about our offerings, partnering with us, joining us or doing business with us - contact us to see how we can help you - Contact Us" />
                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="Let's talk Technology Let's talk Solutions - Contact Us" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Let's talk Technology Let's talk Solutions - Contact Us" />
                <meta name="twitter:description" content="Let's talk Technology Let's talk Solutions - Whether you are interested in hearing from us about our offerings, partnering with us, joining us or doing business with us - contact us to see how we can help you" />
                <meta name="twitter:image" content="Let's talk Technology Let's talk Solutions - Contact Us" />
            </Helmet>
            <>
                <section className='Contact_mains'>
                    <GetinTouchConatct />
                    <ReachUs />
                    <SiliconCard />
                </section>
            </>
            </div>

    )
}

export default ContactUs;