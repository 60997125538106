import React, { useEffect, useState } from 'react';
import "./Analytics.scss";
import { Helmet } from 'react-helmet';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { Breadcrumbs,Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { motion } from 'framer-motion';
import Industries from '../../Components/Analytics/Industries';
import WhyEvenion from '../../Components/Analytics/WhyEvenion';


const Analytics = () => {
    const [isLoading, setIsLoading] = useState(false);

    
    const handleLoadingComplete = () => {
        setIsLoading(true);
      };

      useEffect(() => {
        window.scrollTo(0, localStorage.getItem('position'));
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
        };
        scrollToTop();
        const onBeforeUnload = () => {
            window.scrollTo(0, 0);
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        }, [])

        const MotionContainer ={
            hidden: { opacity: 0.5, y: 95 },
            visible: { opacity: 1, y: 0, scale: 1, 
            transition:{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

            hiddenH: { x: -200, y:57, scale: 0.6 },
            visibleH: { x: 0, y:0, scale: 1, 
            transition:{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

            hiddenHM: { x: 0, y:15, scale: 0.7 },
            visibleHM: { x: 0, y:0, scale: 1, 
            transition:{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

            hiddenvideoM: { y: 55, opacity: 0.5 },
            visiblevideoM: {y: 0, opacity: 1, 
            transition:{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},
            
        }    

    return (
        <div>
            <Helmet>
                <title> Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies</title>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies" />
                <meta name="description" content="Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies" />
                <meta property="description" content="Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies" />
                <meta property="og:description" content="Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies" />
                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies" />
                <meta name="twitter:description" content="Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies" />
                <meta name="twitter:image" content="Analytics Services - Enabling small and midscale businesses to leverage the potential of data analytics to make business decisions - Evenion Technologies" />
            </Helmet> 
            {/* <div className="breadcrumb_container">
                <div className="breadcrumb_content">
                    <div className="breadcrumb_links">
                        <div className="link"><a href="/">Home</a></div>
                        <div className="active_now">Professional Services</div>
                    </div>
                </div>
<<<<<<< HEAD
            </div> */}

            {isLoading ? 

                <>
                    <div className='Main_Analytics_services' id='Main_Analytics_Page'>
        <>
        <section className='Analytics_banner'>
                <motion.img loading='lazy' 
                className="blur"  
                        src="/Images/Analytics/Group 7704.png" alt="about-us" />
                            <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
                            <div className='breadcrumb_text'><a href="/">Home</a></div>
                            <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Services</Typography>
                            <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Analytics-as -a-Service</Typography>
                            </Breadcrumbs>
                        
                        <div className='inner_Analytics'>
                <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hidden"
                    whileInView="visible"
                    >
                        <motion.h1
                            viewport={{  once: true}}
                            variants={MotionContainer}
                            initial="hiddenH"
                            whileInView="visibleH"   
                        >Analytics-as <br/>-a-Service</motion.h1>
                        <p className='Pre-para1'>Get Real Insights Quickly - High impact analytics<br/> and reporting with minimal upfront costs</p>
                        <div className="btn-container btn-container-tablet">
                            <div className='btn-lii btn-lii-tablet'>
                                <a
                                    href="/contact-us#contact_form"><span className='btn-liil btn-liil-tablet'>Contact Us  &nbsp; <ArrowBackIcon className="rotatee rotatee-tablet"/></span>
                                </a>
                            </div>
                        </div>
                </motion.div>            
                    </div>
        </section>

        {/* Mobile */}
        <section className='Analytics_banner_mobile'>
        <motion.img loading='lazy' className="blur"      
                src="/Images/Analytics/mobile-banner3.png" alt="about-us" />      
                <div className='inner_Analytics_mobile'>
                <motion.div 
                        viewport={{once: true}}
                        variants={MotionContainer}
                        initial="hiddenvideoM"
                        animate="visiblevideoM"
                    >
                        <motion.h1
                                variants={MotionContainer}
                                initial="hiddenHM"
                                animate="visibleHM"
                        >Analytics<br/>-as-a-
                        <br/>Service</motion.h1>
                        <p className='Pre-para1'>Get Real Insights Quickly - High impact analytics and reporting with minimal upfront costs</p>
                        <div className="btn-stacke-mobile centee-btn-btn">
                                    <a
                                        href="/contact-us#contact_form"><span className='btn-inner-mobile'>Contact Us  &nbsp;<ArrowBackIcon className="stacke-arrow-mobile"/></span>
                                    </a>
                            </div>
                </motion.div>            
                    </div>
        </section>
        </>


<div className='Slide-Analytics'>


<Industries />
<WhyEvenion />






            <section className="Contact-Container"  style={{backgroundColor: "transparent"}}>
                <div className="Contact-Container-inside  ">
                    <a href="/contact-us#contact_form"><span className='Contact-Button  '>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating ' style={{ fontSize: '7vw'}}/>
                    </span>
                    </a>
                </div>
            </section>

            <section className="Contact-Container-tablet" style={{backgroundColor: "#001220"}}>
                <div className="Contact-Container-inside-tablet  ">
                    <a href="/contact-us#contact_form"><span className='Contact-Button-tablet'>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating-tablet ' style={{ fontSize: '7vw'}}/>
                    </span>
                    </a>
                </div>
            </section>

            <section className="Contact-Container-mobile" style={{backgroundColor: "#001220"}}>
                <div className="Contact-Container-inside-mobile">
                    <a href="/contact-us#contact_form"><span className='Contact-Button-mobile'>Contact Us &nbsp;
                    <ArrowBackIcon className='Irotating-mobile' style={{ fontSize: '8vw'}}/>
                    </span>
                    </a>
                </div>
            </section>
</div>




                        


                    </div>
                </>
           : <LoadingSpinner onLoadingComplete={handleLoadingComplete}/> }
        </div>
    )
}

export default Analytics;
