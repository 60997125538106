import React from 'react'
import "./WhyDataSenSex.scss";
import { motion } from 'framer-motion';


const WhyDataSenSex = () => {

  const MotionContainer ={
    hidden: { y: 250, scale: 0.7, opacity: 0.1 },
    visible: { y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1 }},


    hiddenContainer: { y: 200, scale: 0.8, opacity: 0.1 },
    visibleContainer: { y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1,  }},


    hiddenHeading: { y: 35,  },
    visibleHeading: { y: 0, 
    transition:{ duration: 1,  }},


    hiddenHead: { y: 35, scale: 0.9, },
    visibleHead: { y: 0, scale: 1,
    transition:{ duration: 1, }},


    hiddenPara: { y: 30,  },
    visiblePara: { y: 0 ,
    transition:{ duration: 1,  }},

  }








  return (
    <section className='WhyDataSenSex-Main1'>
        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hidden"
          whileInView="visible"        
        className='WhyDataSenSex-inside1'>
          <motion.h1
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenHead"
          whileInView="visibleHead"             
          >Why DataSenseX</motion.h1>
          <motion.p 
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenPara"
          whileInView="visiblePara"  
          className='WhyData-para1'> Automate <span class="orange_code"> Unstructured Data Platform </span>Using AI</motion.p>
        </motion.div>
        
<div className='WhyDataSensex-Container-main'>
        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hiddenContainer"
          whileInView="visibleContainer"          
        className='WhyDataSensex-Container1'> 
                <div className='img-Container1'>
                <motion.img loading='lazy'  
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenImg"
                whileInView="visibleImg"  
                src="/Images/DataSenSex/robotic.png" alt="" />
                </div>
                <div className='Content-Container1'>
                    <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenHeading"
                    whileInView="visibleHeading"          
                    className='Content-para1'>User Friendly</motion.h1>
                    <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"                     
                    className='Content-para2'> AI to handle diverse unstructured data enhancing automation with AI, bots, and humans for top-notch data processing</motion.p>
                </div>
        </motion.div>
        
        <motion.div
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hiddenContainer"
          whileInView="visibleContainer"        
        className='WhyDataSensex-Container2'> 
                <div className='Content-Container2'>
                    <motion.h1 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenHeading"
                    whileInView="visibleHeading"   
                    className='Content-para1'>Smart</motion.h1>
                    <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"  
                    className='Content-para2'> Combine AI, bots, and human workers for better data quality, lower costs, and faster processing</motion.p>
                </div>
                <div className='img-Container2'>
                <motion.img loading='lazy'  
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenImg"
                whileInView="visibleImg"  
                src="/Images/DataSenSex/Group 3594.png" alt="" />
                </div>
        </motion.div>
        
        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hiddenContainer"
          whileInView="visibleContainer"           
        className='WhyDataSensex-Container3'> 
        <div className='img-Container3'>
                <motion.img loading='lazy'  
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenImg"
                whileInView="visibleImg"  
                src="/Images/DataSenSex/Group 3592.png" alt="" />
                </div>
                <div className='Content-Container3'>
                    <motion.h1 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenHeading"
                    whileInView="visibleHeading"  
                    className='Content-para1'>Flexible</motion.h1>
                    <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"  
                    className='Content-para2'>Deploy AI to production in a matter of weeks—not months or years</motion.p>
                </div>
        </motion.div>


        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hiddenContainer"
          whileInView="visibleContainer"            
        className='WhyDataSensex-Container4'> 
                <div className='Content-Container4'>
                    <motion.h1 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenHeading"
                    whileInView="visibleHeading"  
                    className='Content-para1'>Fast</motion.h1>
                    <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPara"
                    whileInView="visiblePara"  
                    className='Content-para2'> Easily integrate, extend, and customize the platform with existing RPA, AI, and human investments</motion.p>
                </div>
                <div className='img-Container4'>
                <motion.img loading='lazy'  
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenImg"
                whileInView="visibleImg"  
                src="/Images/DataSenSex/Group 3593.png" alt="" />
                </div>
        </motion.div>
        

        </div>

    </section>
  )
}

export default WhyDataSenSex