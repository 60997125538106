import React from "react";
import "./LoadingSpinner.css";
import { Row, Col } from "reactstrap";
import { useEffect } from "react";
import { useState } from "react";
import { red } from "@mui/material/colors";
import { motion, useScroll, useSpring } from "framer-motion";
import { setLoadData } from "../../util";

const LoadingSpinner = ({ onLoadingComplete }) => {

        const [progress, setProgress] = useState(0);

        useEffect(() => {
          window.scrollTo(0, localStorage.getItem('position'));

          const interval = setInterval(() => {
            if (progress < 100) {
              setProgress(progress + 5);
              setLoadData(progress + 5)
            } else {
              clearInterval(interval);
              onLoadingComplete();
            }
          }, 50);
      
          return () => clearInterval(interval);
        }, [progress, onLoadingComplete]);


    return(
        <div className="spinner-container">
          {window.innerWidth>1023?
          <>
        <div className="loading-spinner" style={{overflow:'hidden'}}>
        {/* <h1>Digital Transformation</h1> */}
          {/* <Row>
            <Col>
            <h1 style={{marginLeft:"5%"}}>Strategy</h1>
            <h1 style={{marginLeft:"15%"}}>Consulting</h1>
            </Col>
            <Col> */}
               <div className="loader-bar">
          <div
            className="loader-progress"
            style={{ width: `${progress+20}%` }}
          />
        </div>
            <video loop autoPlay muted playsInline className="loaderGIF">
                                    <source src="/Images/logo/helix - loop.mp4" type="video/mp4" />
                                    <p>This browser not support in video tag</p>
                                </video>
            {/* </Col>
          </Row> */}
        </div>
        {/* <div className="loader-container">
     
        <p className="loader-percentage">{progress}%</p>
         </div> */}
          </>
         :
         <>
          <div className="loading-spinner" style={{overflow:'hidden'}}>
        <div className="loader-bar">
          <div
            className="loader-progress"
            style={{ width: `${progress+20}%` }}
          />
        </div>
            <video loop autoPlay muted playsInline className="loaderGIF-mobile">
                                    <source src="/Images/logo/helix - loop.mp4" type="video/mp4" />
                                    <p>This browser not support in video tag</p>
                                </video>
        </div>
        {/* <div className="loader-container-mobile">
        <p className="loader-percentage-mobile">{progress}%</p>
         </div> */}
         </> 
        }

      </div>
    )

}

export default LoadingSpinner;