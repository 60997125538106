import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import { Link, NavLink } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Blog.scss';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import { Row, Col } from 'reactstrap';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import Smartcode from '../../Components/Smartcode/smartcode';
import Casestudy from '../../Components/casestudy/casestudy';
import Featured from '../../Components/Featured/featured';
import Productmanagement from '../../Components/productmanagement/productmanagement';
import Evenionofferings from '../../Components/evenionofferings/evenionofferings';
import Designux from '../../Components/designux/designux';
import Devops from '../../Components/devops/devops';
import Managementdesk from '../../Components/managementdesk/managementdesk';
import Allblogs from '../../Components/AllBlogs/allblogs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useInView } from 'framer-motion';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useSmoothHorizontalScroll from 'use-smooth-horizontal-scroll';
import { Diversity1TwoTone, Padding } from '@mui/icons-material';
import { Breadcrumbs, Typography } from '@mui/material';
import {Modal, ModalBody, Button} from 'reactstrap';
import Lottie from 'react-lottie';
import * as mailAnimation from '../../jsonFiles/mail_send.json';
import validate from '../ContactUs/getValidateInfoConatact';
import useForm from '../../Components/CareerOpen/useForm';


const Career = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(0);
    const [activeTab, setActiveTab] = useState("tab1");
    const [blogList, setBlogList] = useState();
    const ref = useRef(null)
    // const isInView = useInView(ref, { once: false });
    const [searchTerm, setSearchTerm] = useState('');
    const [email, setEmail] = useState('');
    const { handleChange, values } = useForm(validate);
    const [errors, setErrors] = useState({});
    const [confirmmodal, setconfirmmodal] = useState(false);
    const toggleConfirm = () => setconfirmmodal(!confirmmodal);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: mailAnimation}


    const filteredBlogs = blogList?.filter(blog =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.short_description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } = useSmoothHorizontalScroll();

    const tabsObject = [
        { name: 'All' },
        { name: 'Featured' },
        { name: 'Smart Code' },
        { name: 'Case Study' },
        { name: 'Product Management' },
        { name: 'Evenion Offerings' },
        { name: 'Experience Design' },
        { name: 'Dev Ops' },
        { name: 'Management Desk' }
    ]

    const getBlogs = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/blogs`)
            .then(response => {
                setBlogList(response.data.data[0]);
                setIsLoading(false);

            })
            .catch(error => {
                console.log("Error => ", error);
            });
    }


    const handleLoadingComplete = () => {
        setIsLoading(true);
    };


    useEffect(() => {
        localStorage.setItem("tab", 'Services')
        getBlogs();
    }, [])


    function myFunction(e, id) {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        setActive(id);

    }

    const [scrolled, setScrolled] = React.useState(false);
    const handleScrollNav = () => {
        const offset = window.scrollY;
        if (offset > 500) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }

        if ((window.innerHeight + window.scrollY + 1200) >= document.body.offsetHeight) {
            setScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScrollNav)
    }, []);

    let navbarClasses = ['navlist'];
    if (scrolled) {
        navbarClasses.push('scrolled');
    }

    let navbarClassesmob = ['navlist_mob'];
    if (scrolled) {
        navbarClassesmob.push('scrolled');
    }

    var regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;

    const handleEmailSubscribe = (e) => {

        setErrors(validate(values));
        
        if (email !== '' && regex.test(email)) {
            setErrors({})
            const payload = {
                "email" : email,
                "from" : "Evenion"
            }
            
            const getApply = async () => {
                const url = `${process.env.REACT_APP_API_URL}/subscribe`;
                await axios.post(url, payload ).then((response) => {
                    setconfirmmodal(true);
                    setEmail("");
                    setErrors({})
                })
                    .catch(error => {
                        console.log("Error => ", error);
                    });
            };
            getApply();        }

    };


    return (
        <div>
            <Helmet>
                <title>Trends in Tech Innovation &amp; Digital Transformation - Blogs</title>
                <meta name="description" content="Trends in Tech Innovation &amp; Digital Transformation - Blogs" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Trends in Tech Innovation &amp; Digital Transformation - Blogs" />
                <meta property="og:description" content="Trends in Tech Innovation &amp; Digital Transformation - Blogs" />
                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="Trends in Tech Innovation &amp; Digital Transformation - Blogs" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Trends in Tech Innovation &amp; Digital Transformation - Blogs" />
                <meta name="twitter:description" content="Trends in Tech Innovation &amp; Digital Transformation - Blogs" />
                <meta name="twitter:image" content="Trends in Tech Innovation &amp; Digital Transformation - Blogs" />
            </Helmet>

            {isLoading ?
                <>
                    <div className="main_container_blogs">
                        {window.innerWidth>480 ? 
                        <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{ top: 0 }}>
                            <div className='breadcrumb_text'><a href="/">Home</a></div>
                            <Typography color="#0F326C" fontFamily=' space-Grotesksb' fontWeight='300' >Blogs</Typography>
                        </Breadcrumbs>
                        : "" }
                        <div>
                            <div className="main_container_blogs">
                                <div className='sub_container_blogs'>
                                    {window.innerWidth > 768 ?
                                        <Row style={{ margin: 0, marginTop: '3%' }}>
                                            <Col>
                                                <div className='main_head'>
                                                    <h1 className='blogs_head'>Blogs </h1>
                                                    <p className='main_sub1'>Stay Tuned</p>
                                                    <p className='main_sub2'>Trends in Tech Innovation & Digital Transformation</p>
                                                </div>
                                            </Col>
                                            <Col style={{alignSelf:'center'}}>
                                                <div className='search_container'>
                                                    <input className='subscribe_input'
                                                        type="text"
                                                        placeholder="Search Topics"
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        <>
                                             <div className='main_head-mob'>
                                                <h1 className='blogs_head-mobile'>Blogs</h1>
                                                    <p className='main_sub1-mob'>Stay Tuned</p>
                                                    
                                                    <p className='main_sub2-mob'>Trends in Tech Innovation &<br/> Digital Transformation</p>
                                                </div>
                                            <div className='search_container-mobile'>
                                                <input className='subscribe_input'
                                                    type="text"
                                                    placeholder="Search Topics"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </>
                                    }

                                    <>
                                        <div className='filter-categories'>
                                            <ArrowBackIosIcon className='arrow-carousal' style={{ color: isAtStart == true ? '#8080807a' : "#fff" }} onClick={() => scrollTo(-200)} disabled={isAtStart} />
                                            <ul ref={scrollContainerRef} onScroll={handleScroll}>
                                                {tabsObject.map((ele, id) => (
                                                    <div>
                                                        <li><a href={`#${ele.name.replace(/[^A-Z0-9]/ig, "").toLowerCase()}`} className={id == active ? `navitem active` : 'navitem'} onClick={() => myFunction(ele.name, id)}>  {ele.name}</a></li>
                                                    </div>

                                                ))}
                                            </ul>
                                            <ArrowForwardIosIcon className='arrow-carousal' style={{ color: isAtEnd == true ? '#8080807a' : "#fff" }} onClick={() => scrollTo(200)} disabled={isAtEnd} />
                                        </div>
                                        <div className="featured" >

                                            {active == '0' ? <Allblogs blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                            {active == '1' ? <Featured blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                            {active == '2' ? <Smartcode blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                            {active == '3' ? <Casestudy blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                            {active == '4' ? <Productmanagement blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                            {active == '5' ? <Evenionofferings blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                            {active == '6' ? <Designux blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                            {active == '7' ? <Devops blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                            {active == '8' ? <Managementdesk blogData={filteredBlogs} searchTerm={searchTerm} /> : ''}

                                        </div>
                                    </>
                                </div>

                                {/* <div><img loading='lazy' src="/Images/new/line.png" alt="model" className="divider"/></div>
                        <br></br>
                        <></>
                        <br></br>
                         <Row className="m-0 insta_title">
                            <Col className="m-0 p-0">
                         <div class="insta_heading">
                            <img loading='lazy' className="insta_heading_rotate_img" src="/Images/new/heading_logo_2.png" alt="Angular" /> 
                            <span class="insta_heading_img">Insta Diaries</span></div>
                            </Col>
                        </Row>

                    <div className='instagram'>
                    <Row className='insta_items'>
                        <Container className="container-new" >
                            <Slider {...settings_insta} className="insta_slider">
                                <div>
                                    <InstagramFeeds counter='8' />
                                </div>
                                <div>
                                    <InstagramFeeds flag="true" counter='16'/>
                                </div>
                            </Slider>
                            <Slider {...settings_mob_insta} className="insta_mob_slider">
                                <div>
                                    <InstagramFeeds counter='3'/>
                                </div>
                            </Slider>
                        </Container>                 
                    </Row>
                    </div> */}
                                <div className='subscribe-container'>
                                    {window.innerWidth > 599 ?
                                        <div>
                                            <span className='subscribe_text'>Get notified on the latest blogs and updates</span><br />
                                            <input placeholder="Email" type="email" className='subscribe_input'  value={email} onChange={handleEmail} />
                                            <button className="subscribe_button" onClick={handleEmailSubscribe} type="submit">Subscribe</button>
                                            {errors.email && <p className="error_text-email">{errors.email}</p>}
                                         </div>
                                        :
                                        <div>
                                            <span className='subscribe_text'>Get notified on <br /> the latest blogs and updates</span><br />
                                            <input placeholder="Email" type="email" className='subscribe_input'  value={email}  onChange={handleEmail} />
                                            {errors.email && <p className="error_text-email">{errors.email}</p>}
                                            <button type="submit" className='subscribe_button' onClick={handleEmailSubscribe} >Subscribe</button>
                                            
                                        </div>
                                    }
                                </div>


                                {window.innerWidth > 1023 ?
                                    <section className="Contact-Container_Career" >
                                        <div className="Contact-Container-inside_Career">
                                            <a href="/contact-us#contact_form"><span className='Contact-Button_career'>Contact Us &nbsp;
                                                <ArrowBackIcon className='Irotating ' style={{ fontSize: '7vw' }} />
                                            </span></a>
                                        </div>
                                    </section> :
                                    <>
                                        <section className="Contact-Container-tablet">
                                            <div className="Contact-Container-inside-tablet  ">
                                                <a href="/contact-us#contact_form"><span className='Contact-Button-tablet'>Contact Us &nbsp;
                                                    <ArrowBackIcon className='Irotating-tablet ' style={{ fontSize: '7vw' }} />
                                                </span>
                                                </a>
                                            </div>
                                        </section>

                                        <section className="Contact-Container-mobile">
                                            <div className="Contact-Container-inside-mobile">
                                                <a href="/contact-us#contact_form"><span className='Contact-Button-mobile'>Contact Us &nbsp;
                                                    <ArrowBackIcon className='Irotating-mobile' style={{ fontSize: '8vw' }} />
                                                </span>
                                                </a>
                                            </div>
                                        </section>
                                    </>
                                }
                            </div>

                            {/* <div s */}
                        </div>
                    </div>
                    <Modal isOpen={confirmmodal} className='modal-contentPro' style={{alignItems:'center'}}>
                    <ModalBody>
                    <h5 className="text-center bg-color my-4">We appreciate your subscription! </h5>
                    <div className='gif-set'>
                    <Lottie options={defaultOptions}/>
                    </div>
                    {window.innerWidth> 470?
                    <p className="mb-4 text-center model_patra">Stay tuned for exciting blogs and exclusive content</p>
                    :
                    <p className="mb-4 text-center model_patra">Stay tuned for exciting blogs<br/> and exclusive content</p>
                    }
                    <Button color="btn-white" className="modal_close" onClick={toggleConfirm}>Close</Button>
                </ModalBody>
                 </Modal>
                </>
                : <LoadingSpinner onLoadingComplete={handleLoadingComplete} />}
        </div>
    )
}
export default Career; 