import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import products from './Conatiners/Products/Products';
import career from './Conatiners/Career/Career';
import blog from './Conatiners/Blog/Blog';
import aboutus from './Conatiners/AboutUs/AboutUs';
import contactUs from './Conatiners/ContactUs/ContactUs';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Professional from "./Conatiners/Professional/Professional";
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import BlogLanding from './Conatiners/BlogLanding/BlogLanding';
import Policy from './Conatiners/Policy/Policy';
import PolicyLanding from './Conatiners/PolicyLanding/PolicyLanding';
import Sitemap from './Conatiners/Sitemap/sitemap';
import NotFoundPage from './Conatiners/NotFoundPage';

import { AnimatePresence } from 'framer-motion';
import HomePage from './Conatiners/HomePage/HomePage';
import Services from './Conatiners/Services/Services';
import ProjectServices from './Conatiners/ProjectServices/ProjectServices';
import Products from './Conatiners/Products/Products';
import AboutUs from './Conatiners/AboutUs/AboutUs';
import Career from './Conatiners/Career/Career';
import Blog from './Conatiners/Blog/Blog';
import ContactUs from './Conatiners/ContactUs/ContactUs';
import Flowise from './Conatiners/Partnerships/Flowise';
import Datasensex from './Conatiners/Products/Datasensex';
import Precisely from './Conatiners/Partnerships/Precisely';
import Analytics from './Conatiners/Analytics/Analytics';
import Taas from './Conatiners/TaaS/TaaS';
import DigitalEngineering from './Conatiners/DigitalEngineering/DigitalEngineering';

function App() {

  function forceTrailingSlash(nextState, replace) {
    const path = nextState.location.pathname;
    if (path.slice(-1) !== '/') {
      replace({
        ...nextState.location,
        pathname: path + '/'
      });
    }
  }

  function forceTrailingSlashOnChange(prevState, nextState, replace) {
    forceTrailingSlash(nextState, replace);
  }

  return (
    <Router>
      <ScrollToTop />
      <Header/>
      <AnimatePresence>
      <Routes>
        <Route path="/" exact element={<HomePage/>} />
        <Route path="/digital-services" element={<Services />} />
        <Route path="/stackerank" element={<Products />} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/career" element={<Career />} />
        <Route path="/blogs" exact element={<Blog />} onEnter={forceTrailingSlash} onChange={forceTrailingSlashOnChange} />
        <Route path="/employee_policies" exact element={<Policy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/professional-services" element={<Professional/>} />
        <Route path="/analytics-services" element={<Analytics/>} />
        <Route path="/taas-services" element={<Taas/>} />
        <Route path="/DigitalEngineering" element={<DigitalEngineering/>} />
        <Route path="/blogs/:category" exact element={<BlogLanding/>} />
        <Route path="/employee_policies/:category" exact element={<PolicyLanding/>} />
        <Route path="/sitemap" element={<Sitemap/>} />
        <Route path="/flowize" element={<Flowise/>} />
        <Route path="/datasensex" element={<Datasensex/>} />
        <Route path="/precisely" element={<Precisely/>} />
        <Route path="*" exact element={<NotFoundPage/>} status={404} />
      </Routes>
      </AnimatePresence>
        <Footer />
    </Router>
  );
}
export default App;