// var data = "Services";
var data 
var loadData = 0;

export const SetData = (id) =>{
data = id;
}

export const getData = ()=>{
    return data;
}

export const setLoadData = (perc) =>{
    loadData = perc;
    }
    
    export const getLoadData = ()=>{
        return loadData;
    }