import React from 'react'
import './AtEvenion.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { motion } from 'framer-motion'; 

const AtEvenion = () => {
    
    const MotionContainer ={
        hidden: { opacity: 0.5, y: 300, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},
       
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenP2: { y: 35,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
    
        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},

        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

        hiddenMobP2: { y: 20,  opacity: 0.9 },
        visibleMobP2: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

        
    } 
  return (
        <>
    <section className='Main-AtEvenion'>
        <section  className='Main-AtEvenion-inside'>
            <div className='left-AtEvenion'>
                <motion.h2
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"                   
                >At</motion.h2>
                <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"                   
                >Evenion</motion.h1>
                <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP2"
            whileInView="visibleP2"                   
                >we are dedicated to elevating your testing efforts with our Testing as a Service (TaaS) solutions. Whether you need comprehensive testing for a critical project or ongoing support, we have the expertise and resources to meet your needs. Contact us today to learn more and take the first step toward optimizing your testing processes.</motion.p>
            </div>
            <div className='right-AtEvenion'>
                {/* <img loading='lazy' src="/Images/taas/Mask Group 228.png" alt="default" /> */}
                <div className='inside-right-AtEvenion'></div>
            </div>
        </section>
        <div className='TaaS-BtnContainer'>
        <a href="/contact-us#contact_form"><span className='TaaS-Btn'>Contact Us &nbsp;
            <ArrowForwardIcon className='Irotating-TaaS' style={{ fontSize: '8vw'}}/>
            </span>
        </a>
        </div>

    </section>

    {/* Mobile */}

    <section className='Main-AtEvenion-mobile'>
        <section  className='Main-AtEvenion-inside-mobile'>
            <div className='left-AtEvenion-mobile'>
                <motion.h2
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                   
                >At</motion.h2>
                <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                   
                >Evenion</motion.h1>
                <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP2"
            whileInView="visibleMobP2"                   
                >we are dedicated to elevating your testing efforts with our Testing as a Service (TaaS) solutions. Whether you need comprehensive testing for a critical project or ongoing support, we have the expertise and resources to meet your needs. Contact us today to learn more and take the first step toward optimizing your testing processes.</motion.p>
            </div>
            <div className='right-AtEvenion-mobile1'>
                <div className='inside-right-AtEvenion-mobile1'></div>
            </div>
        </section>
        <div className='TaaS-BtnContainer-mobile'>
        <a href="/contact-us#contact_form"><span className='TaaS-Btn'>Contact Us &nbsp;
            <ArrowForwardIcon className='Irotating-TaaS' style={{ fontSize: '13vw'}}/>
            </span>
        </a>
        </div>

    </section>
    </>
  )
}

export default AtEvenion