import React, { useEffect, useState } from "react";
import './PolicyLanding.css';
import { Row, Col } from "reactstrap";
import { useLocation } from "react-router";
import axios from "axios";
import { useNavigate } from 'react-router';
import dateFormat from 'dateformat';
import { Helmet } from 'react-helmet';
import { useReadingTime } from "react-hook-reading-time";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import demoPdf from '../../pdf/Aboutus.pdf';
import { Breadcrumbs, Typography } from "@mui/material";


const PolicyLanding = () => {
    const location = useLocation();
    const [policyList, setPolicyList] = useState();
    const [flag, setFlag] = useState('false');
    const [isLoading, setIsLoading] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [file, setFile] = useState('');

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    let navigate = useNavigate();
    let path = location.pathname.split('/');

    const getPolicys = (count_flag, id) => {
        localStorage.setItem('cFlag', true);

        axios.get(`${process.env.REACT_APP_API_URL}/employee_handbook`)
            .then(response => {
                setFlag(localStorage.getItem('cFlag'));

                const data = response.data.data[0];
                data && data.map(ele => {
                    if (ele.slug === path[2]) {
                        localStorage.setItem('slug', ele.slug);
                    }
                })

                if (localStorage.getItem('slug') === path[2]) {
                    setPolicyList(response.data.data[0]);
                    navigate(`/employee_policies/${path[2]}`);
                } else {
                    navigate('/errors');
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log("Error => ", error);
            });

    }

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            getPolicys();
        }, 1000);
    }, [path[2]]);

    const getSinglePolicys = (data) => {
        // window.scrollTo(0, 0);
        setIsLoading(true);
        setTimeout(function () {
            try {
                navigate(`/employee_policies/${data.slug}`);
                setIsLoading(false);
                localStorage.setItem('slug', data.slug);

            } catch (err) {
                console.log(err);
            }
        }, 2000);
    }

    function createMarkup(data) {
        return {
            __html: data
        };
    };

    const GetTime = (data) => {
        const { text } = useReadingTime(data);
        if (text === 'less than a min') {
            return 'Less than 1 min'
        }
        return text;
    }



    return (
        <>
            {
                policyList && policyList.map(ele => (
                    <>
                        {ele.slug === path[2] &&
                            <Helmet>
                                <title>{ele.policy_title}</title>
                                <meta property="og:locale" content="en_US" />
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content={ele.meta_title} />
                                <meta property="og:meta_description" content={ele.meta_description} />
                                <meta property="og:canonical_link" content={ele.canonical} />
                                <meta property="keywords" content={ele.meta_keywords} />
                                <meta property="abstract" content={ele.abstract} />
                                <meta property="robots" content={ele.meta_robots} />
                                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                                <meta property="og:image" content={ele.title} />
                                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                                <meta property="og:image:width" content="630" />
                                <meta property="og:image:height" content="420" />
                                <meta property="og:image:alt" content="Evenion" />
                                <meta property="og:image:type" content="image/jpeg" />
                                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                                {/* <meta name="twitter:card" content="summary_large_image" />
                                <meta name="twitter:title" content={ele.title} /> */}
                                {/* <meta name="twitter:description" content={ele.short_description} /> */}
                                {/* <meta name="twitter:image" content={ele.title} /> */}
                            </Helmet>
                        }
                    </>
                ))
            }
            <div>
                {/* <div className="breadcrumb_container" id="breadcrumb_container">
                    <div className="breadcrumb_content">
                        <div className="breadcrumb_links">
                            <div className="link"><a href="/">Home</a> <a href="/employee_policies">&#160;Employee Policies</a></div>
                            <div className="active_now">
                                {policyList && policyList.map(ele => (
                                    <>
                                        {ele.slug === path[2] &&
                                            ele.policy_title
                                        }
                                    </>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                {window.innerWidth>480?
                <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new'>
                    <div className='breadcrumb_text'><a href="/">Home</a></div>
                    <div className='breadcrumb_text'><a href="/employee_policies">Employee Policies</a></div>
                    <Typography color="#0F326C" fontFamily=' space-Grotesksb' fontWeight='300' >{policyList && policyList.map(ele => (
                                    <>
                                        {ele.slug === path[2] &&
                                            ele.policy_title
                                        }
                                    </>
                                ))
                                }
                    </Typography>
                </Breadcrumbs>
               :"" }

                <div className="policy__landing__container">
                    <Row className="space_landing">
                        <Col id="landing_section" className="description_section">
                            {isLoading ? <LoadingSpinner /> :
                                policyList && policyList.map(ele => (
                                    <>
                                        {ele.slug === path[2] &&
                                            <div className="landing_banner">
                                                <Row className="policy-author-section">
                                                    <Col md={1} sm={3} xs={2}>
                                                        <img loading='lazy' src={`/Images/new/${ele.author}.png`} className="author-img-height" alt="img" />
                                                    </Col>
                                                    <Col md={11} sm={6} xs={8} className="height-fix height-fix-main section-para">
                                                        <Row className="published_dt">
                                                            <Col md={8} xs={6} sm={4} className="p-0 author_name">{ele.author}</Col>
                                                            <Col md={2} xs={4} sm={4} className="p-0 date_placement">{dateFormat(ele.published_at, 'mmm d, yyyy')}</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {/* <div className="policys-heading">{ele.policy_title}</div> */}
                                                <div className="short_employee_landingpage">{ele.version_history}
                                                </div>
                                                <div className="section-para">
                                                    <iframe src={`${process.env.REACT_APP_ADMIN_URL_PDF}${ele.pdf}#toolbar=0&zoom=125`} type="application/pdf" frameBorder="0" border="0" height="3000px" width="100%" oncopy="return false" allowtransparency="true" ></iframe>
                                                </div>
                                            </div>
                                        }
                                    </>
                                ))
                            }
                        </Col>
                        
                    </Row>

                    <Col md={8} className="recent_tabs recent_tabs_mobile">
                        <div className="policy_page_line">
                            <img loading='lazy' src="/Images/new/mobile-strip.png" alt="logo" />
                        </div>
                        <div className="recent-head"><span>Other Policies<img loading='lazy' className="img_space" src="/Images/new/heading_logo_2.png" alt="logo" /></span></div>
                        <div className="recent_view_container">
                            {policyList && policyList.slice(0, 3).map(ele => (
                                <>
                                    {ele.status === 'Active' && ele.slug !== path[2] &&
                                        <div className="recent_view">
                                            <Row className="recent_view_policy">
                                                <Col md={9} className="height-fix height-fix-1 author_name_mob">
                                                    <div>
                                                        <div className="p-0">
                                                            {ele.author}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="py-2 px-0">
                                                            <div className="recent_para recent-section-policy-title" onClick={() => getSinglePolicys(ele)}>{ele.policy_title.length > 60 ? ele.policy_title.substring(0, 60) + "..." : ele.policy_title}</div>
                                                        </div>
                                                    </div>
                                                    <div className="height-fix   date_reading pl-0">
                                                        <div md={6} className="p-0">
                                                            {dateFormat(ele.published_at, 'mmm d, yyyy')}
                                                        </div>
                                                        <div md={6} className="p-0">
                                                            {GetTime(ele.content)}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </Col>
                </div>
            </div>
        </>
    )
}
export default PolicyLanding;