import React, { useState , useEffect} from 'react'
import { motion } from 'framer-motion';
import "./WhyUs.scss";

const WhyUs = () => {
    const texts = ["EMPOWER", "EMPOWER, ENABLE", "EMPOWER, ENABLE, OPTIMIZE"];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentText, setCurrentText] = useState(texts[currentIndex]);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        const nextIndex = (currentIndex + 1) % texts.length;
        setCurrentIndex(nextIndex);
  
        // Split the current text into words
        const words = texts[nextIndex].split(' ');
  
        // Apply different styles to the last word
        const styledWords = words.map((word, index) => (
          index === words.length - 1 ? (
            <span key={index} style={{ color: '#FF6600' }}> {word}</span>
          ) : (
            <span key={index}> {word}</span>
          )
        ));
  
        setCurrentText(styledWords);
      }, 1500);
  
      return () => clearTimeout(timer);
    }, [currentIndex]);



    const MotionContainer ={
        hidden: { y: 250, scale: 0.8, opacity: 0.8 },
        visible: { y: 0, scale: 1, opacity: 1 ,
        transition:{ duration: 1, }},

        hiddenWhy: { y: 135, opacity: 0.8 },
        visibleWhy: { y: 0,  opacity: 1 ,
        transition:{ duration: 1, }},

        hiddenH1: { y: 40,  opacity: 0.7 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 25,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenP2: { y: 30,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenPP: { y: 40,  opacity: 0.9 },
        visiblePP: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},

        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

        hiddenMobP2: { y: 20,  opacity: 0.9 },
        visibleMobP2: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},
    }


  return (
    <>
            <section className="why-us">
        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hidden"
          whileInView="visible"        
        className='WhyUs1'>
            <motion.h1
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenH1"
          whileInView="visibleH1"               
            >Why Us?</motion.h1>
            <motion.p 
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenP1"
          whileInView="visibleP1"               
            className='para1'>Here are some of the reasons why organizations, large & small, have chosen to work with us</motion.p>
            <motion.p 
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenP2"
          whileInView="visibleP2"               
            className='para2'>
                {/* <span class="orange_code">EMPOWER </span> */}
            {/* <span className="animates"></span> */}
            {currentText}
            </motion.p>
        </motion.div>
        <motion.div 
          viewport={{  once: true}}
          variants={MotionContainer}
          initial="hiddenWhy"
          whileInView="visibleWhy"   
        className='WhyUs-Box'>

            <div  className='whyUs-Box22'>
                <div className='whyUs-Box2'>
                            <div class="image-containerr">
                                <img loading='lazy' src="/Images/Proffesional_Services/Mask Group 40.png" alt="img1"/>
                                {/* <img loading='lazy' src="/Images/Proffesional_Services/felxible_apporach.png" alt="img1"/> */}
                            </div>
                    <motion.h1 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenH1"
                        whileInView="visibleH1"                      
                    className='headlines2'>Flexible Approach</motion.h1>
                    <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPP"
                        whileInView="visiblePP"                      
                    className='para2'>We employ best project management practices that are brought, controlled and continuously maintained by our global product and project managers. This means you will get a result-oriented yet flexible team that can easily adapt to your internal processes.</motion.p>
                </div>
            </div>
            
            <div  className='whyUs-Box22'>
                    <div className='whyUs-Box2'>
                        <div class="image-containerr">
                            <img loading='lazy' src="/Images/Proffesional_Services/Mask Group 41.png" alt="img1"/>
                            {/* <img loading='lazy' src="/Images/Proffesional_Services/Toptech talent.png" alt="img1"/> */}
                        </div>
                        <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenH1"
                        whileInView="visibleH1"                          
                        className='headlines2'>Top Tech Talent</motion.h1>
                        <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenPP"
                        whileInView="visiblePP"                          
                        className='para2'>Our 5-level interview process and strong recruiting network allows us to provide you with access to the best development talent and expertise. We accept only the top 5% of candidates that we interview</motion.p>
                    </div>
                </div>

                <div  className='whyUs-Box22'>
                    <div className='whyUs-Box2'>
                    <div class="image-containerr">
                        <img loading='lazy' src="/Images/Proffesional_Services/Mask Group 42.png" alt="img1"/>
                        {/* <img loading='lazy' src="/Images/Proffesional_Services/Efficient_processes.png" alt="img1"/> */}
                    </div>
                    <motion.h1 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenH1"
                    whileInView="visibleH1"             
                    className='headlines2'>Efficient Processes</motion.h1>
                    <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenPP"
                    whileInView="visiblePP"                      
                    className='para2'>You get a team that follows the highest communication standards to ensure full transparency throughout the process, just as if you were working with an in-house team. Needless to say, they adapt to your local hours.</motion.p>
                    </div>
            </div>
        </motion.div>
    </section>

    {/* mobile */}
    <section className="why-us-mobile">
        <motion.div 
                viewport={{  once: true}}
                variants={MotionContainer}
                initial="hiddenMob"
                whileInView="visibleMob"
        className='WhyUs1-mobile'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                     
            >Why Us?</motion.h1>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                     
            className='para1'>Here are some of the reasons why organizations, large & small, have chosen to work with us</motion.p>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP2"
            whileInView="visibleMobP2"                     
            // className='para2'><span class="orange_code">EMPOWER </span></motion.p>
            className='para2'>{currentText}</motion.p>
        </motion.div>

        <motion.div 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hiddenMob"
        whileInView="visibleMob"       
        className='Why-Box-mobile'>
            <div className='Why-Box1-mobile'>
                   <div className='Why-img1'>                        
                   {/* <img loading='lazy' src="/Images/Proffesional_Services/Mask Group 40.png" alt="img1"/> */}
                   <img loading='lazy' src="/Images/Proffesional_Services/felxible_apporach.png" alt="img1"/>
                   </div>
                   <div className='Why-content1'>
                        <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"                         
                        className='para1'>Flexible Approach</motion.h1>
                        <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP1"
                        whileInView="visibleMobP1"                         
                        className='para2 margin-para' >We employ best project management practices that are brought, controlled and continuously maintained by our global product and project managers. This means you will get a result-oriented yet flexible team that can easily adapt to your internal processes.</motion.p>
                   </div>
            </div>
            <div className='Why-Box1-mobile'>
                <div className='Why-img1'>                        
                {/* <img loading='lazy' src="/Images/Proffesional_Services/Mask Group 41.png" alt="img1"/> */}
                <img loading='lazy' src="/Images/Proffesional_Services/Toptech talent.png" alt="img1"/>
                </div>
                <div className='Why-content1'>
                    <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobH1"
                    whileInView="visibleMobH1"                     
                    className='para1'>Top Tech Talent</motion.h1>
                    <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"                     
                    className='para2 margin-para1' >Our 5-level interview process and strong recruiting network allows us to provide you with access to the best development talent and expertise. We accept only the top 5% of candidates that we interview</motion.p>
                </div>
            </div>
            <div className='Why-Box1-mobile'>
                <div className='Why-img1'>                        
                {/* <img loading='lazy' src="/Images/Proffesional_Services/Mask Group 42.png" alt="img1"/> */}
                <img loading='lazy' src="/Images/Proffesional_Services/Efficient_processes.png" alt="img1"/>
                </div>
                <div className='Why-content1'>
                    <motion.h1 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobH1"
                    whileInView="visibleMobH1"                     
                    className='para1'>Efficient Processes</motion.h1>
                    <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"                     
                    className='para2 margin-para1'>You get a team that follows the highest communication standards to ensure full transparency throughout the process, just as if you were working with an in-house team. Needless to say, they adapt to your local hours.</motion.p>
                </div>
            </div>

        </motion.div>

    </section>
            
            </>
        
  )
  
}

export default WhyUs