import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Career.scss';
import '../AboutUs/AboutUs.scss'
import CareerOpen from '../../Components/CareerOpen/CareerOpen';
import OurCulture from './OurCulture';
import { Helmet } from 'react-helmet';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { motion, useInView } from 'framer-motion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { WindowSharp } from '@mui/icons-material';
import { Breadcrumbs, Typography } from '@mui/material';
import { color } from '@mui/system';


function Career() {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: false });
    const [isLoading, setIsLoading] = useState(false);

    const handleLoadingComplete = () => {
        setIsLoading(true);
      };

    useEffect(() => {
        // setIsLoading(true);
        // setTimeout(() => {
            window.scrollTo(0, localStorage.getItem('position'));
            // setIsLoading(false);
        // }, 2000);
    }, [])


    return (
        <>
            <Helmet>
                <title>Watching The World of Next-Tech Unfold - It's time to be part of it - Career</title>
                <meta name="description" content="Watching The World of Next-Tech Unfold - It's time to be part of it - Career" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Watching The World of Next-Tech Unfold - It's time to be part of it - Careers" />
                <meta property="description" content="Watching The World of Next-Tech Unfold - It's time to be part of it - Careers" />
                <meta property="og:description" content="Watching The World of Next-Tech Unfold - It's time to be part of it - Careers" />
                <meta property="og:url" content="Watching The World of Next-Tech Unfold - It's time to be part of it - Career" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="Watching The World of Next-Tech Unfold - It's time to be part of it - Careers" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Watching The World of Next-Tech Unfold - It's time to be part of it - Career" />
                <meta name="twitter:description" content="Watching The World of Next-Tech Unfold - It's time to be part of it" />
                <meta name="twitter:image" content="Watching The World of Next-Tech Unfold - It's time to be part of it - Career" />
            </Helmet>

            {isLoading ? 
                <>
                    <div className="career-container">
                        {window.innerWidth > 768 ?
                            <section
                                className="framer-about-us-main-section">
                                <motion.img
                                    className="careermain_image"
                                    src="\Images\career\banner_image_career.png" alt="career_backdrouf" />
                                     <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
                                    <div className='breadcrumb_text'><a href="/">Home</a></div>
                                    <Typography color="#0F326C" fontFamily=' space-Grotesksb' fontWeight='300'  className='page_bread'>Career</Typography>
                                </Breadcrumbs>
                                <div className='framer-career-insideContent framer-career-insideContent-mobile framer-career-insideContent-tablet'>


                                    <motion.div
                                        style={{
                                            width: "100%",
                                        }}
                                        variants={{
                                            hidden: { opacity: 0.5, y: 85, },
                                            visible: { opacity: 1, y: 0, }
                                        }}
                                        initial="hidden"
                                        animate="visible"
                                        transition={{ duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }}
                                    >
                                        <motion.h1
                                            variants={{
                                                hidden: { x: -274, y: 72, scale: 0.4 },
                                                visible: { x: 0, y: 0, scale: 1 }
                                            }}
                                            initial="hidden"
                                            animate="visible"
                                            transition={{ duration: 0.5, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }}
                                        // style={{fontSize:180}}
                                        >Join Our Team</motion.h1>
                                        <p className='f-para1'>Watching The World of Next-Tech Unfold?</p>
                                        <p className='f-para2'>It's time to be part of it</p>
                                        <div className="btn-container btn-container-mobile btn-container-tablet">
                                            <div className='btn-lii btn-lii-tablet'>
                                                <a
                                                    href="/contact-us#contact_form"><span className='btn-liil btn-liil-tablet'>Contact Us  &nbsp;<ArrowBackIcon className="rotatee rotatee-tablet" /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </motion.div>
                                </div>
                            </section> :
                            <section
                                className="framer-main-career">
                                <img
                                    className="careermain_image-tab"
                                    src="\Images\career\banner_image_career.png" alt="career_backdrouf" />
                                    {window.innerWidth>480?
                                    <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
                                    <div className='breadcrumb_text'><a href="/">Home</a></div>
                                    <Typography color="#0F326C" fontFamily=' space-Grotesksb' fontWeight='300' >Career</Typography>
                                    </Breadcrumbs> : "" }
                                <div className='framer-career-insideContent framer-career-insideContent-mobile framer-career-insideContent-tablet'>

                                    {window.innerWidth > 480 ?
                                        <motion.div
                                            style={{
                                                width: "100%",
                                            }}
                                            variants={{
                                                hidden: { opacity: 0.5, y: 85, },
                                                visible: { opacity: 1, y: 0, }
                                            }}
                                            initial="hidden"
                                            animate="visible"
                                            transition={{ duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }}
                                        >
                                            <motion.h1
                                                variants={{
                                                    hidden: { x: -274, y: 72, scale: 0.4 },
                                                    visible: { x: 0, y: 0, scale: 1 }
                                                }}
                                                initial="hidden"
                                                animate="visible"
                                                transition={{ duration: 0.5, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }}
                                                style={{ fontSize: 80 }}
                                            >Join Our Team</motion.h1>

                                            {window.innerWidth>480?
                                            <p className='f-para1' style={{ fontSize: 18 }}>Watching The World of Next-Tech Unfold?</p>
                                            :
                                            <p className='f-para1' style={{ fontSize: 18 }}>Watching The World of <br/> Next-Tech Unfold?</p>
                                            }
                                            <p className='f-para2' style={{ fontSize: 14 }}>It's time to be part of it</p>
                                            <div className="btn-container btn-container-mobile btn-container-tablet">
                                                <div className='btn-lii btn-lii-tablet'>
                                                    <a
                                                        href="/contact-us#contact_form"><span className='btn-liil btn-liil-tablet'>Contact Us  &nbsp;<ArrowBackIcon className="rotatee rotatee-tablet" /></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </motion.div>
                                        :
                                        <motion.div className='careermbile'
                                            style={{
                                                width: "90%",
                                                padding:"1%"
                                            }}
                                            variants={{
                                                hidden: { opacity: 0.5, y: 85, },
                                                visible: { opacity: 1, y: 0, }
                                            }}
                                            initial="hidden"
                                            animate="visible"
                                            transition={{ duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }}
                                        >
                                            <motion.h1
                                                className='blur-head'
                                                variants={{
                                                    hidden: { x: -274, y: 72, scale: 0.4 },
                                                    visible: { x: 0, y: 0, scale: 1 }
                                                }}
                                                initial="hidden"
                                                animate="visible"
                                                transition={{ duration: 0.5, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] }}
                                            >Join Our Team</motion.h1>


                                            <p className='f-para1-mobile' style={{ fontSize: 18 }}>Watching The World of Next-Tech Unfold?</p>
                                            <p className='f-para2-mobile' style={{ fontSize: 15, color: '#fff' , fontFamily:' space-Groteskr' }}>It's time to be part of it</p>
                
                                               <div className="btn-stacke-mobile">
                                                    <a
                                                        href="/contact-us#contact_form"><span className='btn-inner-mobile'>Contact Us  &nbsp;<ArrowBackIcon className="stacke-arrow-mobile"/></span>
                                                    </a>
                                                </div>
                                        </motion.div>
                                    }
                                </div>
                            </section>
                        }
                        <div ref={ref} className="main_container_career">

                            <OurCulture />
                            <CareerOpen />
                            {window.innerWidth > 1023?
                            <section className="Contact-Container_Career" >
                                <div className="Contact-Container-inside_Career">
                                    <a href="/contact-us#contact_form"><span className='Contact-Button_career'>Contact Us &nbsp;
                                <ArrowBackIcon className='Irotating ' style={{ fontSize: '7vw'}}/>
                                </span></a>
                                </div>
                            </section>:
<>
                            <section className="Contact-Container-tablet">
                                <div className="Contact-Container-inside-tablet  ">
                                    <a href="/contact-us#contact_form"><span className='Contact-Button-tablet'>Contact Us &nbsp;
                                        <ArrowBackIcon className='Irotating-tablet ' style={{ fontSize: '7vw'}}/>
                                    </span>
                                    </a>
                                </div>
                            </section>

                                    <section className="Contact-Container-mobile">
                                        <div className="Contact-Container-inside-mobile">
                                            <a href="/contact-us#contact_form"><span className='Contact-Button-mobile'>Contact Us &nbsp;
                                                <ArrowBackIcon className='Irotating-mobile' style={{ fontSize: '8vw' }} />
                                            </span>
                                            </a>
                                        </div>
                                    </section>
                                </>
                            }

                        </div>
                    </div>
                </>
        : <LoadingSpinner onLoadingComplete={handleLoadingComplete}/>     
        }
        </>
    )
}
export default Career;