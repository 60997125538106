import React, { useEffect, useRef, useState } from "react";
import "./YourComponent.scss";
import { motion } from 'framer-motion';

const YourComponent = ({ imageUrl, heading2, heading1, paragraph, nummm }) => {
  const MotionContainer ={
   
    hiddenH1: { y: 30,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, }},

    hiddenP1: { y: 25,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 0.75, delay: 0.15,}},

    hiddenBox: { y: 20,  opacity: 0.9 },
    visibleBox: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},
    
}    
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef();

  const handleScroll = () => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      const isInView =
        rect.top <= window.innerHeight / 2 &&
        rect.bottom >= window.innerHeight / 2;
      setIsVisible(isInView);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.div
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hiddenBox"
        whileInView="visibleBox"
      ref={divRef}
      className={`your-container-view ${isVisible ? "in-view" : ""}`}
    >
      <img loading='lazy' src={imageUrl} alt="Your Image" />
      <motion.h1
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenH1"
        whileInView="visibleH1"
      className="head-view">{heading1} <br></br>{heading2} <span className='numClass'> {nummm} </span></motion.h1>
      <motion.p 
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1"
      // className="para-view" style={{ display: isVisible ? "block" : "none" }}>{paragraph}</motion.p>
      className="para-view"   style={{
        maxHeight: isVisible ? "500px" : "0",
        opacity: isVisible ? 1 : 0,
      }}>{paragraph}</motion.p>
    </motion.div>
  );
};

export default YourComponent;
















