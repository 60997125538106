import React, { useState } from 'react';
import "./TaaSAccordion.scss"
import { motion } from 'framer-motion';

const TaaSAccordion = () => {
    const MotionContainer ={
        hidden: { opacity: 0.5, y: 300, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},
       
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenP2: { y: 15,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 0.75, }},
        
    }  
  const headings = [
    'Application Testing',
    'Cloud Testing',
    'Disaster Recovery Testing',
    'Functional Testing',
    'GUI Testing',
    'Load Testing',
    'Penetration Testing (Ptaas)',
    'Performance Testing',
    'Quality Assurance Testing',
    'Regression Testing',
    'Security Testing',
    'Unit Testing',
  ];

  const paragraphs = [
    'Comprehensive testing to ensure your applications meet quality standards.',
    'Evaluating the performance, security, and scalability of your cloud-based solutions',
    'Preparing your organization for unforeseen disasters and data recovery.',
    'Validating that your applications meet the specified functional requirements.',
    'Assessing the user interfaces functionality and usability',
    'Evaluating the systems performance under heavy loads.',
    'Identifying vulnerabilities and strengthening your security posture.',
    'Measuring the systems speed, responsiveness, and stability.',
    'Ensuring the highest level of quality in your software products.',
    'Detecting issues introduced by code changes.',
    'Safeguarding your applications and data from threats',
    'Validating individual components or units of code.',
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleParagraph = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the clicked paragraph
    } else {
      setOpenIndex(index); // Open the clicked paragraph
    }
  };

  return (
    <div className="accordion-container">
      {headings.map((heading, index) => (
        <div
          key={index}
          className={`accordion-item   ${
            openIndex === index ? 'open' : ''
          }`}
        >
          <div
            className="accordion-heading"
            onClick={() => toggleParagraph(index)}
          >
            <motion.h2 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
            className="animated">{heading}</motion.h2>
          </div>
          <div className="accordion-paragraph" 
          onClick={() => toggleParagraph(index)}>
            <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
            >{paragraphs[index]}</motion.p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TaaSAccordion;
