import React, { useRef } from 'react';
import { motion,    useInView,  } from 'framer-motion';
import "./Industries.scss"

const Industries = () => {
    

  const MotionContainer ={
    hidden: { y: 250, scale: 0.8, opacity: 0.5 },
    visible: { y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, }},

    hiddenP1: { y: 30,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoximg: { y:30,  opacity: 0.5},
    visibleBoximg: { y:0,  opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoxP1: { y:40,  opacity: 0.5},
    visibleBoxP1: { y:0,  opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoxP2: { y:30,  opacity: 0.5},
    visibleBoxP2: { y:0,  opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoxIcon: { y:200,  opacity: 0.2},
    visibleBoxIcon: {y:0,  opacity: 1,
    transition:{ duration: 0.95, }},

    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.15, }},
        
    hiddenMobimg: { y:35,  opacity: 0.5},
    visibleMobimg: { y:0,  opacity: 1,
    transition:{ duration: 0.55, delay: 0.10,}},

    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    hiddenMobP2: { y: 20,  opacity: 0.9 },
    visibleMobP2: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    
}
const ref = useRef(null)
const isInView = useInView(ref, {once: false});

  return (
    <>
    <section className='Industries'>
        <motion.div
                  viewport={{ ref, once: true}}
                  variants={MotionContainer}
                  initial="hidden"
                  whileInView="visible"
        >
        <motion.h1
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenH1"
        whileInView="visibleH1"
        >Industries</motion.h1>
        </motion.div>
        <motion.div 
                    viewport={{once: true}}
                    variants={MotionContainer}
                    initial="hiddenBoxIcon"
                    whileInView="visibleBoxIcon"
        className='Industries_inner'>


            <div className='indus-box1 gradient-border-mask'>
                <motion.img loading='lazy' 
                viewport={{once: false}}
                variants={MotionContainer}
                initial="hiddenBoximg"
                whileInView="visibleBoximg" 
                src="/Images/Analytics/mdi_oil-saver.svg" alt=''/>
                <motion.h1 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                className='Head_indus'>Oil and Gas</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"                
                className='para_indus1'>View average credit limit per customer categories, tax amount & transactions VS proﬁt for customer categories, ﬁnancial summary, proﬁts and expense statistics, customer fulﬁllment statistics etc.</motion.p> 
            </div>

            <div className='indus-box2 gradient-border-mask'>
                <motion.img loading='lazy' 
                viewport={{once: false}}
                variants={MotionContainer}
                initial="hiddenBoximg"
                whileInView="visibleBoximg" 
                src="/Images/Analytics/icon-park-outline_market-analysis.svg" alt=''/>
                <motion.h1 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                className='Head_indus'>Govt Sector <br></br>(PWD Department)</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"                
                className='para_indus2'>View number of incidents by districts, incidents location heat map, call count by year/month, toll collection data, contractor trend analysis.</motion.p> 
            </div>
            <div className='indus-box3 gradient-border-mask'>
                <motion.img loading='lazy' 
                viewport={{once: false}}
                variants={MotionContainer}
                initial="hiddenBoximg"
                whileInView="visibleBoximg" 
                src="/Images/Analytics/mdi_cart-sale.svg" alt=''/>
                <motion.h1 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                className='Head_indus'>Sales and Purchase</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"                
                className='para_indus3'>View customer sales analysis, total purchase by supplier category, purchase quantity by delivery method, year-wise & product-wise comparisons by total purchase.</motion.p> 
            </div>
        </motion.div>

    </section> 

{/* mobile */}
    <section className='Industries_mobile'>
    <motion.div
            viewport={{once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
    >
        <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
        >Industries</motion.h1>
    </motion.div>
        <motion.div 
        viewport={{once: true}}
        variants={MotionContainer}
        initial="hiddenMob"
        whileInView="visibleMob"
        className='Industries_inner_mobile'>
            <div className='indus-box1_mobile gradient-border-mask-mobile-indus'>
                <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobimg"
                    whileInView="visibleMobimg"                 
                src="/Images/Analytics/mdi_oil-saver.svg" alt=''/>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"
                className='Head_indus'>Oil and Gas</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"
                className='para_indus1'>View average credit limit per customer categories, tax amount & transactions VS proﬁt for customer categories, ﬁnancial summary, proﬁts and expense statistics, customer fulﬁllment statistics etc.</motion.p> 
            </div>
            <div className='indus-box2_mobile gradient-border-mask-mobile-indus'>
                <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobimg"
                    whileInView="visibleMobimg"                 
                src="/Images/Analytics/icon-park-outline_market-analysis.svg" alt=''/>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"
                className='Head_indus'>Govt Sector <br></br> (PWD Department)</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"
                className='para_indus2'>View number of incidents by districts, incidents location heat map, call count by year/month, toll collection data, contractor trend analysis.</motion.p> 
            </div>
            <div className='indus-box3_mobile gradient-border-mask-mobile-indus'>
                <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobimg"
                    whileInView="visibleMobimg"                 
                src="/Images/Analytics/mdi_cart-sale.svg" alt=''/>
                <motion.h1
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"
                className='Head_indus'>Sales and Purchase</motion.h1>
                <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"
                className='para_indus3'>View customer sales analysis, total purchase by supplier category, purchase quantity by delivery method, year-wise & product-wise comparisons by total purchase.</motion.p> 

            </div>
        </motion.div>
    </section>

{/* desktop */}
    <section className='No-code'>

        <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
        >No-code, ML-powered, Unified platform to accelerate your data to outcome journey</motion.h1>
        <div className='No-code-inner'>
            <div className='No-code-box'>
                <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1"
                >Data Integration </motion.p>
            </div>
            <div className='No-code-box'>
                <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1"
                >Machine Learning </motion.p>
            </div>
            <div className='No-code-box'>
            <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1"
                >Analytics </motion.p>
            </div>
            <div className='No-code-box'>
                <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1"
                >Process Automation </motion.p>
            </div>
        </div>
    </section>


{/* Mobile */}
    <motion.section
                viewport={{once: true}}
                variants={MotionContainer}
                initial="hiddenMob"
                whileInView="visibleMob"
    className='No-code-mobile'>
        <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
        >No-code, ML-powered, Unified platform to accelerate your data to outcome journey</motion.h1>
        <div className='No-code-inner-mobile1'>
            <div className='No-code-box-mobile'>
                <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
                >Data Integration </motion.p>
            </div>
            
            <div className='No-code-box-mobile'>
                <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
                >Machine Learning </motion.p>
            </div>
        </div>
        <div className='No-code-inner-mobile2'>
            <div className='No-code-box-mobile'>
                <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
                >Analytics </motion.p>
            </div>
            <div className='No-code-box-mobile'>
                <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
                >Process Automation </motion.p>
            </div>
        </div>
    </motion.section>

    </>
  )
}

export default Industries