import React, { useState, useRef } from 'react';
import './AboutUs.scss';
import './OurTeam.scss';
import { motion,    useInView,  } from 'framer-motion';
import { Container, Row, Col } from "reactstrap";

const OurTeam = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, {once: false});


    return (
    <>
    
    {/* <motion.section
            className='Our-Team-Container-New Our-Team-Container-New-mobile col-md-12 row d-flex flex-row'>
                <div 
                className='Our-Team-Content Our-Team-Content-mobile col-md-6'>
                    <motion.h1
                    viewport={{ref, once:false}}
                    variants={{
                        hidden: { y: 30, opacity: 0.8 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}} 
                    >Our Team</motion.h1>
                    <motion.p  
                    viewport={{ref, once:false}}
                    variants={{
                        hidden: { y: 30, opacity: 0.8 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}} 
                    className='Our-Team-pa  Our-Team-pa-mobile'>Our team is a group of highly skilled and dedicated professionals who are committed to achieving our company's goals. </motion.p>
                    <motion.p  
                    viewport={{ref, once:false}}
                    variants={{
                        hidden: { y: 30, opacity: 0.8 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}} 
                    className='Our-Team-paa Our-Team-paa-mobile'>We come from diverse backgrounds and bring a wealth of experience and expertise to our work. Together, we strive to provide the highest level of service and support to our customers, and we are constantly pushing ourselves to innovate and improve.</motion.p>
                    <motion.p  
                    viewport={{ref, once:false}}
                    variants={{
                        hidden: { y: 40, opacity: 0.8 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}} 
                    className='Our-Team-paaa Our-Team-paaa-mobile'>Together, we're building something special.</motion.p>
                </div>

                <motion.div className='Our-Team-ContentImages   col-md-6'>
                <Container >
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/9.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/10.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/11.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/18.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/19.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/21.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />   
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />  
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/18.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/19.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/21.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />  
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/9.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/10.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/11.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                    </Row>
                                </Container>
                </motion.div>
</motion.section> */}

<motion.section
            className='Our-Team-Container-New-mobile col-md-12 row d-flex flex-row' id='our_team'>
                <div 
                className=' Our-Team-Content-mobile col-md-6'>
                    <motion.h1
                    viewport={{ref, once:false}}
                    variants={{
                        hidden: { y: 30, opacity: 0.8 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}} 
                    >Our Team</motion.h1>
                    <motion.p  
                    viewport={{ref, once:false}}
                    variants={{
                        hidden: { y: 30, opacity: 0.8 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}} 
                    className=' Our-Team-pa-mobile'>Our team is a group of highly skilled and dedicated professionals who are committed to achieving our company's goals. </motion.p>
                    <motion.p  
                    viewport={{ref, once:false}}
                    variants={{
                        hidden: { y: 30, opacity: 0.8 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}} 
                    className='Our-Team-paa-mobile'>We come from diverse backgrounds and bring a wealth of experience and expertise to our work. Together, we strive to provide the highest level of service and support to our customers, and we are constantly pushing ourselves to innovate and improve.</motion.p>
                    <motion.p  
                    viewport={{ref, once:false}}
                    variants={{
                        hidden: { y: 40, opacity: 0.8 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.9, delay: 0.25,}} 
                    className='Our-Team-paaa-mobile'>Together, we're building something special.</motion.p>
                </div>

                <motion.div className='Our-Team-ContentImages   col-md-6'>
                <Container >
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/9.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/10.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/11.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/18.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/19.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/21.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />   
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />  
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/18.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/19.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/21.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />  
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/9.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/10.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/11.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                    </Row>
                                </Container>
                </motion.div>
</motion.section>



<section className='OurTeam-Grid' > 
<motion.div 
viewport={{ref, once:true}}
variants={{
    hidden: { y: 50,  opacity: 0.9 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='ourTeam-content'>
                    <h1 className='ourH1'>Our Team</h1>
                    <p  className='ourp1'>Our team is a group of highly skilled and dedicated professionals who are committed to achieving our company's goals. </p>
                    <p className='ourp2'>We come from diverse backgrounds and bring a wealth of experience and expertise to our work. Together, we strive to provide the highest level of service and support to our customers, and we are constantly pushing ourselves to innovate and improve.</p>
                    <p  className='ourp3'>Together, we're building something special.</p>
</motion.div>
<div className='demo-grid-main'>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row1-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/1.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/2.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/3.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/4.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/5.png" alt="emp_image" /> 

</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: -160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row2-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/9.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/8.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/11.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/10.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/13.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/12.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/35.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}} 
className='row3-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/6.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/19.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/16.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/17.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/18.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/21.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/20.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: -160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row4-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/22.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/23.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/24.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/25.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/26.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/27.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/42.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/28.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row5-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/38.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/39.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/30.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/31.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/44.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/7.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/14.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: -160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row6-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/46.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/36.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/37.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/32.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/29.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/41.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/43.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row7-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/33.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/40.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/45.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/34.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/15.png" alt="emp_image" /> 
</motion.div>
</div>
</section>




<motion.section
viewport={{ref, once:true}}
variants={{
    hidden: { y: 40,  opacity: 0.9 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='OurTeam-Grid-tablet-Conatiner'>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 40,  opacity: 0.9 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='OurTeam-Grid-tablet'> 
<div className='ourTeam-content-tablet'>
                    <h1 className='ourH1-tablet'>Our Team</h1>
                    <p  className='ourp1-tablet'>Our team is a group of highly skilled and dedicated professionals who are committed to achieving our company's goals. </p>
                    <p className='ourp2-tablet'>We come from diverse backgrounds and bring a wealth of experience and expertise to our work. Together, we strive to provide the highest level of service and support to our customers, and we are constantly pushing ourselves to innovate and improve.</p>
                    <p  className='ourp3-tablet'>Together, we're building something special.</p>
</div>
</motion.div>
<div className='demo-grid-main1'>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row1-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/1.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/2.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/3.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/4.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/5.png" alt="emp_image" /> 

</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: -160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row2-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/9.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/8.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/11.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/10.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/13.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/12.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/35.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}} 
className='row3-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/6.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/19.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/16.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/17.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/18.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/21.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/20.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: -160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row4-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/22.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/23.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/24.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/25.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/26.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/27.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/42.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/28.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row5-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/38.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/39.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/30.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/31.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/44.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/7.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/14.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: -160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row6-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/46.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/36.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/37.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/32.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/29.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/41.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/43.png" alt="emp_image" /> 
</motion.div>
<motion.div 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 160,  opacity: 0.2 },
    visible: { y: 0,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
// whileHover="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='row7-demo'>
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/33.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/40.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/45.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/34.png" alt="emp_image" /> 
        <img loading='lazy' className='colorChange' src="/Images/new/employees pic/15.png" alt="emp_image" /> 
</motion.div>
</div>
</motion.section>

                <motion.section
                viewport={{ref, once:false}}
                variants={{
                    hidden: { y: 30, opacity: 0.8 },
                    visible: { y: 0, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.9, delay: 0.25,}} 
                className='Our-Team-ContentImages-tablet '>
                <Container >
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/9.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/10.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/11.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/18.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/19.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/21.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />   
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />  
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/18.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/19.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/21.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br />  
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/1.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/2.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/3.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/4.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/5.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/6.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/7.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/8.png" alt="emp_image" /></Col>
                                    </Row>
                                    <br /> 
                                    <Row>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/9.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/10.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/11.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/12.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/13.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/14.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/15.png" alt="emp_image" /></Col>
                                        <Col className='color-change'><img loading='lazy' src="/Images/new/new-teams/16.png" alt="emp_image" /></Col>
                                    </Row>
                                </Container>
                </motion.section>







<motion.section 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 45, opacity:1},
    visible: { y: 0, opacity:1},
}}
initial="hidden"
whileInView="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='Photos-mobile'>
    <div className='photos-ContainerA-mobile'>
            <div className='photos-InsideContainerA-mobile'><img loading='lazy' className='colorC' src="/Images/new/employees pic/1.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerA-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/2.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerA-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/3.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerB-mobile'>
            <div className='photos-InsideContainerB-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/4.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerB-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/5.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerB-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/6.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerB-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/7.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerC-mobile'>
            <div className='photos-InsideContainerC-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/8.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerC-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/9.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerC-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/10.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerD-mobile'>
            <div className='photos-InsideContainerD-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/11.png" alt="emp_image" /></div>  
            <div className='photos-InsideContainerD-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/12.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerD-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/13.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerD-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/14.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerE-mobile'>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/15.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/16.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/17.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerF-mobile'>
            <div className='photos-InsideContainerF-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/18.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerF-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/19.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerF-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/20.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerF-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/21.png" alt="emp_image" /></div>
    </div>

</motion.section>

<motion.section 
viewport={{ref, once:false}}
variants={{
    hidden: { y: 45, opacity:1},
    visible: { y: 0, opacity:1},
}}
initial="hidden"
whileInView="visible"
transition={{ duration: 0.9, delay: 0.25,}}
className='Photos-mobile1'>
    <div className='photos-ContainerA-mobile'>
            <div className='photos-InsideContainerA-mobile'><img loading='lazy' className='colorC' src="/Images/new/employees pic/22.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerA-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/23.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerA-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/24.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerB-mobile'>
            <div className='photos-InsideContainerB-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/25.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerB-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/26.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerB-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/27.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerB-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/28.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerC-mobile'>
            <div className='photos-InsideContainerC-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/29.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerC-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/30.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerC-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/31.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerD-mobile'>
            <div className='photos-InsideContainerD-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/32.png" alt="emp_image" /></div>  
            <div className='photos-InsideContainerD-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/34.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerD-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/35.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerD-mobile'><img loading='lazy'  className='colorC' src="/Images/new/employees pic/36.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerE-mobile'>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/37.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/38.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/39.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerF-mobile'>
            <div className='photos-InsideContainerF-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/40.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerF-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/41.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerF-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/42.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerF-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/43.png" alt="emp_image" /></div>
    </div>
    <div className='photos-ContainerE-mobile'>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/44.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/45.png" alt="emp_image" /></div>
            <div className='photos-InsideContainerE-mobile'><img loading='lazy'  className='colorC'  src="/Images/new/employees pic/17.png" alt="emp_image" /></div>
    </div>
</motion.section>
    </>
  )
}

export default OurTeam