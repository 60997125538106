import { Row, Container, Col } from 'reactstrap';
import "./Products.scss"
import "./Products.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetInTouch from './GetInTouchProduct';
import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { Breadcrumbs,Typography } from '@mui/material';
import * as dataSensexAnimation from '../../jsonFiles/Animated-web-screens-[remix] (2).json'
import Lottie from 'react-lottie';


const products = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: dataSensexAnimation,
      };


    const MotionContainer ={
        hidden: { y: 300, scale: 0.8, opacity: 0.5 },
        visible: { y: 0, scale: 1, opacity: 1 ,
        transition:{ duration: 1, }},

        hiddenH1: { y: 40,  opacity: 0.7 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.2 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenP2: { y: 35,  opacity: 0.2 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenBox1: { y:200,  opacity: 0.2},
        visibleBox1: {y:0,  opacity: 1,
        transition:{ duration: 1, }},

        hiddenBox2: { y:200,  opacity: 0.2},
        visibleBox2: {y:0,  opacity: 1,
        transition:{ duration: 1, }},

        hiddenBoxmobile1: { y:150,  opacity: 0.2},
        visibleBoxmobile1: {y:0,  opacity: 1,
        transition:{ duration: 1, }},

        hiddenBoxmobile2: { y:150,  opacity: 0.2},
        visibleBoxmobile2: {y:0,  opacity: 1,
        transition:{ duration: 1, }},

        hiddenBoximg: { y:30,  opacity: 0.5},
        visibleBoximg: { y:0,  opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenBoxP1: { y:40,  opacity: 0.5},
        visibleBoxP1: { y:0,  opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenBoxP2: { y:30,  opacity: 0.5},
        visibleBoxP2: { y:0,  opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenH2: { y: 50, opacity: 0.6 },
        visibleH2: { y: 0, opacity: 1, 
        transition:{ duration: 1, delay: 0.25,}},

        hiddenBoxIcon1: { x:-175,  opacity: 0.2},
        visibleBoxIcon1: {x:0,  opacity: 1,
        transition:{ duration: 0.95, }},

        hiddenBoxIcon2: { y:175,  opacity: 0.2},
        visibleBoxIcon2: {y:0,  opacity: 1,
        transition:{ duration: 0.95, }},

        hiddenBoxIcon3: { x:175,  opacity: 0.2},
        visibleBoxIcon3: {x:0,  opacity: 1,
        transition:{ duration: 0.95, }},

        hiddenBoxIcon: { y:175,  opacity: 0.2},
        visibleBoxIcon: {y:0,  opacity: 1,
        transition:{ duration: 0.95, }},

        hiddenVideo: { opacity: 0.5, y: 85 },
        visibleVideo: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

        hiddenHMobile: { x: -75, y:40, scale: 0.6, opacity: 0.5},
        visibleHMobile: {x: 0, opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

        hiddenvideoM: { y: 55, opacity: 0.5 },
        visiblevideoM: {y: 0, opacity: 1,
        transition: { duration: 0.50,  ease: [0, 0.71, 0.2, 1.01] }},

        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},
            
        hiddenMobimg: { y:35,  opacity: 0.5},
        visibleMobimg: { y:0,  opacity: 1,
        transition:{ duration: 0.55, delay: 0.10,}},

        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

        hiddenMobP2: { y: 20,  opacity: 0.9 },
        visibleMobP2: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},
    }




    return (
        <>
<div   className='Main-Class1' id="stackerank">
        <section className='StackeRank-Main'>
        <motion.img loading='lazy'  
        // className="blur blurrr"
         src="/Images/Stakerank/banner_stackerank.svg" alt="" />
        <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
        <div className='breadcrumb_text'><a href="/">Home</a></div>
        <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Products</Typography>
        <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >StackeRank</Typography>
        </Breadcrumbs>
         <div className='banner-content'>

        <div className='StackeRank-inner'>
        <motion.div
                style={{
                    width: "100%",
                }}
                variants={{
                    hidden: { opacity: 0.5, y: 85,  },
                    visible: { opacity: 1, y: 0, }
                }}
                initial="hidden"
                animate="visible"
                transition={{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}}>
            <motion.h1
            className='blur-h'
            variants={{
                hidden: { x: -331, y:72, scale: 0.4},
                visible: { x: 0, y: 0, scale: 1 }
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.25 ,ease: [0, 0.71, 0.2, 1.01]}}>
            StackéRank</motion.h1>
            <p className='Stackpara1'>Technology Agnostic, <br></br> Coding Assessment Platform</p>
            <p className='Stackpara2'>Hire on Merit Not on background</p>
            <div className="btn-container-stacker">
                <div className='btn-lii-stacker'>
                    <a
                        href="/stackerank#contact_form_product"><span className='btn-liil-stacker'>Connect for Demo  &nbsp;<ArrowBackIcon className="rotatee-stacker "/></span>
                    </a>
                </div>
            </div>
            <div className="btn-stacke-tablet">
                    <a href="/stackerank#contact_form_product"><span className='btn-inner'>Connect for Demo  &nbsp;<ArrowBackIcon className="stacke-arrow-tablet"/></span></a>
            </div>

        </motion.div>
        </div>
                    <div  className='banner-right'>
                        <motion.div
                            viewport={{  once: true}}
                            variants={MotionContainer}
                            initial="hiddenVideo"
                            whileInView="visibleVideo">
                        {/* <video className='Stackerank-video' loop autoPlay muted playsInline>
                            <source src="/Images/Stakerank/Animated-web-screens-[remix].mp4" type="video/mp4" />
                            <p>This browser not support in video tag</p>
                        </video> */}
                        <Lottie options={defaultOptions}/>
                        </motion.div>
                        </div>
        </div>
        </section>


{/* Mobile */}
        <section className='StackeRank-Main-Mobile'>
            <img loading='lazy' src="/Images/Stakerank/banner_stackerank_mobile.svg" alt="" />
            {window.innerWidth>480?
            <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
        <div className='breadcrumb_text'><a href="/">Home</a></div>
        <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Products</Typography>
        <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >StackeRank</Typography>
        </Breadcrumbs>
           : "" }
            <div className='banner-up'>
                        <Lottie options={defaultOptions}/>
            </div>

            <div className='Stackerank-Content-Main'>
                <motion.div
                  viewport={{ once: true }}
                  variants={MotionContainer}
                  initial="hiddenvideoM"
                  animate="visiblevideoM"
                >
                <motion.h1
                viewport={{  once: true}}
                variants={MotionContainer}
                initial="hiddenHMobile"
                animate="visibleHMobile"
                > StackéRank</motion.h1>
                <p className='Stackpara1'>Technology Agnostic,<br></br> Coding Assessment Platform</p>
            <p className='Stackpara2'>Hire on Merit Not on background</p>
            <div className="btn-stacke-mobile">
                    <a
                        href="/stackerank#contact_form_product"><span className='btn-inner-mobile'>Connect for Demo  &nbsp;<ArrowBackIcon className="stacke-arrow-mobile"/></span>
                    </a>
            </div>
                        </motion.div>
            </div>

        </section>


<div className='main-Product2' >

    <section  className='main-ProductInside'>
        <motion.section
                viewport={{  once: true}}
                variants={MotionContainer}
                initial="hidden"
                whileInView="visible"
                className='StakeRank-ProblemCase'>
            <motion.h1 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >Problem Case</motion.h1>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
            className='Stacke-Para1'>Discover the new way to hire - our platform agnostic pre evaluation testing software</motion.p>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP2"
            whileInView="visibleP2"
            className='Stacke-Para2'>SKILLS TESTING FOR <br></br><span class="orange_code"> SMARTER HIRING</span></motion.p>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP2"
            whileInView="visibleP2"
            className='Stacke-Para2-desktop'>SKILLS TESTING FOR <span class="orange_code"> SMARTER HIRING</span></motion.p>
            <div className='stacke-boxs'>
                <motion.div 
                viewport={{once: true}}
                variants={MotionContainer}
                initial="hiddenBox1"
                whileInView="visibleBox1"
                className='stacke-inside-box1 gradient-border-mask-product'>
                    <motion.img
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg" src="Images/Stakerank/background.png" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Stacke-Problem-para1'>Hiring based on <br/><span class="orange_code"> Background</span>  </motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Stacke-Problem-para2'>Over 80 percent Engineering Students are unemployable – NASSCOM. Majority of MVPs built need to be scratched and products need to be rebuilt. Large projects fail due to scalability, security and performance.</motion.p>
                </motion.div>

                <motion.div 
                viewport={{once: true}}
                variants={MotionContainer}
                initial="hiddenBox2"
                whileInView="visibleBox2"
                className='stacke-inside-box2 gradient-border-mask-product'>
                    <motion.img
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"
                    src="Images/Stakerank/wronghirng.png" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Stacke-Problem-para1'>High Impact of <br/><span class="orange_code">Wrong Hire</span></motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Stacke-Problem-para22'>Single interview step takes 30 minutes to one hour. Decision to hire / not hire a tech resource is made with 2-3 hours of interaction. Analysis of up-skill, cross-skill & re-skill capabilities is trivial.</motion.p>
                </motion.div>
            </div>
            
            <div className='stacke-boxs-mobile'>
                <motion.div 
                viewport={{once: true}}
                variants={MotionContainer}
                initial="hiddenBoxmobile1"
                whileInView="visibleBoxmobile1"
                className='stacke-inside-box1-mobile gradient-border-mask-product1'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"
                    src="Images/Stakerank/background.png" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Stacke-Problem-para1-mobile'>Hiring based on <br/><span class="orange_code"> Background</span>  </motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Stacke-Problem-para2-mobile'>Over 80 percent Engineering Students are unemployable – NASSCOM. Majority of MVPs built need to be scratched and products need to be rebuilt. Large projects fail due to scalability, security and performance.</motion.p>
                </motion.div>
                <motion.div 
                viewport={{once: true}}
                variants={MotionContainer}
                initial="hiddenBoxmobile2"
                whileInView="visibleBoxmobile2"
                className='stacke-inside-box2-mobile gradient-border-mask-product1'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"
                    src="Images/Stakerank/wronghirng.png" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Stacke-Problem-para1-mobile'>High Impact of <br/><span class="orange_code">Wrong Hire</span></motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Stacke-Problem-para22-mobile'>Single interview step takes 30 minutes to one hour. Decision to hire / not hire a tech resource is made with 2-3 hours of interaction. Analysis of up-skill, cross-skill & re-skill capabilities is trivial.</motion.p>
                </motion.div>
            </div>
        </motion.section>
        



<div className='stackerank-box'>
    

        <motion.section 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hidden"
        whileInView="visible"
        className='StakeRank-Content'>
            <motion.h1 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH2"
            whileInView="visibleH2"
            >StackéRank</motion.h1>
            <motion.p 
            viewport={{once: false}}
            variants={MotionContainer}
            initial="hiddenBoxP1"
            whileInView="visibleBoxP1"
            className='Stack-content-para1'>We help companies see how candidates do job-related tasks so they can make hiring decisions based on performance, without bias</motion.p>
            <motion.p 
            viewport={{once: false}}
            variants={MotionContainer}
            initial="hiddenBoxP2"
            whileInView="visibleBoxP2"
            className='Stack-content-para2'><span class="orange_code">HIRE ON MERIT <br></br></span> NOT ON BACKGROUND</motion.p>
            <motion.p 
            viewport={{once: false}}
            variants={MotionContainer}
            initial="hiddenBoxP2"
            whileInView="visibleBoxP2"
            className='Stack-content-para2-desktop'><span class="orange_code">HIRE ON MERIT </span> NOT ON BACKGROUND</motion.p>


            <motion.div 
            viewport={{once: true}}
            variants={MotionContainer}
            initial="hiddenBoxIcon"
            whileInView="visibleBoxIcon"
            className='stacke-content-box'> 
                <motion.div 
                // viewport={{once: true}}
                // variants={MotionContainer}
                // initial="hiddenBoxIcon1"
                // whileInView="visibleBoxIcon1"
                className='stacke-Insidecontent-box'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg" 
                    src="Images/Stakerank/Group 2068.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Stacke-content-para1'><span class="orange_code">LOW</span><br/> STRESS & ADAPTIVE</motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Stacke-content-para21'>Candidate Assessment in just 30 Minutes</motion.p>
                </motion.div>
                <motion.div 
                // viewport={{once: true}}
                // variants={MotionContainer}
                // initial="hiddenBoxIcon2"
                // whileInView="visibleBoxIcon2"
                className='stacke-Insidecontent-box'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg" 
                    src="/Images/Stakerank/Group 2074.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Stacke-content-para1'><span class="orange_code">ASSESSMENT </span><br/>INSIGHTS & REPORT</motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Stacke-content-para23'>Standardization and Formatting, CPU and Memory Utilization, Code Performance, Lines of Code</motion.p>
                </motion.div>
                <motion.div 
                // viewport={{once: true}}
                // variants={MotionContainer}
                // initial="hiddenBoxIcon3"
                // whileInView="visibleBoxIcon3"
                className='stacke-Insidecontent-box'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg" 
                    src="Images/Stakerank/Group 2186.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Stacke-content-para1'><span class="orange_code">AI/ML</span> <br/>GRADING & ANALYSIS</motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Stacke-content-para22'>Weighted Average and S-Curve Sorting</motion.p>            
                </motion.div>
            </motion.div>


        <motion.div 
        className='stacke-content-box-mobile'> 
            <div className='stacke-content-box-mobile1' >
                    <motion.div 
                    viewport={{once: true}}
                    variants={MotionContainer}
                    initial="hiddenBoxmobile1"
                    whileInView="visibleBoxmobile1"
                    className='stacke-Insidecontent-box-mobile'>
                        <img loading='lazy' src="Images/Stakerank/Group 2068.svg" alt="" />
                        <motion.p 
                        viewport={{once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoxP1"
                        whileInView="visibleBoxP1"
                        className='Stacke-content-para1-mobile'><span class="orange_code">LOW</span><br/> STRESS & ADAPTIVE</motion.p>
                        <motion.p 
                        viewport={{once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoxP2"
                        whileInView="visibleBoxP2"
                        className='Stacke-content-para21-mobile'>Candidate Assessment in just 30 Minutes</motion.p>
                    </motion.div>
                    <motion.div 
                    viewport={{once: true}}
                    variants={MotionContainer}
                    initial="hiddenBoxmobile2"
                    whileInView="visibleBoxmobile2"
                    className='stacke-Insidecontent-box-mobile1'>
                        <img loading='lazy' src="Images/Stakerank/Group 2186.svg" alt="" />
                        <motion.p 
                        viewport={{once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoxP1"
                        whileInView="visibleBoxP1"
                        className='Stacke-content-para1-mobile1'><span class="orange_code">AI/ML</span> <br/>GRADING & ANALYSIS</motion.p>
                        <motion.p 
                        viewport={{once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoxP2"
                        whileInView="visibleBoxP2"
                        className='Stacke-content-para24-mobile1'>Weighted Average and S-Curve Sorting</motion.p>            
                    </motion.div>
            </div>
        </motion.div>
<motion.div
                    viewport={{once: true}}
                    variants={MotionContainer}
                    initial="hiddenBoxmobile2"
                    whileInView="visibleBoxmobile2" 
className='stacke-content-box-mobile2'>
                <motion.div 
                
                className='stacke-Insidecontent-box-mobile'>
                        <img loading='lazy' src="/Images/Stakerank/Group 2074.svg" alt="" />
                        <motion.p 
                        viewport={{once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoxP1"
                        whileInView="visibleBoxP1"
                        className='Stacke-content-para1-mobile'><span class="orange_code">ASSESSMENT </span><br/>INSIGHTS & REPORT</motion.p>
                        <motion.p 
                        viewport={{once: false}}
                        variants={MotionContainer}
                        initial="hiddenBoxP2"
                        whileInView="visibleBoxP2"
                        className='Stacke-content-para23-mobile'>Standardization and Formatting, CPU and Memory Utilization, Code Performance, Lines of Code</motion.p>
                    </motion.div>
</motion.div>
        </motion.section>


<GetInTouch/>
<div className='add_svg'><img loading='lazy' src="Images/Stakerank/Group 454.svg" alt="" /></div>

        


</div>
</section>
<></>
</div>

        </div>

        
        </>
    )
}

export default products;
