import React, { useEffect, useState , } from 'react';
import Products from '../../Components/Products/Products';
import { Helmet } from 'react-helmet';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

const Productpage = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleLoadingComplete = () => {
        setIsLoading(true);
      };

    useEffect(() => {
        window.scrollTo(0, localStorage.getItem('position'));
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
        };
        scrollToTop();
        const onBeforeUnload = () => {
            window.scrollTo(0, 0);
        };
        window.addEventListener('beforeunload', onBeforeUnload);

        }, [])
    return (
        <div>
            <Helmet>
                <title>Technology Agnostic Coding Assessment Platform - Stackerank</title>
                <meta name="description" content="Technology Agnostic Coding Assessment Platform - Stackerank" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Technology Agnostic Coding Assessment Platform - Stackerank" />
                <meta property="og:description" content="Technology Agnostic Coding Assessment Platform - Stackerank" />
                <meta property="description" content="Technology Agnostic Coding Assessment Platform - Stackerank" />
                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="Technology Agnostic Coding Assessment Platform - Stackerank" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Technology Agnostic Coding Assessment Platform - Stackerank" />
                <meta name="twitter:description" content="Technology Agnostic Coding Assessment Platform - Stackerank" />
                <meta name="twitter:image" content="Technology Agnostic Coding Assessment Platform - Stackerank" />
            </Helmet>

            {isLoading ? 
                <>
                    <Products />
                </>
            : <LoadingSpinner onLoadingComplete={handleLoadingComplete}/> }
        </div>
    )
}

export default Productpage;