import React, { useEffect, useState } from "react";
import ourCulture from "../../Components/OurCulture/OurCulture";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { Col, Row } from "reactstrap";

const Possibilities = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: false });

    const [isActive, setIsActive] = useState(false);
    const handleClickChange = event => {
        setIsActive(current => !current);
    };
    const viewVariants = {
        hidden: { y: 20 },
        visible: {
            y: 0,
            transition: {
                duration: 1,
                delay: 0.15
            }
        }

    }
    return (
        <>
            <motion.section
                viewport={{ ref, once: true }}
                variants={{
                    hidden: { y: 300, scale: 0.8, opacity: 0.5 },
                    visible: { y: 0, scale: 1, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.8, delay: 0.25 }}
                className="Our-Approach Our-Approach-mobile Our-Approach-tablet col-md-12">

                <div className="Our-Approach-Ex-Container Our-Approach-Ex-Container-mobile Our-Approach-Ex-Container-tablet col-md-12">
                    <motion.h1
                        variants={{
                            hidden: { y: 40, scale: 0.9, opacity: 0.5 },
                            visible: { y: 0, scale: 1, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25, }}
                        className='possible_head col-md-12' >Unlimited possibilities <br></br>
                        with all-in-one</motion.h1>
                    <motion.div
                        viewport={{ ref, once: false }}
                        variants={{
                            hidden: { y: 20, opacity: 0.3 },
                            visible: { y: 0, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25, }}
                    >
                        <motion.p
                            viewport={{ ref, once: false }}
                            variants={{
                                hidden: { y: 30, opacity: 0.2 },
                                visible: { y: 0, opacity: 1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={{ duration: 1, delay: 0.15, }}
                            className='possibl_para col-md-12'>Workflows and processes nowadays go beyond traditional frameworks. To fully use the potential of your data you want to connect a variety of processes. Whether it’s automation, integration, communication or development: Flowize enables you to easily build flows combining every advanced feature you need. Withour independent, all-in-one platform possibilities are unlimited.</motion.p>
                        {/* <motion.p
                            viewport={{ ref, once: false }}
                            variants={{
                                hidden: { y: 30, opacity: 0.2 },
                                visible: { y: 0, opacity: 1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={{ duration: 1, delay: 0.15, }}
                            className='Our-pa col-md-12' style={{paddingBottom:'4%', fontSize:18}}>SHAPING FUTURE<span className='orange_code'> DIGITALLY </span> </motion.p> */}
                    </motion.div>
                    {window.innerWidth > 767 ?
                        <div style={{paddingRight:"5%", paddingLeft:'5%', marginBottom:"0%"}}>
                            <Row>
                                <Col>
                                        <motion.div className="possible_box"
                                            viewport={{ ref, once: false }}
                                            variants={{
                                                hidden: { y: 20, },
                                                visible: { y: 0, },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25, }}>
                                            <Row>
                                                <motion.img loading='lazy' src="/Images/partnership/flowise_1.png" alt="how_we_add" className="possible_img"
                                                    viewport={{ ref, once: false }}
                                                    variants={{
                                                        hidden: { y: 20, opacity: 0.3 },
                                                        visible: { y: 0, opacity: 1 },
                                                    }}
                                                    initial="hidden"
                                                    whileInView="visible"
                                                    transition={{ duration: 1, delay: 0.25, }} />
                                                <div className="culture_content">
                                                    <div className="possible_head-box"><span className="orange_code">Integrate</span></div>
                                                    <div className="possible_para-box">Any integration is possible: Flowize’s interoperability connects your legacy systems and your new systems, data from partners; and any other available (big) data source. &nbsp; &nbsp;</div>
                                                </div>
                                            </Row>
                                    </motion.div>
                                </Col>
                                <Col>
                                        <motion.div className="possible_box"
                                            viewport={{ ref, once: false }}
                                            variants={{
                                                hidden: { y: 20 },
                                                visible: { y: 0 },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25, }}>
                                            <Row>
                                                <motion.img loading='lazy' src="/Images/partnership/flowise_2.png" alt="how_we_add" className="possible_img"
                                                    viewport={{ ref, once: false }}
                                                    variants={{
                                                        hidden: { y: 20 },
                                                        visible: { y: 0 },
                                                    }}
                                                    initial="hidden"
                                                    whileInView="visible"
                                                    transition={{ duration: 1, delay: 0.25, }} />
                                                <div className="culture_content">
                                                    <div className="possible_head-box"><span className="orange_code">Automate</span></div>
                                                    <div className="possible_para-box">Automation makes cumbersome and time-consuming operations easy. Save time and improve productivity. With automation, you’re in control of your processes.</div>
                                                </div>
                                            </Row>
                                    </motion.div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                        <motion.div className="possible_box" viewport={{ ref, once: false }}
                                            variants={{
                                                hidden: { y: 20 },
                                                visible: { y: 0 },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25, }}>
                                            <Row>
                                                <motion.img loading='lazy' src="/Images/partnership/flowise_3.png" alt="how_we_add" className="possible_img"
                                                    viewport={{ ref, once: false }}
                                                    variants={{
                                                        hidden: { y: 20 },
                                                        visible: { y: 0 },
                                                    }}
                                                    initial="hidden"
                                                    whileInView="visible"
                                                    transition={{ duration: 1, delay: 0.25, }} />
                                                <div className="culture_content">
                                                    <div className="possible_head-box"><span className="orange_code">Communicate</span></div>
                                                    <div className="possible_para-box">Your customers want to be in control when & how you communicate with them. Create engaging experiences by sending precisely targeted & personalized content. &nbsp;</div>
                                                </div>
                                            </Row>
                                        </motion.div>
                                </Col>
                                <Col>
                                        <motion.div className="possible_box"
                                            viewport={{ ref, once: false }}
                                            variants={{
                                                hidden: { y: 20 },
                                                visible: { y: 0 },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25, }}>
                                            <Row>
                                                <motion.img loading='lazy' src="/Images/partnership/flowise_4.png" alt="how_we_add" className="possible_img" height={100}
                                                    viewport={{ ref, once: false }}
                                                    variants={{
                                                        hidden: { y: 20 },
                                                        visible: { y: 0 },
                                                    }}
                                                    initial="hidden"
                                                    whileInView="visible"
                                                    transition={{ duration: 1, delay: 0.25, }} />
                                                <div className="culture_content">
                                                    <div className="possible_head-box"><span className="orange_code">Develop</span></div>
                                                    <div className="possible_para-box">Flowize makes it easy for your teams to design and implement flows. Work in multiple projects, manage versioning, and clone projects.</div>
                                                </div>
                                            </Row>
                                        </motion.div>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div style={{marginBottom:"15%"}}>
                            <div>
                                    <motion.div className="possible_a1-mobile"
                                        viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <Row>
                                            <motion.div className="culture_content" viewport={{ ref, once: false }}
                                                variants={{
                                                    hidden: { y: 20 },
                                                    visible: { y: 0 },
                                                }}
                                                initial="hidden"
                                                whileInView="visible"
                                                transition={{ duration: 1, delay: 0.25, }}>
                                                    {window.innerWidth >480 ?
                                                     <Row>
                                                     <motion.img loading='lazy' src="/Images/partnership/flowise_1.png" alt="how_we_add" className="possible_img-mobile"/> 
                                                     <div className="possible_head-box"><span className="orange_code">Intergrate</span></div>
                                                     </Row> 
                                                     :
                                                     <>
                                                      <Row>
                                                    <motion.img loading='lazy' src="/Images/partnership/flowise_1.png" alt="how_we_add" className="possible_img-mobile"/> 
                                                     </Row>
                                                    <div className="possible_head-box"><span className="orange_code">Intergrate</span></div>
                                                     </>
                                                }
                                               
                                                <div className="possible_para-box-mobile">Any integration is possible: Flowize’s interoperability connects your legacy systems and your new systems, data from partners; and any other available (big) data source.</div>
                                                <br />
                                            </motion.div>
                                        </Row>
                                </motion.div>
                            </div>
                            <div>
                                    <motion.div className="possible_a1-mobile" viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <Row>
                                            <motion.div className="culture_content" viewport={{ ref, once: false }}
                                                variants={{
                                                    hidden: { y: 20 },
                                                    visible: { y: 0 },
                                                }}
                                                initial="hidden"
                                                whileInView="visible"
                                                transition={{ duration: 1, delay: 0.25, }}>
                                                    {window.innerWidth >480 ?
                                                    <Row>
                                                    <motion.img loading='lazy' src="/Images/partnership/flowise_2.png" alt="how_we_add" className="possible_img-mobile" />
                                                    <div className="possible_head-box"><span className="orange_code">Automate</span></div>
                                                    </Row>
                                                     :
                                                     <>
                                                    <Row>
                                                        <motion.img loading='lazy' src="/Images/partnership/flowise_2.png" alt="how_we_add" className="possible_img-mobile" />
                                                    </Row>
                                                        <div className="possible_head-box"><span className="orange_code">Automate</span></div>
                                                     </>
                                                }
                                                <div className="possible_para-box-mobile">Automation makes cumbersome and time-consuming operations easy. Save time and improve productivity. With automation, you’re in control of your processes.</div>
                                                <br />
                                            </motion.div>
                                        </Row>
                                </motion.div>
                            </div>
                            <div>
                               <motion.div className="possible_a1-mobile" viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <Row>
                                            <motion.div className="culture_content"
                                                viewport={{ ref, once: false }}
                                                variants={{
                                                    hidden: { y: 20 },
                                                    visible: { y: 0 },
                                                }}
                                                initial="hidden"
                                                whileInView="visible"
                                                transition={{ duration: 1, delay: 0.25, }}>
                                                {window.innerWidth >480 ?
                                                    <Row>
                                                    <motion.img loading='lazy' src="/Images/partnership/flowise_3.png" alt="how_we_add" className="possible_img-mobile" />
                                                    <div className="possible_head-box"><span className="orange_code">Communicate</span></div>
                                                    </Row>
                                                     :
                                                     <>
                                                   <Row>
                                                    <motion.img loading='lazy' src="/Images/partnership/flowise_3.png" alt="how_we_add" className="possible_img-mobile" />
                                                </Row>
                                                    <div className="possible_head-box"><span className="orange_code">Communicate</span></div>
                                                     </>
                                                }
                                                <div className="possible_para-box-mobile">Your customers want to be in control when & how you communicate with them. Create engaging experiences by sending precisely targeted & personalized content.</div>
                                                <br />
                                            </motion.div>
                                        </Row>
                                    </motion.div>
                            </div>
                            <div>
                               <motion.div className="possible_a1-mobile"
                                        viewport={{ ref, once: false }}
                                        variants={{
                                            hidden: { y: 20 },
                                            visible: { y: 0 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={{ duration: 1, delay: 0.25, }}>
                                        <Row>
                                            <motion.div className="culture_content" viewport={{ ref, once: false }}
                                                variants={{
                                                    hidden: { y: 20 },
                                                    visible: { y: 0 },
                                                }}
                                                initial="hidden"
                                                whileInView="visible"
                                                transition={{ duration: 1, delay: 0.25, }}>
                                                    {window.innerWidth >480 ?
                                                     <Row>
                                                     <motion.img loading='lazy' src="/Images/partnership/flowise_4.png" alt="how_we_add" className="possible_img-mobile"/>
                                                     <div className="possible_head-box"><span className="orange_code">Develop</span></div>
                                                     </Row>
                                                     :
                                                     <>
                                                    <Row>
                                                    <motion.img loading='lazy' src="/Images/partnership/flowise_4.png" alt="how_we_add" className="possible_img-mobile"/>
                                                    </Row>
                                                    <div className="possible_head-box"><span className="orange_code">Develop</span></div>
                                                     </>
                                                }
                                                <div className="possible_para-box-mobile">Flowize makes it easy for your teams to design and implement flows. Work in multiple projects, manage versioning, and clone projects.</div>
                                                <br />
                                            </motion.div>
                                        </Row>
                                    </motion.div>
                            </div>
                        </div>
                    }
                </div>
            </motion.section>
        </>
    )
}
export default Possibilities;