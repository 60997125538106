import React, { useState } from 'react';
import "./ReachUs.scss";
import { motion, useInView, } from 'framer-motion';

const ReachUs = () => {

    const MotionReach = {
        hidden: { opacity: 0.5, y: 150 },
        visible: {
            opacity: 1, y: 0,
            transition: { duration: 0.60, delay: 0.25 }
        },

        hiddenHead: { opacity: 0.9, y: 40, },
        visibleHead: {
            opacity: 1, y: 0,
            transition: { duration: 0.60, delay: 0.25 }
        },

        hiddenPara: { opacity: 0.9, y: 30, },
        visiblePara: {
            opacity: 1, y: 0,
            transition: { duration: 0.60, }
        },

        hiddenMobileReach: { opacity: 0.9, y: 200, scale: 0.95 },
        visibleMobileReach: {
            opacity: 1, y: 0, scale: 1,
            transition: { duration: 0.60, }
        },

        hiddenMHeadReach: { opacity: 0.9, y: 200, scale: 0.95 },
        visibleMHeadReach: {
            opacity: 1, y: 0, scale: 1,
            transition: { duration: 0.60, }
        },

        hiddenMobileHeadReach: { y: 30 },
        visibleMobileHeadReach: {
            y: 0,
            transition: { duration: 0.60, }
        },

        hiddenMobileParaReach: { y: 30 },
        visibleMobileParaReach: {
            y: 0,
            transition: { duration: 0.60, }
        },


    }

    return (
        <>
            <section className='Contact-Location'>
                <motion.div
                    viewport={{ once: true }}
                    variants={MotionReach}
                    initial="hidden"
                    whileInView="visible"
                    className='Contact-Location-right'>
                    <motion.h1
                        viewport={{ once: true }}
                        variants={MotionReach}
                        initial="hiddenHead"
                        whileInView="visibleHead"
                    >Reach Us</motion.h1>
                    <div className='Contact-Location-insideBox1'>
                        <motion.div
                            viewport={{ once: false }}
                            variants={MotionReach}
                            initial="hiddenPara"
                            whileInView="visiblePara"
                            className='insideBox1'>
                            <h1>India</h1><br />
                            <p>#47/1, Narayana Arcade, 2nd Floor, <br />
                                Subbaramachetty Road, Basavanagudi, <br />
                                Bangalore-560004</p>
                        </motion.div>
                        <motion.div
                            viewport={{ once: false }}
                            variants={MotionReach}
                            initial="hiddenPara"
                            whileInView="visiblePara"
                            className='insideBox2'>
                            <h1>USA</h1><br />
                            <p>8 The Green, Suite R<br />
                                City of Dover, State of Delaware<br />
                                DE-19901.</p>
                        </motion.div>
                    </div>

                </motion.div>
                <motion.div
                    viewport={{ once: true }}
                    variants={MotionReach}
                    initial="hidden"
                    whileInView="visible"
                    className='Contact-Location-left'>
                    <img loading='lazy' src="/Images/ContactUs/world.png" alt="" />
                    {/* <img loading='lazy' src="/Images/ContactUs/banner_digital_services_2.2.png" alt="" /> */}
                </motion.div>
            </section>



            <section className='Contact-Location-mobile'>
                <motion.div
                    viewport={{ once: true }}
                    variants={MotionReach}
                    initial="hiddenMobileReach"
                    whileInView="visibleMobileReach"
                    className='Contact-Location-mobile-inside1'>
                    <motion.h1
                        viewport={{ once: false }}
                        variants={MotionReach}
                        initial="hiddenMobileHeadReach"
                        whileInView="visibleMobileHeadReach"
                    >Reach Us</motion.h1>
                    <div className='world_img'>
                        <img loading='lazy' src="/Images/ContactUs/world.png" alt="" />
                    </div>
                </motion.div>
                <motion.div
                    viewport={{ once: true }}
                    variants={MotionReach}
                    initial="hiddenMHeadReach"
                    whileInView="visibleMHeadReach"
                    className='Contact-Location-mobile-inside2'>
                    <motion.div
                        viewport={{ once: false }}
                        variants={MotionReach}
                        initial="hiddenMobileParaReach"
                        whileInView="visibleMobileParaReach"
                        className='inside_loc_insideBox1'>
                        <h1>India</h1>
                        <p>#47/1, Narayana Arcade, 2nd Floor,                         Subbaramachetty Road, Basavanagudi,
                            <br />Bangalore-560004</p>
                    </motion.div>
                    <motion.div
                        viewport={{ once: false }}
                        variants={MotionReach}
                        initial="hiddenMobileParaReach"
                        whileInView="visibleMobileParaReach"
                        className='inside_loc_insideBox2'>
                        <h1>USA</h1>
                        <p>8 The Green, Suite R<br />
                            City of Dover, State of Delaware<br />
                            DE-19901.</p>
                    </motion.div>

                </motion.div>
            </section>
        </>
    )
}

export default ReachUs