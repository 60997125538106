import React from 'react'
import './WhatMakesEvenionDifferent.scss';
import { motion } from 'framer-motion';

const WhatMakesEvenionDifferent = () => {
    const MotionContainer ={
        hidden: { opacity: 0.5, y: 300, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},

        hiddenContent: { opacity: 0.5, y: 250 },
        visibleContent: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1, }},
        
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenP2: { y: 35,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},
            
        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

        hiddenMobP2: { y: 20,  opacity: 0.9 },
        visibleMobP2: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},
        
    }    
    
  return (
    <>

    <section className='main-EvenionDiff'>
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"
        className='evenionDiff-content'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >What Makes <br></br> Evenion Different?</motion.h1>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
            className='para1'>Evenion stands out with its full-stack capabilities, agile prowess, and user-centric<br></br> philosophy, providing accelerated innovations backed by domain expertise</motion.p>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
            className='para2'>SHAPING <span className='orange_code'>INNOVATION</span>, EMPOWERING <span className='orange_code'>DIFFERENCE</span></motion.p>
        </motion.div>

        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenContent"
            whileInView="visibleContent"
        className='evenionDiff-box'>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >FULL STACK ENGINEERING CAPABILITIES</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >Our team possesses comprehensive full-stack engineering capabilities, ensuring that we can handle every aspect of your project, from front-end development to back-end infrastructure.</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (14).png" alt="default" />
                </div>
            </div>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >AGILE PROCESSES</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >We thrive on agile processes and a modern tech stack, which enables us to rapidly innovate and adapt, ensuring that your project stays ahead in a rapidly evolving digital landscape.</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (16).png" alt="default" />
                </div>
            </div>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >ACCELERATED INNOVATIONS</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >At Evenion, we're committed to accelerating innovations. Our agile methodologies, coupled with the latest technologies, empower us to deliver solutions faster and more efficiently</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/agile.png" alt="default" />
                </div>
            </div>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >USER-CENTERED APPROACH</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >We prioritize your users at the heart of our solutions. Our user-centered approach ensures that every design and feature caters to the needs and expectations of your target audience.</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (18).png" alt="default" />
                </div>
            </div>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >DOMAIN EXPERTISE</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >With extensive domain expertise, we bring a deep understanding of your industry. This knowledge allows us to provide tailored solutions that align with your specific business goals.</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (19).png" alt="default" />
                </div>
            </div>
        </motion.div>

        {/* tablet box */}
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenContent"
            whileInView="visibleContent"
        className='evenionDiff-box-tablet'>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >FULL STACK ENGINEERING CAPABILITIES</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >Our team possesses comprehensive full-stack engineering capabilities, ensuring that we can handle every aspect of your project, from front-end development to back-end infrastructure.</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (14).png" alt="default" />
                </div>
            </div>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >AGILE PROCESSES</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >We thrive on agile processes and a modern tech stack, which enables us to rapidly innovate and adapt, ensuring that your project stays ahead in a rapidly evolving digital landscape.</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (16).png" alt="default" />
                </div>
            </div>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >ACCELERATED INNOVATIONS</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >At Evenion, we're committed to accelerating innovations. Our agile methodologies, coupled with the latest technologies, empower us to deliver solutions faster and more efficiently</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/agile.png" alt="default" />
                </div>
            </div>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >USER-CENTERED APPROACH</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >We prioritize your users at the heart of our solutions. Our user-centered approach ensures that every design and feature caters to the needs and expectations of your target audience.</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (18).png" alt="default" />
                </div>
            </div>
            <div className='box1'>
                <div className='content-box'>
                    <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    >DOMAIN EXPERTISE</motion.h1>
                    <motion.p
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
                    >With extensive domain expertise, we bring a deep understanding of your industry. This knowledge allows us to provide tailored solutions that align with your specific business goals.</motion.p>
                </div>
                <div className='img-box1'>
                    <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (19).png" alt="default" />
                </div>
            </div>
        </motion.div>

    </section>
    
    {/* mobile */}
    <section className='main-EvenionDiff-mobile'>
        <motion.div 
                viewport={{  once: true}}
                variants={MotionContainer}
                initial="hiddenMob"
                whileInView="visibleMob"
        className='evenionDiff-content-mobile'>
            <motion.h1 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
            >What Makes <br></br> Evenion Different?</motion.h1>
            <motion.p  
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
            className='para1'>Evenion stands out with its full-stack capabilities, agile prowess, and user-centric philosophy, providing accelerated innovations backed by domain expertise</motion.p>
            <motion.p  
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP2"
                whileInView="visibleMobP2"
            className='para2'>SHAPING <span className='orange_code'>INNOVATION</span>, EMPOWERING <span className='orange_code'>DIFFERENCE</span></motion.p>
        </motion.div>

        <motion.div 
                viewport={{  once: true}}
                variants={MotionContainer}
                initial="hiddenMob"
                whileInView="visibleMob" 
        className='evenionDiff-box-mobile'>
            <div className='boxDigi'> 
                <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"
                >FULL STACK ENGINEERING CAPABILITIES</motion.h1>
                <motion.p
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1" 
                >Our team possesses comprehensive full-stack engineering capabilities, ensuring that we can handle every aspect of your project, from front-end development to back-end infrastructure.</motion.p> 
                <div className='imgDigi'><img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (14).png" alt="default" /></div>
            </div>
            <div className='boxDigi'> 
                <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"
                >AGILE PROCESSES</motion.h1>
                <motion.p
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1" 
                >We thrive on agile processes and a modern tech stack, which enables us to rapidly innovate and adapt, ensuring that your project stays ahead in a rapidly evolving digital landscape.</motion.p> 
                <div className='imgDigi'><img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (16).png" alt="default" /></div>
            </div>
            <div className='boxDigi'> 
                <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"
                >ACCELERATED INNOVATIONS</motion.h1>
                <motion.p
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1" 
                >At Evenion, we're committed to accelerating innovations. Our agile methodologies, coupled with the latest technologies, empower us to deliver solutions faster and more efficiently</motion.p> 
                <div className='imgDigi'><img loading='lazy' src="/Images/DigitalEngineering/agile.png" alt="default" /></div>
            </div>
            <div className='boxDigi'> 
                <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"
                >USER-CENTERED APPROACH</motion.h1>
                <motion.p
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1" 
                >We prioritize your users at the heart of our solutions. Our user-centered approach ensures that every design and feature caters to the needs and expectations of your target audience.</motion.p> 
                <div className='imgDigi'> <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (18).png" alt="default" /></div>
            </div>
            <div className='boxDigi'> 
                <motion.h1
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"
                >DOMAIN EXPERTISE</motion.h1>
                <motion.p
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1" 
                >With extensive domain expertise, we bring a deep understanding of your industry. This knowledge allows us to provide tailored solutions that align with your specific business goals.</motion.p> 
                <div className='imgDigi'>  <img loading='lazy' src="/Images/DigitalEngineering/image-removebg-preview (19).png" alt="default" /></div>
            </div>
        </motion.div>
    </section>
    </>
  )
}

export default WhatMakesEvenionDifferent