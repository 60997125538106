import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, localStorage.getItem('position'));
    window.scrollTo(0, 0);
  }, [pathname]);

  window.onscroll = function (e) {

    var distanceScrolled = document.documentElement.scrollTop;

    if (distanceScrolled > 1) {
      document.getElementById("logo-eve").style = "padding:0.5rem 0rem";
    } else {
      document.getElementById("logo-eve").style = "padding:1.3rem 0rem";
    }

  }
  return null;
}