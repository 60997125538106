import React, { useState, useRef } from 'react';
import './AboutUs.scss';
import { motion,    useInView,  } from 'framer-motion';

const OurStories = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, {once: false});


return (
<>
<motion.section 
                style={{ overflow:"hidden"}}
                viewport={{ref, once:true}}
                variants={{
                    hidden: { y: 350,  opacity: 0.2, },
                    visible: { y: -35, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1,}}
                className="Our-Stories Our-Stories-tablet col-md-12">
                    <div className="Our-Stories-Ex-Container Our-Stories-Ex-Container-tablet col-md-12">
                        <motion.h1 
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40, scale:0.8, opacity: 0.2 },
                            visible: { y: 0, scale: 1, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 0.9, delay: 0.25,}}
                        className='col-md-12' >Our Story</motion.h1>
                        <motion.p 
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40,  opacity: 0.2 },
                            visible: { y: 0, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 0.9, delay: 0.25,}}
                        className='Our-Stories-p   col-md-12'>We specialize in Digital Transformation, Software Product Development & Data Technology </motion.p>
                        <motion.p 
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40, opacity: 0.2 },
                            visible: { y: 0,  opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 0.9, delay: 0.25,}}
                        className='Our-Stories-pa   col-md-12'>From Strategy to<span className='orange_code'>  Execution</span> </motion.p>
                        <motion.p 
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40,  opacity: 0.2 },
                            visible: { y: 0,  opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 0.9, delay: 0.25,}}
                        className='Our-Stories-paa  col-md-12'>We were born from the idea that development abroad can be done in a very people-centered & integrated way that doesn’t sacrifice the brand’s voice or quality. We envision to help solve three main issues for our clients : Lack of in-house expertise, Absence of diverse experience, Limited talent pool</motion.p>
                    </div>
                </motion.section>
                
                <motion.section 
                viewport={{ref, once:true}}
                variants={{
                    hidden: { y: 40, opacity: 0.5, scale:0.7},
                    visible: { y: 0, opacity: 1 ,scale:1}
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.25 }} 
                className='Our-Stories-Icon-mobile'>
                    <div className='Our-Stories-Icon-InsideContainer-mobile col-md-12  row'>
                        <div className='boxxx'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                                    variants={{
                                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                                        visible: { y: 0, opacity: 1 ,scale:1}
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/Group 7164(1).svg" alt="2016" />
                        <motion.p
                                viewport={{ref, once:false}}
                                variants={{
                                    hidden: { y: 40, opacity: 0.5, scale:0.7},
                                    visible: { y: 0, opacity: 1 ,scale:1}
                                }}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'> 2016 </span>Established</motion.p>
                        </div>
                        <div className='boxx'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                                    variants={{
                                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                                        visible: { y: 0, opacity: 1 ,scale:1}
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/Group 6741.svg" alt="20+" />
                        <motion.p
                                viewport={{ref, once:false}}
                                variants={{
                                    hidden: { y: 40, opacity: 0.5, scale:0.7},
                                    visible: { y: 0, opacity: 1 ,scale:1}
                                }}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'> 20+</span> Satisfied <br/>  Customers</motion.p>
                        </div>
                        <div className='boxx'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                                    variants={{
                                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                                        visible: { y: 0, opacity: 1 ,scale:1}
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/si-glyphcustomer-support(1).svg" alt="98%"  />
                        <motion.p
                                viewport={{ref, once:false}}
                                variants={{
                                    hidden: { y: 40, opacity: 0.5, scale:0.7},
                                    visible: { y: 0, opacity: 1 ,scale:1}
                                }}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'>98 %</span> Customer Satisfaction Rate</motion.p>
                        </div>
                        <div className='boxx'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                                    variants={{
                                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                                        visible: { y: 0, opacity: 1 ,scale:1}
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/Group.svg" alt="7.25" />
                        <motion.p
                                viewport={{ref, once:false}}
                                variants={{
                                    hidden: { y: 40, opacity: 0.5, scale:0.7},
                                    visible: { y: 0, opacity: 1 ,scale:1}
                                }}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'>7.5Yrs</span> Average Team Experience</motion.p>
                        </div>
                        <div className='boxx'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                                    variants={{
                                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                                        visible: { y: 0, opacity: 1 ,scale:1}
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/Group1.svg" alt="50+"  />
                        <motion.p
                                viewport={{ref, once:false}}
                                variants={{
                                    hidden: { y: 40, opacity: 0.5, scale:0.7},
                                    visible: { y: 0, opacity: 1 ,scale:1}
                                }}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'> 50+ </span> Engineers</motion.p>
                        </div>
                    </div>
                </motion.section>
                


                <motion.section 
                viewport={{ref, once:true}}
                variants={{
                    hidden: { y: 50, opacity: 0.5, },
                    visible: { y: 0, opacity: 1 ,}
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.25 }} 
                className='Our-Stories-Icon-tablet'>
                    <div className='Our-Stories-Icon-InsideContainer-tablet col-xs-12  row'>
                        <div className='boxx-tablet'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                        variants={{
                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                        visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/Group 7164(1).svg" alt="2016" />
                        <motion.p
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40, opacity: 0.5, scale:0.7},
                            visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'> 2016 </span>Established</motion.p>
                        </div>
                        <div className='boxx-tablet'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                        variants={{
                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                        visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/Group 6741.svg" alt="20+" />
                        <motion.p
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40, opacity: 0.5, scale:0.7},
                            visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'> 20+</span> Satisfied Customers</motion.p>
                        </div>
                        <div className='boxx-tablet'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                        variants={{
                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                        visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/si-glyphcustomer-support(1).svg" alt="98%"  />
                        <motion.p
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40, opacity: 0.5, scale:0.7},
                            visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'>98 %</span> Customer Satisfaction Rate</motion.p>
                        </div>
                        <div className='boxx-tablet'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                        variants={{
                        hidden: { y: 40, opacity: 0.5, scale:0.7},
                        visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        src="/Images/new/Group.svg" alt="7.25" />
                        <motion.p
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40, opacity: 0.5, scale:0.7},
                            visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'>7.5Yrs</span> Average Team Experience</motion.p>
                        </div>
                        <div className='boxx-tablet'>
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40, opacity: 0.5, scale:0.7},
                            visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }}                         
                        src="/Images/new/Group1.svg" alt="50+"  />
                        <motion.p
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 40, opacity: 0.5, scale:0.7},
                            visible: { y: 0, opacity: 1 ,scale:1}
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25 }} 
                        ><span className='orange_code'> 50+ </span>  Engineers</motion.p>
                        </div>
                    </div>
                </motion.section>



<motion.section
viewport={{ref, once:true}}
variants={{
    hidden: { y: 50, opacity: 0.5},
    visible: { y: 0, opacity: 1 }
}}
initial="hidden"
whileInView="visible"
transition={{ duration: 1, delay: 0.25 }} 
className='box-border'>
    <div className='box-border-inside1'>   
        <motion.img loading='lazy'  
        viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        src="/Images/new/Group 7164(1).svg" alt="2016" />
        <motion.p 
                viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        ><span className='orange_code'> 2016 </span>Established</motion.p >
    </div>
    <div className='box-border-inside'>   
        <motion.img loading='lazy'  
        viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        src="/Images/new/Group 6741.svg" alt="20+" />
        <motion.p  
                viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        ><span className='orange_code'> 20+</span> Satisfied <br/>Customers</motion.p >
    </div>
    <div className='box-border-inside'>   
        <motion.img loading='lazy'  
        viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        src="/Images/new/si-glyphcustomer-support(1).svg" alt="98%"  />
        <motion.p  
                viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        ><span className='orange_code'>98 %</span> Customer Satisfaction Rate</motion.p >
    </div>
    <div className='box-border-inside'>   
        <motion.img loading='lazy'  
        viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        src="/Images/new/Group.svg" alt="7.25" />
        <motion.p  
                viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        ><span className='orange_code'>7.5Yrs</span> Average Team Experience</motion.p >
    </div>
    <div className='box-border-inside'>   
        <motion.img loading='lazy'  
        viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        src="/Images/new/Group1.svg" alt="50+"  />
        <motion.p  
                viewport={{ref, once:false}}
        variants={{
            hidden: { y: 30, opacity: 0.8, scale:0.9},
            visible: { y: 0, opacity: 1 ,scale:1}
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25 }} 
        ><span className='orange_code'> 50+ </span>  Engineers</motion.p >
    </div>
</motion.section>



</>
  )
}

export default OurStories