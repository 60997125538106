import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./GetInTouchContact.scss";
import { Button, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import getuseForm from './getFormContact.js';
import getvalidate from './getValidateInfoConatact';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Lottie from 'react-lottie';
import * as mailAnimation from '../../jsonFiles/mail_send.json'
import { Breadcrumbs, Typography } from '@mui/material';
import { motion,    useInView,  } from 'framer-motion';


const GetinTouchConatct = (props) => {
 const MotionContact = {
    hidden: { opacity: 0.5, y: 105,  },
    visible: { opacity: 1, y: 0, 
    transition: { duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

    hiddenHead: { x: -280, y:72, scale: 0.4},
    visibleHead: { x: 0, y: 0, scale: 1, 
    transition: { duration: 0.5, delay: 0.25 ,ease: [0, 0.71, 0.2, 1.01]}},

    hiddenInput: { y: 150 },
    visibleInput: { y: 0,  
    transition: { duration: 0.5, delay: 0.25 }},

    hiddenMobile: { y: 150, scale: 0.8 },
    visibleMobile: { y: 0, scale: 1, 
    transition: { duration: 0.5, delay: 0.25 }},

    hiddenHeadMobile: { y: 30,  },
    visibleHeadMobile: { y: 0,  
    transition: { duration: 0.5, delay: 0.25 }},

 }


 
    var regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    var reg = /^[a-zA-Z\s]*$/;
    var phoneNumberRegex2 = /^[\d+\s()-]+$/;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: mailAnimation,
    };

    const [errors, setErrors] = useState({});
    const { handleChange, values } = getuseForm(getvalidate);

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(getvalidate(values));

        if (values.name !== '' && reg.test(values.name) && values.companyname !== '' && isNaN(values.companyname)  && values.email !== '' && regex.test(values.email) && values.phone !== '' && phoneNumberRegex2.test(values.phone) && values.phone.length >5 && values.phone.length <20   && values.message !== '' && isNaN(values.message)) {
            const getData = async () => {
                let currentPath = window.location.hash;
                let url;
                if (currentPath) {
                    url = `${process.env.REACT_APP_API_URL}/contactus`;
                } else {
                    url = `${process.env.REACT_APP_API_URL}/contact`;
                }
                await axios.post(url, {
                    name: values.name,
                    email: values.email,
                    phone_number: values.phone,
                    message: values.message,
                    company_name: values.companyname,
                    // company_name: "test",
                    from: 'Evenion'
                })
                    .then(response => {
                        if (response) {
                            setModal(true);
                            handleReset();
                        }
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            }
            getData();

        }
    }
    const handleReset = (e) => {
        values.name = "";
        values.email = "";
        values.phone = "";
        values.message = "";
        values.companyname = "";
    }


    return (
        <>
    <section className='Contact_mainHead'>
        {window.innerWidth>480?
    <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{ top: 0 }}>
        <div className='breadcrumb_text'><a href="/">Home</a></div>
        <Typography color="#0F326C" fontFamily=' space-Grotesksb' fontWeight='300' >Contact Us</Typography>
    </Breadcrumbs>
       : "" }
    <div className='Contact_mainHead-inside1'>
        <motion.div style={{width: "100%", 
        // border: "1px solid red"
    }}
        viewport={{  once: true}}
        variants={MotionContact}
        initial="hidden"
        animate="visible"
        >
        <motion.h1 
        viewport={{  once: true}}
        variants={MotionContact}
        initial="hiddenHead"
        animate="visibleHead"
        >Let’s Talk</motion.h1>
        <p className='Contactpara1'>About Technology & Solutions</p>
        <p className='Contactpara2'>We are always looking for new ideas & new partnerships. Whether you are interested in hearing from us about our offerings, partnering with us, joining us or doing business with us - contact us to see how we can help you</p>
        </motion.div>
    </div>

            <div className='Contact_mainHead-inside1-mobile'>
                <motion.div
                viewport={{  once: true}}
                variants={MotionContact}
                initial="hiddenMobile"
                animate="visibleMobile"
                >
                <motion.h1 
                viewport={{  once: false}}
                variants={MotionContact}
                initial="hiddenHeadMobile"
                whileInView="visibleHeadMobile"
                >Let’s Talk</motion.h1>
                <p className='Contactpara1-mobile'>About Technology & Solutions</p>
                <p className='Contactpara2-mobile'>We are always looking for new ideas & new partnerships. Whether you are interested in hearing from us about our offerings, partnering with us, joining us or doing business with us - contact us to see how we can help you</p>
                </motion.div>
            </div>
    <div className='Contact_mainHead-inside2'>
    <motion.div 
            viewport={{  once: true}}
            variants={MotionContact}
            initial="hiddenInput"
            animate="visibleInput"
    class="Contact-login-box ">
    <form onSubmit={handleSubmit}>
    <div class="Contact-user-box ">
        <input 
        type="text" 
        name="name" 
        placeholder='Name' 
        className='input-color' 
        autoComplete='off'
        value={values.name}
        onChange={handleChange} /><br />
        {errors.name && <p className="
        error_text-Contact">{errors.name}</p>}
    </div>
    <div class="Contact-user-box ">
        <input 
        type="text"
        name="email"
        placeholder='Email' 
        className='input-color'          
        autoComplete='off'
        value={values.email}
        onChange={handleChange} /><br />
        {errors.email && <p className="

        error_text-Contact">{errors.email}</p>}
    </div>
    <div class="Contact-user-box ">
        <input 
        type="text"
        name="companyname"
        placeholder='Company ' 
        className='input-color'          
        autoComplete='off'
        value={values.companyname}
        onChange={handleChange} /><br />
        {errors.companyname && <p className="
        error_text-Contact">{errors.companyname}</p>}
    </div>
    <div class="Contact-user-box u">
        <input
        type="text"
        name="phone"
        placeholder='Phone No' 
        className='input-color'
        autoComplete='new-password'
        value={values.phone}
        onChange={handleChange} /><br />
        {errors.phone && <p className="

        error_text-Contact ">{errors.phone}</p>}
    </div>
        <div class="Contact-user-boxx ">
        <textarea 
        type="text"
        name="message"
        placeholder='Message' 
        className='input-color'   
        autoComplete='off'
        value={values.message}
        onChange={handleChange} />
        
        <br />
        {errors.message && <p className="
        error_text-Contact">{errors.message}</p>}
    </div>
        <button type="submit" className='send-btn-contact'>Send Now &nbsp;<ArrowBackIcon className='rotate-arrow'/>  </button>
        <button type="submit" className='send-btn-contact-mobile'>Send Now &nbsp;<ArrowBackIcon className='rotate-arrow'/> </button>
    </form>
</motion.div>
    </div>
                <Modal isOpen={modal} className='modal-contentPro '>
                    <ModalBody >
                        <h5 className="text-center bg-color my-4">Thank you for contacting us!</h5>
                        <div className='gif-set'>
                            {/* <img loading='lazy' src="Images/Stakerank/mail send (2).gif" alt="" /> */}
                            <Lottie options={defaultOptions} />
                        </div>
                        <p className="mb-4 text-center model_patra">Our Team will reach you shortly!
                        </p>
                        <Button color="btn-white" className="modal_close" onClick={toggle}>Close  </Button>
                    </ModalBody>
                </Modal>
            </section>
        </>

    )
}

export default GetinTouchConatct;




















