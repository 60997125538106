import { useState } from 'react';

const useForm = validate => {
    const [values, setValues] = useState({
        email: "",
        phone: "",
        currentComapany: "",
        location: ""
    });
    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    return { handleChange, values };

};
export default useForm;