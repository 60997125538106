import React from 'react';
import "./BenefitsModel.css";
import { Row, Col } from 'reactstrap';

const benefitsModel = () => {
    return (
        <div className="benefits_container">
            <Row className="m-0 benefits_heading">
                <Col className="p-0">
                    <div className="section_main_heading heading-right"><img loading='lazy' className="heading_rotate_img" src="/Images/new/heading_logo_2.png" alt="Angular" /> <span className="heading_img"><span className="orange_code">B</span>enefits of <span className="orange_code">C</span>ollaboration</span></div>
                    <div className="section_sub_heading heading-right">Purpose-Built Products with Business User Control</div>
                    <div className="section_sub_text heading-right">We don't just build, <span className="orange_code">We Perform</span></div>
                </Col>
            </Row>
            <div className="benefits_content">
                <Row className="implement_box_benefit service_rotate">
                    <Col md={4} className="px-3">
                        <div className="services_img"><img loading='lazy' src="/Images/new/thumbs_up.svg" alt="logo" /></div>
                        <div className="benefit_img_para"><span className="orange_code">High</span> Competence</div>
                    </Col>
                    <Col md={4} className="px-3">
                        <div className="services_img"><img loading='lazy' src="/Images/new/dollar.svg" alt="logo" /></div>
                        <div className="benefit_img_para">Great <span className="orange_code">Value</span> for Money</div>
                    </Col>
                    <Col md={4} className="px-3">
                        <div className="services_img"><img loading='lazy' src="/Images/new/time.svg" alt="logo" /></div>
                        <div className="benefit_img_para">Full commitment that your <span className="orange_code">team works</span> only for you</div>
                    </Col>
                    <Col md={4} className="px-3 services_top">
                        <div className="services_img"><img loading='lazy' src="/Images/new/user.svg" alt="logo" /></div>
                        <div className="benefit_img_para">Hand picked <span className="orange_code">Agile Teams</span> to match your needs</div>
                    </Col>
                    <Col md={4} className="px-3 services_top">
                        <div className="services_img"><img loading='lazy' src="/Images/new/arrow.svg" alt="logo" /></div>
                        <div className="benefit_img_para">Complete overview &amp; flexibility for <span className="orange_code">Managing &amp; Scaling</span> up the team</div>
                    </Col>
                    <Col md={4} className="services_top_banner">
                        <div className="services_img_1">
                            <img loading='lazy' src="/Images/new/contact_banner.svg" alt="logo" />

                            <div className="benefit_banner">
                                <div className="caption_heading">WANT TO TALK TO OUR <span className="caption_heading_1 caption_heading_mob_1">SALES TEAM</span></div>
                                <div className="caption_link caption_link_mob"><a href="mailto:sales@eveniontechnologies.com">sales@eveniontechnologies.com</a></div>
                            </div>
                            <div className="benefit_banner_contact">
                                <div className="banner_btn_1 banner_btn_mob">
                                { window.innerWidth > 768 ?
                                    <a href="/contact-us#contact_form">Contact Us</a> 
                                     :
                                    <a href="/contact-us#contact_us_form_mob">Contact Us</a>
                                }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            </div>
    )
}

export default benefitsModel;