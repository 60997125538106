import React from 'react'
import TaaSAccordion from './TaaSAccordion'
import './TaaSTypes.scss'
import { motion } from 'framer-motion';

const TaaSTypes = () => {
    const MotionContainer ={
        hidden: { opacity: 0.5, y: 300, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},
       
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenP2: { y: 35,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},

        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

        hiddenMobP2: { y: 20,  opacity: 0.9 },
        visibleMobP2: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

    }  
  return (
    <>
    <section className='Main-Types'>
        <motion.div className='left-TaasTypes'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"            
            >Types of <span className='orange_code'> TAAS</span> </motion.h1>
            <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"            
            >Our diverse array of Testing as a Service (TaaS) offerings covers everything from application testing to security assessments, providing customized solutions to meet your specific needs</motion.p>
            <motion.h2
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP2"
            whileInView="visibleP2"            
            >TAILORED <span className='orange_code'> TAAS</span> EXCELLENCE</motion.h2>
            <img loading='lazy' src="/Images/taas/asset.png" alt="Types"/>
        </motion.div>
        <div className='right-TaasTypes'>
            <TaaSAccordion />
        </div>
    </section>


{/* Mobile */}
    <section className='Main-Types-mobile'>
        <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hiddenMob"
                    whileInView="visibleMob"     
        className='left-TaasTypes-mobile'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"                 
            >Types of <span className='orange_code'> TAAS</span> </motion.h1>
            <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"                 
            >Our diverse array of Testing as a Service (TaaS) offerings covers everything from application testing to security assessments, providing customized solutions to meet your specific needs</motion.p>
            <motion.h2
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP2"
            whileInView="visibleMobP2"                 
            >TAILORED <span className='orange_code'> TAAS</span> EXCELLENCE</motion.h2>
            <img loading='lazy' src="/Images/taas/asset.png" alt="Types"/>
        </motion.div>
        <div className='right-TaasTypes-mobile'>
            <TaaSAccordion />
        </div>
    </section>
    </>
  )
}

export default TaaSTypes