import React, { useEffect, useState } from 'react'
import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from 'reactstrap';
import "./Header.scss";
import { Link, NavLink } from 'react-router-dom';
import UseSticky from '../../Components/customHook/useSticky';
import SlideToggle from "react-slide-toggle";
import {motion, transform, useScroll, useSpring} from 'framer-motion'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const Header = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenPartnership, setDropdownOpenPartnership] = useState(false);
    const [dropdownOpenProduct, setDropdownOpenProduct] = useState(false);
    const [show, setShow] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const toggleP = () => setDropdownOpenPartnership(prevState => !prevState);
    const toggleProduct = () => setDropdownOpenProduct(prevState => !prevState);
    const { isSticky, element } = UseSticky();
    const [flag, setFlag] = useState(false);
    const [flagProduct, setFlagProduct] = useState(false);
    const [flagPartner, setFlagPartner] = useState(false);

    const [flagSD, setFlagSD] = useState(false);
    const [flagProductD, setFlagProductD] = useState(false);
    const [flagPartnerD, setFlagPartnerD] = useState(false);

    const [classActive, setClassActive] = useState(false);
    const [classActiveServices, setclassActiveServices] = useState(false);
    const [classActivePartnership, setclassActivePartnership] = useState(false);
    const [classActiveProducts, setclassActiveProducts] = useState(false);



    const [openDropdown, setOpenDropdown] = useState(null);


    const getUrl = window.location.pathname;

    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
      stiffness: 100,
      damping: 30,
      restDelta: 0.001
    });

    useEffect(() => {
        currentMenuActive();
        currentMenuLowerDevice();
    });


    const currentMenuActive = () => {
        if(getUrl === '/digital-services'){
            document.getElementById("menu_drop").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop_product").classList.remove('active_menu');
            document.getElementById("menu_drop_partner").classList.remove('active_menu');
        }else if(getUrl === '/project-services'){
            document.getElementById("menu_drop").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop_partner").classList.remove('active_menu');
            document.getElementById("menu_drop_product").classList.remove('active_menu');
        }else if(getUrl === '/DigitalEngineering'){
            document.getElementById("menu_drop").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop_partner").classList.remove('active_menu');
            document.getElementById("menu_drop_product").classList.remove('active_menu');
        }else if(getUrl === '/professional-services'){
            document.getElementById("menu_drop").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop_partner").classList.remove('active_menu');
            document.getElementById("menu_drop_product").classList.remove('active_menu');
        }else if(getUrl === '/analytics-services'){
            document.getElementById("menu_drop").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop_partner").classList.remove('active_menu');
            document.getElementById("menu_drop_product").classList.remove('active_menu');
        }else if(getUrl === '/taas-services'){
            document.getElementById("menu_drop").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop_partner").classList.remove('active_menu');
            document.getElementById("menu_drop_product").classList.remove('active_menu');
        }else if(getUrl === '/datasensex'){
            document.getElementById("menu_drop_product").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop").classList.remove('active_menu');
            document.getElementById("menu_drop_partner").classList.remove('active_menu');
        }else if(getUrl === '/stackerank'){
            document.getElementById("menu_drop_product").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop").classList.remove('active_menu');
            document.getElementById("menu_drop_partner").classList.remove('active_menu');
        } else if(getUrl === '/flowize'){
            document.getElementById("menu_drop_partner").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop").classList.remove('active_menu');
            document.getElementById("menu_drop_product").classList.remove('active_menu');
        } else if(getUrl === '/precisely'){
            document.getElementById("menu_drop_partner").className += ' active_menu';
            document.getElementById("home_page_link").classList.remove('active_menu');
            document.getElementById("menu_drop").classList.remove('active_menu');
            document.getElementById("menu_drop_product").classList.remove('active_menu');
        }  else if (getUrl === '/') {
            document.getElementById("home_page_link").className += ' active_menu';
        }
        else {
            // console.log("Yes Else part");
        }
    }

    const currentMenuLowerDevice = () => {
        if(window.innerWidth < 820){
            if(getUrl === '/'){
                setClassActive(true);
            }else{
                
                if(getUrl === '/digital-services'){
                    setclassActiveServices(true);
                }else if(getUrl === '/project-services'){
                    setclassActiveServices(true);
                }else if(getUrl === '/DigitalEngineering'){
                    setclassActiveServices(true);
                }else if(getUrl === '/professional-services'){
                    setclassActiveServices(true);
                }else if(getUrl === '/analytics-services'){
                    setclassActiveServices(true);
                }else if(getUrl === '/taas-services'){
                    setclassActiveServices(true);
                } else if(getUrl === '/flowize'){
                    setclassActivePartnership(true);
                } else if(getUrl === '/precisely'){
                    setclassActivePartnership(true);
                } else if(getUrl === '/stackerank'){
                    setclassActiveProducts(true);
                } else if(getUrl === '/datasensex'){
                    setclassActiveProducts(true);
                }
            }
        }
    }

    const changeFlagState = () => {
        setFlag(!flag);
    }
    const changeFlagStateProduct = () => {
        setFlagProduct(!flagProduct);
    }
    const changeFlagStatePartner = () => {
        setFlagPartner(!flagPartner);
    }

    const changeFlagStateSD = () => {
        setFlagSD(!flagSD);
        setFlagPartnerD(false);
        setFlagProductD(false);
    }
    const changeFlagStateProductD = () => {
        setFlagProductD(!flagProductD);
        setFlagSD(false);
        setFlagPartnerD(false);
    }
    const changeFlagStatePartnerD = () => {
        setFlagPartnerD(!flagPartnerD);
        setFlagProductD(false);
        setFlagSD(false);
    }

    const rmvClass = () => {
        document.getElementById("menu_drop").classList.remove('active_menu');
        document.getElementById("menu_drop_partner").classList.remove('active_menu');
        document.getElementById("menu_drop_product").classList.remove('active_menu');
        document.getElementById("home_page_link").classList.remove('active_menu');
        setFlagPartnerD(false);
        setFlagProductD(false);
        setFlagSD(false);
    }
    const addClass = () => {
        document.getElementById("home_page_link").className += ' active_menu';
        document.getElementById("menu_drop").classList.remove('active_menu');
        document.getElementById("menu_drop_partner").classList.remove('active_menu');
        document.getElementById("menu_drop_product").classList.remove('active_menu');
    }

    const rmvClassMob = () => {
        setShow(false);
        setClassActive(false);
        setclassActiveServices(false);
        setclassActivePartnership(false);
        setclassActiveProducts(false)
        setFlag(false);
        setFlagPartner(false);
        setFlagProduct(false);
    }
 
    const closeButton = () => {
        var d = document.getElementById("close-animate");
        d.className += " sidebar-close";
    }



    // mobile
    const toggleDropdown = (dropdownType) => {
        setOpenDropdown((prevOpenDropdown) => (prevOpenDropdown === dropdownType ? null : dropdownType));
      };
      
    return (
        <>
        <div 
        // style={containerStyle}
        className={`${isSticky ? "isSticky" : ""}`}
        // className="isSticky"
        >
                <div className="container home-page-container" ref={element} >
                    <div className="header-container row m-0">
                        <div className="logo" id="logo-eve">
                            <a href="/">
                                <img loading='lazy' src="../Images/logo/logo_mobile.png" alt="logo"  className='logo_header'/>
                            </a>
                        </div>
                        <div className="navbar menu-items siderbar__animate">
                            {/* <MenuIcon className="icon-bar" onClick={() => setShow(!show)} /> */}
                            <img loading='lazy' src='/Images/icons/mobile_menu.svg' className="icon-bar" onClick={() => setShow(!show)}/>
                            <ul id="nav-lists">
                                <li onClick={addClass}>
                                    <Link id="home_page_link" to="/">Home</Link>
                                </li>
                                <li>
                                    <Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={changeFlagStateSD}>
                                        <DropdownToggle id="menu_drop" caret>
                                            Services {flagSD ? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu  p-0">
                                            {/* <DropdownItem className="dropdown-item_modify">
                                                <div> <NavLink to="/digital-services" activeClassName="active_menu">Digital Services</NavLink> </div>
                                            </DropdownItem> */}
                                            <DropdownItem className="dropdown-item_modify">
                                                <div> <NavLink to="/DigitalEngineering" activeClassName="active_menu">Digital Engineering</NavLink> </div>
                                            </DropdownItem>
                                            {/* <DropdownItem className="dropdown-item_modify">
                                                <div> <NavLink to="/project-services" activeClassName="active_menu">Project Services</NavLink> </div>
                                            </DropdownItem> */}
                                            <DropdownItem className="dropdown-item_modify">
                                                <div> <NavLink to="/professional-services" activeClassName="active_menu">Professional Services</NavLink> </div>
                                            </DropdownItem>
                                            <DropdownItem className="dropdown-item_modify">
                                                <div> <NavLink to="/analytics-services" activeClassName="active_menu">Analytic Services</NavLink> </div>
                                            </DropdownItem>
                                            <DropdownItem className="dropdown-item_modify">
                                                <div> <NavLink to="/taas-services" activeClassName="active_menu">Testing as a Service</NavLink> </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                                <li>
                                    <Dropdown isOpen={dropdownOpenProduct} toggle={toggleProduct} onClick={changeFlagStateProductD}>
                                        <DropdownToggle id="menu_drop_product" caret>
                                            Products {flagProductD ? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}

                                        </DropdownToggle>
                                        <DropdownMenu className="p-0">
                                            <DropdownItem className="dropdown-item_modify">
                                                <div> <NavLink to="/stackerank" activeClassName="active_menu">StackéRank</NavLink> </div>  
                                            </DropdownItem>
                                            <DropdownItem className="dropdown-item_modify">
                                               <div> <NavLink to="/datasensex" activeClassName="active_menu">DataSenseX</NavLink> </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                                <li>
                                    <Dropdown isOpen={dropdownOpenPartnership} toggle={toggleP} onClick={changeFlagStatePartnerD}>
                                        <DropdownToggle id="menu_drop_partner" caret>
                                        Partners {flagPartnerD ? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>}
                                        </DropdownToggle>
                                        <DropdownMenu className="p-0">
                                            <DropdownItem className="dropdown-item_modify">
                                            <div> <NavLink to="/precisely" activeClassName="active_menu">Precisely</NavLink> </div>
                                            </DropdownItem>
                                            <DropdownItem className="dropdown-item_modify">
                                                <div> <NavLink to="/flowize" activeClassName="active_menu">Flowize</NavLink> </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                                <li onClick={rmvClass}>
                                    <NavLink to="/about-us" activeClassName="active_menu">About Us</NavLink>
                                </li>
                                <li onClick={rmvClass}>
                                    <NavLink to="/career" activeClassName="active_menu">Career</NavLink>
                                </li>
                                <li onClick={rmvClass}>
                                    <NavLink to="/blogs" activeClassName="active_menu">Blogs</NavLink></li>
                                    {/* <li onClick={rmvClass} > */}
                                    <Row className='row_contact'>
                                        <Col sm={6}>
                                        <div class="container-btn">
                                                <div class="button-btnn">
                                                <a href="/contact-us"> 
                                                <div class="label-down flexx"><span class="padd"> Contact </span> <span class="label-down1 paddd">Us </span></div>
                                                    </a>
                                                
                                            <a href="/contact-us"> 
                                                <div class="label-down11 flexx"><span class="label-down label-down12">Contact </span><span class="label-down22">Us</span></div>
                                            </a>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} className='col_arrow'>
                                        <ArrowForwardIcon className='rotateeicon'/>
                                        </Col>
                                    </Row>
                                               
                                    {/* </li>   */}
                            </ul>
                        </div>
                    </div>  
                </div>
                <motion.div className="progress-bar isSticky" style={{ scaleX }} />
        </div>


            <Container fluid="xxl">
                {show ?
                    <div  id="close-animate" className="sidebar-container" scrollable >
                        <div className="sidebar-header">
                            <div className="sidebar-logo">
                                <a href="/">
                                    <img loading='lazy' src="../Images/logo/even-logo.png" alt="logo" />
                                </a>
                            </div>
                            <div onClick={() => closeButton()}>
                                <img loading='lazy' className="sidebar-close-img"  src="../Images/new/close_btn_color.png" alt="logo" />
                            </div>
                        </div>
                        <motion.div className="nav-bar py-4" >
                            <ul className="flex-column nav-lists-mobile" id="nav-lists">
                                <motion.li 
                                variants={{
                                    hidden: { opacity: 0, y: 85, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 1, delay: 0.28,  }}
                                onClick={rmvClassMob}>
                                    <NavLink className={`${classActive ? "active_menu" : ""}`} to="/">Home</NavLink>
                                </motion.li>
                                <motion.li
                                variants={{
                                    hidden: { opacity: 0, y: 105, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 0.85, delay: 0.31, }}
                                >
                                    <SlideToggle duration={500} collapsed render={({ setCollapsibleElement   }) => (
                                        <div className="dropdown">
                                            {/* <button className={`${classActiveServices ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown" onClick={changeFlagState}> */}
                                            <button className={`${classActiveServices ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown" onClick={() => toggleDropdown('services')}>
                                            <Row 
                                            // onClick={toggle}
                                            // onClick={closeDropdowns}
                                             >
                                                    <Col>
                                                    <span>Services</span>
                                                    </Col>
                                                    <Col style={{textAlign:'end'}}>
                                                 {/* {flag ? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>} */}
                                                 {openDropdown === 'services' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}                                                    </Col>
                                                </Row>
                                                </button>{openDropdown === 'services' && (
                                            // <div ref={setCollapsibleElement}>
                                                <ul className="nested__menu_items">
                                                    {/* <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/digital-services">Digital Services</NavLink></li> */}
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/DigitalEngineering">Digital Engineering</NavLink></li>
                                                    {/* <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/project-services">Project Services</NavLink></li> */}
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/professional-services">Professional Services</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/analytics-services">Analytic Services</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/taas-services">Testing as a Service</NavLink></li>
                                                </ul>
                                                )}
                                            {/* // </div> */}
                                        </div>
                                    )}
                                    />
                                </motion.li>
                                <motion.li
                                variants={{
                                    hidden: { opacity: 0, y: 125, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 1.05, delay: 0.34,  }}
                                >
                                    <SlideToggle duration={500} collapsed render={({  setCollapsibleElement   }) => (
                                        <div className="dropdown">
                                            {/* <button className={`${classActiveProducts ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown"  onClick={changeFlagStateProduct}> */}
                                            <button className={`${classActiveProducts ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown"  onClick={() => toggleDropdown('products')}>
                                                <Row 
                                                // onClick={toggle}
                                                // onClick={closeDropdowns}
                                                >
                                                    <Col>
                                                    <span>Products</span>
                                                    </Col>
                                                    <Col style={{textAlign:'end'}}>
                                                    {/* {flagProduct ? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>} */}
                                                    {openDropdown === 'products' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}                                                    </Col>
                                                </Row>
                                                </button>
                                            {/* <div ref={setCollapsibleElement}> */}
                                            {openDropdown === 'products' && (
                                                <ul className="nested__menu_items">
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/stackerank">StackéRank</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/datasensex">DataSenseX</NavLink></li>
                                                </ul>
                                            )}
                                            {/* </div> */}
                                        </div>
                                    )}
                                    />
                                </motion.li>

                                <motion.li
                                variants={{
                                    hidden: { opacity: 0, y: 145, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 1.25, delay: 0.37,  }}
                                >
                                    <SlideToggle duration={500} collapsed render={({  setCollapsibleElement   }) => (
                                        <div className="dropdown">
                                            {/* <button className={`${classActivePartnership ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown" onClick={changeFlagStatePartner}> */}
                                            <button className={`${classActivePartnership ? "active_menu" : " btn btn-secondary"}`} type="button" data-toggle="dropdown" onClick={() => toggleDropdown('partners')}>
                                            <Row 
                                            // onClick={toggle}
                                            // onClick={closeDropdowns}
                                            >
                                                    <Col>
                                                    <span>Partners</span>
                                                    </Col>
                                                    <Col style={{textAlign:'end'}}>
                                                    {/* {flagPartner ? <KeyboardArrowUpIcon/>: <KeyboardArrowDownIcon/>} */}
                                                    {openDropdown === 'partners' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}                                                    </Col>
                                                </Row>
                                                </button>
                                            {/* <div ref={setCollapsibleElement}> */}
                                            {openDropdown === 'partners' && (
                                                <ul className="nested__menu_items">
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/precisely">Precisely</NavLink></li>
                                                    <li onClick={rmvClassMob}><NavLink activeClassName="active_menu" to="/flowize">Flowize</NavLink></li>
                                                </ul>
                                            )}
                                            {/* </div> */}
                                        </div>
                                    )}
                                    />
                                </motion.li>
                                <motion.li 
                                variants={{
                                    hidden: { opacity: 0, y: 165, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 1.45, delay: 0.40, }}
                                onClick={rmvClassMob}>
                                    {/* <Link onClick={() => setShow(false)} to="/about-us">About Us</Link> */}
                                    <NavLink to="/about-us" activeClassName="active_menu">About Us</NavLink>
                                </motion.li>
                                <motion.li 
                                variants={{
                                    hidden: { opacity: 0, y: 185, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 1.65, delay: 0.43,  }}
                                onClick={rmvClassMob}>
                                    <NavLink to="/career" activeClassName="active_menu">Career</NavLink>
                                </motion.li>
                                <motion.li 
                                variants={{
                                    hidden: { opacity: 0, y: 205, },
                                    visible: { opacity: 1, y: 0, }
                                }}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 1.85, delay: 0.46, }}
                                onClick={rmvClassMob}>
                                    <NavLink to="/blogs" activeClassName="active_menu">Blogs</NavLink>
                                </motion.li>
                            </ul>
                        </motion.div>

                        <div style={{position:'absolute', bottom:'5%', padding:'0 1.5rem', width:'100%'}}>
                        <a href="/contact-us#contact_us_form_mob" className="active_menu_btn">Let's Talk <ArrowForwardIcon className='arow-icon'/></a> <br/>
                                <a target="_blank" href="https://www.linkedin.com/company/evenion-technologies">
                                <img loading='lazy' src='/Images/icons/linkedin.svg' className='socialIcon-header'/>
                                </a>
                                <a target="_blank" href="https://m.facebook.com/eveniontechnologies/">
                                <img loading='lazy' src='/Images/icons/insta-mob.svg' className='socialIcon-header'/>
                                </a>
                                <a target="_blank" href="https://www.instagram.com/evenion_technologies">
                                <img loading='lazy' src='/Images/icons/fb-mob.svg' className='socialIcon-header'/>
                                </a>
                            {/* <p className='subscribe-header'>SUBSCRIBE TO OUR NEWS LETTERS</p>
                            <div className='divider-header'></div> */}
                        </div>
                        {/* <div className="sidebar__bottom">
                            <div className="sidebar__bottom_text">EVENION</div>
                        </div> */}
                    </div> : null
                }
                                     {/* <motsion.div className="progress-bar isSticky" style={{ scaleX }} /> */}

            </Container>

        </>
    )
}
export default Header;