import React, { useEffect, useState } from "react";
import "./BlogLanding.css";
import { Row, Col } from "reactstrap";
import { useLocation } from "react-router";
import axios from "axios";
import { useNavigate } from "react-router";
import dateFormat from "dateformat";
import { Helmet } from "react-helmet";
import { useReadingTime } from "react-hook-reading-time";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { Breadcrumbs, Typography } from "@mui/material";

const BlogLanding = () => {
  const location = useLocation();

  const [blogList, setBlogList] = useState();
  const [flag, setFlag] = useState("false");
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  let path = location.pathname.split("/");

  const handleLoadingComplete = () => {
    setIsLoading(true);
  };

  const getBlogs = (count_flag, id) => {
    localStorage.setItem("cFlag", true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/blogs`)
      .then((response) => {
        setFlag(localStorage.getItem("cFlag"));

        const data = response.data.data[0];
        data &&
          data.map((ele) => {
            if (ele.slug === path[2]) {
              localStorage.setItem("slug", ele.slug);
            }
          });

        if (localStorage.getItem("slug") === path[2]) {
          setBlogList(response.data.data[0]);
        } else {
          navigate("/errors");
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error => ", error);
      });
  };

  useEffect(() => {
    // handleLoadingComplete()
    // setTimeout(() => {
    getBlogs();
    // }, 1000);
  }, [path[2]]);

  const getSingleBlogs = (data) => {
    window.scrollTo(0, 0);
    // handleLoadingComplete()
    setTimeout(
      function () {
        try {
          navigate(`/blogs/${data.slug}`);
          // setIsLoading(false);
          localStorage.setItem("slug", data.slug);
        } catch (err) {
          console.log(err);
        }
      },
      2000,
      [path[2]]
    );
  };

  const checkClickEvent = (id) => {
    let data =
      localStorage.getItem("allEntries") &&
      JSON.parse(localStorage.getItem("allEntries"));
    let clap_id_local =
      data &&
      data.map((ele) => {
        return ele.id;
      });

    return clap_id_local && clap_id_local.includes(id);
  };

  const checkClickEventLike = (id) => {
    let data =
      localStorage.getItem("allEntriesLike") &&
      JSON.parse(localStorage.getItem("allEntriesLike"));
    let like_id_local =
      data &&
      data.map((ele) => {
        return ele.id;
      });

    return like_id_local && like_id_local.includes(id);
  };

  const isActionHandled = (count, l_count, id) => {
    var existingEntries = JSON.parse(localStorage.getItem("allEntries"));
    if (existingEntries == null) existingEntries = [];
    if (count < 0 || count == null) {
      count = 0;
    }
    var entryTitle = true;
    var entryText = id;
    var entry = {
      clapCount: entryTitle,
      id: entryText,
    };

    localStorage.setItem("entry", JSON.stringify(entry));
    existingEntries.push(entry);

    localStorage.setItem("allEntries", JSON.stringify(existingEntries));
    increaseCountCelebrate(parseInt(count), l_count, parseInt(id));
  };

  const isActionHandledLike = (count, l_count, id) => {
    var existingEntries = JSON.parse(localStorage.getItem("allEntriesLike"));
    if (existingEntries == null) existingEntries = [];

    const likeObj = {
      likeCOunt: true,
      id: id,
    };
    localStorage.setItem("entryLike", JSON.stringify(likeObj));
    existingEntries.push(likeObj);

    localStorage.setItem("allEntriesLike", JSON.stringify(existingEntries));
    increaseCountLike(count, parseInt(l_count), parseInt(id));
  };

  const isActionHandledAfterClick = (id, flag) => {
    let data =
      localStorage.getItem("allEntries") &&
      JSON.parse(localStorage.getItem("allEntries"));
    let clap_id_local =
      data &&
      data.map((ele) => {
        return ele.id;
      });
    if (clap_id_local && clap_id_local.includes(id)) {
      // console.log(" No allowed to update ");
    }
  };

  const isActionHandledAfterClickLike = (id) => {
    let data =
      localStorage.getItem("allEntriesLike") &&
      JSON.parse(localStorage.getItem("allEntriesLike"));
    let like_id_local =
      data &&
      data.map((ele) => {
        return ele.id;
      });
    if (like_id_local && like_id_local.includes(id)) {
      // console.log(" No allowed to update ");
    }
  };

  const increaseCountCelebrate = (count, l_count, id) => {
    if (count == null) {
      count = count + 1;
    } else {
      count = parseInt(count) + 1;
    }
    const payload = {
      id: id,
      like_count: l_count,
      clap_count: count,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/blog-count`, payload)
      .then((res) => {
        getBlogs(true, id);
      })
      .catch((err) => console.log(err));
  };

  const increaseCountLike = (count, c_count, id) => {
    let getCountId = localStorage.getItem("likeCountId");

    if (getCountId === id) {
    } else {
      let like_flag = false;
      if (count == null) {
        count = count + 1;
      } else {
        count = parseInt(count) + 1;
      }
      const payload = {
        id: id,
        like_count: count,
        clap_count: c_count,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/blog-count`, payload)
        .then((res) => {
          getBlogs(like_flag, id);
        })
        .catch((err) => console.log(err));
    }
    // increaseCountCelebrate();
    // increaseCountLike();
  };

  function createMarkup(data) {
    return {
      __html: data,
    };
  }

  const GetTime = (data) => {
    const { text } = useReadingTime(data);
    if (text === "less than a min") {
      return "Less than 1 min";
    }
    return text;
  };

  return (
    <>
      {blogList &&
        blogList.map((ele) => (
          <>
            {ele.slug === path[2] && (
              <Helmet>
                <title>{ele.title}</title>
                <meta name="description" content={ele.short_description} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={ele.title} />
                <meta property="description" content={ele.short_description} />
                <meta
                  property="og:meta_description"
                  content={ele.meta_description}
                />
                <meta property="og:canonical_link" content={ele.canonical} />
                <meta property="keywords" content={ele.meta_keywords} />
                <meta property="abstract" content={ele.abstract} />
                <meta property="robots" content={ele.meta_robots} />
                <meta
                  property="og:url"
                  content="Digital Transformation and Consulting Services Company - Evenion Technologies"
                />
                <meta
                  property="og:site_name"
                  content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation"
                />
                <meta
                  property="og:updated_time"
                  content="2021-08-29T14:49:23+00:00"
                />
                <meta property="og:image" content={ele.title} />
                <meta
                  property="og:image:secure_url"
                  content="Digital Transformation and Consulting Services Company - Evenion Technologies"
                />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta
                  property="article:published_time"
                  content="2020-11-25GMT+000012:40:02+00:00"
                />
                <meta
                  property="article:modified_time"
                  content="2021-08-29GMT+000014:49:23+00:00"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={ele.title} />
                <meta
                  name="twitter:description"
                  content={ele.short_description}
                />
                <meta name="twitter:image" content={ele.title} />
              </Helmet>
            )}
          </>
        ))}
      <div>
        {window.innerWidth > 480 ? (
          <Breadcrumbs
            aria-label="breadcrumb"
            separator=">>"
            className="breadcrumb_container_new"
          >
            <div className="breadcrumb_text">
              <a href="/">Home</a>
            </div>
            <div className="breadcrumb_text">
              <a href="/blogs">Blogs</a>
            </div>
            <Typography
              color="#0F326C"
              fontFamily="space-Grotesksb"
              fontWeight="300"
            >
              {" "}
              {blogList &&
                blogList.map((ele) => <>{ele.slug === path[2] && ele.title}</>)}
            </Typography>
          </Breadcrumbs>
        ) : (
          ""
        )}
        {isLoading ? (
          <>
            <div className="blog__landing__container">
              <Row className="space_landing">
                <Col
                  md={8}
                  id="landing_section"
                  className="description_section"
                >
                  {blogList &&
                    blogList.map((ele) => (
                      <>
                        {ele.slug === path[2] && (
                          <div className="landing_banner">
                            <Row className="blog-author-section">
                              <Col md={1} sm={3} xs={2}>
                                <img
                                  src={`/Images/new/${ele.author}.png`}
                                  className="author-img-height"
                                  alt="author"
                                />
                              </Col>
                              <Col
                                md={11}
                                sm={6}
                                xs={8}
                                className="height-fix height-fix-main section-para"
                              >
                                <Row className="published_dt">
                                  <Col
                                    md={8}
                                    xs={6}
                                    sm={4}
                                    className="p-0 author_name"
                                  >
                                    {ele.author}
                                  </Col>
                                  <Col
                                    md={2}
                                    xs={4}
                                    sm={4}
                                    className="p-0 date_placement"
                                  >
                                    {dateFormat(
                                      ele.published_datetime,
                                      "mmm d, yyyy"
                                    )}
                                  </Col>
                                  <Col
                                    md={2}
                                    xs={2}
                                    sm={4}
                                    className="p-0 date_placement"
                                  >
                                    {GetTime(ele.description)}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <div className="blogs-heading">{ele.title}</div>
                            <div className="short_description_landingpage">
                              {ele.short_description}
                            </div>
                            <Col md={12} className="like-section1">
                              <div className="margin_div_section">
                                {localStorage.getItem("cFlag") && (
                                  <>
                                    {checkClickEvent(ele.id) ? (
                                      <img
                                        src="/Images/new/69920-clap.svg"
                                        onClick={() =>
                                          isActionHandledAfterClick(ele.id)
                                        }
                                        alt="clap_logo"
                                      />
                                    ) : (
                                      <img
                                        src="/Images/new/celebrate.png"
                                        onClick={() =>
                                          isActionHandled(
                                            ele.clap_count,
                                            ele.like_count,
                                            ele.id
                                          )
                                        }
                                        alt="clap_logo"
                                      />
                                    )}
                                  </>
                                )}
                                <span className="pl-1">{ele.clap_count}</span>
                              </div>
                              <div className="margin_div_section pl-1">
                                {localStorage.getItem("cFlag") && (
                                  <>
                                    {checkClickEventLike(ele.id) ? (
                                      <img
                                        src="/Images/new/like_svg.svg"
                                        onClick={(e) =>
                                          isActionHandledAfterClickLike(ele.id)
                                        }
                                        alt="clap_logo"
                                      />
                                    ) : (
                                      <img
                                        src="/Images/new/like.png"
                                        onClick={(e) =>
                                          isActionHandledLike(
                                            ele.like_count,
                                            ele.clap_count,
                                            ele.id
                                          )
                                        }
                                        alt="clap_logo"
                                      />
                                    )}
                                  </>
                                )}
                                <span className="pl-1">{ele.like_count}</span>
                              </div>
                            </Col>
                            <div className="blog-image">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/${ele.desktop_image}`}
                                alt="clap_logo"
                              />
                            </div>
                            <div
                              className="section-para"
                              dangerouslySetInnerHTML={createMarkup(
                                ele.description
                              )}
                            ></div>
                          </div>
                        )}
                      </>
                    ))}
                </Col>
                <Col md={4} className="recent_tabs" id="recent_tab_desk">
                  <div className="recent-head">
                    <span>
                      Recent Post
                      <img
                        className="img_space"
                        src="/Images/new/heading_logo_2.png"
                        alt="clap_logo"
                      />
                    </span>
                  </div>
                  <div className="recent_view_container">
                    {blogList &&
                      blogList
                        .sort(
                          (a, b) =>
                            new Date(b.published_datetime) -
                            new Date(a.published_datetime)
                        )
                        .filter((ele) => ele.slug != path[2])
                        .map(
                          (ele, i) =>
                            i < 3 && (
                              <>
                                {ele.status === "Active" &&
                                  ele.slug !== path[2] && (
                                    <div className="recent_view">
                                      <Row className="recent_view_blog">
                                        <Col md={2} className="">
                                          <div className="">
                                            <img
                                              onClick={() =>
                                                getSingleBlogs(ele)
                                              }
                                              src={`${process.env.REACT_APP_BASE_URL}/${ele.mobile_image}`}
                                              className="blog-img-recent-section"
                                              alt="clap_logo"
                                            />
                                          </div>
                                        </Col>
                                        <Col
                                          md={9}
                                          className="height-fix height-fix-1 author_name_mob"
                                        >
                                          <Row>
                                            <Col className="p-0">
                                              {ele.author}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="py-2 px-0">
                                              <div
                                                className="recent_para recent-section-blog-title"
                                                onClick={() =>
                                                  getSingleBlogs(ele)
                                                }
                                              >
                                                {ele.title.length > 70
                                                  ? ele.title.substring(0, 70) +
                                                    "..."
                                                  : ele.title}
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row className="height-fix pl-0 height-fix-date">
                                            <Col md={6} className="p-0">
                                              {dateFormat(
                                                ele.published_datetime,
                                                "mmm d, yyyy"
                                              )}
                                            </Col>
                                            <Col md={6} className="p-0">
                                              {GetTime(ele.description)}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                              </>
                            )
                        )}
                  </div>
                </Col>
              </Row>

              <Col md={8} className="recent_tabs recent_tabs_mobile">
                <div className="blog_page_line">
                  <img loading='lazy' src="/Images/new/mobile-strip.png" alt="clap_logo" />
                </div>
                <div className="recent-head">
                  <span>
                    Recent Post
                    <img
                      className="img_space"
                      src="/Images/new/heading_logo_2.png"
                      alt="clap_logo"
                    />
                  </span>
                </div>
                <div className="recent_view_container">
                  {blogList &&
                    blogList.slice(0, 4).map((ele) => (
                      <>
                        {ele.status === "Active" && ele.slug !== path[2] && (
                          <div className="recent_view">
                            <Row className="recent_view_blog">
                              <Col md={3} className="">
                                <div className="">
                                  <img
                                    onClick={() => getSingleBlogs(ele)}
                                    src={`${process.env.REACT_APP_BASE_URL}/${ele.mobile_image}`}
                                    className="blog-img-recent-section"
                                    alt="clap_logo"
                                  />
                                </div>
                              </Col>
                              <Col
                                md={9}
                                className="height-fix height-fix-1 author_name_mob"
                              >
                                <div>
                                  <div className="p-0">{ele.author}</div>
                                </div>
                                <div>
                                  <div className="py-2 px-0">
                                    <div
                                      className="recent_para recent-section-blog-title"
                                      onClick={() => getSingleBlogs(ele)}
                                    >
                                      {ele.title.length > 60
                                        ? ele.title.substring(0, 60) + "..."
                                        : ele.title}
                                    </div>
                                  </div>
                                </div>
                                <div className="height-fix   date_reading pl-0">
                                  <div md={6} className="p-0">
                                    {dateFormat(
                                      ele.published_datetime,
                                      "mmm d, yyyy"
                                    )}
                                  </div>
                                  <div md={6} className="p-0">
                                    {GetTime(ele.description)}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </Col>
            </div>
          </>
        ) : (
          <LoadingSpinner onLoadingComplete={handleLoadingComplete} />
        )}
      </div>
    </>
  );
};
export default BlogLanding;
