import React, { useRef } from 'react';
import { motion,    useInView,  } from 'framer-motion';
import "./OurOfferings.scss"

const OurOfferings = () => {




  const MotionContainer ={
    hidden: { y: 300, scale: 0.8, opacity: 0.5 },
    visible: { y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1, }},

    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, }},

    hiddenP1: { y: 30,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP2: { y: 35,  opacity: 0.9 },
    visibleP2: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoximg: { y:30,  opacity: 0.5},
    visibleBoximg: { y:0,  opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoxP1: { y:40,  opacity: 0.5},
    visibleBoxP1: { y:0,  opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoxP2: { y:30,  opacity: 0.5},
    visibleBoxP2: { y:0,  opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenBoxIcon: { y:200,  opacity: 0.2},
    visibleBoxIcon: {y:0,  opacity: 1,
    transition:{ duration: 0.95, }},

    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.15, }},
        
    hiddenMobimg: { y:35,  opacity: 0.5},
    visibleMobimg: { y:0,  opacity: 1,
    transition:{ duration: 0.55, delay: 0.10,}},

    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    hiddenMobP2: { y: 20,  opacity: 0.9 },
    visibleMobP2: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

}


const ref = useRef(null)
const isInView = useInView(ref, {once: false});





  return (
    <>
    <motion.section className='Our_offerings'    >
        <motion.div 
          viewport={{ ref, once: true}}
          variants={MotionContainer}
          initial="hidden"
          whileInView="visible"
        className='Our_ffoerings1'>
          <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
          >Our Offerings</motion.h1>
          <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
          className='para1'>We help empower, enable & optimize solutions across all industries</motion.p>
          <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP2"
            whileInView="visibleP2"
          className='para2'><span className='orange_code'>OUR RELATIONSHIPS</span> CREATE A LASTING IMPACT <br></br> ON PEOPLE & THEIR <span className='orange_code'>LIVES EVERYDAY</span> </motion.p>
        </motion.div>
      
        <motion.div 
            viewport={{once: true}}
            variants={MotionContainer}
            initial="hiddenBoxIcon"
            whileInView="visibleBoxIcon"
            className='Our_Offoerings_box'> 
                <motion.div 
                className='Our_Offoerings-Insidecontent-box'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg" 
                    src="/Images/Proffesional_Services/Group 3728.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Our_Offoerings-content-para1'>CONTRACT <span class="orange_code">STAFFING</span> </motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Our_Offoerings-content-para21'>Our team works with you to find the right fit resources from a talent pool created to cater to the specific requirement of the business. We work to understand the challenges being faced in the business & help bring the right fit resources to the table, thereby adding value to the engagements.</motion.p>
                </motion.div>
                <motion.div 
                className='Our_Offoerings-Insidecontent-box'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg" 
                    src="/Images/Proffesional_Services/Group 3727.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Our_Offoerings-content-para1'>LATERAL <span class="orange_code">HIRING</span></motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Our_Offoerings-content-para23'>We specialize in broad perspective mobile app development services which are in-line with the ever evolving user engagement needs especially considering the market’s mobile devices fragmentation</motion.p>
                </motion.div>
                <motion.div 
                className='Our_Offoerings-Insidecontent-box'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg" 
                    src="/Images/Proffesional_Services/Group 3729.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP1"
                    whileInView="visibleBoxP1"
                    className='Our_Offoerings-content-para1'>PROJECT  <span class="orange_code">PORTFOLIO</span> MANAGEMENT</motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoxP2"
                    whileInView="visibleBoxP2"
                    className='Our_Offoerings-content-para22'>In the course of growth, organizations deploy various IT products in the organization & upkeep of the same becomes challenging over a period of time. We bring a portfolio of services to the table that can be taken to the market for enhanced value addition to the customers</motion.p>            
                </motion.div>
            </motion.div>
{/* 

                <div class="waveee">
                </div> */}
    </motion.section>

    {/* mobile desgin */}
    <section  className='Our_offerings_mobile_mobile' >
          <motion.div 
            viewport={{ ref, once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
          className='Our_ffoerings1_mobile'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
            >Our Offerings</motion.h1>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
            className='para1'>We help empower, enable & optimize <br></br> solutions across all industries</motion.p>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP2"
            whileInView="visibleMobP2"
            className='para2'><span className='orange_code'>OUR RELATIONSHIPS <br></br></span> CREATE A LASTING IMPACT <br></br> ON PEOPLE & THEIR <br></br><span className='orange_code'>LIVES EVERYDAY</span> </motion.p>
          </motion.div>

          <motion.div 
            viewport={{once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
            className='Our_Offoerings_box_mobile'> 
                <div 
                className='Our_Offoerings-Insidecontent-box_mobile'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobimg"
                    whileInView="visibleMobimg" 
                    src="/Images/Proffesional_Services/Group 3728.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"
                    className='Our_Offoerings-content-para1'>CONTRACT <span class="orange_code">STAFFING</span> </motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"
                    className='Our_Offoerings-content-para21'>Our team works with you to find the right fit resources from a talent pool created to cater to the specific requirement of the business. We work to understand the challenges being faced in the business & help bring the right fit resources to the table, thereby adding value to the engagements.</motion.p>
                </div>
                <div 
                className='Our_Offoerings-Insidecontent-box_mobile'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobimg"
                    whileInView="visibleMobimg" 
                    src="/Images/Proffesional_Services/Group 3727.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"
                    className='Our_Offoerings-content-para1'>LATERAL <span class="orange_code">HIRING</span></motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"
                    className='Our_Offoerings-content-para23'>We specialize in broad perspective mobile app development services which are in-line with the ever evolving user engagement needs especially considering the market’s mobile devices fragmentation</motion.p>
                </div>
                <div 
                className='Our_Offoerings-Insidecontent-box_mobile'>
                    <motion.img loading='lazy' 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobimg"
                    whileInView="visibleMobimg" 
                    src="/Images/Proffesional_Services/Group 3729.svg" alt="" />
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP1"
                    whileInView="visibleMobP1"
                    className='Our_Offoerings-content-para1'>PROJECT  <span class="orange_code">PORTFOLIO</span> MANAGEMENT</motion.p>
                    <motion.p 
                    viewport={{once: false}}
                    variants={MotionContainer}
                    initial="hiddenMobP2"
                    whileInView="visibleMobP2"
                    className='Our_Offoerings-content-para22'>In the course of growth, organizations deploy various IT products in the organization & upkeep of the same becomes challenging over a period of time. We bring a portfolio of services to the table that can be taken to the market for enhanced value addition to the customers</motion.p>            
                </div>
            </motion.div>

    </section>

                </>
  )
}

export default OurOfferings