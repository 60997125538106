import React from 'react'
import './PracticeAreas.scss'
import { motion } from 'framer-motion';

const PracticeAreas = () => {

    const MotionContainer ={
        hidden: { opacity: 0.5, y: 300, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},

        hiddenContent: { opacity: 0.5, y: 250 },
        visibleContent: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1, }},
        
        hiddenBoximg: { y:30,  opacity: 0.5},
        visibleBoximg: { y:0,  opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenP2: { y: 35,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},

        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},
                    
        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},
        
    }    
  return (
    <>
    <section className='Main-Pratice'>
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"
        className='Pratice-content'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >Practice Areas</motion.h1>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
            className='para1'>Our practice areas encompass the full spectrum of digital engineering, from development and modernization to maintenance and enterprise solutions</motion.p>
            <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP2"
                        whileInView="visibleP2"
            className='para2'><span className='orange_code'>MASTERS OF</span>  DIGITAL <span className='orange_code'>EXCELLENCE</span></motion.p>
        </motion.div>

        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenContent"
            whileInView="visibleContent"
        className='Pratice-box'>
          <div className='box1'>
                <div className='inside-b1 pointer '>
                  <motion.h1 
                      viewport={{  once: false}}
                      variants={MotionContainer}
                      initial="hiddenH1"
                      whileInView="visibleH1"
                  >APPLICATION <br></br> DEVELOPMENT</motion.h1>
                  <motion.p  
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                  >Our expertise in application development spans from ideation to deployment, creating robust, user-friendly, and scalable software solutions</motion.p>
                </div>
                <div className='inside-b2 pointer'>
                  <motion.h1 
                      viewport={{  once: false}}
                      variants={MotionContainer}
                      initial="hiddenH1"
                      whileInView="visibleH1"
                  >APPLICATION <br></br> MAINTENANCE</motion.h1>
                  <motion.p  
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                  >Evenion takes pride in offering ongoing application maintenance services, ensuring your software remains up to date and optimized for peak performance.</motion.p> 
                </div>
          </div>
          <div className='box2'>
                <div className='inside-b1 pointer'>
                  <motion.h1 
                      viewport={{  once: false}}
                      variants={MotionContainer}
                      initial="hiddenH1"
                      whileInView="visibleH1"
                  >APPLICATION <br></br> MODERNIZATION</motion.h1>
                  <motion.p  
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                  >We specialize in modernizing existing applications, breathing new life into legacy systems and making them more efficient, secure, and adaptable.</motion.p>
                </div>
                <div className='inside-b2 pointer'>
                  <motion.h1 
                      viewport={{  once: false}}
                      variants={MotionContainer}
                      initial="hiddenH1"
                      whileInView="visibleH1"
                  >ENTERPRISE <br></br> SOLUTIONS</motion.h1>
                  <motion.p  
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                  >Our enterprise solutions are designed to address the unique challenges of large organizations. We provide scalable, integrated systems that enhance productivity and collaboration within your company.</motion.p>
                </div>
          </div>

        </motion.div>
        {/* <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"        
        className='para-end'>With Evenion's dedication to agile innovation, user-focused design, and extensive domain knowledge, your digital engineering project is poised for success. Whether you're looking to develop new applications, modernize existing ones, or maintain your software, we've got the expertise and commitment to drive your digital transformation.</motion.p> */}

    </section>

{/* mobile */}
    <section className='Main-Pratice-mobile'>
      <motion.div 
                viewport={{  once: true}}
                variants={MotionContainer}
                initial="hiddenMob"
                whileInView="visibleMob" 
      className='Pratice-content-mobile'>
        <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
        >Practice Areas</motion.h1>
        <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"
        className='para1'>Our practice areas encompass the full spectrum of digital engineering, from development and modernization to maintenance and enterprise solutions</motion.p>
        <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP2"
            whileInView="visibleMobP2"
        className='para2'><span className='orange_code'>MASTERS OF</span><br></br> DIGITAL <br></br><span className='orange_code'>EXCELLENCE</span></motion.p>
      </motion.div>
    
      <motion.div
                viewport={{  once: true}}
                variants={MotionContainer}
                initial="hiddenMob"
                whileInView="visibleMob" 
      className='Pratice-box-mobile'>
        <div className='PracBox1'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"
            >APPLICATION <br></br> DEVELOPMENT</motion.h1>
            <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
            >Our expertise in application development spans from ideation to deployment, creating robust, user-friendly, and scalable software solutions</motion.p>  
        </div>
        <div className='PracBox2'>
              <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenMobH1"
                  whileInView="visibleMobH1"
              >APPLICATION <br></br> MODERNIZATION</motion.h1>
              <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenMobP1"
                  whileInView="visibleMobP1"
              >We specialize in modernizing existing applications, breathing new life into legacy systems and making them more efficient, secure, and adaptable.</motion.p>    
        </div>
        <div className='PracBox1'>
            <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenMobH1"
                  whileInView="visibleMobH1"
            >ENTERPRISE <br></br> SOLUTIONS</motion.h1>
            <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenMobP1"
                  whileInView="visibleMobP1"
            >Our enterprise solutions are designed to address the unique challenges of large organizations. We provide scalable, integrated systems that enhance productivity and collaboration within your company.</motion.p>      
        </div>
        <div className='PracBox2'>
            <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenMobH1"
                  whileInView="visibleMobH1"
            >APPLICATION <br></br> MAINTENANCE</motion.h1>
            <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
            >Evenion takes pride in offering ongoing application maintenance services, ensuring your software remains up to date and optimized for peak performance.</motion.p> 
        </div>
      </motion.div>
      {/* <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1" 
      className='para-end-mobile'>With Evenion's dedication to agile innovation, user-focused design, and extensive domain knowledge, your digital engineering project is poised for success. Whether you're looking to develop new applications, modernize existing ones, or maintain your software, we've got the expertise and commitment to drive your digital transformation.</motion.p> */}
    </section>
 
    </>
  )
}

export default PracticeAreas;