import React from 'react';
import { Row, Col } from 'reactstrap';
import "./CollaborationModel.css";


const collaborationModel = () => {
    return (
        <div className="collaboration_models m-0">
            <div className="p-0 m-0 digital_content">
                <Row className="m-0">
                    <Col className="heading_padding">
                        <div className="section_main_heading heading-left"><span className="heading_img_1"><span className="orange_code">C</span>ollaboration <span className="orange_code">M</span>odels</span> <img loading='lazy' className="image" src="/Images/new/heading_logo_2.png" alt="heading_img" /></div>
                        <div className="section_sub_heading heading-left">We have enough breadth to figure out nearly any specification &amp; software development solution</div>
                        <div className="section_sub_text heading-left collaboraion_sub_text">We've got you <span className="orange_code">covered</span></div>
                        <div className="section_sub_heading section_sub_heading_1 heading-left">We create, design, deploy and support software solutions that deliver value and drive business growth with our Agile Development Approach. Unleash your vision &amp; gain a powerful competitive advantage with Digital Transformation.</div>
                    </Col>
                </Row>
                <Row className="digi_col_content models_content m-0">
                    <Col md={2} className="how_it_content_img">
                        <div className="how_it_head_img shadow_effect">
                            <img loading='lazy' src="/Images/new/MaskGroup4.png" alt="prof_1" />
                        </div>
                    </Col>
                    <Col md={9} className="model_mob_padding">
                        <div className="how_it_prof_head">Hand Picked <span className="orange_code">Teams</span></div>
                        <div className="how_it_head">A great way to expand your in-house team with experts working from our offices across the globe. You combine a cost-smart on-site/near-shore/off-shore alignment with the quality that is often associated with in-house teams. As a business, you work on growing the organization &amp; managing your daily activities, while we focus on driving Digital Innovation for your Vision &amp; Growth. We work to build a collaborative work environment with a personal touch that puts personnel development &amp; sustainability first.</div>
                        <div className="how_it_prof_head how_it_prof_head_for"><span className="orange_code">Recommended For</span></div>
                        <div className="how_it_head p-0">Growth Stage Organizations with a long-term, strategic need to expand and scale up their own teams in a cost-effective way, while building an in-house experience and knowledge centre without compromising on their unique brand or culture.</div>
                    </Col>
                </Row>
                <Row className="digi_col_content models_content_end m-0">
                    <Col md={2} className="how_it_content_img">
                        <div className="how_it_head_img shadow_effect">
                            <img loading='lazy' src="/Images/new/MaskGroup2.png" alt="prof_3" />
                        </div>
                    </Col>
                    <Col md={9} className="model_mob_padding">
                        <div className="how_it_prof_head">Software <span className="orange_code">Solutions</span></div>
                        <div className="how_it_head">If you need a turnkey, tailored software solution, our entire ecosystem can be activated to create it for you. We combine strong in-house expertise along with qualified network of hyper-specialized development teams to deliver great ideas, efficient design, rapid development and a touch of our unique culture. In a solution model, you can pay for the hours and resources used, or a fixed price that is determined in advance. We take responsibility for the final delivery.</div>
                        <div className="how_it_prof_head how_it_prof_head_for"><span className="orange_code">Recommended For</span></div>
                        <div className="how_it_head p-0">Mature Organizations who need short term solutions, and are able to define fixed scope &amp; engagement.</div>
                    </Col>
                </Row>
                <Row className="digi_col_content models_content m-0">
                    <Col md={2} className="how_it_content_img">
                        <div className="how_it_head_img shadow_effect">
                            <img loading='lazy' src="/Images/new/MaskGroup3.png" alt="prof_2"/>
                        </div>
                    </Col>
                    <Col md={9} className="model_mob_padding">
                        <div className="how_it_prof_head">Hybrid <span className="orange_code">Teams</span></div>
                        <div className="how_it_head">An intuitive, modern solution, consisting of on-site IT consultants with inhouse tech-talent. Here, you experience a close coordination with the team by having key consultants present on site with you. This way you get to experience the best of our Tech Talent first hand while the team works closely with you on solving your business problems.</div>
                        <div className="how_it_prof_head how_it_prof_head_for"><span className="orange_code">Recommended For</span></div>
                        <div className="how_it_head p-0">Those who have a long-term, strategic need and who want to reduce risk and geographical distance. This team extension works best in case you already have a development team that you manage in-house, and you are looking to add specific skills or domain expertise that are hard to be sourced on-site.</div>
                    </Col>
                </Row>
                <Row className="digi_col_content models_content_end m-0">
                    <Col md={2} className="how_it_content_img">
                        <div className="how_it_head_img shadow_effect">
                            <img loading='lazy' src="/Images/new/MaskGroup5.png" alt="prof_4"/>
                        </div>
                    </Col>
                    <Col md={9} className="model_mob_padding">
                        <div className="how_it_prof_head">Consultation on <span className="orange_code">Site</span></div>
                        <div className="how_it_head">A self-propelled team allows you to get the job done &amp; at the same time have daily input in the process, without having to worry about the many logistical side chores that are added when you have a team. We assign you a team of carefully selected &amp; proven experts. You make sure they have jobs to do, so we take care of everything around.</div>
                        <div className="how_it_prof_head how_it_prof_head_for"><span className="orange_code">Recommended For</span></div>
                        <div className="how_it_head p-0">Those who have a need in the medium term &amp; who want to keep &amp; easily scale up their team without spending internal resources on management.</div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default collaborationModel;