import React, { useState } from 'react';
import "./PreciselyCard.scss"
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import Lottie from 'react-lottie';


const PreciselyCard = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoLink = 'https://www.youtube.com/watch?v=-BKDIc6yrpg'; 

  const handleDivClick = () => {
    setIsPlaying(true);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: videoLink,
    }

  const MotionContainer = {
    hiddenimg: { opacity: 0.5,  scale: 1 },
    visibleimg: { opacity: 1,  scale: [0, 1, 0.5, 1], },

    hiddenimg1: { y: 50,  },
    visibleimg1: { y: 0, 
    transition:{ duration: 1, delay: 0.25 }},

    hiddenSec: { y: 50,  },
    visibleSec: { y: 0, 
    transition:{ duration: 1, delay: 0.25 }},

    
    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.15, }},
        
    hiddenMobimg: { y:35,  opacity: 0.5},
    visibleMobimg: { y:0,  opacity: 1,
    transition:{ duration: 0.55, delay: 0.10,}},

    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    hiddenMobP2: { y: 20,  opacity: 0.9 },
    visibleMobP2: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

}    

const spring = {
  type: "spring",
  stiffness: 100,
  damping: 30,
  bounce: 0.25,
  duration: 1, 
  restSpeed: 0.5 
};
    // const handleClick = () =>{
    //     window.location.href = 'https://www.youtube.com/watch?v=-BKDIc6yrpg'
    // }

  



  return (
    <motion.section 
      viewport={{  once: true}}
      variants={MotionContainer}
      initial="hiddenSec"
      whileInView="visibleSec"  
    className='precisely_card'>
        <div  className='inside_card1' >
            <motion.img loading='lazy' 
              viewport={{  once: true}}
              variants={MotionContainer}
              initial="hiddenimg1"
              whileInView="visibleimg1"  
            src="Images/precisely/Image 28@2x.png"  alt="" onClick={handleDivClick}/>
            <div className='youtube-logo'    onClick={handleDivClick} style={{ cursor: 'pointer' }}>
            {isPlaying ? (
              <div className='video-Sizing'>
          <ReactPlayer
            url={videoLink}
            // allow="autoplay; encrypted-media"
            controls autoPlay
            playing
            options={defaultOptions}
            width="100%"
            height="100%"
            />
            </div>
        ) : (
              <motion.img loading='lazy' 
              viewport={{  once: true}}
              variants={MotionContainer}
              initial="hiddenimg"
              whileInView="visibleimg"  
              transition={spring} 
            src="Images/precisely/5296521_play_video_vlog_youtube_youtube logo_icon.png" alt="" />
            )}

            </div>
        </div>
    </motion.section>

  )
}

export default PreciselyCard