import React from 'react'
import './BrandAssociation.scss'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from 'reactstrap';
import { motion } from 'framer-motion';

const BrandAssociation = () => {


    const MotionContainer ={
        hidden: { opacity: 0.5, y: 200, scale: 0.98, },
        visible: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},
    
        hiddenContent: { opacity: 0.5, y: 250 },
        visibleContent: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1, }},
        
        hiddenBoximg: { y:10,  },
        visibleBoximg: { y:0,  
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenH1: { y: 40,  opacity: 0.9 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},
    
        hiddenP1: { y: 30,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
    
        hiddenP2: { y: 35,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
    
        hiddenMob: { opacity: 0.5, y: 200, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 1,   }},
        
    }    
  var BrandSetting = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    speed: 2000,
    prevArrow: false,
    nextArrow: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2500,
    cssEase: "linear",
    pauseOnHover: false,
    centerMode: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
  return (

    <motion.section 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hidden"
        whileInView="visible"  
    className='Main-BA'>
    <motion.h1
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenH1"
        whileInView="visibleH1"
    >Brand Association</motion.h1>
    <motion.p 
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1"
    >With Evenion's dedication to agile innovation, user-focused design, and extensive domain knowledge, your digital engineering project is poised for success. Whether you're looking to develop new applications, modernize existing ones, or maintain your software, we've got the expertise and commitment to drive your digital transformation.</motion.p>


    <Container className="client-desktop-container">
                <Slider {...BrandSetting}>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/1.png" alt="flutter.png" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/2.png" alt="Rails" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/3.png" alt="Android Ios" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/4.png" alt="Android Ios" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/5.png" alt="Android Ios" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/6.png" alt="Component" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/7.png" alt="Angular" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/8.png" alt="Java" />
                    </div>
                    <div style={{ width:300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/9.png" alt="Cjpl" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/10.png" alt="Gyansys" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/11.png" alt="Node" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/12.png" alt="Shadow" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/13.png" alt="Shadow" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/14.png" alt="Component" />
                    </div>
                    <div style={{ width: 400 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/15.png" alt="Kafka" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/16.png" alt="Kafka" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/17.png" alt="Aviators" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/18.png" alt="Vue" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/20.png" alt="React" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/DigitalEngineering/21.png" alt="Python" />
                    </div>
                </Slider>
            </Container>
  </motion.section>
  )
}

export default BrandAssociation