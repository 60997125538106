import React, { useState, useEffect , useRef} from 'react';
import "./TaaSSlider.scss";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { motion } from 'framer-motion';

const TaaSSlider = () => {
  const ref = useRef(null)

  const MotionContainer ={
    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, }},

    hiddenP1: { y: 30,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP1Mob: { y: 25,  opacity: 0.9 },
    visibleP1Mob: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},


    
}   
  const slides = [
    {
      heading1: 'OUR',
      heading2: 'QA APPROACH',
      heading3: '– TESTING CYCLE',
      paragraph: 'Our testing cycle is a well-structured process designed to deliver consistent and reliable results. We follow industry-standard best practices to guarantee the success of your testing projects.',
      image: "/Images/taas/Mask Group 3 (1).png",
    },
    {
      heading1: 'TEST',
      heading2: 'AUTOMATION',
      heading3: '',
      paragraph: 'We offer both Functional and Non-Functional Test Automation services to improve the efficiency and accuracy of your testing processes.',
      image: "/Images/taas/Mask Group 1.png",
    },
    {
        heading1: 'TEST',
        heading2: 'MANAGEMENT',
        heading3: ' ',
      paragraph: 'Our comprehensive test management services ensure that your testing efforts are organized, streamlined, and yield valuable insights',
      image: "/Images/taas/Mask Group 2.png",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!hovered) {
        nextSlide();
      }
    }, 3000); 

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, hovered]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + slides.length) % slides.length
    );
  };

  const currentSlide = slides[currentIndex];

  return (
    <>
    <section className='slider-sec'  ref={ref}>


    <div 
      className={`slider-container ${hovered ? 'hovered' : ''}`}

    >
      <motion.div      className="slider-content">
         <motion.h2 
         ref={ref}
                     viewport={{  once: false}}
                     variants={MotionContainer}
                     initial="hiddenH1"
                     whileInView="visibleH1"
         >{currentSlide.heading1} <span style={{color: "#FF6600"}}> {currentSlide.heading2}</span> {currentSlide.heading3}</motion.h2>
        <motion.p
        ref={ref}
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
          onClick={() => setHovered(false)} 
        >
          {currentSlide.paragraph}
        </motion.p>
        <div  className='btn-flex'>
                     <div className="slider-button circless" onClick={prevSlide}>
                     <ArrowBackIcon />
                     </div>
                     <div className="slider-button circless" onClick={nextSlide}>
                     <ArrowForwardIcon />
                    </div>             
        </div>
      </motion.div>
      <div className={`slider-image ${hovered ? 'slide' : ''}`}>
        <img loading='lazy' src={currentSlide.image} alt={`Slide ${currentIndex + 1}`} />
      </div>
    </div>
    </section>


{/* Mobile */}
<section className='slider-sec-mobile'>
    <div
      className={`slider-container-mobile ${hovered ? 'hovered' : ''}`}

    >
      <div className={`slider-image-mobile ${hovered ? 'slide' : ''}`}>
        <img loading='lazy' src={currentSlide.image} alt={`Slide ${currentIndex + 1}`} />
      </div>
      <div className="slider-content-mobile">
         <motion.h2
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1Mob"
        whileInView="visibleP1Mob"   
         >{currentSlide.heading1} <span style={{color: "#FF6600"}}> {currentSlide.heading2}</span> {currentSlide.heading3}</motion.h2>
        <motion.p
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1Mob"
        whileInView="visibleP1Mob"

onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
          onClick={() => setHovered(false)} 
        >
          {currentSlide.paragraph}
        </motion.p>
        <div  className='btn-flex-mobile'>
                     <div className="slider-button-mobile circless-mobile" onClick={prevSlide}>
                     <ArrowBackIcon />
                     </div>
                     <div className="slider-button-mobile circless-mobile" onClick={nextSlide}>
                     <ArrowForwardIcon />
                    </div>             
        </div>
      </div>
    </div>
    </section>
    
      </>
  );
};

export default TaaSSlider;
