import React from 'react';
import "./Client.css";
import { Container } from 'reactstrap';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Clients = () => {

    var settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        speed: 2000,
        prevArrow: false,
        nextArrow: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2500,
        cssEase: "linear",
        pauseOnHover: false,
        centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="clients-baclground-style">
            <div className="client_heading_container">
                <div className="section_main_heading heading-right client_heading"><img loading='lazy' className="heading_rotate_img" src="/Images/new/heading_logo_2.png" alt="Angular" /> <span className="heading_img"><span className="orange_code">B</span>rand <span className="orange_code">A</span>ssociations</span></div>
                <div className="section_sub_heading heading-right">We’ve worked with the top companies to transform &amp; create a digital future</div>
                <div className="section_sub_text heading-right"><span className="orange_code">Trusted</span> by the Best</div>
            </div>
            <Container className="client-desktop-container">
                <Slider {...settings}>
                    <div style={{ width: 225 }}>
                        <img loading='lazy' src="/Images/clients/new2/atos2x.png" alt="flutter.png" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/clients/new2/atossyntel2x.png" alt="Rails" />
                    </div>
                    <div style={{ width: 212 }}>
                        <img loading='lazy' src="/Images/clients/new2/WNS2x.png" alt="Android Ios" />
                    </div>
                    <div style={{ width: 400 }}>
                        <img loading='lazy' src="/Images/clients/new2/replicon.png" alt="Android Ios" />
                    </div>
                    <div style={{ width: 122 }}>
                        <img loading='lazy' src="/Images/clients/new2/hk.png" alt="Android Ios" />
                    </div>
                    <div style={{ width: 300 }}>
                        <img loading='lazy' src="/Images/clients/new2/carestream.png" alt="Component" />
                    </div>
                    <div style={{ width: 280 }}>
                        <img loading='lazy' src="/Images/clients/LTI_logo.png" alt="Angular" />
                    </div>
                    <div style={{ width: 188 }}>
                        <img loading='lazy' src="/Images/clients/new2/healthium2x.png" alt="Java" />
                    </div>
                    <div style={{ width:280 }}>
                        <img loading='lazy' src="/Images/clients/cjpl.png" alt="Cjpl" />
                    </div>
                    <div style={{ width: 225 }}>
                        <img loading='lazy' src="/Images/clients/gyansys_logo.png" alt="Gyansys" />
                    </div>
                    <div style={{ width: 147 }}>
                        <img loading='lazy' src="/Images/clients/new2/utopia2x.png" alt="Node" />
                    </div>
                    <div style={{ width: 220 }}>
                        <img loading='lazy' src="/Images/clients/new2/mphasislogo2x.png" alt="Shadow" />
                    </div>
                    <div style={{ width: 225 }}>
                        <img loading='lazy' src="/Images/clients/new2/smr_automative.png" alt="Shadow" />
                    </div>
                    <div style={{ width: 114 }}>
                        <img loading='lazy' src="/Images/clients/new2/helicall.png" alt="Component" />
                    </div>
                    <div style={{ width: 260 }}>
                        <img loading='lazy' src="/Images/clients/new2/spartan.png" alt="Kafka" />
                    </div>
                    <div style={{ width: 250 }}>
                        <img loading='lazy' src="/Images/clients/new2/mother.png" alt="Kafka" />
                    </div>
                    <div style={{ width: 225 }}>
                        <img loading='lazy' src="/Images/clients/new2/aviators.png" alt="Aviators" />
                    </div>
                    <div style={{ width: 140 }}>
                        <img loading='lazy' src="/Images/clients/new2/quadrific2x.png" alt="Vue" />
                    </div>
                    <div style={{ width: 250 }}>
                        <img loading='lazy' src="/Images/clients/new2/Exxon_Mobil_Logo.png" alt="React" />
                    </div>
                    <div style={{ width: 175 }}>
                        <img loading='lazy' src="/Images/clients/new2/softtek2x.png" alt="Python" />
                    </div>
                </Slider>
            </Container>
        </div>
    )
}

export default Clients;