import React from 'react';
import { motion,    } from 'framer-motion';
import "./HowItWorks.scss";


const HowItWorks = () => {

    const MotionContainer ={
        hidden: { y: 300, scale: 0.8, opacity: 0.5 },
        visible: { y: 0, scale: 1, opacity: 1 ,
        transition:{ duration: 1, }},

        hiddenH1: { y: 40,  opacity: 0.7 },
        visibleH1: { y: 0, opacity: 1, 
        transition:{ duration: 1, }},

        hiddenP1: { y: 25,  opacity: 0.9 },
        visibleP1: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenP2: { y: 30,  opacity: 0.9 },
        visibleP2: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
        
        hiddenP3: { y: 35,  opacity: 0.9 },
        visibleP3: { y: 0, opacity: 1,
        transition:{ duration: 1, delay: 0.15,}},
            
        hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
        visibleMob: { opacity: 1, y: 0, scale: 1, 
        transition:{ duration: 0.50, delay: 0.15, }},
            
        hiddenMobimg: { y:35,  opacity: 0.5},
        visibleMobimg: { y:0,  opacity: 1,
        transition:{ duration: 0.55, delay: 0.10,}},

        hiddenMobH1: { y: 30,  opacity: 0.9 },
        visibleMobH1: { y: 0, opacity: 1, 
        transition:{ duration: 0.50, },},

        hiddenMobP1: { y: 25,  opacity: 0.9 },
        visibleMobP1: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

        hiddenMobP2: { y: 20,  opacity: 0.9 },
        visibleMobP2: { y: 0, opacity: 1,
        transition:{ duration: 0.50, }},

}
return (
    <>
<section className="how-it-works-outer">


<div className='wave-bg'>
{/* <img loading='lazy' src="/Images/Proffesional_Services/wavy_professionals_service.svg" alt="howItWoks" /> */}
<img loading='lazy' src="/Images/Proffesional_Services/wavy_professionals_service1.svg" alt="howItWoks" />
</div>
 <section className="how-it-works">

        <motion.div   
        className='HowItWorks-box1'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >How It Works</motion.h1>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"
            className='para1'>We will provide you with the highest skilled engineering talent & deeper level of support to make sure the work is aligned with your project plan, objectives, & target delivery dates</motion.p>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP2"
            whileInView="visibleP2"
            className='para2'><span class="orange_code">UNLOCK</span> NEW EFFICIENCIES, <br/><span class="orange_code">IMPROVE</span> CUSTOMER EXPERIENCE</motion.p>
        </motion.div>
        <div className='HowItWorks-box2'>
            <div className='HowItWork-img'>
                <img loading='lazy' src='/Images/Proffesional_Services/Rectangle 1143.svg' alt="howItWoks" />
            </div>

            <motion.div  
            className='Box-right'>

            <div className='inside-box-right1'>
                <div className='flexx-set'>
                    <div className='mergin-set'>
                        <div className='Circle_No'>01</div>
                    </div> 
                    <div>
                        <motion.h1 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP1"
                            whileInView="visibleP1"
                        className='headline'>Recruitment</motion.h1>
                        <motion.p
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP2"
                            whileInView="visibleP2"
                        className='para-right'>Utilize our unique talent pool of pre-screened experts to choose and personally interview prime candidates. The ultimate hiring decision is yours. We ensure candidates possess essential technical and soft skills to seamlessly integrate into your team and bring value</motion.p>
                        <div className='rightt'>
                        <motion.img loading='lazy' 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP3"
                            whileInView="visibleP3"
                        src="/Images/Proffesional_Services/Mask Group 113@2x.png" alt="Recruitment" />
                        </div>
                    </div>
                </div>
            </div>

        <div className='inside-box-right2'>
            <div className='flexx-set'>
                    <div className='mergin-set'>
                        <div className='Circle_No'>02</div>
                    </div>
                    <div>
                        <motion.h1 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP1"
                            whileInView="visibleP1"                        
                        className='headline'>Team Integration</motion.h1>
                        <motion.p 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP2"
                            whileInView="visibleP2"                        
                        className='para-right'>Once onboard, the external development team from Evenion seamlessly integrates into your in-house staff. They directly report to your in-house project manager, adhere to internal processes, and employ your selected communication and collaboration tools, mirroring your in-house team's approach.</motion.p>
                        <div className='rightt'>
                        <motion.img loading='lazy' 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP3"
                            whileInView="visibleP3"                        
                        src="/Images/Proffesional_Services/Group 114@2x.png" alt="Team" />
                        </div>
                    </div>
            </div>
        </div>

        <div className='inside-box-right3'>
            <div className='flexx-set'>
                    <div className='mergin-set'>
                        <div className='Circle_No'>03</div>
                    </div>
                    <div>
                        <motion.h1 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP1"
                            whileInView="visibleP1"                        
                        className='headline'>Ongoing Support</motion.h1>
                        <motion.p 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP2"
                            whileInView="visibleP2"                        
                        className='para-right'>Count on our recruitment squad for ongoing assistance, aiding you in overcoming future hurdles. This allows you to effortlessly adapt team size or skill sets as your project advances, aligning with its evolving needs.</motion.p>
                        <div className='rightt'>
                        <motion.img loading='lazy' 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenP3"
                            whileInView="visibleP3"                        
                        src="/Images/Proffesional_Services/Mask Group 115@2x.png" alt="Support" />
                        </div>
                    </div>
            </div>        
        </div>

        </motion.div>


        </div>
    </section>

</section>

{/* Mobile */}
<section className="how-it-works-mobile">
<div className='wave-bg'>
<img loading='lazy' src="/Images/Proffesional_Services/wavy_professionals_service1.svg" alt="howItWoks" />
</div>
        <motion.div
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hiddenMob"
        whileInView="visibleMob"
        className='HowItWorks-box1-mobile'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobH1"
            whileInView="visibleMobH1"            
            >How It Works</motion.h1>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP1"
            whileInView="visibleMobP1"            
            className='para1'>We will provide you with the highest skilled engineering talent & deeper level of support to make sure the work is aligned with your project plan, objectives, & target delivery dates</motion.p>
            <motion.p 
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenMobP2"
            whileInView="visibleMobP2"            
            className='para2'><span class="orange_code">UNLOCK</span> NEW EFFICIENCIES, <br/><span class="orange_code">IMPROVE</span> CUSTOMER EXPERIENCE</motion.p>
        </motion.div>

        <div className='Box-right-mobile'>

            <div className='inside-box-right1-mobile'>
                <div className='flexx-set-mobile'>
                    <div className='mergin-set-mobile'>
                        <div className='Circle_No-mobile'>01</div>
                    </div> 
                    <div>
                        <motion.h1 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenMobH1"
                            whileInView="visibleMobH1"                        
                        className='headline-mobile'>Recruitment</motion.h1>
                        <motion.p 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenMobP1"
                            whileInView="visibleMobP1"                        
                        className='para-right-mobile'>Utilize our unique talent pool of pre-screened experts to choose and personally interview prime candidates. The ultimate hiring decision is yours. We ensure candidates possess essential technical and soft skills to seamlessly integrate into your team and bring value</motion.p>
                        <div className='rightt-mobile'>
                        <motion.img loading='lazy' 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenMobP2"
                            whileInView="visibleMobP2"                        
                        src="/Images/Proffesional_Services/Mask Group 113@2x.png" alt="Recruitment" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='inside-box-right2-mobile'>
            <div className='flexx-set-mobile'>
                    <div className='mergin-set-mobile'>
                        <div className='Circle_No-mobile'>02</div>
                    </div>
                    <div>
                        <motion.h1 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobH1"
                        whileInView="visibleMobH1"                        
                        className='headline-mobile'>Team Integration</motion.h1>
                        <motion.p 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP1"
                        whileInView="visibleMobP1"                        
                        className='para-right-mobile'>Once onboard, the external development team from Evenion seamlessly integrates into your in-house staff. They directly report to your in-house project manager, adhere to internal processes, and employ your selected communication and collaboration tools, mirroring your in-house team's approach.</motion.p>
                        <div className='rightt-mobile'>
                        <motion.img loading='lazy' 
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenMobP2"
                        whileInView="visibleMobP2"                   
                        src="/Images/Proffesional_Services/Group 114@2x.png" alt="Team" />
                        </div>
                    </div>
            </div>
            </div>

            <div className='inside-box-right3-mobile'>
            <div className='flexx-set-mobile'>
                    <div className='mergin-set-mobile'>
                        <div className='Circle_No-mobile'>03</div>
                    </div>
                    <div>
                        <motion.h1 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenMobH1"
                            whileInView="visibleMobH1"                        
                        className='headline-mobile'>Ongoing Support</motion.h1>
                        <motion.p 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenMobP1"
                            whileInView="visibleMobP1"                        
                        className='para-right-mobile'>Count on our recruitment squad for ongoing assistance, aiding you in overcoming future hurdles. This allows you to effortlessly adapt team size or skill sets as your project advances, aligning with its evolving needs.</motion.p>
                        <div className='rightt-mobile'>
                        <motion.img loading='lazy' 
                            viewport={{  once: false}}
                            variants={MotionContainer}
                            initial="hiddenMobP2"
                            whileInView="visibleMobP2"                        
                        src="/Images/Proffesional_Services/Mask Group 115@2x.png" alt="Support" />
                        </div>
                    </div>
            </div>        
            </div>

            </div>

</section>
</>
  )
}

export default HowItWorks