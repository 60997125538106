import React from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';
import * as error404 from '../jsonFiles/404.json'
import { Row, Col } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import  ArrowForwardIcon  from '@mui/icons-material/ArrowForward';

const NotFoundPage = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: error404}


    return (
        <div className="spinner-container">
            <Helmet>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Digital transformation consulting and Services Company - Evenion Technologies" />
                <meta property="og:description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="og:url" content="https://www.eveniontechnologies.com/home" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image"
                    content="https://eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <meta property="og:image:secure_url" content="https://eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Digital transformation consulting and Services Company - Evenion Technologies" />
                <meta name="twitter:description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta name="twitter:image" content="https://eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <script type="application/ld+json">
                    {`{
                        "@context": "Schema.org - Schema.org 
                        "@type": "Organization",
                        "name": "Evenion Technologies",
                        "url": "https://www.eveniontechnologies.com/", 
                        "logo": "https://eveniontechnologies.com/Images/logo/evenion_logo.png", 
                        "sameAs": [
                        "https://facebook.com/eveniontechnologies/",
                        "https://www.linkedin.com/company/evenion-technologies/",
                        "https://twitter.com/EvenionTech/"
                        ]
                    }`
                    }
                </script>
            </Helmet>
            <div className="page_404_container">
                {window.innerWidth > 921 ?
                <div className="error-404" style={{overflow:'hidden'}}>
                    <Row>
                        <Col>
                    <h1>Uh Oh.</h1><br/><br/>
                    <h1 className='error-title'>
                    Page doesn't exist <img loading='lazy' src="/Images/logo/zzz.svg" className='zzz-img'/> <br/> Please go back and try again.  
                       <div className='back2home' >
                            <div> <a href='/' className='back2home-text'>Back to HomePage <ArrowBackIcon className='rotatee'/></a> </div>
                        </div> 
                       </h1>
                        </Col>
                        <Col>
                    <div className='gif-404'>
                    <Lottie options={defaultOptions}/> 
                    </div>
                        </Col>
                    </Row>
                </div>
                     :
                     <div className="error-404" style={{overflow:'hidden'}}>
                     <div className='gif-404-mobile'>
                     <Lottie options={defaultOptions}/> 
                     </div>
                     <h1>Uh Oh.</h1>
                     <h1 className='error-title'>
                     Page doesn't exist <img loading='lazy' src="/Images/logo/zzz.svg" className='zzz-img'/> <br/> Please go back and try again.  
                             <div className='back2home-mob'> <div><a href='/' className='back2home-textmob'>Back to HomePage <ArrowForwardIcon className='rotatee'/></a> </div></div>
                        </h1>
                 </div>
                   }
            </div>

        </div>
    )
}

export default NotFoundPage;