export default function validateInfo(values){
    let errors = {};

    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var phoneNumberRegex2 = /^[\d+\s()-]+$/;


    if(!values.email){
        errors.email = "Email Required";
    }else if(!reg.test(values.email)){
        errors.email = "Invalid Email";
    }

    if(!values.phone){
        errors.phone = "Phone Number is Required";
    }else if (!phoneNumberRegex2.test(values.phone)){
        errors.phone = "Should be Number";
    }else if (values.phone.length<5){
        errors.phone = "Phone Must minimum of 5 digits";
    }else if (values.phone.length>20){
        errors.phone = "Phone Must be maximum of 20 digits";
    }

    if(!values.currentComapany){
        errors.currentComapany = "Current Company Name Required";
    }
    if(!values.location){
        errors.location = "Please select Preferred Location";
    }
    
    return errors;

}