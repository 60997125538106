import React from 'react'
import './DigiOurStack.scss'
import { motion } from 'framer-motion';

const DigiOurStack = () => {
  
  const MotionContainer ={
    hidden: { opacity: 0.5, y: 200, scale: 0.98, },
    visible: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1,   }},

    hiddenContent: { opacity: 0.5, y: 250 },
    visibleContent: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1, }},
    
    hiddenBoximg: { y:10,  },
    visibleBoximg: { y:0,  
    transition:{ duration: 1, delay: 0.15,}},
    
    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, }},

    hiddenP1: { y: 30,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP2: { y: 35,  opacity: 0.9 },
    visibleP2: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    
}    
  return (
    <section className='main-OurStack'>
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"        
        className='OS-head'>
            <motion.h1
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenH1"
            whileInView="visibleH1"
            >Our Stacks</motion.h1>
            <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1"
            className='para1' >We accelerate Digital Transformation through robust & purpose-built tech solutions</motion.p>
            <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP2"
                whileInView="visibleP2"
            className='para2' ><span className='orange_code'>INNOVATIVE</span> TECH PROWESS IS IN OUR DNA</motion.p>
        </motion.div>
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"   
        className='OS-lang'>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB1-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB2-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB3-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB4-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB5-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB6-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB7-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB8-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB9-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB10-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB11-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB12-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB13-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB14-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src="/Images/DigitalEngineering/OSB15-removebg-preview.png" alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB16-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB17-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB18-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB19-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB20-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB21-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB22-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB23-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB24-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB25-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB26-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB27-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB28-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB29-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src='/Images/DigitalEngineering/OSB30-removebg-preview.png' alt="Os"/>
              </div>
              <div className='Os-box'>
                <motion.img
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenBoximg"
                    whileInView="visibleBoximg"                  
                src="/Images/DigitalEngineering/OSB32-removebg-preview.png" alt="Os"/>
              </div>
        </motion.div>




    </section>
  )
}

export default DigiOurStack