import React, { useState, useEffect } from 'react';
import './EngagmentModel.scss'
import { motion } from 'framer-motion';

const EngagmentModel = () => {
  const MotionContainer ={
    hidden: { opacity: 0.5, y: 200, scale: 0.98, },
    visible: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1,   delay: 0.15,}},
    
    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, delay: 0.15,}},

    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    hiddenP1: { y: 35,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP2: { y: 20,  opacity: 0.9 },
    visibleP2: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.15, }},
    
}    

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
      }, 2000); // Adjust the interval as needed
  
      return () => clearInterval(interval);
    }, []);
  
    const Textchanges = ['Capture Market', 'Gain Traction', 'Obtain Success'];


  return (
    <>
    
    <section className='EngagmentMain'>
      <motion.div
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"
      >
            <div className='Engag-content'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                >Engagement Models</motion.h1>
                <motion.p
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1"
                >Implement your Ideas, NOW</motion.p>
            </div>

        <div className="Textchange-list-container">
            {Textchanges.map((Textchange, index) => (
                <motion.div
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenP1"
                  whileInView="visibleP1" 
                key={index}
                className={`Textchange ${index === activeIndex ? 'active' : ''}`}
                >
                {Textchange}
                </motion.div>
            ))}
        </div>

        </motion.div>

{/* Desktop Container */}
 <motion.section 
    viewport={{  once: true}}
    variants={MotionContainer}
    initial="hidden"
    whileInView="visible"
 className='Engag-boxs'>
      <div className="Engag-boxs1">
          <div className='EngagBox111' id='newhover'>
              <div className='head-box'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                >PRODUCTS & <br></br> SOLUTIONS</motion.h1>
                <img     className='Dclass2' src='/Images/Home/Group 3945-1.svg' alt='default' />
                <img     className='Aclass2' src='/Images/Home/Group 3945(1).svg' alt='default' />
              </div>
                <div className='paradiv'>
                  <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1" 
                  className='para1'>Define specific features, functionalities & scope for your product or solution, and Evenion will deliver on time and on budget, tailored to the needs and goals of your organization.</motion.p>
                </div>
          </div>


          <div className='EngagBox'>
              <div className='head-box'>
                <motion.h1
                  viewport={{  once: false}}
                  variants={MotionContainer}
                  initial="hiddenH1"
                  whileInView="visibleH1"
                >TECHNOLOGY <br></br> PARTNERSHIP</motion.h1>
                <img     className='Dclass1' src='/Images/Home/Group 3951.svg' alt='default' />
                  <img     className='Aclass1' src='/Images/Home/Group 3951(1).svg' alt='default' />


              </div>
              <div className='paradiv'>
                <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1" 
                className='para1'>Use our Partnership services to build your Engineering team, equipped with Project Managers, Program Managers, Scrum Masters, Developers, Testers and DevOps. We partner for you to Win and Grow</motion.p>
              </div>
          </div>

        </div>

        <div className="Engag-boxs2">
            <div className='EngagBox'>
                <div className='head-box'>
                  <motion.h1
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenH1"
                    whileInView="visibleH1"
                  >Data  <br></br> Integration <br></br> Services </motion.h1>
                  <img     className='Dclass1' src='/Images/Home/Group 3950.svg' alt='default' />
                  <img     className='Aclass1' src='/Images/Home/Group 3950(1).svg' alt='default' />
                </div>
                <div className='paradiv'>
                  <motion.p 
                    viewport={{  once: false}}
                    variants={MotionContainer}
                    initial="hiddenP1"
                    whileInView="visibleP1" 
                  className='para1'>Build complex hybrid and integrated systems & services. Our integration 
                  Services engagement allows you to solve complex business problems, create seamless data distribution, allow data transformation and create digital interface for your business growth.</motion.p>
                </div>
            </div>
        </div>
</motion.section>


{/* Tablet Container */}
<motion.section
    viewport={{  once: true}}
    variants={MotionContainer}
    initial="hidden"
    whileInView="visible"
className='Enagag-Tab'>
  <div className='Enagag-TabBox1'>
        <div className='Eng-Box'>
          <div className='head-box'>
            <motion.h1
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenH1"
                whileInView="visibleH1"
            >PRODUCTS & SOLUTIONS</motion.h1>
            <img     src='/Images/Home/Group 3945(1).svg' alt='def' />
          </div>
          <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1" 
          >Define specific features, functionalities & scope for your product or solution, and Evenion will deliver on time and on budget, tailored to the needs and goals of your organization.</motion.p>
        </div>

        <div className='Eng-Box'>
          <div className='head-box'>
            <motion.h1
              viewport={{  once: false}}
              variants={MotionContainer}
              initial="hiddenH1"
              whileInView="visibleH1"
            >TECHNOLOGY  PARTNERSHIP</motion.h1>
            <img     src='/Images/Home/Group 3951(1).svg' alt='def' />
          </div>
          <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1" 
          >Use our Partnership services to build your Engineering team, equipped with Project Managers, Program Managers, Scrum Masters, Developers, Testers and DevOps. We partner for you to Win and Grow</motion.p>
        </div>
  </div>

      <div className='Enagag-TabBox1'>
        <div className='Eng-Box'>
          <div className='head-box'>
            <motion.h1
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenH1"
                whileInView="visibleH1"
            >Data Integration Services</motion.h1>
            <img     src='/Images/Home/Group 3950(1).svg' alt='def' />
          </div>
          <motion.p
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP1"
            whileInView="visibleP1"           
          >Build complex hybrid and integrated systems & services. Our integration 
                  Services engagement allows you to solve complex business problems, create seamless data distribution, allow data transformation and create digital interface for your business growth.</motion.p>
        </div>
      </div>
</motion.section>




    </section>

{/* Mobile */}
    <section 
    className='Main-Engagment-Mobile'>
      <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
      className='Mobile-Engag'>
        <motion.h1
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenMobH1"
          whileInView="visibleMobH1"
        >Engagement <br></br> Models</motion.h1>
        <motion.p 
          viewport={{  once: false}}
          variants={MotionContainer}
          initial="hiddenMobP1"
          whileInView="visibleMobP1" 
        className='para1'>Implement your Ideas, NOW</motion.p>
        <div className="Textchange-list-container-Mobile">
            {Textchanges.map((Textchange, index) => (
                <motion.div
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
                key={index}
                className={`Textchange ${index === activeIndex ? 'active' : ''}`}
                >
                {Textchange}
                </motion.div>
            ))}
        </div>
      </motion.div>

      <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
      className='Mobile-EngagBox'> 

          
          <div className='Mob-Box'>
              <img     src="/Images/Home/Group 3945(1).svg" alt='01' />
              <motion.h1
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobH1"
                whileInView="visibleMobH1"
              >PRODUCTS & SOLUTIONS</motion.h1>
              <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1" 
              className='para1'>Define specific features, functionalities & scope for your product or solution, and Evenion will deliver on time and on budget, tailored to the needs and goals of your organization.</motion.p>
          </div>


          <div className='Mob-Box'>
              <img     src="/Images/Home/Group 3950(1).svg" alt='02' />
              <motion.h1
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobH1"
                whileInView="visibleMobH1"
              >Data Integration Services</motion.h1>
              <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1" 
              className='para1'>Build complex hybrid and integrated systems & services. Our integration Services engagement allows you to solve complex business problems, create seamless data distribution, allow data transformation and create digital interface for your business growth.</motion.p>
          </div>


          <div className='Mob-Box'>
              <img     src="/Images/Home/Group 3951(1).svg" alt='03' />
              <motion.h1
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobH1"
                whileInView="visibleMobH1"
              >TECHNOLOGY PARTNERSHIP</motion.h1>
              <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1" 
              className='para1'>Use our Partnership services to build your Engineering team, equipped with Project Managers, Program Managers, Scrum Masters, Developers, Testers and DevOps. We partner for you to Win and Grow</motion.p>
          </div>

          
      </motion.div>
    </section>
</>
  )
}

export default EngagmentModel