import React, { useEffect, useRef, useState } from 'react';
import "./CareerOpen.css";
import "./CareerOpen.scss";
import { Button, Row, Col, Container, ModalBody, Modal } from 'reactstrap';
import JobList from '../JobList/JobList';
import axios from 'axios';
import moment from 'moment';
import useForm from './useForm';
import validate from './validateInfo';
import { useInView, motion } from 'framer-motion';
import { RemoveFromQueue } from '@mui/icons-material';
import Lottie from 'react-lottie';
import * as mailAnimation from '../../jsonFiles/mail_send.json'


const CareerOpen = (props) => {

    const ref = useRef(null)
    const isInView = useInView(ref, {once: false});

    var regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    var phoneNumberRegex2 = /^[\d+\s()-]+$/;

    const [careerList, setCareerList] = useState();
    const [modal, setModal] = useState(false);
    const [modals, setModals] = useState(false);
    const toggles = () => setModals(!modals);
    const toggle = () => setModal(!modal);
    const [view, setView] = useState(true);
    const [confirmmodal, setconfirmmodal] = useState(false);
    const toggleConfirm = () => setconfirmmodal(!confirmmodal);

    const [careerId, setCareerId] = useState('');

    const [ids, setIds] = useState(null);

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: mailAnimation}


    const [errors, setErrors] = useState({});
    const { handleChange, values } = useForm(validate);
    const [selectedFile, setFile] = useState();
    const [getFileName, setFileNamme] = useState('')

    function handleChange1(event) {
        setFile(event.target.files[0]);
        var fileInput = document.getElementById('upload_file');
        var filePath = fileInput.value;
        var allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd)$/i;
        if (!allowedExtensions.exec(filePath)) {
            alert('Invalid file type');
            fileInput.value = '';
            return false;
        }
        setFileNamme(event.target.files[0].name)

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('filedata', selectedFile);
        formData.append('current_company', values.currentComapany);
        formData.append('email', values.email);
        formData.append('phone_number', values.phone);
        formData.append('preferred_location', values.location);
        formData.append('CareerID', careerId);

        setErrors(validate(values));

        if (values.currentComapany !== '' && isNaN(values.currentComapany) && values.email !== '' && regex.test(values.email) && values.phone !== '' && phoneNumberRegex2.test(values.phone) && values.phone.length >5 && values.phone.length <20 && values.location !== '') {
            const headers = {
                'x-device-id': 'stuff',
                'Content-Type': 'multipart/form-data'
            }

            const getApply = async () => {
                const url = `${process.env.REACT_APP_API_URL}/career_request`;
                await axios.post(url, formData, headers).then((response) => {
                    setModal(false);
                    setconfirmmodal(true);
                })
                    .catch(error => {
                        console.log("Error => ", error);
                    });
            };
            getApply();

        }

    };


    const handleApply = (e) => {
        setCareerId(e);
        values.email = "";
        values.phone = "";
        values.currentComapany = "";
        values.location = "";
        setModal(true);
    }

    const viewDescription = (id) => {
        setIds(id);
        if (ids === id) {
            setView(!view);
        }
    }

    const viewDescriptionss = (id) => {
        setIds(id);
        setModals(true); // Move this line outside of the if block
      };
      
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/careers`)
            .then(response => {
                setCareerList(response.data);
            })
            .catch(error => {
                console.log("Error => ", error);
            });
    }, [])

    function createMarkup(data) {
        return {
            __html: data
        };
    };

    function CloseModalPopup() {
        setModal(false);
        setModals(false);
    }
    return (
        <>
        <motion.section
                viewport={{ ref, once: true}}
                variants={{
                    hidden: { y: 100, opacity: 0.5 },
                    visible: { y: 0,  opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.25 }}
                
                className="Our-Approach Our-Approach-mobile Our-Approach-tablet col-md-12">
                    
                <div className="Our-Approach-Ex-Container Our-Approach-Ex-Container-mobile Our-Approach-Ex-Container-tablet col-md-12"
                style={{paddingTop: "0%"}}
                >
                    <motion.h1 
                    style={{paddingTop: "0%"}}
                    variants={{
                        hidden: { y: 40, scale: 0.9, opacity: 0.5 },
                        visible: { y: 0, scale: 1, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 1, delay: 0.25,}}
                    className='open_oppor col-md-12' >Open Opportunities</motion.h1>
                    <motion.div 
                        viewport={{ref, once: false}}
                        variants={{
                            hidden: { y: 20, opacity: 0.3 },
                            visible: { y: 0,  opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25,}}
                    >
                            <motion.p 
                            viewport={{ref, once: false}}
                            variants={{
                                hidden: { y: 30,  opacity: 0.2 },
                                visible: { y: 0, opacity: 1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={{ duration: 1, delay: 0.15,  }}
                            className='Our-p col-md-12'  style={{fontSize:20, paddingBottom:"0%"}}>Get ready for a fresh start</motion.p>
                            <motion.p 
                            viewport={{ ref, once: false}}
                            variants={{
                                hidden: { y: 30,  opacity: 0.2 },
                                visible: { y: 0, opacity: 1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={{ duration: 1, delay: 0.15,  }} 
                            className='Our-pa col-md-12' style={{paddingBottom:"12%"}}>Get, Set<span className='orange_code'> Go </span> !! </motion.p>
                    </motion.div>
                    </div>
        
       

                 <Modal isOpen={modal}  id="myModal" className='modal_content_applying2' style={{width:'100%'}}>
                <ModalBody charCode="X" className='modal_applying2' style={{width:'100%'}}>
                    
                    <h5 className="details-message">Apply Now <span className="model_box_close_btn1" onClick={CloseModalPopup}>&times;</span></h5>
                   
                    <div className="section-get-center">
                        <form className="form-career1">
                            <div className='flex-input'>
                            <input id="email_id" type="text" placeholder='Email'
                                name="email"
                                className="text-box1"
                                autoComplete="new-password" 
                                value={values.email}
                                onChange={handleChange} />
                            <br />
                            {errors.email && <p className="error_text">{errors.email}</p>}
                            <input id="phone_id" className="text-box1" type="text" name="phone" placeholder='Contact Number' autoComplete="new-password"
                                value={values.phone}
                                onChange={handleChange} /><br />
                            {errors.phone && <p className="error_text">{errors.phone}</p>}
                            </div>
                            <div className='flex-input'>
                            <input id="current_company_id" className="text-box1" type="text" name="currentComapany" placeholder='Current Company' autoComplete="new-password"
                                value={values.currentComapany}
                                onChange={handleChange} /><br />
                            {errors.currentComapany && <p className="error_text">{errors.currentComapany}</p>}

                            <select id="location_id" name="location" className="text-box1" placeholder='Preffered Location'
                                onChange={handleChange}>
                                <option value="" style={{color:'#0C143C'}}>Preffered Location</option>
                                <option value="Bangalore" style={{color:'#0C143C'}}>Bangalore</option>
                                <option value="Pune" style={{color:'#0C143C'}}>Pune</option>
                                <option value="Stockholm" style={{color:'#0C143C'}}>Stockholm</option>
                            </select>
                            {errors.location && <p className="error_text">{errors.location}</p>}
                            </div>
                            <br></br>
                            <span>Resume/CV</span>
                                <br></br>
                            <div className="file-upload-button1">
                                {/* <span className="file-upload-button-text">Attach</span> */}
                                <input 
                                type="file"
                                id="upload_file"
                                placeholder='Attach'
                                onChange={handleChange1} />
                            </div>  {getFileName && <span>{getFileName}</span>}
                            <br/>
                            <div className="center-apply">
                            <button color="btn-white" className="apply_btn_applying center-apply" onClick={handleSubmit} type="submit">Apply Now</button>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={confirmmodal} className='modal-contentPro' style={{alignItems:'center'}}>
                <ModalBody>
                    <h5 className="text-center bg-color my-4">Thank You for Applying!</h5>
                    <div className='gif-set'>
                    {/* <img loading='lazy' src="Images/Stakerank/mail send (2).gif" alt="" /> */}
                    <Lottie options={defaultOptions}/>
                    </div>
                    <p className="mb-4 text-center model_patra">Your resume has been uploaded successfully.<br/> You're one step closer to joining our team
                    </p>
                    <Button color="btn-white" className="modal_close" onClick={toggleConfirm}>Close</Button>
                </ModalBody>
            </Modal>
            </motion.section>





            <section className='career-main'>
                {/* <div className='career-inside1'>
                    <h1>Open Opportunities</h1>
                    <p className='career-p1'>Get ready for a fresh start</p>
                    <p className='career-p2'>Get, Set<span className='orange_code'> Go </span> !!</p>
                </div> */}



                {careerList && careerList?.data[0].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).filter(ele => ele.status === 'Active').map((careers, i) =>
                <>
                <motion.div className='career-inside2' key={i} onClick={() => viewDescriptionss(careers.id)}
                          viewport={{ref, once: true}}
                          variants={{
                              hidden: { y: 200 },
                              visible: { y: 0 },
                          }}
                          initial="hidden"
                          whileInView="visible"
                          transition={{ duration: 1, delay: 0.25,}}>
                    <div className='career-links1' >
                        <h1>{careers.title}</h1>
                            <div  className='career-links2'>
                                <p>Full Time &nbsp;  &nbsp;<span className='lii'></span> &nbsp;  &nbsp; </p>
                                <p>{careers.location}</p>
                            </div>
                    </div>

                {ids === careers.id &&
                <div className='model-main'>

                <Modal isOpen={modals}  id="myModal" className='modal_content_applying' style={{"max-width":'100%', "background": "#000"}}>
                <ModalBody charCode="X" className='modal_applying' style={{width:'100%'}}>
                    <div className='model-above'>
                        <h1>{careers.title}</h1>
                        <div className='modell-heading'>
                            <p>Full Time   &nbsp; | </p>
                            <p> &nbsp; {careers.location}</p>
                        </div>
                    </div>
                    <div className='model-content-main'>
                        <div className='btn-career'>
                            {/* <div className='share-career'>Share</div> */}
                            <div className='share-career-link'></div>
                            <div className='Appply-career' onClick={() => handleApply(careers.id)}> Apply Now</div>
                        </div>
                        <h1 className="btn-career-head">About Evenion Team</h1>
                        <p  className='para-evee'>At Evenion Technologies, our success is driven by a team of highly skilled and dedicated professionals who are committed to achieving our company's goals. Our team members come from diverse backgrounds and bring a wealth of experience and expertise to our work. Together, we strive to provide the highest level of service and support to our customers, and we are constantly pushing ourselves to innovate and improve.</p>
                        <p  className='para-evee'>We're building something special, and we want you to be a part of it. Join our rapidly growing team and help us deliver pioneering solutions and services on a global scale</p>
                        <h1 className="btn-career-head">Roles & Responsibilities</h1>
                        <p className='para-evee para-evee1' dangerouslySetInnerHTML={createMarkup(careers.description)}>
                            {/* {careers.description} */}
                        </p>
                        <div className='btn-career1'>
                            <div className='close-career' onClick={CloseModalPopup}>Go Back</div>
                            <div className='Appply-career' onClick={() => handleApply(careers.id)}> Apply for this Job</div>
                        </div>

                    </div>
                </ModalBody>
                </Modal>
                </div>
            }
            </motion.div>
                </>
                )}









             





            </section>
        </>
    )
}

export default CareerOpen;














