import React, { useEffect, useState, useRef } from "react";
import "./Partnerships.scss";
import Clients from "../../Components/Clients/Clients";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { motion, useInView, useScroll, useSpring } from "framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Possibilities from "./Possibiities";
import FlowiseBenifits from "./FlowiseBenifits";
import { Breadcrumbs, Typography } from "@mui/material";

const Flowise = () => {
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadingComplete = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    window.scrollTo(0, localStorage.getItem("position"));
  }, []);

  return (
    <div>
      <Helmet>
        <title>For Powerful Possibilities Partner With Evenion - Flowise</title>
        <meta
          name="description"
          content="For Powerful Possibilities Partner With Evenion - Flowise"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="the platform - flowize" />
        <meta
          property="og:title"
          content="For Powerful Possibilities Partner With Evenion - Flowise"
        />
        <meta
          property="description"
          content="Use the potential of your data and connect a variety of processes. Flowize enables you to easily build flows combining every feature you need. Please contact Evenion Technologies for more information."
        />
        <meta
          property="og:description"
          content="Use the potential of your data and connect a variety of processes. Flowize enables you to easily build flows combining every feature you need. Please contact Evenion Technologies for more information."
        />
        <meta
          property="og:url"
          content="Digital Transformation and Consulting Services Company - Evenion Technologies"
        />
        <meta
          property="og:site_name"
          content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation"
        />
        <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
        <meta
          property="og:image"
          content="For Powerful Possibilities Partner With Evenion - Flowise"
        />
        <meta
          property="og:image:secure_url"
          content="Digital Transformation and Consulting Services Company - Evenion Technologies"
        />
        <meta property="og:image:width" content="630" />
        <meta property="og:image:height" content="420" />
        <meta property="og:image:alt" content="Evenion" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="article:published_time"
          content="2020-11-25GMT+000012:40:02+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-08-29GMT+000014:49:23+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="For Powerful Possibilities Partner With Evenion - Flowise"
        />
        <meta
          name="twitter:description"
          content="For Powerful Possibilities Partner With Evenion"
        />
        <meta
          name="twitter:image"
          content="For Powerful Possibilities Partner With Evenion - Flowise"
        />
      </Helmet>

      {isLoading ? (
        <>
          <div className="partnership-container">
            {window.innerWidth > 767 ? (
              <section className="framer-about-us-main-section">
                <motion.img
                  className="careermain_image"
                  src="\Images\partnership\flowise_back.png"
                  alt="career_backdrouf"
                />
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator=">>"
                  className="breadcrumb_container_new"
                  style={{ top: 0 }}
                >
                  <div className="breadcrumb_text">
                    <a href="/">Home</a>
                  </div>
                  <Typography
                    color="#0F326C"
                    fontFamily=" space-Grotesksb"
                    fontWeight="300"
                  >
                    Partnership
                  </Typography>
                  <Typography
                    color="#0F326C"
                    fontFamily=" space-Grotesksb"
                    fontWeight="300"
                  >
                    Flowize
                  </Typography>
                </Breadcrumbs>
                <div className="framer-partnership-insideContent framer-partnership-insideContent-tablet">
                  <motion.div
                    style={{
                      width: "100%",
                    }}
                    variants={{
                      hidden: { opacity: 0.5, y: 45 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate="visible"
                    transition={{
                      duration: 1,
                      delay: 0.25,
                      ease: [0, 0.71, 0.2, 1.01],
                    }}
                  >
                    <motion.h1
                      variants={{
                        hidden: { x: -192, y: 72, scale: 0.4 },
                        visible: { x: 0, y: 0, scale: 1 },
                      }}
                      initial="hidden"
                      animate="visible"
                      transition={{
                        duration: 0.5,
                        delay: 0.25,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      Flowize
                    </motion.h1>
                    <p
                      className="f-para1 f-para1-mobile"
                      style={{ color: "#FF6600" }}
                    >
                      The all-in-one, low code digital transformation <br></br>
                      platform with a human touch
                    </p>
                    <div className="btn-container btn-container-mobile btn-container-tablet">
                      <div className="btn-lii btn-lii-tablet">
                        <a href="/contact-us#contact_form">
                          <span className="btn-liil btn-liil-tablet">
                            Contact Us &nbsp;
                            <ArrowBackIcon className="rotatee rotatee-tablet" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </section>
            ) : (
              <>
                <section className="framer-main-partnership">
                  {window.innerWidth > 480 ? (
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator=">>"
                      className="breadcrumb_container_new"
                    >
                      <div className="breadcrumb_text">
                        <a href="/">Home</a>
                      </div>
                      <Typography
                        color="#0F326C"
                        fontFamily=" space-Grotesksb"
                        fontWeight="300"
                      >
                        Partnership
                      </Typography>
                      <Typography
                        color="#0F326C"
                        fontFamily=" space-Grotesksb"
                        fontWeight="300"
                      >
                        Flowize
                      </Typography>
                    </Breadcrumbs>
                  ) : (
                    ""
                  )}
                  <img
                    src="\Images\partnership\banner.png"
                    alt="career_backdrouf"
                    style={{
                      position: "relative",
                      marginBottom: "10%",
                      marginTop: "15%",
                    }}
                    className="flowise_mob-img"
                  />
                  <div className="framer-partnership-insideContent framer-partnership-insideContent-tablet framer-partnership-insideContent-mobile">
                    <motion.div
                      className="partnershipmbile"
                      variants={{
                        hidden: { opacity: 0.5, y: 85 },
                        visible: { opacity: 1, y: 0 },
                      }}
                      initial="hidden"
                      animate="visible"
                      transition={{
                        duration: 1,
                        delay: 0.25,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      <motion.h1
                        className="head_mob"
                        variants={{
                          hidden: { x: -274, y: 72, scale: 0.4 },
                          visible: { x: 0, y: 0, scale: 1 },
                        }}
                        initial="hidden"
                        animate="visible"
                        transition={{
                          duration: 0.5,
                          delay: 0.25,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                      >
                        Flowize
                      </motion.h1>

                      <p className="f-para1-mobile">
                        The all-in-one, low code digital transformation platform
                        with a human touch
                      </p>
                      <div className="btn-stacke-mobile">
                        <a href="/contact-us#contact_form">
                          <span className="btn-inner-mobile">
                            Contact Us &nbsp;
                            <ArrowBackIcon className="stacke-arrow-mobile" />
                          </span>
                        </a>
                      </div>
                    </motion.div>
                  </div>
                </section>
              </>
            )}
            <div ref={ref} className="main_container_flowise">
              <Possibilities />
              <FlowiseBenifits />

              {window.innerWidth > 1023 ? (
                <section className="Contact-Container_Career">
                  <div className="Contact-Container-inside_Career">
                    <a href="/contact-us#contact_form">
                      <span className="Contact-Button_career">
                        Contact Us &nbsp;
                        <ArrowBackIcon
                          className="Irotating "
                          style={{ fontSize: "7vw" }}
                        />
                      </span>
                    </a>
                  </div>
                </section>
              ) : (
                <>
                  <section className="Contact-Container-tablet">
                    <div className="Contact-Container-inside-tablet  ">
                      <a href="/contact-us#contact_form">
                        <span className="Contact-Button-tablet">
                          Contact Us &nbsp;
                          <ArrowBackIcon
                            className="Irotating-tablet "
                            style={{ fontSize: "7vw" }}
                          />
                        </span>
                      </a>
                    </div>
                  </section>

                  <section className="Contact-Container-mobile">
                    <div className="Contact-Container-inside-mobile">
                      <a href="/contact-us#contact_form">
                        <span className="Contact-Button-mobile">
                          Contact Us &nbsp;
                          <ArrowBackIcon
                            className="Irotating-mobile"
                            style={{ fontSize: "8vw" }}
                          />
                        </span>
                      </a>
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <LoadingSpinner onLoadingComplete={handleLoadingComplete} />
      )}
    </div>
  );
};

export default Flowise;
