import React, { useEffect, useState } from 'react';
import "./TaaS.scss";
import { Helmet } from 'react-helmet';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { Breadcrumbs,Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { motion } from 'framer-motion';
import TaaSBenefits from '../../Components/Taas/TaaSBenefits';
import WhatWeOffer from '../../Components/Taas/WhatWeOffer';
import TaaSSlider from '../../Components/Taas/TaaSSlider';
import { Padding } from '@mui/icons-material';
import TaaSTypes from '../../Components/Taas/TaaSTypes';
import AtEvenion from '../../Components/Taas/AtEvenion';

 

const TaaS = () => {
    const [isLoading, setIsLoading] = useState(false);

    
    const handleLoadingComplete = () => {
        setIsLoading(true);
      };

      useEffect(() => {
        window.scrollTo(0, localStorage.getItem('position'));
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
        };
        scrollToTop();
        const onBeforeUnload = () => {
            window.scrollTo(0, 0);
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        }, [])

        const MotionContainer ={
            hidden: { opacity: 0.5, y: 95 },
            visible: { opacity: 1, y: 0, scale: 1, 
            transition:{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

            hiddenH: { x: -225, y:54, scale: 0.6 },
            visibleH: { x: 0, y:0, scale: 1, 
            transition:{ duration: 1, delay: 0.25,  ease: [0, 0.71, 0.2, 1.01]}},

            hiddenHM: { x: -65, y:15, scale: 0.7 },
            visibleHM: { x: 0, y:0, scale: 1, 
            transition:{ duration: 0.55,  ease: [0, 0.71, 0.2, 1.01] }},

            hiddenvideoM: { y: 55, opacity: 0.5 },
            visiblevideoM: {y: 0, opacity: 1, 
            transition:{ duration: 0.50,  ease: [0, 0.71, 0.2, 1.01] }},
            
        }    

    return (
        <div>
            <Helmet>
                <title> TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies</title>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies" />
                <meta name="description" content="TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies" />
                <meta property="description" content="TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies" />
                <meta property="og:description" content="TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies" />
                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies" />
                <meta name="twitter:description" content="TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies" />
                <meta name="twitter:image" content="TaaS Services - Providing services like software product engineering, quality assurance, quality engineering, advisory and consulting for both manual and automated testing for web and mobile projects, have domain expertise in a number of industries, and can ensure continuous QA support at each stage of software development. - Evenion Technologies" />
            </Helmet> 
            {isLoading ? 

                <>
                    <div className='Main_Taas_services' id='Main_Taas_Page'>
        <>
        <section className='Taas_banner'>
                <motion.img loading='lazy' 
                className="blur"  
                        src="/Images/taas/Image 44@2x.png"alt="about-us" />
                            <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
                            <div className='breadcrumb_text'><a href="/">Home</a></div>
                            <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Services</Typography>
                            <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Testing-as -a-Service</Typography>
                            </Breadcrumbs>
                        
                        <div className='inner_Taas'>
                <motion.div 
                    viewport={{  once: true}}
                    variants={MotionContainer}
                    initial="hidden"
                    whileInView="visible"
                    className='inner_Taas1'
                    >
                        <motion.h1
                            viewport={{  once: true}}
                            variants={MotionContainer}
                            initial="hiddenH"
                            whileInView="visibleH"   
                        >TaaS</motion.h1>
                        <h2>Testing As A Service</h2>
                        <p className='Pre-para1'>Elevate Your Testing  Efforts with Us</p>
                        <p className='Pre-para2'>Empowers organizations to enhance testing efficiency and quality by outsourcing testing activities to expert service providers.</p>
                        <div className="btn-container btn-container-tablet">
                            <div className='btn-lii btn-lii-tablet'>
                                <a
                                    href="/contact-us#contact_form"><span className='btn-liil btn-liil-tablet'>Contact Us  &nbsp; <ArrowBackIcon className="rotatee rotatee-tablet"/></span>
                                </a>
                            </div>
                        </div>
                </motion.div>            
                    </div>
        </section>

        {/* Mobile */}
        <section className='Taas_banner_mobile'>
        <motion.img loading='lazy' className="blur"   
                src="/Images/taas/banner_taas_mobile.png" alt="about-us" />      
                <div className='inner_Taas_mobile'>
                <motion.div 
                        viewport={{once: true}}
                        variants={MotionContainer}
                        initial="hiddenvideoM"
                        animate="visiblevideoM"
                    >
                        <motion.h1
                                variants={MotionContainer}
                                initial="hiddenHM"
                                animate="visibleHM"
                        >TaaS</motion.h1>
                        <h2>Testing As A Service</h2>
                         <p className='Pre-para1'>Elevate Your Testing <br/> Efforts with Us</p>
                        <p className='Pre-para2'>Empowers organizations to enhance testing efficiency and quality by outsourcing testing activities to expert service providers.</p>
                        <div className="btn-stacke-mobile ">
                                    <a
                                        href="/contact-us#contact_form"><span className='btn-inner-mobile'>Contact Us  &nbsp;<ArrowBackIcon className="stacke-arrow-mobile"/></span>
                                    </a>
                            </div>
                </motion.div>            
                    </div>
        </section>
        </>


<div className='Slide-Taas'>

<TaaSBenefits />
<WhatWeOffer />
<TaaSSlider />
<TaaSTypes />
<AtEvenion />

</div>
                    </div>
                </>
           : <LoadingSpinner onLoadingComplete={handleLoadingComplete}/> }
        </div>
    )
}

export default TaaS;
