import React, { useEffect, useRef, useState } from "react";
import { Button, Row, Col, Container, ModalBody, Modal } from "reactstrap";
import axios from "axios";
import moment from "moment";
import { useInView, motion } from "framer-motion";
import { RemoveFromQueue, TroubleshootRounded } from "@mui/icons-material";
import Lottie from "react-lottie";
import * as mailAnimation from "../../jsonFiles/mail_send.json";

const FlowiseBenifits = (props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <>
      <motion.section
        style={{ paddingTop: "0%" }}
        className="Our-Approach Our-Approach-mobile Our-Approach-tablet Our-Approach-Ex-Container col-md-12"
      >
        <motion.div
          viewport={{ ref, once: true }}
          variants={{
            hidden: { y: 50, opacity: 0.9, scale: 0.6 },
            visible: { y: 0, opacity: 1, scale: 1 },
          }}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 1 }}
        >
          <motion.h1
            style={{ paddingTop: "5%" }}
            viewport={{ ref, once: false }}
            variants={{
              hidden: { y: 40, opacity: 0.9 },
              visible: { y: 0, opacity: 1 },
            }}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 1, delay: 0.1 }}
            className="benifit_h1 col-md-12"
          >
            Benefits
          </motion.h1>
        </motion.div>
        {/* </motion.div> */}

        <motion.section
          className="joblist-container"
          viewport={{ ref, once: false }}
          variants={{
            hidden: { y: 20 },
            visible: { y: 0 },
          }}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 1, delay: 0.25 }}
        >
          <motion.div
            className="jobList_career jobList_career-mobile"
            viewport={{ ref, once: true }}
            variants={{
              hidden: { y: 100, scale: 0.9, opacity: 0.5 },
              visible: { y: 0, scale: 1, opacity: 1 },
            }}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.75 }}
          >
            {window.innerWidth > 767 ? (
              <>
                <Row className="benifit_row">
                  <Col
                    style={{
                      padding: "2%",
                      paddingRight: "4%",
                      paddingLeft: "3%",
                    }}
                  >
                    <Row>
                      <motion.div
                        className="benifit_head"
                        viewport={{ ref, once: false }}
                        variants={{
                          hidden: { y: 20 },
                          visible: { y: 0 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.5 }}
                      >
                        Dynamic flows with human intervention
                      </motion.div>
                    </Row>
                    <Row>
                      <motion.div
                        viewport={{ ref, once: false }}
                        className="benifit_para"
                        variants={{
                          hidden: { y: 20 },
                          visible: { y: 0 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 2, delay: 0.5 }}
                      >
                        Digital flows become more extensive every day. IoT,
                        human intervention, complex redirections. Because your
                        flows require a high degree of dynamics to be truly
                        effective, Flowize enables you to easily incorporate the
                        actions, reactions, landingpages and thoughtful steps
                        your flows need – even when online and offline meet, and
                        with the ability to deal with human intervention.
                      </motion.div>
                    </Row>
                  </Col>

                  <Col className="apply__col">
                    <motion.img
                      src="\Images\partnership\benifit1.png"
                      className="benifits_img"
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    />
                  </Col>
                </Row>

                <Row className="benifit_row">
                  <Col className="apply__col">
                    <motion.img
                      src="\Images\partnership\benifits2.png"
                      style={{ objectFit: "contain", width: "80%" }}
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    />
                  </Col>
                  <Col
                    style={{
                      padding: "2%",
                      paddingRight: "4%",
                      paddingLeft: "3%",
                    }}
                  >
                    <Row>
                      <motion.div
                        className="benifit_head"
                        viewport={{ ref, once: false }}
                        variants={{
                          hidden: { y: 20 },
                          visible: { y: 0 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 2, delay: 0.5 }}
                      >
                        Fast time to market with low code
                      </motion.div>
                    </Row>
                    <Row>
                      <motion.div
                        viewport={{ ref, once: false }}
                        className="benifit_para"
                        variants={{
                          hidden: { y: 20 },
                          visible: { y: 0 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 2, delay: 0.5 }}
                      >
                        Our easy-to-use, low code platform enables you to
                        develop and visualize your complete processes quickly.
                        Building a new feature, or restructuring existing
                        features? No more long-term development projects, but
                        pragmatic & effective actions. Flowize provides all the
                        tools to realize a fast time to market.
                      </motion.div>
                    </Row>
                  </Col>
                </Row>
                <Row className="benifit_row">
                  <Col
                    style={{
                      padding: "2%",
                      paddingRight: "4%",
                      paddingLeft: "3%",
                    }}
                  >
                    <Row>
                      <motion.div
                        className="benifit_head"
                        viewport={{ ref, once: false }}
                        variants={{
                          hidden: { y: 20 },
                          visible: { y: 0 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 2, delay: 0.5 }}
                      >
                        Safe data storage with secure hosting
                      </motion.div>
                    </Row>
                    <Row>
                      <motion.div
                        viewport={{ ref, once: false }}
                        className="benifit_para"
                        variants={{
                          hidden: { y: 20 },
                          visible: { y: 0 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 2, delay: 0.5 }}
                      >
                        The more data you control, the more insights you gain.
                        Flowize lets you store and combine customer
                        preferences-data, history/archive, additional customer
                        data, etc, from multiple data sources, channels and
                        databases Flowize provides secure Private Cloud Hosting
                        on a location of your choosing and release management
                        according to the accepted DTAP (Development, Testing,
                        Acceptance, Production) approach.
                      </motion.div>
                    </Row>
                  </Col>
                  <Col className="apply__col">
                    <motion.img
                      src="\Images\partnership\benifits3.png"
                      className="benifits_img"
                      style={{ paddingBottom: "3%" }}
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="benifit_row">
                  <Row style={{ justifyContent: "center" }}>
                    <motion.img
                      src="\Images\partnership\benifit1.png"
                      className="benifits_img"
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    />
                  </Row>
                  <Row>
                    <motion.div
                      className="benifit_head"
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    >
                      Dynamic flows with human intervention
                    </motion.div>
                  </Row>
                  <Row>
                    <motion.div
                      viewport={{ ref, once: false }}
                      className="benifit_para"
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    >
                      Digital flows become more extensive every day. IoT, human
                      intervention, complex redirections. Because your flows
                      require a high degree of dynamics to be truly effective,
                      Flowize enables you to easily incorporate the actions,
                      reactions, landingpages and thoughtful steps your flows
                      need – even when online and offline meet, and with the
                      ability to deal with human intervention.
                    </motion.div>
                  </Row>
                </Row>
                <Row className="benifit_row">
                  <Row style={{ justifyContent: "center" }}>
                    <motion.img
                      src="\Images\partnership\benifits2.png"
                      style={{
                        objectFit: "contain",
                        width: "80%",
                        paddingBottom: "5%",
                      }}
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    />
                  </Row>
                  <Row>
                    <motion.div
                      className="benifit_head"
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    >
                      Fast time to market with low code
                    </motion.div>
                  </Row>
                  <Row>
                    <motion.div
                      viewport={{ ref, once: false }}
                      className="benifit_para"
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    >
                      Our easy-to-use, low code platform enables you to develop
                      and visualize your complete processes quickly. Building a
                      new feature, or restructuring existing features? No more
                      long-term development projects, but pragmatic & effective
                      actions. Flowize provides all the tools to realize a fast
                      time to market.
                    </motion.div>
                  </Row>
                </Row>
                <Row className="benifit_row">
                  <Row style={{ justifyContent: "center" }}>
                    <motion.img
                      src="\Images\partnership\benifits3.png"
                      className="benifits_img"
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    />
                  </Row>
                  <Row>
                    <motion.div
                      className="benifit_head"
                      viewport={{ ref, once: false }}
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    >
                      Safe data storage with secure hosting
                    </motion.div>
                  </Row>
                  <Row>
                    <motion.div
                      viewport={{ ref, once: false }}
                      className="benifit_para"
                      variants={{
                        hidden: { y: 20 },
                        visible: { y: 0 },
                      }}
                      initial="hidden"
                      whileInView="visible"
                      transition={{ duration: 2, delay: 0.5 }}
                    >
                      The more data you control, the more insights you gain.
                      Flowize lets you store and combine customer
                      preferences-data, history/archive, additional customer
                      data, etc, from multiple data sources, channels and
                      databases Flowize provides secure Private Cloud Hosting on
                      a location of your choosing and release management
                      according to the accepted DTAP (Development, Testing,
                      Acceptance, Production) approach.
                    </motion.div>
                  </Row>
                </Row>
              </>
            )}
          </motion.div>
        </motion.section>
      </motion.section>
    </>
  );
};

export default FlowiseBenifits;
