import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "./OurPackagesNew.css";


const ourPackagesNew = () => {
    return (
        <div className="our_packages_new">
            <div className="our_packages_content_new">
                <Row className="m-0 package_title">
                    <Col className="p-0">
                        <div className="section_main_heading heading-left"><span className="heading_img_1"><span className="orange_code">O</span>ur <span className="orange_code">P</span>ackages</span> <img loading='lazy' className="image_1" src="/Images/new/heading_logo_2.png" alt="heading_img" /></div>
                        <div className="section_sub_heading heading-left">From Websites to Mobile Apps &amp; Complex Server Software</div>
                        <div className="section_sub_text collaboraion_sub_text heading-left">Lets Develop your <span className="orange_code">Digital Transformation</span></div>
                        <div className="section_sub_heading section_sub_heading_1 heading-left">Every project is unique, but this is how we start each of them - “tell us everything”. Where you started, where you want to go next, what you value, who are your customers and why they care about you. Because we care about you and your business, our approach includes innovative strategy, transparent communication and new-age design thinking that goes beyond the ordinary.</div>
                    </Col>
                </Row>
                    <Container className="ourpackage_box_container">
                    <Row className="m-0">
                        <Col md={4} className="package_space_in_mob">
                            <div className="package_container">
                                <div className="package_img"><img loading='lazy' className="" src="/Images/new/package_new_1_1.png" alt="heading_img" />
                                    <div className="package_content">
                                        <div className="package_head">Startup Optimized</div>
                                        <div className="package_para"><img loading='lazy' className="image_middle" src="/Images/new/tick_circle_1.svg" alt="img" /> Create a pitch deck &amp; focus on the valuable project discovery phase deliverables that attract investor attention</div>
                                        <div className="package_para"><img loading='lazy' className="image_middle" src="/Images/new/tick_circle_1.svg" alt="img" /> A great way to kick off the software product development journey as a startup</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className="package_space_in_mob">
                            <div className="package_container">
                                <div className="package_img"><img loading='lazy' className="" src="/Images/new/package_new_1_2.png" alt="heading_img" />
                                    <div className="package_content">
                                        <div className="package_head white_color">Custom</div>
                                        <div className="package_para white_color"><img loading='lazy' className="image_middle" src="/Images/new/tick_circle_1.svg" alt="img" /> A custom project discovery process that will enable you to immediately engage with the  developers and start working</div>
                                        <div className="package_para white_color"><img loading='lazy' className="image_middle" src="/Images/new/tick_circle_1.svg" alt="img" /> It is ideal for enterprise solutions and companies that want to invest more, proactively, in their idea before starting development</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className="package_space_in_mob">
                            <div className="package_container">
                                <div className="package_img"><img loading='lazy' className="" src="/Images/new/package_new_1_1.png" alt="heading_img" />
                                    <div className="package_content">
                                        <div className="package_head">All-Inclusive</div>
                                        <div className="package_para"><img loading='lazy' className="image_middle" src="/Images/new/tick_circle_1.svg" alt="img" /> After the project discovery phase, work with Evenion on the full development cycle, including :</div>
                                        <div className="package_para package_text">&#8226; Design</div>
                                        <div className="package_para package_text">&#8226; Development</div>
                                        <div className="package_para package_text">&#8226; Testing and more</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Container className="our_package_banner digital_our_banner">
                        <Row>
                            <Col md={12} className="our_pacakge_banner_desk">
                                <div className="services_img_1"><img loading='lazy' src="/Images/new/contact_banner_large.svg" alt="logo" /></div>
                                <div className="digital_banner_img_caption">
                                    <div className="caption_heading">INTERESTED IN JOINING <span className="caption_heading_1">OUR TEAM</span></div>
                                    <div className="caption_link digi_link"><a href="mailto:careers@eveniontechnologies.com">careers@eveniontechnologies.com</a></div>
                                </div>
                                <div className="digital_banner_contact_caption">
                                    <div className="banner_btn_1 our_btn_digital"><a href="/contact-us#contact_form">Contact Us</a></div>
                                </div>
                            </Col>
                            <Col md={4} className="our_pacakge_banner_mob">
                                <div className="services_img_1">
                                    <img loading='lazy' src="/Images/new/banner_industry.png" alt="logo" />

                                    <div className="benefit_banner">
                                        <div className="caption_heading">INTERESTED IN JOINING <span className="caption_heading_1 caption_heading_mob_1">OUR TEAM</span></div>
                                        <div className="caption_link caption_link_mob"><a href="mailto:careers@eveniontechnologies.com">careers@eveniontechnologies.com</a></div>
                                    </div>
                                    <div className="benefit_banner_contact">
                                        <div className="banner_btn_1 banner_btn_mob"><a href="/contact-us#contact_us_form_mob">Contact Us</a></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </div>
    )
}

export default ourPackagesNew;