export default function validateInfo(values){
    let errors = {};

    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regex = /^[a-zA-Z\s]*$/;
    const phoneNumberRegex = /^(?=.{5,20}$)[+\-\s()0-9]+$/;
    const phoneNumberRegex1 = /^\+(?:[0-9]+[-\s()]?)+$/;
    const phoneNumberRegex2 = /^[\d+\s()-]+$/;
    
    if(!values.name){
        errors.name = "Name Required"
    }else if (!isNaN(values.name)){
        errors.name = "Name should be in text";
    }else if(!regex.test(values.name)){
        errors.name = "Name should be in only text";
    }

    if(!values.email){
        errors.email = "Email Required";
    }else if(!reg.test(values.email)){
        errors.email = "Invalid Email";
    }

    if(!values.phone){
        errors.phone = "Phone Number is Required";
    }else if (!phoneNumberRegex2.test(values.phone)){
        errors.phone = "Should be Number";
    }else if (values.phone.length<5){
        errors.phone = "Phone Must minimum of 5 digits";
    }else if (values.phone.length>20){
        errors.phone = "Phone Must be maximum of 20 digits";
    }

    if(!values.companyname){
        errors.companyname = "Current Company Name Required";
    }
    if(!values.message){
        errors.message = "Description Required";
    }
    
    return errors;
}