import  { useState, React } from 'react'
import './BrandAssociation.scss'
import { motion } from 'framer-motion';
import ImageSwitcher from './ImageSwitcher';

const BrandAssociation = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const MotionContainer ={
    hidden: { opacity: 0.5, y: 200, scale: 0.98, },
    visible: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1,   delay: 0.15,}},
    
    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP1: { y: 35,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP2: { y: 20,  opacity: 0.9 },
    visibleP2: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP3: { y: 15,  opacity: 0.9 },
    visibleP3: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1,   delay: 0.15,}},
    
    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    
}    

  return (
    <>

    <section className='Main-BrandAssciation'>
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"
        className='brand-content'>
            <motion.h1
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenH1"
                whileInView="visibleH1"
            className='head'>Brand Association</motion.h1>
            <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP1"
                whileInView="visibleP1"
            className='para1'>We help empower, enable & optimize solutions across all industries</motion.p>
            <motion.p 
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenP2"
                whileInView="visibleP2"
            className='para2'><span className='orange_code'> OUR RELATIONSHIPS</span> CREATE A LASTING IMPACT ON <br></br> PEOPLE & THEIR<span className='orange_code'> LIVES EVERYDAY</span> </motion.p>
        </motion.div>

    {/* desktop container */}
        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hidden"
            whileInView="visible"
        className='imgTable'>
        <table>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='thImg'><img src='/Images/Home/1.png'  /></th>
            <th className='thImg'><img src='/Images/Home/2.png'  /></th>
            <th className='thImg'><img src='/Images/Home/3.png'  /></th>
            <th className='thImg'><img src='/Images/Home/4.png'  /></th>
            <th className='thImg'><img src='/Images/Home/5.png'  /></th>
            <th className='thImg'><img src='/Images/Home/6.png'  /></th>
        </motion.tr>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='thImg'><img src='/Images/Home/7.png' /></th>
            <th className='thImg'><img src='/Images/Home/8.png' /></th>
            <th className='thImg'><img src='/Images/Home/9.png' /></th>
            <th className='thImg'><img src='/Images/Home/10.png'  /></th>
            <th className='thImg'><img src='/Images/Home/11.png'  /></th>
            <th className='thImg'><img src='/Images/Home/12.png'  /></th>
        </motion.tr>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='thImg'><img src='/Images/Home/13.png'  /></th>
            <th className='thImg'><img src='/Images/Home/14.png'  /></th>
            <th className='thImg'><img src='/Images/Home/15.png'  /></th>
            <th className='thImg'><img src='/Images/Home/16.png'  /></th>
            <th className='thImg'><img src='/Images/Home/17.png'  /></th>
            <th className='thImg'><img src='/Images/Home/18.png'  /></th>
        </motion.tr>
        </table>
        </motion.div>


{/* Desktop 1024 to 1399 */}
        <motion.div 
    viewport={{  once: true}}
    variants={MotionContainer}
    initial="hidden"
    whileInView="visible"
className='imgTable-Desk'>
        <table>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='thImg'><img src="/Images/Home/1.png"  /></th>
            <th className='thImg'><img src="/Images/Home/2.png"  /></th>
            <th className='thImg'><img src='/Images/Home/3.png'  /></th>
            <th className='thImg'><img src='/Images/Home/4.png'  /></th>
            <th className='thImg'><img src='/Images/Home/5.png'  /></th>
            <th className='thImg'><img src="/Images/Home/6.png"  /></th>
        </motion.tr>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='thImg'><img src='/Images/Home/7.png' /></th>
            <th className='thImg'><img src='/Images/Home/8.png' /></th>
            <th className='thImg'><img src='/Images/Home/9.png' /></th>
            <th className='thImg'><img src='/Images/Home/10.png'  /></th>
            <th className='thImg'><img src='/Images/Home/11.png'  /></th>
            <th className='thImg'><img src='/Images/Home/12.png'  /></th>
        </motion.tr>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='thImg'><img src='/Images/Home/13.png'  /></th>
            <th className='thImg'><img src='/Images/Home/14.png'  /></th>
            <th className='thImg'><img src='/Images/Home/15.png'  /></th>
            <th className='thImg'><img src='/Images/Home/16.png'  /></th>
            <th className='thImg'><img src='/Images/Home/17.png'  /></th>
            <th className='thImg'><img src='/Images/Home/18.png'  /></th>
        </motion.tr>
        </table>
        </motion.div>

    {/* Tablet container */}
        <motion.div 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hidden"
        whileInView="visible"
    className='imgTable-tab'>
        <table>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='brand-Tablet'><img    src="/Images/Home/1.png"  alt="deafaultimg"     /></th>
            <th className='brand-Tablet'><img    src="/Images/Home/2.png"  alt="deafaultimg"     /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/3.png'  alt="deafaultimg"     /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/4.png'  alt="deafaultimg"     /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/5.png'  alt="deafaultimg"     /></th>
            <th className='brand-Tablet'><img    src="/Images/Home/6.png"  alt="deafaultimg"     /></th>
        </motion.tr>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='brand-Tablet'><img    src='/Images/Home/7.png'  alt="deafaultimg"     /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/8.png'  alt="deafaultimg"     /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/9.png'  alt="deafaultimg"     /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/10.png' alt="deafaultimg"      /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/11.png' alt="deafaultimg"      /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/12.png' alt="deafaultimg"      /></th>
        </motion.tr>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className='brand-Tablet'><img    src='/Images/Home/13.png' alt="deafaultimg"    /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/14.png' alt="deafaultimg"    /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/15.png' alt="deafaultimg"    /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/16.png' alt="deafaultimg"    /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/17.png' alt="deafaultimg"    /></th>
            <th className='brand-Tablet'><img    src='/Images/Home/18.png' alt="deafaultimg"    /></th>
        </motion.tr>
        </table>
        </motion.div>
    </section>


{/* Mobile */}
    <section className='Brand-Mobile'>
    <motion.div 
        viewport={{  once: true}}
        variants={MotionContainer}
        initial="hiddenMob"
        whileInView="visibleMob"
    className='brand-content-Mobile'>
            <motion.h1
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobH1"
                whileInView="visibleMobH1"
            className='head'>Brand <br></br> Association</motion.h1>
            <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
            className='para1'>We help empower, enable & optimize solutions across all industries</motion.p>
            <motion.p
                viewport={{  once: false}}
                variants={MotionContainer}
                initial="hiddenMobP1"
                whileInView="visibleMobP1"
            className='para2'><span className='orange_code'> OUR RELATIONSHIPS</span>  CREATE A LASTING IMPACT ON  PEOPLE & THEIR<span className='orange_code'> LIVES EVERYDAY</span> </motion.p>
        </motion.div>

        <motion.div 
            viewport={{  once: true}}
            variants={MotionContainer}
            initial="hiddenMob"
            whileInView="visibleMob"
        className='imgTable-Mobile'>
        <table>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className="Brand-img-img"><img    src="/Images/Home/1.png" alt="01" /></th>
            <th className="Brand-img-img"><img    src="/Images/Home/2.png" alt="02" /></th>
            <th className="Brand-img-img"><img    src='/Images/Home/3.png' alt="03" /></th>
        </motion.tr>
         <motion.tr
         
         >
            <th className="Brand-img-img"><img    src='/Images/Home/4.png' alt="04" /></th>
            <th className="Brand-img-img"><img    src='/Images/Home/5.png' alt="05" /></th>
            <th className="Brand-img-img"><img    src="/Images/Home/6.png" alt="06" /></th>
        </motion.tr>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th className="Brand-img-img"><img    src='/Images/Home/7.png' alt="07" /></th>
            <th className="Brand-img-img"><img    src='/Images/Home/8.png' alt="08" /></th>
            <th className="Brand-img-img"><img    src='/Images/Home/9.png' alt="09" /></th>
            </motion.tr>
         <motion.tr
                viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
         >
            <th className="Brand-img-img"><img    src='/Images/Home/10.png'alt="10"  /></th>
            <th className="Brand-img-img"><img    src='/Images/Home/11.png'alt="11"  /></th>
            <th className="Brand-img-img"><img    src='/Images/Home/12.png'alt="12"  /></th>
        </motion.tr>
        <motion.tr
            viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
        >
            <th  className="Brand-img-img"><img    src='/Images/Home/13.png'alt="13" /></th>
            <th  className="Brand-img-img"><img    src='/Images/Home/14.png'alt="14" /></th>
            <th  className="Brand-img-img"><img    src='/Images/Home/15.png'alt="15" /></th>
            </motion.tr>
         <motion.tr
                viewport={{  once: false}}
            variants={MotionContainer}
            initial="hiddenP3"
            whileInView="visibleP3"
         >
            <th  className="Brand-img-img"><img    src='/Images/Home/16.png'alt="16" /></th>
            <th  className="Brand-img-img"><img    src='/Images/Home/17.png'alt="17" /></th>
            <th  className="Brand-img-img"><img    src='/Images/Home/18.png'alt="18" /></th>
        </motion.tr>
        </table>
        </motion.div>
    </section>
    <div className='semi-circle-animate'></div>

    </>
  )
}

export default BrandAssociation