import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import './Policy.css'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import axios from "axios"
import { Breadcrumbs,Typography } from '@mui/material';

const Policy = () => {
    const [policyList, setPolicyList] = useState();
    const navigate = useNavigate();

    const getPolicy = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/employee_handbook`)
            .then(response => {
                setPolicyList(response.data.data[0]);
            })
            .catch(error => {
                console.log("Error => ", error);
            });
    }

    useEffect(() => {
        getPolicy();
    }, [])


        const getSinglePolicys = (data) => {
            navigate(`/employee_policies/${data.slug}/`);
        }
    

    return (
        <>
            <Helmet>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Digital transformation consulting and Services Company - Evenion Technologies" />
                <meta name="description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="og:description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta property="og:url" content="https://www.eveniontechnologies.com/home" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image"
                    content="https://www.eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <meta property="og:image:secure_url" content="https://www.eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Digital transformation consulting and Services Company - Evenion Technologies" />
                <meta name="twitter:description" content="End-to-End Digital Transformation strategies and solutions to help enterprises in Financial Services, Telecom, Life Science, Healthcare and Product Engineering services" />
                <meta name="twitter:image" content="https://www.eveniontechnologies.com/Images/logo/evenion_logo.png" />
                <script type="application/ld+json">
                    {`{
                        "@context": "Schema.org - Schema.org 
                        "@type": "Organization",
                        "name": "Evenion Technologies",
                        "url": "https://www.eveniontechnologies.com/", 
                        "logo": "https://www.eveniontechnologies.com/Images/logo/evenion_logo.png", 
                        "sameAs": [
                        "https://facebook.com/eveniontechnologies/",
                        "https://www.linkedin.com/company/evenion-technologies/",
                        "https://twitter.com/EvenionTech/"
                        ]
                    }`
                    }
                </script>
            </Helmet>
            {window.innerWidth>480?
            <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new'>
            <div className='breadcrumb_text'><a href="/">Home</a></div>
            <Typography color="#0F326C" fontFamily=' space-Grotesksb' fontWeight='300' >Employee Policies</Typography>
            </Breadcrumbs>
            : ""}
            {/* <div className="breadcrumb_container">
                <div className="breadcrumb_content">
                    <div className="breadcrumb_links">
                        <div className="link"><a href="/">Home</a></div>
                        <div className="active_now">Employee Policies</div>
                    </div>
                </div>
            </div> */}
            <Container fluid="xxl" className="p-0 m-0" style={{marginTop:10}}>
                <section className="page-content policy_container_main">
                    <div className="container-fluid contact-policy policy_container">
                        <div className="row p-0">
                            <div className="card w-100">
                                <div className="card-body">
                                        <h1 className="align p-3">Employee Policies </h1>
                                        <div className="row list_container">
                                            <div className="col-md-6">
                                                <ul className='sub_class_policy'>
                                                    <h3 className="size_1">Policies</h3>
                                                    {policyList && policyList.map(ele => (
                                                        <>
                                                            {ele.status === 'Active' &&
                                                                <li className="children" onClick={() => getSinglePolicys(ele)} >{ele.policy_title}</li>
                                                            }
                                                        </>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}

export default Policy;