import React from 'react'
import { motion,    useInView,  } from 'framer-motion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const siliconIndia = () => {
  const MotionCard = {
    hidden: { opacity: 0.5, y: 50,  },
    visible: { opacity: 1, y: 0, 
    transition: { duration: 1, delay: 0.25,  }},

    hiddenMobile: { opacity: 0.5, y: 50,  },
    visibleMobile: { opacity: 1, y: 0, 
    transition: { duration: 1, delay: 0.25,  }},
  }




  const handleClick1 = () => {
    window.open('https://consultants.siliconindia.com/ranking/digital-transformation-consultants-2023-rid-1834.html', '_blank');
  };
  const handleClick2 = () => {
    window.open('https://aloa.co/blog/health-app-developers', '_blank');
  };
  const handleClick3 = () => {
    window.open('https://www.facebook.com/watch/?v=340167646932739', '_blank');
  };
  const handleClick4 = () => {
    window.open('https://www.flowize.com/flowize-worldwide/partners/evenion-technologies/', '_blank');
  };
  return (
    <>

<motion.div  
      viewport={{  once: true}}
      variants={MotionCard}
      initial="hidden"
      whileInView="visible"
     className="image-container">
      <div onClick={handleClick2}>
            <img loading='lazy'  className='Borderr' src="/Images/ContactUs/banner_digital_services_2.2.png" alt="top10" /> 
      <div className="contentimg">
      <img loading='lazy' src="/Images/ContactUs/aloa-ico.png" alt="Health" />
        <p className='silicon-para1'>Evenion listed in Top 7 Health App Developers: Industry Leaders - 2024</p>
        <div className='contact-Readmore'>  
          <a href="/contact-us#contact_form"><span className='btn-liil-mobile'>Read more <ArrowBackIcon className='rotateSilicon' /> </span></a>
        </div>
      </div>
      </div>
    </motion.div>

    <motion.div  
      viewport={{  once: true}}
      variants={MotionCard}
      initial="hidden"
      whileInView="visible"
    className="image-container">
      <div onClick={handleClick4}>
      <img loading='lazy' className='Borderr' src="/Images/new/bannerpartnership.png" alt="" />
      <div className="contentimg">
      <img loading='lazy' src="/Images/ContactUs/flowizelogo.png" alt="" />
        <p className='silicon-para1'>Evenion and Flowize enter into worldwide partnership</p>
        <div className='contact-Readmore'>  
          <a href="/contact-us#contact_form"><span className='btn-liil-mobile'>Read more <ArrowBackIcon className='rotateSilicon' /> </span></a>
        </div>
      </div>
      </div>
    </motion.div>
    <motion.div  
      viewport={{  once: true}}
      variants={MotionCard}
      initial="hidden"
      whileInView="visible"
    className="image-container">
      <div onClick={handleClick1}>
      <img loading='lazy'   src="/Images/ContactUs/banner.png" alt="" />
      <div className="contentimg">
      <img loading='lazy' src="/Images/ContactUs/siliconIndia_logo.svg" alt="" />
        <p className='silicon-para1'>Evenion listed in Top 10 promising Digital Transformation Consultants - 2023</p>
        <div className='contact-Readmore'>  
          <a href="/contact-us#contact_form"><span className='btn-liil-mobile'>Read more <ArrowBackIcon className='rotateSilicon' /> </span></a>
        </div>
      </div>
      </div>
    </motion.div>




    

    <motion.div  
    viewport={{  once: true}}
    variants={MotionCard}
    initial="hiddenMobile"
    whileInView="visibleMobile"
     className="image-container-mobile">
      <div onClick={handleClick2} >
      <img loading='lazy'className='borderr-Mobile' src="/Images/ContactUs/banner_digital_services_2.2.png" alt="" />
      <div className="contentimg-mobile">
      <img loading='lazy' src="/Images/ContactUs/aloa-ico.png" alt="" />
        <p className='silicon-para1-mobile'>Evenion listed in Top 7 Health App Developers: Industry Leaders - 2024</p>
        <div className='contact-Readmore-mobile'>  
          <a href="/contact-us#contact_form"><span className='btn-contact-mobile'>Read more <ArrowBackIcon className='rotateSilicon-mobile'/></span></a>
        </div>
      </div>
      </div>
    </motion.div>

    <motion.div  
    viewport={{  once: true}}
    variants={MotionCard}
    initial="hiddenMobile"
    whileInView="visibleMobile"
     className="image-container-mobile">
      <div onClick={handleClick4} >
      <img loading='lazy'className='borderr-Mobile' src="/Images/new/bannerpartnership.png" alt="" />
      <div className="contentimg-mobile">
      <img loading='lazy' src="/Images/ContactUs/flowizelogo.png" alt="" />
        <p className='silicon-para1-mobile'>Evenion and Flowize enter into worldwide partnership</p>
        <div className='contact-Readmore-mobile'>  
          <a href="/contact-us#contact_form"><span className='btn-contact-mobile'>Read more <ArrowBackIcon className='rotateSilicon-mobile'/></span></a>
        </div>
      </div>
      </div>
    </motion.div>
    <motion.div  
    viewport={{  once: true}}
    variants={MotionCard}
    initial="hiddenMobile"
    whileInView="visibleMobile"
    className="image-container-mobile">
      <div onClick={handleClick1}>
      <img loading='lazy'   src="/Images/ContactUs/banner.png" alt="" />
      <div className="contentimg-mobile">
      <img loading='lazy' src="/Images/ContactUs/siliconIndia_logo.svg" alt="" />
        <p className='silicon-para1-mobile'>Evenion listed in Top 10 promising Digital Transformation Consultants - 2023</p>
        <div className='contact-Readmore-mobile'>  
          <a href="/contact-us#contact_form"><span className='btn-contact-mobile'>Read more <ArrowBackIcon className='rotateSilicon-mobile'/></span></a>
        </div>
      </div>
      </div>
    </motion.div>

    <motion.div  
    viewport={{  once: true}}
    variants={MotionCard}
    initial="hiddenMobile"
    whileInView="visibleMobile"
     className="image-container-mobile">
      <div onClick={handleClick3} >
      <img loading='lazy'className='borderr-Mobile' src="/Images/Home/Mask Group 245.png" alt="" />
      <div className="contentimg-mobile">
      <img loading='lazy' src="/Images/ContactUs/startupicon.png" alt="" />
        <p className='silicon-para1-mobile'>Elevate 2019 finalists Evenion Solutions speak about their technology at the finals.</p>
        <div className='contact-Readmore-mobile'>  
          <a href="/contact-us#contact_form"><span className='btn-contact-mobile'>Read more <ArrowBackIcon className='rotateSilicon-mobile'/></span></a>
        </div>
      </div>
      </div>
    </motion.div>

    <motion.div  
    viewport={{  once: true}}
    variants={MotionCard}
    initial="hidden"
    whileInView="visible"
    className="image-container">
      <div onClick={handleClick3}>
      <img loading='lazy'  className='Borderr'   src="/Images/Home/Mask Group 245.png" alt="" />
      <div className="contentimg">
      <img loading='lazy' src="/Images/ContactUs/startupicon.png" alt="" />
        <p className='silicon-para1'>Elevate 2019 finalists Evenion Solutions speak about their technology at the finals.</p>
        <div className='contact-Readmore'>  
          <a href="/contact-us#contact_form"><span className='btn-contact'>Read more <ArrowBackIcon className='rotateSilicon'/></span></a>
        </div>
      </div>
      </div>
    </motion.div>
    </>
  )
}

export default siliconIndia