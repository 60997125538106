import React, { useEffect, useState } from "react";
// import './Products.css';
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import "./Precisely.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { motion } from "framer-motion";
import { Breadcrumbs, Typography } from "@mui/material";
import PreciselyCard from "../../Components/Precisely/PreciselyCard";
import AboutPrecisely from "../../Components/Precisely/AboutPrecisely";

const Precisely = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadingComplete = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    window.scrollTo(0, localStorage.getItem("position"));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
    const onBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", onBeforeUnload);
  }, []);

  const MotionContainer = {
    hidden: { opacity: 0.5, y: 95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] },
    },


    hiddenH: { x: -183, y: 65, scale: 0.7 },
    visibleH: {
      x: 0,
      y: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenHM: { x: -40, y: 10, scale: 0.8 },
    visibleHM: {
      x: 0,
      y: 0,
      scale: 1,
      transition: { duration: 0.50, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenvideoM: { y: 80, opacity: 0.5 },
    visiblevideoM: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.50, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenCC: { y: 100, scale: 0.9, opacity: 0.5 },
    visibleCC: { y: 0, scale: 1, opacity: 1, transition: { duration: 1 } },

    hiddenPara: { y: 35 },
    visiblePara: { y: 0, transition: { duration: 0.75 } },
  
    hiddenMob: { opacity: 0.5, y: 100, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 0.50, delay: 0.15, }},
        
    hiddenMobimg: { y:35,  opacity: 0.5},
    visibleMobimg: { y:0,  opacity: 1,
    transition:{ duration: 0.55, delay: 0.10,}},

    hiddenMobH1: { y: 30,  opacity: 0.9 },
    visibleMobH1: { y: 0, opacity: 1, 
    transition:{ duration: 0.50, },},

    hiddenMobP1: { y: 25,  opacity: 0.9 },
    visibleMobP1: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},

    hiddenMobP2: { y: 20,  opacity: 0.9 },
    visibleMobP2: { y: 0, opacity: 1,
    transition:{ duration: 0.50, }},
  };

  return (
    <div>
      <Helmet>
        <title>Precisely Automate partner with Evenion - precisely</title>
        <meta
          name="description"
          content="Precisely Automate partner  with Evenion - precisely"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="the platform - flowize" />
        <meta
          property="og:title"
          content="Precisely Automate partner  with Evenion - precisely"
        />
        <meta
          property="description"
          content="Precisely’s solution for Automate Evolve is an automation platform designed to digitize strategic data processes for product launches, customer/vendor onboarding and more and Automate  Studio is an Excel-to-SAP solution platform that enables business users to automate complex SAP business processes to make mass data changes easily"
        />
        <meta
          property="og:description"
          content="Precisely’s solution for Automate Evolve is an automation platform designed to digitize strategic data processes for product launches, customer/vendor onboarding and more and Automate  Studio is an Excel-to-SAP solution platform that enables business users to automate complex SAP business processes to make mass data changes easily"
        />
        <meta
          property="og:url"
          content="Digital Transformation and Consulting Services Company - Evenion Technologies"
        />
        <meta
          property="og:site_name"
          content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation"
        />
        <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
        <meta
          property="og:image"
          content="Precisely Automate partner  with Evenion - precisely"
        />
        <meta
          property="og:image:secure_url"
          content="Digital Transformation and Consulting Services Company - Evenion Technologies"
        />
        <meta property="og:image:width" content="630" />
        <meta property="og:image:height" content="420" />
        <meta property="og:image:alt" content="Evenion" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="article:published_time"
          content="2020-11-25GMT+000012:40:02+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-08-29GMT+000014:49:23+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Precisely Automate partner  with Evenion - precisely"
        />
        <meta
          name="twitter:description"
          content="For Powerful Possibilities Partner With Evenion"
        />
        <meta
          name="twitter:image"
          content="Precisely Automate partner  with Evenion - precisely"
        />
      </Helmet>
      {isLoading ? (
        <>
          {/* <Products /> */}
          <div className="Main_Precisely" id="Main_Precisely_Page">
            <section className="precisely_banner">
              <motion.img
                className="blur "
                src="/Images/precisely/Group 3717@2x.png"
                alt="about-us"
              />
              <Breadcrumbs
                aria-label="breadcrumb"
                separator=">>"
                className="breadcrumb_container_new"
                style={{ top: 0 }}
              >
                <div className="breadcrumb_text">
                  <a href="/">Home</a>
                </div>
                <Typography
                  color="#0F326C"
                  fontFamily=" space-Grotesksb"
                  fontWeight="300"
                >
                  Partnership
                </Typography>
                <Typography
                  color="#0F326C"
                  fontFamily=" space-Grotesksb"
                  fontWeight="300"
                >
                  Precisely
                </Typography>
              </Breadcrumbs>

              <div className="inner_Precise">
                <motion.div
                  viewport={{ once: true }}
                  variants={MotionContainer}
                  initial="hidden"
                  whileInView="visible"
                >
                  <motion.h1
                    viewport={{ once: true }}
                    variants={MotionContainer}
                    initial="hiddenH"
                    whileInView="visibleH"
                  >
                    Precisely
                  </motion.h1>
                  <p className="Pre-para1">Better Data. Better Decisions</p>
                  <p className="Pre-para2">
                    Build your possibilities on data you can trust
                  </p>
                  <div className="btn-container btn-container-tablet">
                    <div className="btn-lii btn-lii-tablet">
                      <a href="/contact-us#contact_form">
                        <span className="btn-liil btn-liil-tablet">
                          Contact Us &nbsp;{" "}
                          <ArrowBackIcon className="rotatee rotatee-tablet" />
                        </span>
                      </a>
                    </div>
                  </div>
                </motion.div>
              </div>
            </section>


{/* Mobile */}
            <section className="precisely_banner-mobile">
              <motion.img
                className="blur "
                src="/Images/precisely/mobile-banner.png"
                alt="about-us"
              />

              <div className="inner_Precise-mobile">
                <motion.div
                  viewport={{ once: true }}
                  variants={MotionContainer}
                  initial="hiddenvideoM"
                  whileInView="visiblevideoM"
                >
                  <motion.h1
                    variants={MotionContainer}
                    initial="hiddenHM"
                    animate="visibleHM"
                  >
                    Precisely
                  </motion.h1>
                  <p className="Pre-para1">Better Data. Better Decisions</p>
                  <p className="Pre-para2">
                    Build your possibilities on<br></br> data you can trust
                  </p>
                  <div className="btn-stacke-mobile">
                    <a href="/contact-us#contact_form">
                      <span className="btn-inner-mobile">
                        Contact Us &nbsp;
                        <ArrowBackIcon className="stacke-arrow-mobile" />
                      </span>
                    </a>
                  </div>
                </motion.div>
              </div>
            </section>

            <div className="Slide-Precisely">
              <section className="Precisely_content">
                <motion.div
                  viewport={{ once: true }}
                  variants={MotionContainer}
                  initial="hiddenCC"
                  whileInView="visibleCC"
                  className="Precisely_content_inside"
                >
                  <div className="precise-image">
                    <img
                      src="Images/precisely/Mask_Group_104-removebg-preview@2x.png"
                      alt=""
                    />
                  </div>
                  <motion.div
                    viewport={{ once: true }}
                    variants={MotionContainer}
                    initial="hiddenMob"
                    whileInView="visibleMob"
                    className="precise-Content"
                  >
                    <motion.p
                      viewport={{ once: false }}
                      variants={MotionContainer}
                      initial="hiddenHeading"
                      whileInView="visibleHeading"
                      className="Content-para1"
                    >
                      Business Decisions With Data
                    </motion.p>
                    <motion.p
                      viewport={{ once: false }}
                      variants={MotionContainer}
                      initial="hiddenPara"
                      whileInView="visiblePara"
                      className="Content-para2"
                    >
                      Precisely leads globally in data integrity, offering
                      precision, uniformity, and meaning to information for over
                      12,000 clients in 100+ nations, including 99 Fortune 100
                      companies. Their solutions in data integration, quality,
                      governance, location insights, and enrichment drive
                      improved business choices and outcomes. A data world
                      innovator, Precisely serves as a comprehensive hub for all
                      data-related tasks like extraction, storage, cleansing,
                      mining, and visualization
                    </motion.p>
                  </motion.div>
                </motion.div>
              </section>

{/* Mobile */}
              <section className="Precisely_content-mobile">
                <div className="Precisely_content_inside_mobile">
                  <motion.div
                    viewport={{ once: false }}
                    variants={MotionContainer}
                    initial="hiddenMobimg"
                    whileInView="visibleMobimg"
                    className="precise-image-mobile"
                  >
                    <img
                      src="Images/precisely/Mask_Group_104-removebg-preview@2x.png"
                      alt=""
                    />
                  </motion.div>
                  <motion.div
                    viewport={{ once: true }}
                    variants={MotionContainer}
                    initial="hiddenMob"
                    whileInView="visibleMob"
                    className="precise-Content-mobile"
                  >
                    <motion.p
                      viewport={{ once: false }}
                      variants={MotionContainer}
                      initial="hiddenMobP1"
                      whileInView="visibleMobP1"
                      className="Content-para1"
                    >
                      Business Decisions With Data
                    </motion.p>
                    <motion.p
                      viewport={{ once: false }}
                      variants={MotionContainer}
                      initial="hiddenMobP2"
                      whileInView="visibleMobP2"
                      className="Content-para2"
                    >
                      Precisely leads globally in data integrity, offering
                      precision, uniformity, and meaning to information for over
                      12,000 clients in 100+ nations, including 99 Fortune 100
                      companies. Their solutions in data integration, quality,
                      governance, location insights, and enrichment drive
                      improved business choices and outcomes. A data world
                      innovator, Precisely serves as a comprehensive hub for all
                      data-related tasks like extraction, storage, cleansing,
                      mining, and visualization
                    </motion.p>
                  </motion.div>
                </div>
              </section>
              <PreciselyCard />
              <AboutPrecisely />
              <section className="Contact-Container">
                <div className="Contact-Container-inside  ">
                  <a href="/contact-us#contact_form">
                    <span className="Contact-Button  ">
                      Contact Us &nbsp;
                      <ArrowBackIcon
                        className="Irotating "
                        style={{ fontSize: "7vw" }}
                      />
                    </span>
                  </a>
                </div>
              </section>

              <section className="Contact-Container-tablet">
                <div className="Contact-Container-inside-tablet  ">
                  <a href="/contact-us#contact_form">
                    <span className="Contact-Button-tablet">
                      Contact Us &nbsp;
                      <ArrowBackIcon
                        className="Irotating-tablet "
                        style={{ fontSize: "7vw" }}
                      />
                    </span>
                  </a>
                </div>
              </section>

              <section className="Contact-Container-mobile">
                <div className="Contact-Container-inside-mobile">
                  <a href="/contact-us#contact_form">
                    <span className="Contact-Button-mobile">
                      Contact Us &nbsp;
                      <ArrowBackIcon
                        className="Irotating-mobile"
                        style={{ fontSize: "8vw" }}
                      />
                    </span>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <LoadingSpinner onLoadingComplete={handleLoadingComplete} />
      )}
    </div>
  );
};

export default Precisely;
