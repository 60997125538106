import React, { useEffect, useState } from "react";
import "./Professional.scss";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { Breadcrumbs, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { motion } from "framer-motion";
import OurOfferings from "../../Components/ProfessionalServices/OurOfferings";
import HowItWorks from "../../Components/ProfessionalServices/HowItWorks";
import WhyUs from "../../Components/ProfessionalServices/WhyUs";

const Professional = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadingComplete = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    window.scrollTo(0, localStorage.getItem("position"));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
    const onBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("beforeunload", onBeforeUnload);
  }, []);

  const MotionContainer = {
    hidden: { opacity: 0.5, y: 95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenH: { x: -320, y: 57, scale: 0.6 },
    visibleH: {
      x: 0,
      y: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.25, ease: [0, 0.71, 0.2, 1.01] },
    },

    hiddenHM: { x: -55, y: 15, scale: 0.7 },
    visibleHM: {
      x: 0, y: 0, scale: 1,
      transition: { duration: 0.55,  ease: [0, 0.71, 0.2, 1.01] }
    },

    hiddenvideoM: { y: 55, opacity: 0.5 },
    visiblevideoM: {
        y: 0, opacity: 1,
        transition: { duration: 0.50,  ease: [0, 0.71, 0.2, 1.01] }
    },



  };
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Professional Services - Add Value through Sustenance &amp; Innovation
          that put your Users First Now &amp; Always - Evenion Technologies
        </title>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Professional Services - Add Value through Sustenance &amp; Innovation that put your Users First Now &amp; Always - Evenion Technologies"
        />
        <meta
          name="description"
          content="Professional Services - Add Value through Sustenance &amp; Innovation that put your Users First Now &amp; Always - Evenion Technologies"
        />
        <meta
          property="description"
          content="Professional Services - Add Value through Sustenance &amp; Innovation that put your Users First Now &amp; Always - Evenion Technologies"
        />
        <meta
          property="og:description"
          content="Professional Services - Add Value through Sustenance &amp; Innovation that put your Users First Now &amp; Always - Evenion Technologies"
        />
        <meta
          property="og:url"
          content="Digital Transformation and Consulting Services Company - Evenion Technologies"
        />
        <meta
          property="og:site_name"
          content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation"
        />
        <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
        <meta
          property="og:image"
          content="Professional Services - Add Value through Sustenance &amp; Innovation that put your Users First Now &amp; Always - Evenion Technologies"
        />
        <meta
          property="og:image:secure_url"
          content="Digital Transformation and Consulting Services Company - Evenion Technologies"
        />
        <meta property="og:image:width" content="630" />
        <meta property="og:image:height" content="420" />
        <meta property="og:image:alt" content="Evenion" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="article:published_time"
          content="2020-11-25GMT+000012:40:02+00:00"
        />
        <meta
          property="article:modified_time"
          content="2021-08-29GMT+000014:49:23+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Professional Services - Add Value through Sustenance &amp; Innovation that put your Users First Now &amp; Always - Evenion Technologies"
        />
        <meta
          name="twitter:description"
          content="Professional Services - Add Value through Sustenance &amp; Innovation that put your Users First Now &amp; Always - Evenion Technologies"
        />
        <meta
          name="twitter:image"
          content="Professional Services - Add Value through Sustenance &amp; Innovation that put your Users First Now &amp; Always - Evenion Technologies"
        />
      </Helmet>
      {isLoading ? (
        <>
          <div className="Main_Professional_services" id="Main_Precisely_Page">
            <section className="Professional_banner">
              <motion.img
                className="blur "
                src="/Images/Proffesional_Services/prfoessional_page.jpg"
                alt="about-us"
              />
              <Breadcrumbs
                aria-label="breadcrumb"
                separator=">>"
                className="breadcrumb_container_new"
                style={{ top: 0 }}
              >
                <div className="breadcrumb_text">
                  <a href="/">Home</a>
                </div>
                <Typography
                  color="#0F326C"
                  fontFamily="space-Grotesksb"
                  fontWeight="300"
                >
                  Services
                </Typography>
                <Typography
                  color="#0F326C"
                  fontFamily="space-Grotesksb"
                  fontWeight="300"
                >
                  Professional Services
                </Typography>
              </Breadcrumbs>

              <div className="inner_Professional">
                <motion.div
                  viewport={{ once: true }}
                  variants={MotionContainer}
                  initial="hidden"
                  whileInView="visible"
                >
                  <motion.h1
                    viewport={{ once: true }}
                    variants={MotionContainer}
                    initial="hiddenH"
                    whileInView="visibleH"
                  >
                    Professional Services
                  </motion.h1>
                  <p className="Pre-para1">Scale, Secure, Perform</p>
                  <p className="Pre-para2">
                    Add Value through Sustenance & Innovation <br></br> that put
                    your Users First Now & Always
                  </p>
                  <div className="btn-container btn-container-tablet">
                    <div className="btn-lii btn-lii-tablet">
                      <a href="/contact-us#contact_form">
                        <span className="btn-liil btn-liil-tablet">
                          Contact Us &nbsp;{" "}
                          <ArrowBackIcon className="rotatee rotatee-tablet" />
                        </span>
                      </a>
                    </div>
                  </div>
                </motion.div>
              </div>
            </section>

{/* mobile */}
            <section className="proffesional_banner-mobile">
              <motion.img
                className="blur "
                src="/Images/Proffesional_Services/Mask Group 110.png"
                alt="about-us"
              />
              <div className="inner_proffesional-mobile">
                <motion.div
                  viewport={{ once: true }}
                  variants={MotionContainer}
                  initial="hiddenvideoM"
                  animate="visiblevideoM"
                >
                  <motion.h1
                    variants={MotionContainer}
                    initial="hiddenHM"
                    animate="visibleHM"
                  >
                    Professional <br></br> Services
                  </motion.h1>
                  <p className="Pre-para1">Scale, Secure, Perform</p>
                  <p className="Pre-para2">
                    Add Value through <br></br>Sustenance & Innovation <br></br>{" "}
                    that put your Users First Now & Always
                  </p>
                  <div className="btn-stacke-mobile">
                    <a href="/contact-us#contact_form">
                      <span className="btn-inner-mobile">
                        Contact Us &nbsp;
                        <ArrowBackIcon className="stacke-arrow-mobile" />
                      </span>
                    </a>
                  </div>
                </motion.div>
              </div>
            </section>

            <div className="Slide-PProfessional">
              <OurOfferings />
              <HowItWorks />
              <WhyUs />

              <section
                className="Contact-Container"
                style={{ backgroundColor: "#001220" }}
              >
                <div className="Contact-Container-inside  ">
                  <a href="/contact-us#contact_form">
                    <span className="Contact-Button  ">
                      Contact Us &nbsp;
                      <ArrowBackIcon
                        className="Irotating "
                        style={{ fontSize: "7vw" }}
                      />
                    </span>
                  </a>
                </div>
              </section>

              <section
                className="Contact-Container-tablet"
                style={{ backgroundColor: "#001220" }}
              >
                <div className="Contact-Container-inside-tablet  ">
                  <a href="/contact-us#contact_form">
                    <span className="Contact-Button-tablet">
                      Contact Us &nbsp;
                      <ArrowBackIcon
                        className="Irotating-tablet "
                        style={{ fontSize: "7vw" }}
                      />
                    </span>
                  </a>
                </div>
              </section>

              <section
                className="Contact-Container-mobile"
                style={{ backgroundColor: "#001220" }}
              >
                <div className="Contact-Container-inside-mobile">
                  <a href="/contact-us#contact_form">
                    <span className="Contact-Button-mobile">
                      Contact Us &nbsp;
                      <ArrowBackIcon
                        className="Irotating-mobile"
                        style={{ fontSize: "8vw" }}
                      />
                    </span>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <LoadingSpinner onLoadingComplete={handleLoadingComplete} />
      )}
    </div>
  );
};

export default Professional;
