import React from 'react'
import './UseCases.scss'
import { motion } from 'framer-motion';


const UseCases = () => {
  const MotionContainer ={
    hidden: { y: 250, scale: 0.7, opacity: 0.1 },
    visible: { y: 0, scale: 1, opacity: 1 ,
    transition:{ duration: 1,}},

    hiddenHead: { y: 35, scale: 0.9, },
    visibleHead: { y: 0, scale: 1,
    transition:{ duration: 1,  }},

    hiddenPara: { y: 30,  },
    visiblePara: { y: 0 ,
    transition:{ duration: 1,  }},

    }



  return (
    <>
    <motion.section 
      viewport={{  once: true}}
      variants={MotionContainer}
      initial="hidden"
      whileInView="visible"  
    className='UseCases-Main1'>
    <motion.h1
      viewport={{  once: false}}
      variants={MotionContainer}
      initial="hiddenHead"
      whileInView="visibleHead"  
    >Use Cases</motion.h1>
    <motion.p
      viewport={{  once: false}}
      variants={MotionContainer}
      initial="hiddenPara"
      whileInView="visiblePara"  
    >Unlock the full potential of DataSenseX across various industries and processes, saving time, resources, and costs. Experience unparalleled automation and efficiency in your business operations today</motion.p>
    </motion.section>
    </>
  )
}

export default UseCases