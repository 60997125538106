import React, { useEffect, useState } from 'react';
import './Services.css';
import CollaborationModel from '../../Components/CollaborationModel/CollaborationModel';
import BenefitsModel from '../../Components/BenefitsModel/BenefitsModel';
import OurPackagesNew from '../../Components/OurPackagesNew/OurPackagesNew';
import Clients from '../../Components/Clients/Clients';
import CommonNeedsDigi from '../../Components/CommonNeedsDigi/CommonNeedsDigi';
import { Helmet } from 'react-helmet';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { Breadcrumbs, Typography } from '@mui/material';

const Services = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleLoadingComplete = () => {
        setIsLoading(true);
      };

    useEffect(() => {

    }, [])
    return (
        <div>
            <Helmet>
                <title>Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies</title>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies" />
                <meta property="og:description" content="Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies" />
                <meta name="description" content="Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies" />
                <meta property="description" content="Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies" />
                <meta property="og:url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:site_name" content="Evenion Technologies - Consulting, Data, Analytics, Digital Transformation" />
                <meta property="og:updated_time" content="2021-08-29T14:49:23+00:00" />
                <meta property="og:image" content="Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies" />
                <meta property="og:image:secure_url" content="Digital Transformation and Consulting Services Company - Evenion Technologies" />
                <meta property="og:image:width" content="630" />
                <meta property="og:image:height" content="420" />
                <meta property="og:image:alt" content="Evenion" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="article:published_time" content="2020-11-25GMT+000012:40:02+00:00" />
                <meta property="article:modified_time" content="2021-08-29GMT+000014:49:23+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies" />
                <meta name="twitter:description" content="Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies" />
                <meta name="twitter:image" content="Digital Services -Agile Processes &amp; Modern Tech Stack Experience for Accelerated Innovations - Evenion Technologies" />
            </Helmet>

            {isLoading ?
                <>
                    <div className="project-services-background-style digital-banner">
                        {window.innerWidth > 768 ?
                            <div className="banner-inner">
                                <img loading='lazy' src="/Images/new/digitalservicesnew1.svg" alt="" />
                                <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
                                    <div className='breadcrumb_text'><a href="/">Home</a></div>
                                    <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Services</Typography>
                                    <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Digital Services</Typography>
                                    </Breadcrumbs>
                                <div className="home_page_caption_inner">
                                    <h1>Digital Services</h1>
                                    <div className="prof_sub_heading"><span className="white_color">The NEXT</span> <span className="animate"></span></div>
                                    <p>Agile Processes &amp; Modern Tech Stack<br />Experience for Accelerated Innovations</p>
                                    <div className="contactbtn py-4"><a href="/contact-us#contact_form">Contact Us</a></div>
                                </div>
                            </div> :
                            <div className="banner-inner-mob digital">
                                <div className="mob_banner_head">
                                    <h1>Digital Services</h1>
                                </div>
                                <img loading='lazy' className="mob_banner" src="/Images/new/mobile_banner_digital.png" alt="" />
                                {window.innerWidth>480?
                                <Breadcrumbs aria-label="breadcrumb" separator=">>" className='breadcrumb_container_new' style={{top:0}}>
                                    <div className='breadcrumb_text'><a href="/">Home</a></div>
                                    <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Services</Typography>
                                    <Typography color="#0F326C" fontFamily='space-Grotesksb' fontWeight='300' >Digital Services</Typography>
                                    </Breadcrumbs>
                               : "" }
                                <div className="mob_banner_sub_head">
                                    <div className="prof_sub_heading"><span className="white_color">The NEXT</span> <span className="animate"></span></div>
                                    <p>Agile Processes &amp; Modern Tech Stack<br />Experience for Accelerated Innovations</p>
                                    <div className="mob_contactbtn"><a href="/contact-us#contact_us_form_mob">Contact Us</a></div>
                                </div>
                            </div>
                        }
                    </div>
                    <CommonNeedsDigi />
                    <CollaborationModel />
                    <BenefitsModel />
                    <OurPackagesNew />
                    <div className="clients_section_digi">
                        <Clients />
                    </div>
                </>
           :  <LoadingSpinner onLoadingComplete={handleLoadingComplete}/>  }
        </div>
    )
}

export default Services;