import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './productmanagement.css';
import { useNavigate } from 'react-router';
import dateFormat from 'dateformat';
import { useReadingTime } from "react-hook-reading-time";
import { Container, Row, Col } from 'reactstrap';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Productmanagement = ({ blogData }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [noOfElement, setnoOfElement] = useState(12);
    const loadmore = () => {
        setnoOfElement(noOfElement + 6);
    }
    const loadmoremob = () => {
        setnoOfElement(noOfElement + 4);
    }

    const lengths = blogData && blogData.filter(ele => ele.category === 'Product management' && ele.status === "Active").length;


    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [])

    const getSingleBlogs = (data) => {
        navigate(`/blogs/${data.slug}`)
    }
    const GetTime = (data) => {
        const { text } = useReadingTime(data);
        if (text == 'less than a min') {
            return 'Less than 1 min'
        }
        return text;
    }

    return (
        <>
            <div className="main-container-box designer-ux">
                <Row className="box-container">

                    {blogData && blogData.filter(ele => ele.category === 'Product management' && ele.status === "Active")
                        .sort((a, b) => b.published_datetime.localeCompare(a.published_datetime)).slice(0, noOfElement)
                        .map(ele => (
                            <Col md={4} className="box">
                                <div className='box-content'>
                                    <img loading='lazy' className='blogimg' src={`${process.env.REACT_APP_BASE_URL}/${ele.mobile_image}`} onClick={() => getSingleBlogs(ele)}></img>
                                    <div className='box-details'>
                                        <h3 onClick={() => getSingleBlogs(ele)}>{ele.title}</h3>
                                        <div className='hashtag-area'>
                                            <span className='hashtag'>
                                            {window.innerWidth>921?
                                                <>
                                                {ele.short_description.length > 130 ? ele.short_description.substring(0, 130) + "..." : ele.short_description}
                                                </>
                                                :
                                                <>
                                                {window.innerWidth>421?
                                                 <>
                                                {ele.short_description.length > 110 ? ele.short_description.substring(0, 110) + "..." : ele.short_description}
                                                </>    :
                                                <>
                                                {window.innerWidth<359?
                                                 <>
                                                {ele.short_description.length > 70 ? ele.short_description.substring(0, 70) + "..." : ele.short_description}
                                                </>    :
                                                <>
                                                {ele.short_description.length > 90 ? ele.short_description.substring(0, 90) + "..." : ele.short_description}
                                                </>
                                                }
                                                </>
                                                }
                                                </>
                                            }                                            </span><br />
                                        </div>
                                        <div className='blogRow'>
                                            <Col style={{ borderRight: "1px solid rgb(31, 48, 58)", padding: 10, alignSelf: 'center' }}>
                                                <div>
                                                    {ele.category}
                                                </div>
                                            </Col>
                                            <Col style={{ borderRight: "1px solid rgb(31, 48, 58)", padding: 10, alignSelf: 'center' }}>
                                                <div><CalendarMonthIcon style={{ resizeMode: 'contain', width: "17%", marginRight: 5, marginBottom: 3 }} />{dateFormat(ele.published_datetime, 'mmm d')}</div>
                                            </Col>
                                            <Col style={{ borderRight: "1px solid rgb(31, 48, 58)", padding: 10, alignSelf: 'center' }}>
                                                <div> <AccessTimeFilledIcon style={{ resizeMode: 'contain', width: "17%", marginBottom: 4 }} /> {GetTime(ele.description)}</div>
                                            </Col>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        ))

                    }
                </Row>

                {
                    noOfElement < lengths && (

                        <div className='btn_loadmore_container'>
                            {window.innerWidth > 768 ?
                                <div >

                                    <button className='btn_loadmore' onClick={() => loadmore()}> Load More   </button>
                                    <img loading='lazy' src='/Images/new/Icon-awesome-angle-double-down.svg' className='loadmore_icon' onClick={() => loadmore()}></img>
                                </div> :
                                <div className='btn-loadmoremob'>
                                    <button className='btn_loadmore' onClick={() => loadmoremob()}> Load More   </button>
                                    <img loading='lazy' src='/Images/new/Icon-awesome-angle-double-down.svg' className='loadmore_icon' onClick={() => loadmoremob()}></img>
                                </div>
                            }
                        </div>

                    )
                }
            </div>
            <div id="evenionofferings"></div>
        </>
    )
}
export default Productmanagement; 