import React, { useState, useRef } from 'react';
import './AboutUs.scss';
import './OurApproach.scss';
import { motion,    useInView,  } from 'framer-motion';
// import { HandymanOutlined } from '@mui/icons-material';
import { useEffect } from 'react';

const OurApproach = () => {
    const [intervalId, setIntervalId] = useState(null);
    const myButtonRef = useRef(null);
    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };
    const springPhoto = {
        type: "spring",
        stiffness: 700,
        damping: 30,
        duration: 1
    };
    const MotionContainer ={
        hidden: { y: 300, scale: 0.8, opacity: 0.5 },
        visible: { y: 0, scale: 1, opacity: 1 ,
        transition:{ duration: 1, delay: 0.25 }},
    }



    const handleHover = () => {
        if (intervalId !== null) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      };
      const handleLeave = () => {
        if (intervalId === null) {
          const id = setInterval(handleClickChange, 5000); 
          setIntervalId(id);
        }
      };


  const ref = useRef(null)
  const isInView = useInView(ref, {once: false});
  
  const [isActive, setIsActive] = useState(false);
  const handleClickChange = event => {
      setIsActive(current => !current);
            if (document.getElementById("divvv_one").style.display === "none"){
                document.getElementById('divvv_two').style.display = "none";
              document.getElementById('divvv_one').style.display = 'block';
          }
          else {
              document.getElementById('divvv_two').style.display = 'block';
              document.getElementById('divvv_one').style.display = 'none';
          }
  };

// useEffect(() => {
//     startInterval();
//     return () => {
//       stopInterval(); 
//     };
// }, [])
useEffect(() => {
    const id = setInterval(handleClickChange, 5000); 
    setIntervalId(id);
    return () => clearInterval(id);
}, [])
// useEffect(() => {
//     const timeout = setInterval(() => {
//         myButtonRef.current.click();
//       }, 7000);
//         return () => clearTimeout(timeout)
// }, [])

  
  const showhide = () => {
    if (document.getElementById('div_one').style.display == 'none'){
        document.getElementById('div_one').style.display = 'block';
        document.getElementById('div_onee').style.display = 'block';
        document.getElementById('div_two').style.display = 'none';
        document.getElementById('div_twoo').style.display = 'none';
    }
    else {
        document.getElementById('div_one').style.display = 'none';
        document.getElementById('div_onee').style.display = 'none';
        document.getElementById('div_two').style.display = 'block';
        document.getElementById('div_twoo').style.display = 'block';
    }
}
  const showhides = () => {
    if (document.getElementById('divv_one').style.display == 'none'){
        document.getElementById('divv_one').style.display = 'block';
        document.getElementById('divv_onee').style.display = 'block';
        document.getElementById('divv_two').style.display = 'none';
        document.getElementById('divv_twoo').style.display = 'none';
    }
    else {
        document.getElementById('divv_one').style.display = 'none';
        document.getElementById('divv_onee').style.display = 'none';
        document.getElementById('divv_two').style.display = 'block';
        document.getElementById('divv_twoo').style.display = 'block';
    }
}



  return (
<>
<motion.section
                viewport={{ ref, once: true}}
                variants={MotionContainer}
                initial="hidden"
                whileInView="visible"
                className="Our-Approach Our-Approach-mobile Our-Approach-tablet col-md-12">
                    <div className="Our-Approach-Ex-Container Our-Approach-Ex-Container-mobile Our-Approach-Ex-Container-tablet col-md-12">
                <motion.h1 
                variants={{
                    hidden: { y: 40, scale: 0.9, opacity: 0.5 },
                    visible: { y: 0, scale: 1, opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.25,}}
                className='col-md-12' >Our Approach</motion.h1>
                <motion.div 
                    viewport={{ref, once: false}}
                    variants={{
                        hidden: { y: 20, opacity: 0.3 },
                        visible: { y: 0,  opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 1, delay: 0.25,}}
                >
                        <motion.p 
                        viewport={{ref, once: false}}
                        variants={{
                            hidden: { y: 30,  opacity: 0.2 },
                            visible: { y: 0, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.15,  }}
                        className='Our-p col-md-12'>We are growing rapidly - delivering pioneering solutions & services on a global scale </motion.p>
                        <motion.p 
                        viewport={{ ref, once: false}}
                        variants={{
                            hidden: { y: 30,  opacity: 0.2 },
                            visible: { y: 0, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.15,  }} 
                        className='Our-pa col-md-12'>WE DO NOT JUST BUILD,<span className='orange_code'> WE PERFORM </span> </motion.p>
                        <motion.p
                        viewport={{ ref, once: false}}
                        variants={{
                            hidden: { y: 45,  opacity: 0.2 },
                            visible: { y: 0, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.15,  }}
                        className='Our-paa col-md-12'>At Evenion, we want to be your end to end IT Service organization, Big enough to undertake your Largest Project, Yet small enough to maintain the Loyalty and the Intimacy of a botique firm & In turn Contribute greatly to your Success</motion.p>
                </motion.div>
                    </div>
</motion.section>

{/* mobile */}
<motion.section
viewport={{ref, once: true}}
variants={{
    hidden: { y:  230, scale:0.7, opacity: 0.3 },
    visible: { y: 0,scale:1,  opacity: 1 },
}}
initial="hidden"
whileInView="visible"
transition={{ duration: 1, delay: 0.25,}}
className="Our-Toggle col-md-12 col-xs-12">
    <div className='row align-items-center d-flex flex-wrap '>
        <motion.div
        viewport={{ref, once:false}}
        variants={{
            hidden: { y: 40, opacity: 0.5 },
            visible: { y: 0, opacity: 1 },
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.25,}}
        className='col-md-1   order-md-1 order-lg-1 order-xl-1 order-2 order-sm-2   stylee'>
            <div>
                <input className='radio-btn  ' type="radio" id="hide" name="examples" value="hide" defaultChecked={true} onClick={() => showhide()} />
            </div>
            <div>
                <input className='radio-btn ' type="radio" id="show" name="examples" value="show" onClick={() => showhide()} />
            </div>
        </motion.div>
            <motion.div
            viewport={{ref, once:false}}
            variants={{
                hidden: {  y: 50, opacity: 0.3, scale:0.6 },
                visible: { y: 0, opacity: 1, scale:1 },
            }}
            initial="hidden"
            whileInView="visible"
            transition={springPhoto} 
            className='col-md-5   order-md-2 order-lg-2 order-xl-2 order-1 order-sm-1  Our-Toggle-Container-img-mobile' 
            id='div_one' 
            style={{display: "none"}} 
            >
                <span><img loading='lazy' className='Our-Toggle-img Our-Toggle-img-tablet '  src="/Images/new/aboutus_our_approach_curve.jpg" alt="" /></span>
                </motion.div>
                <div
                className='col-md-5  order-md-3 order-lg-3 order-xl-3 order-2 order-sm-2 '  id='div_onee' style={{display: "none"}} >
                    <motion.p 
                    viewport={{ ref, once: false}}
                    variants={{
                        hidden: { y: 45,  opacity: 0.9 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 1, delay: 0.15,  }}
                    className='Our-Toggle-pa' >Staying  <br /> Ahead of Curve</motion.p>
                    <motion.p 
                    viewport={{ ref, once: false}}
                    variants={{
                        hidden: { y: 45,  opacity: 0.9 },
                        visible: { y: 0, opacity: 1 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 1, delay: 0.15,  }}
                    className='Our-Toggle-paa'>
                    Over time, we have ventured into increasingly challenging products /services. We constantly upgrade ourselves so we can stay ahead of the market, stay aligned with the technological developments and hence help our clients leverage the benefits of next-gen-tech.</motion.p>
                </div>
                        <motion.div 
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: {  y: 50, opacity: 0.3, scale:0.6 },
                            visible: { y: 0, opacity: 1, scale:1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={springPhoto} 
                        className='col-md-5  order-md-2 order-lg-2 order-xl-2 order-1  order-sm-1  Our-Toggle-Container-img-mobile'
                        id='div_two'  
                        style={{display: "block"}}
                        >
                        <span >
                        <motion.img loading='lazy' 
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { scale:1 },
                            hoverr: { scale:1.1 },
                            tapp: {scale:0.9}
                        }}
                        initial="hidden"
                        // whileHover="hoverr"
                        whileTap="tapp"
                        transition={spring} 
                        className='Our-Toggle-img '   src="/Images/new/aboutus_our_approach_long_term_relationship.jpg" alt="" /></span>
                        </motion.div>
                        <div className='col-md-5  order-md-3 order-lg-3 order-xl-3 order-2 order-sm-2    stylees'  id='div_twoo' style={{display: "block"}} >
                            <motion.p
                            viewport={{ref, once:false}}
                            variants={{
                                hidden: { y: 50, opacity: 0.2 },
                                visible: { y: 0, opacity: 1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={{ duration: 1, delay: 0.25,}}
                            className='Our-Toggle-pa' >Building Strong  <br />  Long Term Relationships</motion.p>
                            <motion.p 
                            viewport={{ref, once:false}}
                            variants={{
                                hidden: { y: 50, opacity: 0.2 },
                                visible: { y: 0, opacity: 1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={{ duration: 1, delay: 0.25,}}
                            className='Our-Toggle-paa'  >
                                Our team is made up of people who live & breathe technology and for us, it is all about passion, not just a job. we work towards our client's success. Over the years, we have had the opportunity to work for several B2B & B2C SaaS, technology companies within several sectors where we have built  credible relationships & a unique footprint
                            </motion.p>
                        </div>
            </div>
</motion.section>

<motion.section
// onMouseEnter={stopInterval}
// onMouseLeave={startInterval}

// onMouseEnter={handleHover}
// onMouseLeave={handleLeave}
viewport={{ref, once:true}}
variants={{
    hidden: { y: 60, opacity: 0.5, scale:0.7},
    visible: { y: 0, opacity: 1, scale:1 },
}}
initial="hidden"
whileInView="visible"
transition={{duration: 1,}}
// style={{border: "1px solid red"}}
className="toggling">
                <div className='roww1'>
                    <input className='radio-btn  'ref={myButtonRef} type="radio" id="hide" name="radioo" value="hide" defaultChecked={true} onClick={() => handleClickChange()} /><br/>
                    <input className='radio-btn  'ref={myButtonRef} type="radio" id="hide" name="radioo" value="hide"  onClick={() => handleClickChange()} />
                    {/* <input className='radio-btn  'ref={myButtonRef} type="radio" id="hide" name="radioo" value="hide" defaultChecked={true} onClick={() => handleClickChange()} /><br/>
                    <input className='radio-btn  'ref={myButtonRef} type="radio" id="hide" name="radioo" value="hide"  onClick={() => handleClickChange()} /> */}
                </div>
<div className='roww2'  >
    <div  className={isActive ? 'image1' : 'image11'}>
    <img loading='lazy' src="/Images/new/aboutus_our_approach_curve.jpg" alt="" />
    </div>
    <div className={isActive ? 'image2' : 'image22'}>
    <img loading='lazy' src="/Images/new/aboutus_our_approach_long_term_relationship.jpg" alt="" />
    </div>    
</div>


<div className='roww3' 
onMouseEnter={handleHover}
onMouseLeave={handleLeave} >
    <motion.div
    viewport={{ref, once:false}}
    variants={{
        hidden: { y: 50, opacity: 0.2 },
        visible: { y: 0, opacity: 1 },
    }}
    initial="hidden"
    whileInView="visible"
    transition={{ duration: 1,}} 
    className='roww3-inside1' id='divvv_one' style={{display: "none"}}>
    <p className='roww3-inside1-pa' >Building Strong Long Term Relationships</p>
    <p className='roww3-inside1-paa' >Our team is made up of people who live & breathe technology and for us, it is all about passion, not just a job. We work towards our client’s success. Over the years, we have had the opportunity to work for several B2B & B2C SaaS, technology companies within several sectors where we have built strong credible relationships & a unique footprint.</p>
    </motion.div>
    <motion.div 
    viewport={{ref, once:false}}
    variants={{
        hidden: { y: 50, opacity: 0.2 },
        visible: { y: 0, opacity: 1 },
    }}
    initial="hidden"
    whileInView="visible"
    transition={{ duration: 1, }} 
    className='roww3-inside2' id='divvv_two' style={{display: "block"}}>
    <p className='roww3-inside2-pa' >Staying Ahead of Curve</p>
    <p className='roww3-inside2-paa' >Over time, we have ventured into increasingly challenging products /services. We constantly upgrade ourselves so we can stay ahead of the market, stay aligned with the technological developments and hence help our clients leverage the benefits of next-gen-tech.</p>
    </motion.div>
</div>

</motion.section>




<motion.section
style={{border: "1px solid blue"}}
                viewport={{ref, once: true}}
                variants={{
                    hidden: { y:  230, scale:0.7, opacity: 0.3 },
                    visible: { y: 0,scale:1,  opacity: 1 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 1, delay: 0.25,}}
                className="Our-Toggle-tablet col-sm-12 col-md-12 col-xs-12">
                    <div className='row align-items-center d-flex flex-wrap '>
                        <motion.div
                        viewport={{ref, once:true}}
                        variants={{
                            hidden: { y: 40, opacity: 0.5 },
                            visible: { y: 0, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25,}}
                        className='col-md-1 col-sm-1 col-xs-1     stylee'>
                            <div>
                                <input className='radio-btn  ' type="radio" id="hide" name="example" value="hide" checked onClick={() => showhides()} />
                            </div>
                            <div>
                                <input className='radio-btn  ' type="radio" id="show" name="example" value="show" onClick={() => showhides()} />
                            </div>
                        </motion.div>
                            <div
                                className='col-md-5  col-sm-5 col-xs-5   Our-Toggle-Container-img-mobile  ' id='divv_one'  style={{ display: 'none' }}>
                                <span><img loading='lazy' className=' Our-Toggle-img-tablet '  src="/Images/new/aboutus_our_approach_curve.jpg" alt="" /></span>
                                </div>
                                <div
                                className='col-md-5 col-sm-5 col-xs-5  '  id='divv_onee'  style={{ display: 'none' }}>
                                    <motion.p 
                                    viewport={{ ref, once: false}}
                                    variants={{
                                        hidden: { y: 45,  opacity: 0.9 },
                                        visible: { y: 0, opacity: 1 },
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.15,  }}
                                    className='Our-Toggle-pa-tablet   ' >Staying  <br /> Ahead of Curve</motion.p>
                                    <motion.p 
                                    viewport={{ ref, once: false}}
                                    variants={{
                                        hidden: { y: 45,  opacity: 0.9 },
                                        visible: { y: 0, opacity: 1 },
                                    }}
                                    initial="hidden"
                                    whileInView="visible"
                                    transition={{ duration: 1, delay: 0.15,  }}
                                    className='Our-Toggle-paa-tablet'>
                                    Over time, we have ventured into increasingly challenging products /services. We constantly upgrade ourselves so we can stay ahead of the market, stay aligned with the technological developments and hence help our clients leverage the benefits of next-gen-tech.</motion.p>
                                </div>
                                        <div  className='col-md-5 col-sm-5 col-xs-5   Our-Toggle-Container-img-mobile' id='divv_two'  style={{ display: 'block' }}>
                                        <span><img loading='lazy' className='Our-Toggle-img-tablet  ' src="/Images/new/aboutus_our_approach_long_term_relationship.jpg" alt="" /></span>
                                        </div>
                                        <div className='col-md-5 col-sm-5 col-xs-5     stylees'  id='divv_twoo'  style={{ display: 'block' }}>
                                            <motion.p
                                            viewport={{ref, once:false}}
                                            variants={{ 
                                                hidden: { y: 50, opacity: 0.2 },
                                                visible: { y: 0, opacity: 1 },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25,}}
                                            className='Our-Toggle-pa-tablet' >Building Strong  <br />  Long Term Relationships</motion.p>
                                            <motion.p 
                                            viewport={{ref, once:false}}
                                            variants={{
                                                hidden: { y: 50, opacity: 0.2 },
                                                visible: { y: 0, opacity: 1 },
                                            }}
                                            initial="hidden"
                                            whileInView="visible"
                                            transition={{ duration: 1, delay: 0.25,}}
                                            className='Our-Toggle-paa-tablet'  >
                                                Our team is made up of people who live & breathe technology and for us, it is all about passion, not just a job. we work towards our client's success. Over the years, we have had the opportunity to work for several B2B & B2C SaaS, technology companies within several sectors where we have built  credible relationships & a unique footprint
                                            </motion.p>
                                        </div>
                            </div>
</motion.section>
</>
  )
}

export default OurApproach