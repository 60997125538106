import React, { useState, useRef, useEffect } from 'react';
import './OurProduct.scss'
import { motion,    useInView,  } from 'framer-motion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TurnedIn } from '@mui/icons-material';


const OurProduct = () => {

  const [intervalId, setIntervalId] = useState(null);
    const myButtonRef = useRef(null);
    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };
    const springPhoto = {
        type: "spring",
        stiffness: 700,
        damping: 30,
        duration: 1
    };
  const MotionContainer ={
    hidden: { opacity: 0.5, y: 200, scale: 0.98, },
    visible: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1,   delay: 0.15,}},
    
    hiddenH1: { y: 40,  opacity: 0.9 },
    visibleH1: { y: 0, opacity: 1, 
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP1: { y: 35,  opacity: 0.9 },
    visibleP1: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenP2: { y: 20,  opacity: 0.9 },
    visibleP2: { y: 0, opacity: 1,
    transition:{ duration: 1, delay: 0.15,}},

    hiddenMob: { opacity: 0.5, y: 200, scale: 0.98, },
    visibleMob: { opacity: 1, y: 0, scale: 1, 
    transition:{ duration: 1,   delay: 0.15,}},
    
}    


    const handleHover = () => {
        if (intervalId !== null) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      };
      const handleLeave = () => {
        if (intervalId === null) {
          const id = setInterval(handleClickChange, 5000); 
          setIntervalId(id);
        }
      };


  const ref = useRef(null)
  const isInView = useInView(ref, {once: false});
  
  const [isActive, setIsActive] = useState(false);
const handleClickChange = event => {
    setIsActive(current => !current);
  
    const divvvOne = document.getElementById('divvv_one');
    const divvvsOne = document.getElementById('divvvs_one');
    const divvvTwo = document.getElementById('divvv_two');
    const divvvsTwo = document.getElementById('divvvs_two');
  
    if (divvvOne && divvvsOne && divvvTwo && divvvsTwo) {
      if (divvvOne.style.display === 'none' && divvvsOne.style.display === 'none') {
        divvvTwo.style.display = 'none';
        divvvsTwo.style.display = 'none';
        divvvOne.style.display = 'block';
        divvvsOne.style.display = 'block';
      } else {
        divvvTwo.style.display = 'block';
        divvvsTwo.style.display = 'block';
        divvvOne.style.display = 'none';
        divvvsOne.style.display = 'none';
      }
    }
  };
  


useEffect(() => {
    const id = setInterval(handleClickChange, 5000); 
    setIntervalId(id);
    return () => clearInterval(id);
}, [])

  const showhide = () => {
    if (document.getElementById('div_one').style.display == 'none'){
        document.getElementById('div_one').style.display = 'block';
        document.getElementById('div_onee').style.display = 'block';
        document.getElementById('div_two').style.display = 'none';
        document.getElementById('div_twoo').style.display = 'none';
    }
    else {
        document.getElementById('div_one').style.display = 'none';
        document.getElementById('div_onee').style.display = 'none';
        document.getElementById('div_two').style.display = 'block';
        document.getElementById('div_twoo').style.display = 'block';
    }
}
  const showhides = () => {
    if (document.getElementById('divv_one').style.display == 'none'){
        document.getElementById('divv_one').style.display = 'block';
        document.getElementById('divv_onee').style.display = 'block';
        document.getElementById('divv_two').style.display = 'none';
        document.getElementById('divv_twoo').style.display = 'none';
    }
    else {
        document.getElementById('divv_one').style.display = 'none';
        document.getElementById('divv_onee').style.display = 'none';
        document.getElementById('divv_two').style.display = 'block';
        document.getElementById('divv_twoo').style.display = 'block';
    }
}

  return (
<section className='Main-togglingPro'>
  <motion.h1
    viewport={{  once: false}}
    variants={MotionContainer}
    initial="hiddenH1"
    whileInView="visibleH1"
  > Our Products </motion.h1>
  {/* Desktop */}
      <section className="togglingPro">
<div className='rowwPro1'>
    <motion.input 
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1"
    className='radio-btn  'ref={myButtonRef} type="radio" id="hide" name="radioo" value="hide" defaultChecked={true} onClick={() => handleClickChange()} /><br/>
    <motion.input 
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1"
    className='radio-btn  'ref={myButtonRef} type="radio" id="hide" name="radioo" value="hide"  onClick={() => handleClickChange()} />
    
</div>


<div className='rowwPro2'  >
    <div  className={isActive ? 'imagePro1' : 'imagePro11'}>
    <motion.img loading='lazy' 
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1"
    src="/Images/Home/Mask Group 28.png" alt="01" />
    </div>
    <div className={isActive ? 'imagePro2' : 'imagePro22'}>
    <motion.img loading='lazy' 
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1"
    src="/Images/Home/Mask Group 27.png" alt="02" />
    </div>    
</div>


<div className='rowwPro3' 
onMouseEnter={handleHover}
onMouseLeave={handleLeave} >
    <motion.div

    className='rowwPro3-inside1' id='divvv_one' style={{display: "none"}}>
    <div className='rowwPro3-inside1-pa flexCl' >DataSenseX    </div>
    <p className='rowwPro3-inside1-paa' >Revolution data management with our AI-driven platform, automating unstructured data effortlessly. Seamlessly convert chaos into actionable insights</p>
    <a href="/datasensex" className='paddingClass'><div className='learnMore-OurPro'>Learn More <ArrowBackIcon className='LearnArrow-OurPro' /> </div></a>
    </motion.div>
    <motion.div 

    className='rowwPro3-inside2' id='divvv_two' style={{display: "block"}}>
    <div className='rowwPro3-inside2-pa flexCl' >StackéRank  </div>
    <p className='rowwPro3-inside2-paa' >We enable unbiased hiring decisions by showing candidates' job-related tasks, ensuring perfomance-based evaluations for the fair and accurate selections.</p>
    <a href="/stackerank" className='paddingClass'><div className='learnMore-OurPro'>Learn More <ArrowBackIcon className='LearnArrow-OurPro' /> </div></a>
    </motion.div>
</div>

      </section>


      {/* Mobile */}
      <motion.section
                className="Our-Toggle PdCl col-md-12 col-xs-12">
                    <div className='row align-items-center d-flex flex-wrap '>
                        <motion.div
                        viewport={{ref, once:false}}
                        variants={{
                            hidden: { y: 30, opacity: 0.5 },
                            visible: { y: 0, opacity: 1 },
                        }}
                        initial="hidden"
                        whileInView="visible"
                        transition={{ duration: 1, delay: 0.25,}}
                        className='col-md-1   order-md-1 order-lg-1 order-xl-1 order-2 order-sm-2   stylee'>
                            <div>
                                <input className='radio-btn  ' type="radio" id="hide" name="examples" value="hide" defaultChecked={true} onClick={() => showhide()} />
                            </div>
                            <div>
                                <input className='radio-btn ' type="radio" id="show" name="examples" value="show" onClick={() => showhide()} />
                            </div>
                        </motion.div>
                            <motion.div
                            viewport={{ref, once:false}}
                            variants={{
                                hidden: {  y: 30, opacity: 0.3, scale:0.6 },
                                visible: { y: 0, opacity: 1, scale:1 },
                            }}
                            initial="hidden"
                            whileInView="visible"
                            transition={springPhoto} 
                            className='col-md-5   order-md-2 order-lg-2 order-xl-2 order-1 order-sm-1  Our-Toggle-Container-img-mobile' 
                            id='div_one' 
                            style={{display: "none"}} 
                            >
                                <span><img loading='lazy' className='Our-Toggle-img Our-Toggle-img-tablet '  src="/Images/Home/Mask Group 27.png" alt="" /></span>
                                </motion.div>
                                <div
                                className='col-md-5  order-md-3 order-lg-3 order-xl-3 order-2 order-sm-2 '  id='div_onee' style={{display: "none"}} >
                                    <motion.p 
                                        viewport={{  once: false}}
                                        variants={MotionContainer}
                                        initial="hiddenP1"
                                        whileInView="visibleP1"
                                    className='Our-Toggle-pa' >DataSenseX </motion.p>
                                    <motion.p 
                                        viewport={{  once: false}}
                                        variants={MotionContainer}
                                        initial="hiddenP1"
                                        whileInView="visibleP1"
                                    className='Our-Toggle-paa'>
                                    Revolution data management with our AI-driven platform, automating unstructured data effortlessly. Seamlessly convert chaos into actionable insights</motion.p>
                                    <a href="/datasensex" className='paddCl'>
                                        <motion.div 
                                            viewport={{  once: false}}
                                            variants={MotionContainer}
                                            initial="hiddenP1"
                                            whileInView="visibleP1"
                                        className='SerMore'>Learn More <ArrowBackIcon className='SerMoreArrow' /> </motion.div></a>  
                                </div>
                                        <motion.div 
                                        viewport={{ref, once:false}}
                                        variants={{
                                            hidden: {  y: 50, opacity: 0.3, scale:0.6 },
                                            visible: { y: 0, opacity: 1, scale:1 },
                                        }}
                                        initial="hidden"
                                        whileInView="visible"
                                        transition={springPhoto} 
                                        className='col-md-5  order-md-2 order-lg-2 order-xl-2 order-1  order-sm-1  Our-Toggle-Container-img-mobile'
                                        id='div_two'  
                                        style={{display: "block"}}
                                        >
                                        <span >
                                        <motion.img loading='lazy' 
                                        viewport={{ref, once:false}}
                                        variants={{
                                            hidden: { scale:1 },
                                            hoverr: { scale:1.1 },
                                            tapp: {scale:0.9}
                                        }}
                                        initial="hidden"
                                        // whileHover="hoverr"
                                        whileTap="tapp"
                                        transition={spring} 
                                        className='Our-Toggle-img '   src="/Images/Home/Mask Group 28.png" alt="" /></span>
                                        </motion.div>
                                        <div className='col-md-5  order-md-3 order-lg-3 order-xl-3 order-2 order-sm-2    stylees'  id='div_twoo' style={{display: "block"}} >
                                            <motion.p
                                                viewport={{  once: false}}
                                                variants={MotionContainer}
                                                initial="hiddenP1"
                                                whileInView="visibleP1"
                                            className='Our-Toggle-pa' >StackéRank</motion.p>
                                            <motion.p 
                                                viewport={{  once: false}}
                                                variants={MotionContainer}
                                                initial="hiddenP1"
                                                whileInView="visibleP1"
                                            className='Our-Toggle-paa'  >
                                                We enable unbiased hiring decisions by showing candidates' job-related tasks, ensuring perfomance-based evaluations for the fair and accurate selections.
                                            </motion.p>
                                            <a href="/stackerank" className='paddCl'>
                                                <motion.div 
                                                    viewport={{  once: false}}
                                                    variants={MotionContainer}
                                                    initial="hiddenP1"
                                                    whileInView="visibleP1"
                                                className='SerMore'>Learn More <ArrowBackIcon className='SerMoreArrow' /> </motion.div></a>  
                                        </div>
                            </div>
     </motion.section>



     {/* Tablet */}
     
<motion.section

viewport={{ref, once:true}}
variants={{
    hidden: { y: 60, opacity: 0.5, scale:0.7},
    visible: { y: 0, opacity: 1, scale:1 },
}}
initial="hidden"
whileInView="visible"
transition={{duration: 1,}}
className="toggling">
                <div className='roww1'>
                    <motion.input
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    className='radio-btn  'ref={myButtonRef} type="radio" id="hide" name="radioo" value="hide" defaultChecked={true} onClick={() => handleClickChange()} /><br/>
                    <motion.input
                        viewport={{  once: false}}
                        variants={MotionContainer}
                        initial="hiddenP1"
                        whileInView="visibleP1"
                    className='radio-btn  'ref={myButtonRef} type="radio" id="hide" name="radioo" value="hide"  onClick={() => handleClickChange()} />
                    
                </div>
<div className='roww2'  >
    <div  className={isActive ? 'image1' : 'image11'}>
    <motion.img loading='lazy' 
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1"
    src="/Images/Home/Mask Group 28.png" alt="" />
    </div>
    <div className={isActive ? 'image2' : 'image22'}>
    <motion.img loading='lazy' 
        viewport={{  once: false}}
        variants={MotionContainer}
        initial="hiddenP1"
        whileInView="visibleP1"
    src="/Images/Home/Mask Group 27.png" alt="" />
    </div>    
</div>


<div className='roww3' 
onMouseEnter={handleHover}
onMouseLeave={handleLeave} >
    <motion.div
    viewport={{ref, once:false}}
    variants={{
        hidden: { y: 50, opacity: 0.2 },
        visible: { y: 0, opacity: 1 },
    }}
    initial="hidden"
    whileInView="visible"
    transition={{ duration: 1,}} 
    className='roww3-inside1' id='divvvs_one' style={{display: "none"}}>
    <p className='roww3-inside1-pa' >DataSenseX</p>
    <p className='roww3-inside1-paa' >Revolution data management with our AI-driven platform, automating unstructured data effortlessly. Seamlessly convert chaos into actionable insights</p>
    <a href="/datasensex" className='paddCl'><div className='SerMore'>Learn More <ArrowBackIcon className='SerMoreArrow' /> </div></a>  
    </motion.div>
    <motion.div 
    viewport={{ref, once:false}}
    variants={{
        hidden: { y: 50, opacity: 0.2 },
        visible: { y: 0, opacity: 1 },
    }}
    initial="hidden"
    whileInView="visible"
    transition={{ duration: 1, }} 
    className='roww3-inside2' id='divvvs_two' style={{display: "block"}}>
    <p className='roww3-inside2-pa' >StackéRank</p>
    <p className='roww3-inside2-paa' >We enable unbiased hiring decisions by showing candidates' job-related tasks, ensuring perfomance-based evaluations for the fair and accurate selections.</p>
    <a href="/stackerank" className='paddCl'><div className='SerMore'>Learn More <ArrowBackIcon className='SerMoreArrow' /> </div></a>  
    </motion.div>
</div>

</motion.section>


</section>


// 
  )
}

export default OurProduct